import { useTranslation } from 'react-i18next';
import { CountryTypes, TemplateGroupType, TemplateGroupTypes, TemplateType, TemplateTypes, UserRoleTypes } from '../../constants';

export default function TemplateInputs({ templateCountry, isUpdate, templateTypeData, onTemplateTypeChange, templateGroupData }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    templateCountry !== null && {
      type: 'select',
      name: 'templateCountry',
      label: t('Country'),
      placeholder: t('SelectCountry'),
      data: CountryTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'select',
      name: 'type',
      label: t('TemplateType'),
      placeholder: t('SelectTemplateType'),
      rules: [{ required: true, message: t('TemplateTypeEmpty') }],
      data: TemplateTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onTemplateTypeChange,
    },
    templateTypeData !== null && {
      type: 'select',
      name: 'group',
      label: t('TemplateGroup'),
      placeholder: t('SelectTemplateGroup'),
      rules: [{ required: true, message: t('TemplateGroupEmpty') }],
      data: TemplateGroupTypes.filter((x) => templateTypeData.data.groups.some((s) => s === x.value)).map((x) => ({ ...x, text: t(x.displayName) })),
    },
    templateTypeData?.value === TemplateType.UserInvite && {
      type: 'select',
      name: 'userRole',
      label: t('UserRole'),
      placeholder: t('SelectUserRole'),
      data: UserRoleTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    isUpdate && {
      type: templateGroupData?.value === TemplateGroupType.SmsTemplate ? 'textarea' : 'editor',
      name: 'content',
      label: t('TemplateContent'),
      rules: [{ required: false, message: t('TemplateContentEmpty') }],
      data: TemplateGroupTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      columnSize: 24,
      height: 400,
    },
  ];
}
