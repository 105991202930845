export const getApiUrl = () => {
  let apiUrl = process.env.REACT_APP_API_URL;
  let appUrl = process.env.REACT_APP_URL;
  let host = window.location.hostname;
  let protocol = window.location.protocol;

  let env = host.split('.')[0];
  switch (env) {
    case 'localhost':
      return `${protocol}//${apiUrl}`;
    case 'dev':
    case 'test':
    case 'staging':
    case 'preprod':
    case 'prod':
      return `${protocol}//${env}.api.arge.${appUrl}`;
    default:
      return `${protocol}//${apiUrl}`;
  }
};

export const getStorageUrl = () => {
  let url = process.env.REACT_APP_STORAGE_URL;
  let host = window.location.hostname;

  let env = host.split('.')[0];
  switch (env) {
    case 'localhost':
      return `${url}/development`;
    case 'dev':
    case 'test':
    case 'staging':
    case 'preprod':
      return `${url}/${env}`;
    default:
      return `${url}/global`;
  }
};
