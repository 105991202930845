import { Tooltip } from 'antd';
import moment from 'moment';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHandHoldingMedical, FaHeart, FaHospitalUser, FaVideo } from 'react-icons/fa';
import { format } from 'react-string-format';
import styled, { css } from 'styled-components';
import { ActionType, DrugType, TreatmentUsageStatusType } from '../../../constants';
import { treatmentUsageStatusUpdate } from '../../../services/api';
import { Button, Calendar, Modal } from '../../globals';

export default function JourneyCalendar({ parameter, response }) {
  // Definitions
  const { t } = useTranslation();
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [rowTreatment, setRowTreatment] = useState(null);

  // Events
  const treatmentUsageClick = (row) => {
    if (moment() > moment(row.time) && parameter.isMyData) {
      setRowTreatment(row);
      setPanelVisibility(true);
    }
  };

  const treatmentUseClick = async (status) => {
    rowTreatment.status = status;

    await treatmentUsageStatusUpdate(rowTreatment, (status, res) => {
      if (status) {
        setPanelVisibility(false);
      }
    });
  };

  // Components
  const dateCellRender = (value) => {
    if (!response) return;

    let vamses = response.vams?.data && response.vams.data.filter((x) => value.isSame(x.modificationTime, 'day'));
    let treatments = response.treatment?.data && response.treatment.data.filter((x) => value.isSame(x.time, 'day'));
    let visits = response.visit?.data && response.visit.data.filter((x) => value.isSame(x.date, 'day'));
    let conferences = response.conference?.data && response.conference.data.filter((x) => value.isSame(x.date, 'day'));

    return (
      <Events>
        {response.user.actions.some((a) => a === ActionType.VisitList) &&
          visits?.map((x, i) => (
            <Event key={`event-visit-${i}`}>
              <Tooltip placement="topLeft" title={format(t('VisitStatus'), t(x.reasonData.displayName))}>
                <VisitEvent>
                  <FaHospitalUser />
                  <Title>{t(x.reasonData.displayName)}</Title>
                </VisitEvent>
              </Tooltip>
            </Event>
          ))}

        {response.user.actions.some((a) => a === ActionType.ConferenceList) &&
          conferences?.map((x, i) => (
            <Event key={`event-conference-${i}`}>
              <Tooltip placement="topLeft" title={format(t('EventStatus'), x.conferenceTypeData.displayName)}>
                <ConferenceEvent>
                  <FaVideo />
                  <Title>{t(x.user.fullName)}</Title>
                </ConferenceEvent>
              </Tooltip>
            </Event>
          ))}

        {response.user.actions.some((a) => a === ActionType.HomeWidgetVAMS) &&
          vamses?.map((x, i) => (
            <Event key={`event-vams-${i}`}>
              <Tooltip
                placement="topLeft"
                title={
                  x.isRelatedTreatment ? (
                    <TooltipDetail>
                      <Value>{format(t('VAMSScorePoint'), t(x.point))}</Value>
                      <Value>{t('ThisVAMSRelatedTreatment')}</Value>
                    </TooltipDetail>
                  ) : (
                    format(t('VAMSScorePoint'), t(x.point))
                  )
                }
              >
                <VAMSEvent isRelatedTreatment={x.isRelatedTreatment}>
                  <FaHeart />
                  <Title>
                    {t('VAMS')} {x.point}
                  </Title>
                </VAMSEvent>
              </Tooltip>
            </Event>
          ))}

        {response.user.actions.some((a) => a === ActionType.TreatmentList) &&
          treatments?.map((x, i) => (
            <Event key={`event-${i}`}>
              <Tooltip
                placement="topLeft"
                title={<TooltipContainer>{format('{0}:{1}', <Title>{t(x.drugData.displayName)}</Title>, <GetDrugValue usage={x} />)}</TooltipContainer>}
              >
                <TreatmentEvent status={x.status} onClick={() => treatmentUsageClick(x)}>
                  <FaHandHoldingMedical />
                  <GetDrugTitle usage={x} />
                </TreatmentEvent>
              </Tooltip>
            </Event>
          ))}
      </Events>
    );
  };

  const GetDrugValue = ({ usage }) => {
    switch (usage.drugData.value) {
      case DrugType.Enspryng:
        return <Value>{usage.dailyDoseMg}mg</Value>;

      default:
        return (
          <Value>
            {usage.dailyDoseMg}mg/{usage.dailyDoseMl}ml
          </Value>
        );
    }
  };

  const GetDrugTitle = ({ usage }) => {
    switch (usage.drugData.value) {
      case DrugType.Enspryng:
        return <Title>{usage.dailyDoseMg}mg</Title>;

      default:
        return (
          <Title>
            {usage.dailyDoseMg}mg/{usage.dailyDoseMl}ml
          </Title>
        );
    }
  };

  // Render
  return (
    <Fragment>
      <Calendar dateCellRender={dateCellRender} templates={['full-width']} />

      <Modal width={320} title={t('TreatmentUsage')} visible={panelVisibility} onCancelClick={() => setPanelVisibility(false)} footer={null} templates={['colored']}>
        <Button onClick={() => treatmentUseClick(TreatmentUsageStatusType.Used)} templates={['colored']} block={true}>
          {t('Used')}
        </Button>
        <p></p>
        <Button onClick={() => treatmentUseClick(TreatmentUsageStatusType.NotUsed)} templates={['colored']} block={true}>
          {t('NotUsed')}
        </Button>
      </Modal>
    </Fragment>
  );
}

const Events = styled.div`
  height: 100%;
  width: 100%;
`;

const Event = styled.div`
  min-height: 25px;
  margin-bottom: 5px;
  border-radius: 5px;
  overflow: hidden;
`;

const VisitEvent = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
  background-color: ${(x) => x.theme.colors.darkTurquoise};
  color: ${(x) => x.theme.colors.white};

  svg {
    margin: 0px 5px;
  }
`;

const ConferenceEvent = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
  background-color: ${(x) => x.theme.colors.darkTurquoise};
  color: ${(x) => x.theme.colors.white};

  svg {
    margin: 0px 5px;
  }
`;

const VAMSEvent = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
  background-color: ${(x) => x.theme.colors.mediumSpringGreen};
  color: ${(x) => x.theme.colors.white};

  svg {
    margin: 0px 5px;
  }

  ${({ isRelatedTreatment }) =>
    isRelatedTreatment &&
    css`
      background-color: ${(x) => x.theme.colors.rustyRed};
    `}
`;

const TreatmentEvent = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
  color: ${(x) => x.theme.colors.white};

  ${({ status }) => {
    switch (status) {
      case TreatmentUsageStatusType.NotUsed:
        return css`
          background-color: ${(x) => x.theme.colors.rustyRed};
        `;
      case TreatmentUsageStatusType.Used:
        return css`
          background-color: ${(x) => x.theme.colors.mediumSpringGreen};
        `;
      case TreatmentUsageStatusType.Pending:
        return css`
          background-color: ${(x) => x.theme.colors.lightBlue};
        `;
      default:
        return css`
          background-color: ${(x) => x.theme.colors.deepKaomaru};
        `;
    }
  }}
`;

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: 10px;
  }
`;

const Title = styled.div`
  font-weight: bold;
`;

const Value = styled.div``;

const TooltipDetail = styled.div``;
