import moment from 'moment';
import { TreatmentLastStatusType, TreatmentStageType, TreatmentStatusType } from '../constants';

export const enspryngCalculator = (status, startDate, duration, stage, lastCount, lastStatus) => {
  if (status === undefined || startDate === undefined || duration === undefined) return null;

  let bottle = 0;
  let miligram = 120;
  let end = moment(startDate).add(duration, 'months');
  let next = moment(startDate);
  let usages = [];

  switch (status) {
    default:
    case TreatmentStatusType.Start:
      bottle = 3;

      usages.push(moment(next));

      next = next.add(14, 'days');
      usages.push(moment(next));

      next = next.add(14, 'days');
      usages.push(moment(next));

      if (duration > 1) {
        next = next.add(28, 'days');

        while (next < end) {
          bottle++;

          usages.push(moment(next));
          next = next.add(28, 'days');
        }
      }
      break;

    case TreatmentStatusType.Continue:
      if (stage === undefined) return null;

      switch (stage) {
        default:
        case TreatmentStageType.StandartTreatment:
          if (lastCount === undefined) return null;

          let diffDays = moment().diff(next, 'days', true);

          if (lastCount === 1) {
            if (diffDays >= 14) {
              return { error: 'StandartTreatment14DaysError' };
            }

            bottle = 2;

            next = next.add(14, 'days');
            usages.push(moment(next));

            next = next.add(14, 'days');
            usages.push(moment(next));
          } else if (lastCount === 2) {
            if (diffDays >= 14) {
              return { error: 'StandartTreatment14DaysError' };
            }

            bottle = 2;

            next = next.add(14, 'days');
            usages.push(moment(next));

            next = next.add(28, 'days');
            usages.push(moment(next));
          } else if (lastCount > 2) {
            if (diffDays >= 28) {
              return { error: 'StandartTreatment28DaysError' };
            }

            bottle = 1;

            next = next.add(28, 'days');
            usages.push(moment(next));
          }

          if (duration > 1) {
            next = next.add(28, 'days');

            while (next < end) {
              bottle++;

              usages.push(moment(next));
              next = next.add(28, 'days');
            }
          }

          break;

        case TreatmentStageType.DelayedSkippedInjections:
          if (lastStatus === undefined) return null;

          switch (lastStatus) {
            default:
            case TreatmentLastStatusType.DSInjection2LessThenWeeks8: //  2nd injection <= 8 weeks
            case TreatmentLastStatusType.DSInjectionGreaterThan12Weeks: // >= 12 weeks
              bottle = 3;

              usages.push(moment(next));

              next = next.add(14, 'days');
              usages.push(moment(next));

              next = next.add(14, 'days');
              usages.push(moment(next));
              break;

            case TreatmentLastStatusType.DSInjection3LessThenWeeks8: // 3nd injection <= 8 weeks
            case TreatmentLastStatusType.DSInjectionMaintenanceRegimenLessThenWeeks8: // Maintenance regimen <= 8 weeks
              bottle = 2;

              usages.push(moment(next));

              next = next.add(28, 'days');
              usages.push(moment(next));

              break;

            case TreatmentLastStatusType.DSInjectionFrom8to12Weeks: // From 8 to 12 weeks
              bottle = 2;

              usages.push(moment(next));

              next = next.add(14, 'days');
              usages.push(moment(next));

              break;
          }

          if (duration > 1) {
            next = next.add(28, 'days');

            while (next < end) {
              bottle++;

              usages.push(moment(next));
              next = next.add(28, 'days');
            }
          }

          break;
        case TreatmentStageType.IncreasedLevelsASTorALT:
          if (lastStatus === undefined) return null;

          switch (lastStatus) {
            default:
            case TreatmentLastStatusType.INCInjectionLessThan12Weeks: // < 12 weeks
              bottle = 1;
              usages.push(moment(next));
              break;

            case TreatmentLastStatusType.INCInjectionGreaterThan12Weeks: // >= 12 weeks
              bottle = 3;

              usages.push(moment(next));

              next = next.add(14, 'days');
              usages.push(moment(next));

              next = next.add(14, 'days');
              usages.push(moment(next));
              break;
          }

          if (duration > 1) {
            next = next.add(28, 'days');

            while (next < end) {
              bottle++;

              usages.push(moment(next));
              next = next.add(28, 'days');
            }
          }
          break;
      }
      break;
  }

  if (bottle > 0) {
    miligram = bottle * miligram;
  }

  return {
    bottle,
    miligram,
    usages,
  };
};
