const NotificationStatusTypes = [
  {
    value: 0,
    name: 'Pending',
    displayName: 'Pending',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Seen',
    displayName: 'Seen',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'ActionPending',
    displayName: 'ActionPending',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'Accepted',
    displayName: 'Accepted',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  },
  {
    value: 4,
    name: 'Rejected',
    displayName: 'Rejected',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {}
  }
];

export default NotificationStatusTypes;