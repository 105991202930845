const NotificationUserRoleType = {
  Special: 0,
  Owner: 1,
  Administrator: 2,
  Manager: 3,
  Editor: 4,
  PSP: 5,
  Doctor: 6,
  Nurse: 7,
  Pharmacist: 8,
  Warehouse: 9,
  IMS: 10,
  Patient: 11,
  Relative: 12
};

export default NotificationUserRoleType;