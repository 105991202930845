import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { UserDeactiveReasonTypes } from '../../constants';

export default function UserStatusInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'country',
    },
    {
      type: 'hidden',
      name: 'userId',
    },
    {
      type: 'hidden',
      name: 'userRole',
    },
    {
      type: 'select',
      name: 'deactiveReason',
      label: t('UserDeactiveStatusType'),
      placeholder: t('SelectUserDeactiveStatusType'),
      rules: [{ required: true, message: t('UserDeactiveStatusTypeEmpty') }],
      data: UserDeactiveReasonTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'textarea',
      name: 'message',
      label: t('DeactiveReasonMessage'),
      placeholder: t('DeactiveReasonMessageEmpty'),
      rules: [
        { required: true, message: t('DeactiveReasonMessageEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
  ];
}
