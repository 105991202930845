import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiMailFill } from 'react-icons/ri';
import { Drawer, Element, Page, Select, Table } from '../../components/globals';
import { ActionType, CountryTypes, NoticeColumns, NoticeInputs } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { noticeList } from '../../services/api';

export default function Notices() {
  // Definitions
  const { t } = useTranslation();
  const [noticeFormRef] = Form.useForm();
  const [user] = useCacheUser();

  const isAllCountry = user.checkAction(ActionType.NoticeAllCountry);

  const [response, setResponse] = useState(null);
  const [panelVisiblity, setPanelVisibility] = useState(false);
  const [rowData, setRowData] = useState(null);

  const [pageState, setPageState] = useState({
    list: true,
    form: true,
    update: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['creationTime|desc'],
    country: parseInt(user.c),
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await noticeList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onDetailClick = (row) => {
    setRowData(row);
    noticeFormRef.setFieldsValue(row);
    setPanelVisibility(true);
  };

  // Render
  return (
    <Page
      title={t('Notices')}
      icon={<RiMailFill />}
      routes={[
        {
          name: t('Notices'),
          breadcrumbName: t('Notices'),
          icon: <RiMailFill />,
        },
      ]}
      templates={[isAllCountry && 'default-1-filter-0']}
      extra={[
        isAllCountry && (
          <Select
            key="country-filter"
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            placeholder={t('SelectCountry')}
            defaultValue={filter.country}
            value={filter.country}
            templates={['filter']}
            allowClear={false}
          />
        ),
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        columns={NoticeColumns({ isAllCountry, onDetailClick: user.checkAction(ActionType.NoticeList) && onDetailClick })}
      />

      <Drawer visible={panelVisiblity} onClose={() => setPanelVisibility(false)}>
        <Element
          key="notice-form"
          ready={pageState.form}
          formRef={noticeFormRef}
          inputs={NoticeInputs({ isExternal: rowData?.contact, type: rowData?.group, isDetail: true })}
          columnSize={24}
        />
      </Drawer>
    </Page>
  );
}
