import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';
import { Page } from '../../components/globals';
import GlobalDashboard from '../../components/pages/dashboard/GlobalDashboard';
import ManagerDashboard from '../../components/pages/dashboard/ManagerDashboard';
import { UserRoleType } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';

export default function Dashboard() {
  // Definitions
  const { t } = useTranslation();

  const [user] = useCacheUser();

  // Render
  return (
    <Page icon={<FaHome />} title={t('Home')}>
      {user?.u === UserRoleType.Owner || user?.u === UserRoleType.Administrator ? <ManagerDashboard /> : <GlobalDashboard />}
    </Page>
  );
}
