import { useTranslation } from 'react-i18next';
import { TreatmentStageTypes, TreatmentStatusTypes } from '../../constants';

export default function TreatmentEnspryngInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'select',
      name: 'status',
      label: t('TreatmentStatus'),
      placeholder: t('SelectTreatmentStatus'),
      rules: [{ required: true, message: t('TreatmentStatusEmpty') }],
      data: TreatmentStatusTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'select',
      name: 'stage',
      label: t('TreatmentStage'),
      placeholder: t('SelectTreatmentStage'),
      rules: [{ required: true, message: t('TreatmentStageEmpty') }],
      data: TreatmentStageTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'number',
      name: 'lastCount',
      label: t('NumberOfLastInjection'),
      placeholder: t('NumberOfLastInjectionEmpty'),
      rules: [{ required: true, message: t('NumberOfLastInjectionEmpty') }],
    },
    {
      type: 'select',
      name: 'lastStatus',
      label: t('TreatmentLastStatus'),
      placeholder: t('SelectTreatmentLastStatus'),
      rules: [{ required: true, message: t('TreatmentLastStatusEmpty') }],
    },
  ];
}
