import { Timeline } from 'antd';
import React from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import styled from 'styled-components';
import { DeliveryStatusType } from '../../../constants';
import DeliveryStep from './DeliveryStep';

export default function DeliveryItemSteps({ country, delivery, steps, flows, onCompleted }) {
  // Render
  return (
    <Container pending={delivery.status === DeliveryStatusType.Pending ? ' ' : null} pendingDot={<FaPaperPlane />}>
      {steps.map((x, i) => (
        <DeliveryStep
          country={country}
          key={`step-${i}`}
          delivery={delivery}
          step={x}
          flows={flows}
          onCompleted={onCompleted}
          row={delivery.status !== DeliveryStatusType.Pending && i === steps.length - 1 ? 'last' : i.toString()}
        />
      ))}
    </Container>
  );
}

const Container = styled(Timeline)`
  .ant-timeline-item-head-custom {
    top: 7px !important;
    left: 6px !important;
    z-index: 20;
    font-size: 20px;
    color: ${(x) => x.theme.colors.naplesYellow};
    border-color: ${(x) => x.theme.colors.naplesYellow};
  }
`;
