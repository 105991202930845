import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { format } from 'react-string-format';
import styled from 'styled-components';
import { NotificationStatusTypes, NotificationTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { replaceParameters } from '../../../utilies/localization';

export default function Survey({ notification, onClose }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();
  const navigate = useNavigate();

  const [data, setData] = useState(null);

  // Hooks
  useEffect(() => {
    if (notification) {
      let result = { ...notification, detail: JSON.parse(notification.data) };

      result.statusData = NotificationStatusTypes.find((x) => x.value === result.status);
      result.typeData = NotificationTypes.find((x) => x.value === result.type);
      result.nameKey = replaceParameters(t(result.detail.nameKey), result.detail.parameters);
      result.descriptionKey = replaceParameters(t(result.detail.descriptionKey), result.detail.parameters);

      if (result.detail.parameters['content.Url']) {
        result.content = {
          title: result.detail.parameters['content.Title'],
          url: result.detail.parameters['content.Url'],
          description: result.detail.parameters['content.Description'],
        };
      }

      setData(result);
    }
  }, [notification]);

  // Events
  const onClickSurvey = () => {
    navigate(`/contents/${data.content.url}`);
    if (onClose) onClose();
  };

  // Render
  return (
    data && (
      <Container>
        <Type>{t(data.typeData?.displayName)}</Type>
        <Date>{moment(data.creationTime).add(user.t, 'minutes').format('llll')}</Date>
        <Body>
          <Detail>{data.nameKey}</Detail>

          {data.content ? (
            <SurveyDetail>
              <SurveyText>{format(data.descriptionKey, <SurveyRedirect onClick={onClickSurvey}>{data.content.title}</SurveyRedirect>)}</SurveyText>
            </SurveyDetail>
          ) : (
            <Detail>{data.descriptionKey}</Detail>
          )}
        </Body>
      </Container>
    )
  );
}

const Container = styled.div`
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Type = styled.div``;

const Date = styled.div``;

const Body = styled.div`
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Detail = styled.div``;

const SurveyDetail = styled.div``;

const SurveyRedirect = styled.strong`
  font-weight: bold;
  cursor: pointer;
  color: ${(x) => x.theme.colors.darkTurquoise};
`;

const SurveyText = styled.div``;
