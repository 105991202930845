import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DrugTypes } from '../../../constants';
import { roleDeliveryDrugList, roleDeliveryDrugUpdate } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { Button, Checkbox } from '../../globals';

export default function RoleDeliveryDrugUpdate({ country, roleId, onConfirm }) {
  // Definitions
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [drugs, setDrugs] = useState([]);

  const [filter, setFilter] = useState(null);

  // Hooks
  useEffect(() => {
    setFilter((x) => ({ ...x, country, roleId }));
  }, [roleId]);

  useEffect(() => {
    if (filter) {
      list();
    }
  }, [filter]);

  // Functions
  const list = async () => {
    await roleDeliveryDrugList(filter, (status, res) => {
      if (status) {
        setDrugs(res.data.map((x) => x.drug));
        setData(res.data);
      }
    });
  };

  // Events
  const onChange = async (value, checked) => {
    setDrugs(checked ? [...drugs, value] : drugs.filter((t) => t !== value));
  };

  const onConfirmClick = async () => {
    await roleDeliveryDrugUpdate({ ...filter, drugs }, (status) => {
      if (status) {
        onConfirm();
        notifySuccess(t('UpdateCompleteSuccess'));
      }
    });
  };

  // Render
  return (
    data && (
      <Container>
        <Description>{t('RoleDeliveryDrugDescription')}</Description>

        {DrugTypes.map((x) => (
          <Checkbox key={`relation-${x.value}`} checked={drugs.includes(x.value)} onChange={(event) => onChange(x.value, event.target.checked)} templates={['multiple']}>
            {t(x.displayName)}
          </Checkbox>
        ))}

        <Button onClick={onConfirmClick} templates={['colored', 'mt-24']}>
          {t('Confirm')}
        </Button>
      </Container>
    )
  );
}

const Container = styled.div``;

const Description = styled.div`
  margin-bottom: 24px;
`;
