const VisitReasonTypes = [
  {
    value: 0,
    name: 'RoutineVisit',
    displayName: 'RoutineVisit',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Exacerbation',
    displayName: 'Exacerbation',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'Hospitalization',
    displayName: 'Hospitalization',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'Other',
    displayName: 'Other',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  }
];

export default VisitReasonTypes;