import { stringify } from 'qs';
import { notifyResponseError } from '../utilies/notification';
import client from './client';

export const form = async (url, model, callback, params, headers) => {
  await client
    .post(url, stringify(model), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        ...headers,
      },
      ...params,
    })
    .then((response) => callback(true, response.data))
    .catch((error) => {
      notifyResponseError(error);
      callback(false, error.response);
    });
};

export const post = async (url, model, callback, params, headers) => {
  await client
    .post(url, model, { params, headers })
    .then((response) => callback(true, response.data))
    .catch((error) => {
      notifyResponseError(error);
      callback(false, error.response);
    });
};

export const put = async (url, model, callback, params, headers) => {
  await client
    .put(url, model, { params, headers })
    .then((response) => callback(true, response.data))
    .catch((error) => {
      notifyResponseError(error);
      callback(false, error.response);
    });
};

export const del = async (url, model, callback, params, headers) => {
  await client
    .delete(url, { data: model, params, headers })
    .then((response) => callback(true, response.data))
    .catch((error) => {
      notifyResponseError(error);
      callback(false, error.response);
    });
};

export const get = async (url, callback, params, headers) => {
  await client
    .get(url, { params, headers })
    .then((response) => callback(true, response.data))
    .catch((error) => {
      notifyResponseError(error);
      callback(false, error.response);
    });
};

export const download = async (url, callback, params, headers, downloadProgressEvent) => {
  await client
    .get(url, {
      params,
      headers,
      responseType: 'blob',
      onDownloadProgress: (event) => {
        if (downloadProgressEvent === undefined) return;

        let percent = Math.floor((event.loaded / event.total) * 100);
        if (percent === 100) {
          downloadProgressEvent(true);
        } else {
          downloadProgressEvent(false, percent);
        }
      },
    })
    .then((response) => {
      let disposition = response.headers['content-disposition'];
      let fileName = disposition && disposition.split('filename=')[1].split(';')[0];
      return callback(true, response.data, fileName);
    })
    .catch((error) => {
      notifyResponseError(error);
      callback(false, error.response);
    });
};

export const upload = async (url, form, callback, params, headers, uploadProgressEvent) => {
  await client
    .post(url, form, {
      onUploadProgress: (event) => {
        if (uploadProgressEvent === undefined) return;

        let percent = Math.floor((event.loaded / event.total) * 100);
        if (percent === 100) {
          uploadProgressEvent(true);
        } else {
          uploadProgressEvent(false, percent);
        }
      },
      params,
      headers: { ...headers, 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => callback(true, response.data))
    .catch((error) => {
      notifyResponseError(error);
      callback(false, error.response);
    });
};
