import { UploadOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaUsersCog } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { format } from 'react-string-format';
import styled from 'styled-components';
import { Button, Drawer, Element, Extra, FileInput, Page, Select, Table } from '../../components/globals';
import RoleActionUpdate from '../../components/pages/role/RoleActionUpdate';
import RoleColumnUpdate from '../../components/pages/role/RoleColumnUpdate';
import RoleDeliveryDrugUpdate from '../../components/pages/role/RoleDeliveryDrugUpdate';
import RoleDrugUpdate from '../../components/pages/role/RoleDrugUpdate';
import RoleFieldUpdate from '../../components/pages/role/RoleFieldUpdate';
import RoleInputUpdate from '../../components/pages/role/RoleInputUpdate';
import RoleLangaugeUpdate from '../../components/pages/role/RoleLanguageUpdate';
import RoleNotificationUpdate from '../../components/pages/role/RoleNotificationUpdate';
import RoleParentUpdate from '../../components/pages/role/RoleParentUpdate';
import { ActionType, CountryTypes, RoleColumns, RoleInputs } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { roleCreate, roleDataExport, roleDataImport, roleList, roleLogout, roleMultipleDeleteRestore } from '../../services/api';
import { notifyError, notifySuccess } from '../../utilies/notification';

export default function Roles() {
  // Definitions
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [roleFormRef] = Form.useForm();
  const [countryFormRef] = Form.useForm();
  const [importFormRef] = Form.useForm();
  const [user] = useCacheUser();

  const isAllCountry = user.checkAction(ActionType.RoleAllCountry);

  const [response, setResponse] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [drugPanelVisibility, setDrugPanelVisibility] = useState(false);
  const [deliveryDrugPanelVisibility, setDeliveryDrugPanelVisibility] = useState(false);
  const [parentPanelVisibility, setParentPanelVisibility] = useState(false);
  const [languagePanelVisibility, setLanguagePanelVisibility] = useState(false);
  const [actionPanelVisibility, setActionPanelVisibility] = useState(false);
  const [fieldPanelVisibility, setFieldPanelVisibility] = useState(false);
  const [inputPanelVisibility, setInputPanelVisibility] = useState(false);
  const [columnPanelVisibility, setColumnPanelVisibility] = useState(false);
  const [notificationPanelVisibility, setNotificationPanelVisibility] = useState(false);
  const [importPanelVisiblity, setImportPanelVisibility] = useState(false);
  const [importFiles, setImportFiles] = useState([]);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await roleList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onCreateClick = () => {
    roleFormRef.resetFields();
    setSelectedRowKeys([]);
    setPanelVisibility(true);
  };

  const onDrugUpdateClick = (row) => {
    setRowData(row);
    setSelectedRowKeys([]);
    setDrugPanelVisibility(true);
  };

  const onDeliveryDrugUpdateClick = (row) => {
    setRowData(row);
    setSelectedRowKeys([]);
    setDeliveryDrugPanelVisibility(true);
  };

  const onParentUpdateClick = (row) => {
    setRowData(row);
    setSelectedRowKeys([]);
    setParentPanelVisibility(true);
  };

  const onLanguageUpdateClick = (row) => {
    setRowData(row);
    setSelectedRowKeys([]);
    setLanguagePanelVisibility(true);
  };

  const onFieldUpdateClick = (row) => {
    setRowData(row);
    setSelectedRowKeys([]);
    setFieldPanelVisibility(true);
  };

  const onInputUpdateClick = (row) => {
    setRowData(row);
    setSelectedRowKeys([]);
    setInputPanelVisibility(true);
  };

  const onColumnUpdateClick = (row) => {
    setRowData(row);
    setSelectedRowKeys([]);
    setColumnPanelVisibility(true);
  };

  const onNotificationUpdateClick = (row) => {
    setRowData(row);
    setSelectedRowKeys([]);
    setNotificationPanelVisibility(true);
  };

  const onActionUpdateClick = (row) => {
    setRowData(row);
    setSelectedRowKeys([]);
    setActionPanelVisibility(true);
  };

  const onLogoutUserClick = async (row) => {
    await roleLogout({ country: filter.country, roleId: row.id }, (status, res) => {
      if (status && res?.data) {
        notifySuccess(t('UpdateCompleteSuccess'));
        setTimeout(() => navigate(window.location.pathname), 500);
      }
    });
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, delete: false }));

    await roleMultipleDeleteRestore({ ids: selectedRowKeys, country: filter.country }, (status, res) => {
      if (status) {
        list();
        notifySuccess(format(t(filter.isDeleted ? 'DataRestoreSuccess' : 'DataDeleteSuccess'), <strong>{res.data}</strong>));
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const onRoleFormFinish = async (model) => {
    setPageState((x) => ({ ...x, create: false }));

    await roleCreate(model, (status, res) => {
      if (status) {
        setPanelVisibility(false);
        notifySuccess(t('CreateCompleteSuccess'));

        if (filter.country !== model.country) {
          setFilter((x) => ({ ...x, country: model.country }));
          countryFormRef.setFieldsValue({ country: model.country });
        } else {
          list();
        }
      }
      setPageState((x) => ({ ...x, create: true }));
    });
  };

  const onImportPanelClick = () => {
    setImportFiles([]);
    setImportPanelVisibility(true);
  };

  const onExportClick = async () => {
    setPageState((x) => ({ ...x, export: false }));

    await roleDataExport({ ids: selectedRowKeys, country: filter.country }, (status, res, fileName) => {
      if (status) {
        saveAs(res, fileName);
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, export: true }));
    });
  };

  const onImportClick = async () => {
    if (importFiles?.length <= 0) {
      notifyError(t('ImportFileRequired'));
      return;
    }

    const form = new FormData();
    form.append('File', importFiles[0]);
    form.append('country', filter.country);

    let formValues = importFormRef.getFieldsValue();
    for (let key of Object.keys(formValues)) {
      form.append(key, formValues[key]);
    }

    setPageState((x) => ({ ...x, import: false }));
    await roleDataImport(filter.country, form, (status, res) => {
      if (status) {
        list();
        notifySuccess(t('UpdateCompleteSuccess'));
        setImportPanelVisibility(false);
        setPanelVisibility(false);
        setImportFiles([]);
      }

      setPageState((x) => ({ ...x, import: true }));
    });
  };

  // Render
  return (
    <Page
      title={t('Roles')}
      icon={<FaUsersCog />}
      subTitle={
        user.checkAction(ActionType.RoleCreate) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Roles'),
          breadcrumbName: t('Roles'),
          icon: <FaUsersCog />,
        },
      ]}
      templates={[isAllCountry ? 'default-1-filter-4' : 'default-0-filter-4']}
      extra={[
        isAllCountry && (
          <Select
            key="country-filter"
            allowClear={false}
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            placeholder={t('SelectCountry')}
            defaultValue={filter.country}
            value={filter.country}
            templates={['filter']}
          />
        ),
        <Extra
          key="filter"
          pageState={pageState}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onFilterChanged={() => setSelectedRowKeys([])}
          onDeleteClick={user.checkAction(ActionType.RoleDelete) && onDeleteClick}
          onExportClick={user.checkAction(ActionType.RoleImportExport) && onExportClick}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        selectedRowKeys={user.checkAction(ActionType.RoleDelete) && selectedRowKeys}
        onRowSelectChange={(values) => setSelectedRowKeys(values)}
        columns={RoleColumns({
          isAllCountry,
          onDrugUpdateClick: user.checkAction(ActionType.RoleUpdate) && onDrugUpdateClick,
          onDeliveryDrugUpdateClick: user.checkAction(ActionType.RoleUpdate) && onDeliveryDrugUpdateClick,
          onLanguageUpdateClick: user.checkAction(ActionType.RoleUpdate) && onLanguageUpdateClick,
          onParentUpdateClick: user.checkAction(ActionType.RoleUpdate) && onParentUpdateClick,
          onFieldUpdateClick: user.checkAction(ActionType.RoleUpdate) && onFieldUpdateClick,
          onInputUpdateClick: user.checkAction(ActionType.RoleUpdate) && onInputUpdateClick,
          onColumnUpdateClick: user.checkAction(ActionType.RoleUpdate) && onColumnUpdateClick,
          onNotificationUpdateClick: user.checkAction(ActionType.RoleUpdate) && onNotificationUpdateClick,
          onActionUpdateClick: user.checkAction(ActionType.RoleUpdate) && onActionUpdateClick,
          onLogoutUserClick: user.checkAction(ActionType.RoleUpdate) && onLogoutUserClick,
        })}
      />

      <Drawer title={t('AddNew')} visible={panelVisibility} onClose={() => setPanelVisibility(false)}>
        {user.checkAction(ActionType.RoleImportExport) && (
          <Button key="import" onClick={onImportPanelClick} icon={<UploadOutlined />} templates={['colored', 'with-icon', 'full-width', 'mb-24']}>
            {t('ImportData')}
          </Button>
        )}

        <Element
          key="role-form"
          ready={pageState.create}
          formRef={roleFormRef}
          onFinish={onRoleFormFinish}
          inputs={RoleInputs({ country: filter.country, isAllCountry, isUpdate: false })}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>

      <Drawer title={t('ImportData')} visible={importPanelVisiblity} onClose={() => setImportPanelVisibility(false)}>
        <ImportDataDescription>{t('ImportDataDescription')}</ImportDataDescription>

        <FileInput files={importFiles} setFiles={setImportFiles} mimeTypes="application/json" />

        <Element key="import-form" ready={pageState.import} formRef={importFormRef} columnSize={24} style={{ margin: '12px 0px 24px' }} />

        <Button ready={pageState.import} icon={<UploadOutlined />} onClick={onImportClick} block={true} templates={['colored']}>
          {t('ImportData')}
        </Button>
      </Drawer>

      <Drawer title={t('RoleDrugs')} visible={drugPanelVisibility} onClose={() => setDrugPanelVisibility(false)}>
        {rowData && drugPanelVisibility && <RoleDrugUpdate country={filter.country} roleId={rowData.id} onConfirm={() => setDrugPanelVisibility(false)} />}
      </Drawer>

      <Drawer title={t('RoleDeliveryDrugs')} visible={deliveryDrugPanelVisibility} onClose={() => setDeliveryDrugPanelVisibility(false)}>
        {rowData && deliveryDrugPanelVisibility && (
          <RoleDeliveryDrugUpdate country={filter.country} roleId={rowData.id} onConfirm={() => setDeliveryDrugPanelVisibility(false)} />
        )}
      </Drawer>

      <Drawer title={t('RoleParents')} visible={parentPanelVisibility} onClose={() => setParentPanelVisibility(false)}>
        {rowData && parentPanelVisibility && <RoleParentUpdate country={filter.country} roleId={rowData.id} onConfirm={() => setParentPanelVisibility(false)} />}
      </Drawer>

      <Drawer title={t('RoleLanguages')} visible={languagePanelVisibility} onClose={() => setLanguagePanelVisibility(false)}>
        {rowData && languagePanelVisibility && <RoleLangaugeUpdate country={filter.country} roleId={rowData.id} onConfirm={() => setLanguagePanelVisibility(false)} />}
      </Drawer>

      <Drawer title={t('RoleFields')} width="80%" visible={fieldPanelVisibility} onClose={() => setFieldPanelVisibility(false)}>
        {rowData && fieldPanelVisibility && <RoleFieldUpdate country={filter.country} roleId={rowData.id} />}
      </Drawer>

      <Drawer title={t('RoleInputs')} width="80%" visible={inputPanelVisibility} onClose={() => setInputPanelVisibility(false)}>
        {rowData && inputPanelVisibility && <RoleInputUpdate country={filter.country} roleId={rowData.id} />}
      </Drawer>

      <Drawer title={t('RoleColumns')} width="80%" visible={columnPanelVisibility} onClose={() => setColumnPanelVisibility(false)}>
        {rowData && columnPanelVisibility && <RoleColumnUpdate country={filter.country} roleId={rowData.id} />}
      </Drawer>

      <Drawer title={t('RoleActions')} width="80%" visible={actionPanelVisibility} onClose={() => setActionPanelVisibility(false)}>
        {rowData && actionPanelVisibility && <RoleActionUpdate country={filter.country} roleId={rowData.id} onConfirm={() => setActionPanelVisibility(false)} />}
      </Drawer>

      <Drawer title={t('RoleNotifications')} width="80%" visible={notificationPanelVisibility} onClose={() => setNotificationPanelVisibility(false)}>
        {rowData && notificationPanelVisibility && <RoleNotificationUpdate country={filter.country} roleId={rowData.id} userRole={rowData.userRole} />}
      </Drawer>
    </Page>
  );
}

const ImportDataDescription = styled.div`
  margin: 12px 0px 24px;
`;
