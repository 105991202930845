import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button, Switch } from '../../components/globals';
import { DrugTypes, RoleInputCalculateTypes, RoleInputDomainTypes, RoleInputTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function RoleInputColumns({ onUpdateClick, onRequirementChange }) {
  const { t } = useTranslation();

  let columns = [
    {
      locale: 'InputDrug',
      name: 'drug',
      type: 'string',
      render: (value, row) => {
        let result = DrugTypes.find((x) => x.value === value);

        return result ? (
          <Tooltip placement="topLeft" title={t(result.displayName)}>
            {t(result.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'InputDomain',
      name: 'domain',
      type: 'string',
      render: (value, row) => {
        let result = RoleInputDomainTypes.find((x) => x.value === value);

        return result ? (
          <Tooltip placement="topLeft" title={t(result.displayName)}>
            {t(result.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'InputCalculate',
      name: 'calculate',
      type: 'string',
      render: (value, row) => {
        let result = RoleInputCalculateTypes.find((x) => x.value === value);

        return result ? (
          <Tooltip placement="topLeft" title={t(result.displayName)}>
            {t(result.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'InputType',
      name: 'type',
      type: 'string',
      render: (value, row) => {
        let result = RoleInputTypes.find((x) => x.value === value);

        return result ? (
          <Tooltip placement="topLeft" title={t(result.displayName)}>
            {t(result.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'InputRow',
      name: 'row',
      type: 'int',
      align: 'center',
      width: 120,
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'IsRequired',
      name: 'isRequired',
      align: 'center',
      width: 120,
      render: (value, row) => <Switch key="isRequired" checked={value} loading={row.loading} onChange={(val) => onRequirementChange(val, row)} />,
    },
    {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
          <RiEdit2Fill />
        </Button>
      ),
    },
  ];

  return ColumnBase({ columns });
}
