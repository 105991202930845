import { Collapse } from 'antd';
import { useState } from 'react';
import { FaCaretRight } from 'react-icons/fa';
import styled, { css } from 'styled-components';

const { Panel } = Collapse;

export default function CollapseBase({ contents: panels }) {
  const [activeKeys, setActiveKeys] = useState([]);

  const onPanelClick = (key) => {
    if (activeKeys?.some((x) => x === key)) {
      setActiveKeys(activeKeys?.filter((y) => y !== key));
    } else {
      setActiveKeys([...activeKeys, key]);
    }
  };

  return (
    <Container activeKey={activeKeys}>
      {panels.map((x, i) => {
        let panelKey = `panel-${i}`;
        return (
          <ContainerPanel
            key={panelKey}
            showArrow={false}
            header={
              <PanelHeaderContainer
                onClick={(event) => {
                  event.stopPropagation();
                  onPanelClick(panelKey);
                }}
                arrow={!!x.showArrow ? 'show' : ''}
              >
                {!!x.showArrow && <CollapseArrow status={activeKeys?.some((x) => x === panelKey) ? 1 : 0} />}

                <PanelHeader>{x.header}</PanelHeader>
                {x.extra && <PanelExtra>{x.extra}</PanelExtra>}
              </PanelHeaderContainer>
            }
          >
            {x.children}
          </ContainerPanel>
        );
      })}
    </Container>
  );
}

const Container = styled(Collapse)`
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid ${(x) => x.theme.colors.americanBlue};
  background-color: ${(x) => x.theme.colors.americanBlue};
  color: ${(x) => x.theme.colors.white};

  > .ant-collapse-item {
    & > .ant-collapse-header {
      color: ${(x) => x.theme.colors.white};
    }

    &:last-child {
      border-bottom: 0px;
    }
  }
`;

const ContainerPanel = styled(Panel)`
  .ant-collapse-header {
    padding: 0px !important;
  }
`;

const PanelHeaderContainer = styled.div`
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(x) =>
    x.arrow
      ? css`
          padding: 0px 12px 0px 40px;
        `
      : css`
          padding: 0px 12px 0px 12px;
        `}

  border-radius: 10px;
`;

const PanelHeader = styled.div`
  width: 100%;
`;

const PanelExtra = styled.div`
  width: 100%;
  text-align: right;
`;

const CollapseArrow = styled(FaCaretRight)`
  position: absolute;
  left: 5px;
  top: 50%;

  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(x) => x.theme.colors.robinEggBlue};

  transition: transform 0.3s;
  transform: translateY(-50%) rotate(90deg);
  ${(x) =>
    x.status &&
    css`
      transform: translateY(-50%) rotate(-90deg);
    `}
`;
