import moment from 'moment';
import { DrugCalculateType, DrugType, TreatmentUsageStatusType } from '../constants';
import { enspryngCalculator } from './enspryngCalculator';

export const usageCalculator = (model, previousUsages) => {
  let usages = [];

  switch (model.drug) {
    default:
    case DrugType.Evrysdi:
      if (model.drugCalculate === DrugCalculateType.EvrysdiShelfLife) {
        let evrysdiNextDate = moment(model.startDate);
        let evrysdiEndDate = moment(model.startDate).add(model.durationInDays, 'days');
        let evrysdiRow = 1;

        while (evrysdiNextDate < evrysdiEndDate) {
          let evrysdiUsage = {
            row: evrysdiRow,
            time: moment(evrysdiNextDate),
            dailyDoseMg: model.dailyDoseMg,
            dailyDoseMl: model.dailyDoseMl,
            status: TreatmentUsageStatusType.Pending,
          };
          let previousUsage = previousUsages?.find((x) => x.time.isSame(evrysdiUsage.time, 'day'));

          usages.push(previousUsage ? { ...previousUsage, row: evrysdiRow } : evrysdiUsage);
          evrysdiNextDate.add(1, 'days');
          evrysdiRow++;
        }
      } else {
        let evrysdiNextDate = moment(model.startDate);
        let evrysdiEndDate = moment(model.startDate).add(model.durationInMonths, 'months');
        let evrysdiRow = 1;

        while (evrysdiNextDate < evrysdiEndDate) {
          let evrysdiUsage = {
            row: evrysdiRow,
            time: moment(evrysdiNextDate),
            dailyDoseMg: model.dailyDoseMg,
            dailyDoseMl: model.dailyDoseMl,
            status: TreatmentUsageStatusType.Pending,
          };
          let previousUsage = previousUsages?.find((x) => x.time.isSame(evrysdiUsage.time, 'day'));

          usages.push(previousUsage ? { ...previousUsage, row: evrysdiRow } : evrysdiUsage);
          evrysdiNextDate.add(1, 'days');
          evrysdiRow++;
        }
      }

      break;

    case DrugType.Enspryng:
      let enspryngResult = enspryngCalculator(model.status, moment(model.startDate), model.durationInMonths, model.stage, model.lastCount, model.lastStatus);

      if (enspryngResult === null || enspryngResult.error) return enspryngResult;

      return enspryngResult.usages.map((x, i) => {
        let enspryngUsage = {
          row: i + 1,
          time: x,
          dailyDoseMg: 120,
          status: TreatmentUsageStatusType.Pending,
        };

        let previousUsage = previousUsages?.find((x) => x.time.isSame(enspryngUsage.time, 'day'));

        return previousUsage ? { ...previousUsage, row: enspryngUsage.row } : enspryngUsage;
      });
  }

  return usages;
};
