import { Navigate, Routes as ReactRoutes, Route, useLocation } from 'react-router-dom';
import { Layout } from './components/globals';
import { ActionType } from './constants';
import { useCacheUser } from './hooks/useCacheUser';
import PatientActivities from './pages/activity/PatientActivities';
import Cities from './pages/city/Cities';
import Conferences from './pages/conference/Conferences';
import Content from './pages/content/Content';
import ContentCreateUpdate from './pages/content/ContentCreateUpdate';
import ContentDetail from './pages/content/ContentDetail';
import ContentList from './pages/content/ContentList';
import Contents from './pages/content/Contents';
import Dashboard from './pages/dashboard/Dashboard';
import Deliveries from './pages/delivery/Deliveries';
import DeliveryCreate from './pages/delivery/DeliveryCreate';
import DeliveryFlowUpdate from './pages/delivery/DeliveryFlowUpdate';
import DeliveryFlows from './pages/delivery/DeliveryFlows';
import Journey from './pages/journey/Journey';
import Localizations from './pages/localization/Localizations';
import Login from './pages/login/Login';
import Logout from './pages/login/Logout';
import SSO from './pages/login/SSO';
import NoticeContacts from './pages/notice/NoticeContacts';
import Notices from './pages/notice/Notices';
import Roles from './pages/role/Roles';
import CalculatorEvrysdi from './pages/static/CalculatorEvrysdi';
import Contact from './pages/static/Contact';
import NotFound from './pages/static/NotFound';
import PrivacyPolicy from './pages/static/PrivacyPolicy';
import Storages from './pages/storage/Storages';
import TemplateUpdate from './pages/template/TemplateUpdate';
import Templates from './pages/template/Templates';
import TreatmentCreateUpdate from './pages/treatment/TreatmentCreateUpdate';
import Treatments from './pages/treatment/Treatments';
import UserCreatePassword from './pages/user/UserCreatePassword';
import UserCreateUpdate from './pages/user/UserCreateUpdate';
import UserForgotPassword from './pages/user/UserForgotPassword';
import UserInvites from './pages/user/UserInvites';
import UserProfile from './pages/user/UserProfile';
import Users from './pages/user/Users';
import Visits from './pages/visit/Visit';
import Vitals from './pages/vital/Vitals';

export default function Routes() {
  // Definitions
  const location = useLocation();
  const [user] = useCacheUser();

  let routeList = [
    { private: false, paths: ['/not-found'], element: <NotFound /> },
    { private: false, paths: ['/privacy-policy'], element: <PrivacyPolicy /> },
    { private: false, paths: ['/contact'], element: <Contact /> },
    { private: false, paths: ['/calculator/evrysdi'], element: <CalculatorEvrysdi /> },
    { private: false, paths: ['/login'], element: <Login /> },
    { private: false, paths: ['/login/sso'], element: <SSO /> },
    { private: false, paths: ['/logout'], element: <Logout /> },
    { private: false, paths: ['/user/create-password'], element: <UserCreatePassword /> },
    { private: false, paths: ['/user/forgot-password'], element: <UserForgotPassword /> },

    { private: true, paths: ['/'], element: <Dashboard /> },
    { private: true, paths: ['/profile'], element: <UserProfile /> },

    { private: true, paths: ['/role', 'role/list'], element: <Roles />, actionTypes: [ActionType.RoleList] },

    { private: true, paths: ['/localization', '/localization/list'], element: <Localizations />, actionTypes: [ActionType.LocalizationList] },

    { private: true, paths: ['/city', 'city/list'], element: <Cities />, actionTypes: [ActionType.CityList] },

    { private: true, paths: ['/user/invite', 'user/invite/list'], element: <UserInvites />, actionTypes: [ActionType.UserInviteList] },

    { private: true, paths: ['/user/list/:userRoleType'], element: <Users /> },
    { private: true, paths: ['/user/create/:userRoleType/:countryType', '/user/create/:userRoleType'], element: <UserCreateUpdate /> },
    { private: true, paths: ['/user/update/:userRoleType/:countryType/:id', '/user/update/:userRoleType/:id'], element: <UserCreateUpdate /> },

    { private: true, paths: ['/template', '/template/list'], element: <Templates />, actionTypes: [ActionType.TemplateList] },
    { private: true, paths: ['/template/update/:id'], element: <TemplateUpdate />, actionTypes: [ActionType.TemplateUpdate] },

    { private: true, paths: ['/notice', '/notice/list'], element: <Notices />, actionTypes: [ActionType.NoticeList] },
    { private: true, paths: ['/notice/contact', '/notice/contact/list'], element: <NoticeContacts />, actionTypes: [ActionType.NoticeContactList] },

    { private: true, paths: ['/conference', 'conference/list'], element: <Conferences />, actionTypes: [ActionType.ConferenceList] },

    { private: true, paths: ['/journey', '/journey/:userRoleType/:countryType/:id'], element: <Journey /> },

    { private: true, paths: ['/delivery/flow/list'], element: <DeliveryFlows /> },
    { private: true, paths: ['/delivery/flow/update/:countryType/:id', '/delivery/flow/update/:id'], element: <DeliveryFlowUpdate /> },

    { private: true, paths: ['/delivery/list'], element: <Deliveries /> },
    { private: true, paths: ['/delivery/create/:userRoleType/:countryType/:id'], element: <DeliveryCreate /> },

    { private: true, paths: ['/content/list'], element: <Contents />, actionTypes: [ActionType.ContentList] },
    { private: true, paths: ['/content/create/'], element: <ContentCreateUpdate />, actionTypes: [ActionType.ContentList, ActionType.ContentCreate] },
    { private: true, paths: ['/content/update/:id'], element: <ContentCreateUpdate />, actionTypes: [ActionType.ContentList, ActionType.ContentUpdate] },

    { private: true, paths: ['/contents'], element: <Content />, actionTypes: [ActionType.ContentPage] },
    { private: true, paths: ['/contents/list/:contentType'], element: <ContentList />, actionTypes: [ActionType.ContentPage] },

    { private: true, paths: ['/contents/:url'], element: <ContentDetail />, actionTypes: [ActionType.ContentPage] },

    { private: true, paths: ['/storage/list'], element: <Storages />, actionTypes: [ActionType.StorageList] },

    { private: true, paths: ['/treatment', 'treatment/list'], element: <Treatments />, actionTypes: [ActionType.TreatmentList] },
    {
      private: true,
      paths: ['/treatment/create', '/treatment/create/:countryType/:userId'],
      element: <TreatmentCreateUpdate />,
      actionTypes: [ActionType.TreatmentList, ActionType.TreatmentCreate],
    },
    {
      private: true,
      paths: ['/treatment/update/:id', '/treatment/update/:countryType/:userId/:id'],
      element: <TreatmentCreateUpdate />,
      actionTypes: [ActionType.TreatmentList, ActionType.TreatmentUpdate],
    },

    { private: true, paths: ['/visit', '/visit/list'], element: <Visits />, actionTypes: [ActionType.VisitList] },

    { private: true, paths: ['/vital', 'vital/list'], element: <Vitals />, actionTypes: [ActionType.VitalList] },

    {
      private: true,
      paths: ['/activity/patient', '/activity/patient/list'],
      element: <PatientActivities />,
      actionTypes: [ActionType.PatientList, ActionType.HomeWidgetPatientActivities],
    },
  ];

  let result = [];
  routeList.forEach((r) => {
    r.paths.forEach((x) => {
      result.push({ path: x, element: r.element, actionTypes: r.actionTypes, private: r.private });
    });
  });

  const getRouteElement = (component, actionTypes) => {
    if (!user && location.pathname === '/') {
      return <Navigate to="/login" />;
    }

    if (!user) {
      return <Navigate to="/login" state={{ path: location.pathname + location.search, error: 'UserAuthorizationException' }} />;
    }

    if (user.expired || !user.v) {
      return <Navigate to="/login" state={{ path: location.pathname + location.search, error: 'UserAuthorizationException' }} />;
    }

    if (!actionTypes) {
      return <Layout children={component} />;
    }

    if (user.a && actionTypes.some((x) => user.a.some((u) => u === x))) {
      return <Layout children={component} />;
    } else {
      return <Navigate to="/" />;
    }
  };

  return (
    <ReactRoutes>
      {result.map((x, i) =>
        x.private ? (
          <Route key={`route-${i}`} path={x.path} element={getRouteElement(x.element, x.actionTypes)} />
        ) : (
          <Route key={`route-${i}`} path={x.path} element={x.element} />
        )
      )}
      <Route path="*" element={<Navigate to="/not-found" replace />} />
    </ReactRoutes>
  );
}
