import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import { TbEdit } from 'react-icons/tb';
import { useParams } from 'react-router';
import { Button, Page } from '../../components/globals';
import ContentForm from '../../components/pages/content/ContentForm';

export default function ContentCreateUpdate() {
  // Definitions
  const { t } = useTranslation();
  const { id } = useParams();

  const [formRef] = Form.useForm();

  const isCreate = id === undefined;

  // Render
  return (
    <Page
      parent={t('Contents')}
      title={t(isCreate ? 'AddNew' : 'Update')}
      icon={isCreate ? <FaPlus /> : <RiEdit2Fill />}
      routes={[
        {
          path: `/content/list/`,
          name: t('Contents'),
          breadcrumbName: 'Contents',
          icon: <TbEdit />,
        },
        {
          name: t(isCreate ? 'AddNew' : 'Update'),
          breadcrumbName: 'ContentCreateUpdate',
          icon: isCreate ? <FaPlus /> : <RiEdit2Fill />,
        },
      ]}
      extra={[
        <Button key="confirm" onClick={() => formRef.submit()} templates={['colored']}>
          {t('Confirm')}
        </Button>,
      ]}
    >
      <ContentForm formRef={formRef} id={id} />
    </Page>
  );
}
