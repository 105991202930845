import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RoleFieldDiseaseTypes, RoleFieldGenderTypes, RoleFieldType } from '../../../constants';

export default function DeliveryUserDetail({ user }) {
  // Definitions
  const { t } = useTranslation();

  // Functions
  const GetFields = ({ fieldType }) => {
    let field = user?.fields?.find((x) => x.fieldType === fieldType);
    if (!field) return null;

    switch (fieldType) {
      case RoleFieldType.GenderType:
        let gender = RoleFieldGenderTypes.find((x) => x.value === field.value);

        return (
          <Detail>
            <Name>{t('Gender')}</Name>
            <Value>{t(gender.displayName)}</Value>
          </Detail>
        );

      case RoleFieldType.Address:
        return (
          <Detail>
            <Name>{t('Address')}</Name>
            <Value>{field.value}</Value>
          </Detail>
        );

      case RoleFieldType.BirthDate:
        return (
          <Detail>
            <Name>{t('BirthDate')}</Name>
            <Value>
              {moment(field.value).format('ll')} ({user.months.toFixed(2)} {t('Months')})
            </Value>
          </Detail>
        );

      case RoleFieldType.DiseaseTypes:
        let diseases = RoleFieldDiseaseTypes.filter((x) => field.value.some((s) => s === x.value));
        let diseaseNames = diseases.map((x) => t(x.displayName)).join(', ');
        return (
          diseases && (
            <Detail>
              <Name>{t('Diseases')}</Name>
              <Value>{diseaseNames}</Value>
            </Detail>
          )
        );

      default:
        return null;
    }
  };

  // Render
  return (
    user && (
      <Container>
        <Details>
          <Detail>
            <Name>{t('FullName')}</Name>
            <Value>{user.fullName}</Value>
          </Detail>
          <GetFields fieldType={RoleFieldType.GenderType} />
          <GetFields fieldType={RoleFieldType.BirthDate} />
          <GetFields fieldType={RoleFieldType.DiseaseTypes} />
          <Detail>
            <Name>{t('City')}</Name>
            <Value>{user.city.name}</Value>
          </Detail>
          <GetFields fieldType={RoleFieldType.Address} />
        </Details>
      </Container>
    )
  );
}

const Container = styled.div`
  margin-right: 24px;
  width: 400px;
`;

const Details = styled.div``;

const Detail = styled.div`
  margin-bottom: 12px;
`;

const Name = styled.div`
  font-size: 11px;
  color: rgba(0, 0, 0, 0.45);
`;

const Value = styled.div``;
