import { css } from 'styled-components';

export const select = css`
  .ant-select-dropdown {
    border: 0px;
    padding: 0px;
    overflow: hidden;
    z-index: 99999;

    border-color: transparent !important;
    box-shadow: 2px 2px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;
    border-radius: ${(x) => x.theme.global.borderRadius};

    .ant-select-item-option {
      min-height: 50px;

      .ant-select-item-option-content {
        display: flex;
        align-items: center;
      }
    }
  }
`;
