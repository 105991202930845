import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Switch } from '../../components/globals';
import UserRoleActionType from '../enums/UserRoleActionType';
import UserRoleTypes from '../types/UserRoleTypes';
import ColumnBase from './_ColumnBase';

export default function UserViewerColumns({ user, userRoleFilter, onViewerChange }) {
  const { t } = useTranslation();

  let columns = [
    userRoleFilter === undefined && {
      locale: 'UserRole',
      name: 'userRole',
      type: 'string',
      render: (value, row) => {
        let userRole = UserRoleTypes.find((x) => x.value === value);

        return userRole ? (
          <Tooltip placement="topLeft" title={t(userRole.displayName)}>
            {t(userRole.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'FirstName',
      name: 'firstName',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'LastName',
      name: 'lastName',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onViewerChange && {
      locale: 'UserViewer',
      name: 'isViewer',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (value, row) =>
        user.checkUserRole(row.userRole, UserRoleActionType.Viewer) && (
          <Switch key="isViewer" checked={value} loading={row.loading} onChange={(val) => onViewerChange(val, row)} />
        ),
    },
  ];

  return ColumnBase({ columns });
}
