import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { CountryTypes, UserRoleTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function UserInviteColumns({ isAllCountry }) {
  const { t } = useTranslation();

  let columns = [
    isAllCountry && {
      locale: 'Country',
      name: 'country',
      type: 'string',
      render: (value, row) => {
        let country = CountryTypes.find((x) => x.value === value);

        return country ? (
          <Tooltip placement="topLeft" title={t(country.displayName)}>
            {t(country.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'UserRole',
      name: 'userRole',
      type: 'string',
      render: (value, row) => {
        let userRole = UserRoleTypes.find((x) => x.value === value);

        return userRole ? (
          <Tooltip placement="topLeft" title={t(userRole.displayName)}>
            {t(userRole.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'ExpireTime',
      name: 'expireTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'CreationTime',
      name: 'creationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
  ];

  return ColumnBase({ columns });
}
