const RoleColumnDomainTypes = [
  {
    value: 0,
    name: 'Treatment',
    displayName: 'RoleColumnDomainTreatment',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Delivery',
    displayName: 'RoleColumnDomainDelivery',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  }
];

export default RoleColumnDomainTypes;