import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';

export default function LineChartBase({ margin, width, height, data, groups, xAxisDataKey, lineDataKey }) {
  // Components
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div>
          <p>{label}</p>
          <p>{payload[0].value}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <Container>
      <LineChart
        width={width}
        height={height}
        data={data}
        margin={
          margin ?? {
            top: 10,
            right: 50,
            left: 0,
            bottom: 0,
          }
        }
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisDataKey} reversed />
        <YAxis />
        {lineDataKey ? <Tooltip content={<CustomTooltip />} /> : <Tooltip />}

        {groups ? (
          groups.map((x, i) => <Line key={`line-${i}`} connectNulls type="monotone" dataKey={x.name} stroke={x.color ?? '#8884d8'} fill={x.color ?? '#8884d8'} />)
        ) : (
          <Line connectNulls type="monotone" dataKey={lineDataKey} stroke="#8884d8" fill="#8884d8" />
        )}
      </LineChart>
    </Container>
  );
}

const Container = styled(ResponsiveContainer)``;
