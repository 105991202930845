import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import {
  DrugTypes,
  RoleColumnDomainTypes,
  RoleFieldGroupType,
  RoleInputCalculateTypes,
  RoleInputColumns,
  RoleInputDomainTypes,
  RoleInputInputs,
  RoleInputTypes,
} from '../../../constants';
import { roleInputCreate, roleInputList, roleInputMultipleDeleteRestore, roleInputUpdate, roleInputUpdateRequirement } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { updateResponse } from '../../../utilies/stateManagement';
import { Button, Drawer, Element, Extra, Section, Select, Table } from '../../globals';

export default function RoleInputUpdate({ country, roleId }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [response, setResponse] = useState(null);
  const [inputType, setInputType] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisibility, setPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({ data: true, sync: undefined });

  const [inputs, setInputs] = useState(RoleInputInputs());

  const [filter, setFilter] = useState({
    roleId,
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['row|asc'],
    country: country,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    await roleInputList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
    });

    if (filter.domain !== undefined) {
      onDomainChange({ value: filter.domain });
    }
  };

  const updateInputs = (data) => {
    setInputs(
      inputs.map((x) => {
        if (data.isUpdate) {
          switch (x.name) {
            case 'drug':
            case 'domain':
            case 'calculate':
            case 'type':
              x.disabled = true;
              break;
            default:
              x.disabled = false;
              break;
          }
        } else {
          x.disabled = false;
        }

        if (x.name === 'type') x.onChange = onInputTypeChange;
        if (x.name === 'domain') x.onChange = onDomainChange;

        if (x.name === 'calculate' && data.domain !== undefined) {
          x.data = RoleInputCalculateTypes.filter((x) => x.group.includes(data.domain)).map((x) => ({ ...x, text: t(x.displayName) }));
        }

        if (data.typeValue !== undefined) {
          if (x.name.includes('Value')) {
            x.visible = RoleInputTypes.some(
              (f) => f.value === data.typeValue && (f.data.group === RoleFieldGroupType.Input || f.data.group === RoleFieldGroupType.Number)
            );
          } else {
            x.visible = true;
          }
        } else if (x.name.includes('Value')) {
          x.visible = true;
        }

        if (x.name === 'type' && data.domain !== undefined) {
          x.data = RoleInputTypes.filter((x) => x.group.includes(data.domain)).map((x) => ({ ...x, text: t(x.displayName) }));
        }

        return x;
      })
    );

    if (data.domain !== undefined) {
      formRef.setFieldsValue({ calculate: null, type: null });
    }
  };

  // Events
  const onInputTypeChange = ({ value }) => {
    let result = RoleInputTypes.find((x) => x.value === value);
    setInputType(result);
    updateInputs({ typeValue: value });
  };

  const onCreateClick = () => {
    updateInputs({ isUpdate: false });
    setPanelVisibility(true);
    setInputType(null);
    formRef.resetFields();
    formRef.setFieldsValue({ roleId, domain: filter.domain });

    if (filter.domain !== undefined) {
      onDomainChange({ value: filter.domain });
    }
  };

  const onUpdateClick = (row) => {
    updateInputs({ isUpdate: true, typeValue: row.type });
    setPanelVisibility(true);
    formRef.setFieldsValue(row);
  };

  const onRequirementChange = async (val, row) => {
    setPageState((x) => ({ ...x, sync: null }));
    setResponse(updateResponse(response, 'isRequired', val, true, row));

    await roleInputUpdateRequirement({ id: row.id, isRequired: val, country }, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
      } else {
        val = !val;
      }

      setResponse(updateResponse(response, 'isRequired', val, false, row));
      setPageState((x) => ({ ...x, sync: status }));
    });
  };

  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, sync: null, data: false }));

    model.country = country;

    let request = model.id === undefined ? roleInputCreate : roleInputUpdate;
    await request(model, (status, res) => {
      if (status) {
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
        setPanelVisibility(false);
        list();
      }
      setPageState((x) => ({ ...x, sync: status, data: true }));
    });
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, sync: null, data: false }));

    await roleInputMultipleDeleteRestore({ ids: selectedRowKeys, country }, (status, res) => {
      if (status) {
        list();
      }
      setPageState((x) => ({ ...x, sync: status, data: true }));
    });
  };

  const onDomainChange = ({ value }) => {
    let domainData = RoleColumnDomainTypes.find((x) => x.value === value);

    updateInputs({ isUpdate: false, domain: domainData.name, typeValue: inputType?.value });
  };

  // Render
  return (
    <Container>
      <Description>{t('RoleInputDescription')}</Description>

      <Section
        headerLeft={[
          <Button key="add" ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-text-title', 'with-icon']}>
            {t('AddNew')}
          </Button>,
        ]}
        headerRight={[
          <Select
            key="drug-filter"
            data={DrugTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(drug) => setFilter((x) => ({ ...x, drug }))}
            placeholder={t('SelectDrug')}
            templates={['filter']}
            defaultValue={filter.drug}
            value={filter.drug}
          />,
          <Select
            key="domain-filter"
            data={RoleInputDomainTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(domain) => setFilter((x) => ({ ...x, domain }))}
            placeholder={t('SelectDomain')}
            templates={['filter']}
            defaultValue={filter.domain}
            value={filter.domain}
          />,
          <Select
            key="calculate-filter"
            data={RoleInputCalculateTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(calculate) => setFilter((x) => ({ ...x, calculate }))}
            placeholder={t('SelectCalculate')}
            templates={['filter']}
            defaultValue={filter.calculate}
            value={filter.calculate}
          />,
          <Extra
            pageState={pageState}
            filter={filter}
            setFilter={setFilter}
            key="filter"
            selectedRowKeys={selectedRowKeys}
            onFilterChanged={() => setSelectedRowKeys([])}
            onDeleteClick={onDeleteClick}
          />,
        ]}
        templates={['role-fields']}
      >
        <Table
          ready={pageState.data}
          response={response}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onRowSelectChange={(values) => setSelectedRowKeys(values)}
          columns={RoleInputColumns({ onUpdateClick, onRequirementChange })}
        />

        <Drawer visible={panelVisibility} onClose={() => setPanelVisibility(false)}>
          {inputType && inputType.data.group === RoleFieldGroupType.Number && <Description>{t('RoleInputTypeNumber')}</Description>}
          {inputType && inputType.data.group === RoleFieldGroupType.Input && <Description>{t('RoleInputTypeInput')}</Description>}
          <Element ready={pageState.data} formRef={formRef} onFinish={onFinish} inputs={inputs} columnSize={24} submit={t('Confirm')} />
        </Drawer>
      </Section>
    </Container>
  );
}

const Container = styled.div``;

const Description = styled.div`
  margin-bottom: 24px;
`;
