import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ContentViewPerDrugColumns, DrugDailyColumns, DrugTypes, NumberOfUsersPerDrugColumns } from '../../../constants';
import { reportList } from '../../../services/api';
import { Card, Table } from '../../globals';

export default function ManagerDashboard() {
  // Definitions
  const { t } = useTranslation();
  const [pageState, setPageState] = useState({ list: false });
  const [data, setData] = useState(null);

  // Hooks
  useEffect(() => {
    list();
  }, []);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await reportList((status, res) => {
      if (status) {
        if (res.data?.patientGenders?.Male || res.data?.patientGenders?.Female) {
          res.data.patientGenders = [
            { name: t('Male'), value: res.data.patientGenders.Male ?? 0, color: '#00d0e3' },
            { name: t('Female'), value: res.data.patientGenders.Female ?? 0, color: '#6353a8' },
          ];
        }

        if (res.data?.newUsersPerDrugTypes) {
          let newUsersPerDrugTypes = [];
          DrugTypes.forEach((drug) => {
            newUsersPerDrugTypes.push({
              id: drug.value,
              drug: t(drug.displayName),
              last24Hours: res.data.newUsersPerDrugTypes[0][drug.name] ?? 0,
              lastWeek: res.data.newUsersPerDrugTypes[1][drug.name] ?? 0,
              lastMonth: res.data.newUsersPerDrugTypes[2][drug.name] ?? 0,
            });
          });

          res.data.newUsersPerDrugTypes = newUsersPerDrugTypes;
        }

        if (res.data?.userCountPerDrugTypes) {
          let userCountPerDrugTypes = [];
          DrugTypes.forEach((drug) => {
            userCountPerDrugTypes.push({
              id: drug.value,
              drug: t(drug.displayName),
              count: res.data.userCountPerDrugTypes[drug.name] ?? 0,
            });
          });

          res.data.userCountPerDrugTypes = userCountPerDrugTypes;
        }

        if (res.data?.scorePerDrugTypes) {
          let scorePerDrugTypes = [];
          DrugTypes.forEach((drug) => {
            scorePerDrugTypes.push({
              id: drug.value,
              drug: t(drug.displayName),
              last24Hours: res.data.scorePerDrugTypes[0][drug.name] ?? 0,
              lastWeek: res.data.scorePerDrugTypes[1][drug.name] ?? 0,
              lastMonth: res.data.scorePerDrugTypes[2][drug.name] ?? 0,
            });
          });

          res.data.scorePerDrugTypes = scorePerDrugTypes;
        }

        if (res.data?.contentViewPerDrugTypes) {
          let contentViewPerDrugTypes = [];
          let contentViewPerDrugTypesKeys = Object.keys(res.data.contentViewPerDrugTypes);
          contentViewPerDrugTypesKeys.forEach((key, index) => {
            let data = { id: index, contentName: key };
            DrugTypes.forEach((drug) => {
              data[drug.name] = res.data.contentViewPerDrugTypes[key][drug.name] ?? 0;
            });

            contentViewPerDrugTypes.push(data);
          });

          res.data.contentViewPerDrugTypes = contentViewPerDrugTypes;
        }

        setData(res.data);
      }

      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Render
  return (
    <Container>
      <Card
        ready={pageState?.list}
        title={t('NumberOfUsersPerDrug')}
        information={t('NumberOfUsersPerDrugInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored', 'calendar']}
      >
        <Table scroll={300} ready={pageState?.list} response={{ data: data?.userCountPerDrugTypes }} columns={NumberOfUsersPerDrugColumns()} />
      </Card>

      <Card
        ready={pageState?.list}
        title={t('NumberOfClicksScoreTimely')}
        information={t('NumberOfClicksScoreTimelyInformation')}
        templates={['widget', 'manager-widget-two', 'with-header', 'header-information', 'header-colored', 'calendar']}
      >
        <Table scroll={300} ready={pageState?.list} response={{ data: data?.scorePerDrugTypes }} columns={DrugDailyColumns()} />
      </Card>

      <Card
        ready={pageState?.list}
        title={t('NumberOfNewUsersPerDrugTimely')}
        information={t('NumberOfUsersPerDrugTimelyInformation')}
        templates={['widget', 'manager-widget-two', 'with-header', 'header-information', 'header-colored', 'calendar']}
      >
        <Table scroll={300} ready={pageState?.list} response={{ data: data?.newUsersPerDrugTypes }} columns={DrugDailyColumns()} />
      </Card>

      <Card
        ready={pageState?.list}
        title={t('NumberOfPatients')}
        information={t('NumberOfPatientsInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && (
          <Body>
            <Point>{data?.patientCount}</Point>
          </Body>
        )}
      </Card>

      <Card
        ready={pageState?.list}
        title={t('NumberOfActivePatients')}
        information={t('NumberOfActivePatientsInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && (
          <Body>
            <Point>{data?.activePatientCount}</Point>
          </Body>
        )}
      </Card>

      <Card
        ready={pageState?.list}
        title={t('NumberOfViewContentsPerDrug')}
        information={t('NumberOfViewContentsPerDrugInformation')}
        templates={['widget', 'manager-widget-two', 'with-header', 'header-information', 'header-colored', 'calendar', 'scroll-content']}
      >
        <Table scroll={300} ready={pageState?.list} response={{ data: data?.contentViewPerDrugTypes }} columns={ContentViewPerDrugColumns()} />
      </Card>

      <Card
        ready={pageState?.list}
        title={t('NumberOfActiveHCPs')}
        information={t('NumberOfActiveHCPsInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && (
          <Body>
            <Point>{data?.activeHCPCount}</Point>
          </Body>
        )}
      </Card>

      <Card
        ready={pageState?.list}
        title={t('NumberOfHCPs')}
        information={t('NumberOfHCPsInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && (
          <Body>
            <Point>{data?.hcpCount}</Point>
          </Body>
        )}
      </Card>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  > * {
    float: left;
    margin-right: 16px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: calc(400px - 48px);
  width: 100%;
`;

const Point = styled.div`
  display: block;
  font-weight: bold;
  font-size: 180px;
  color: #00d0e3;
`;
