import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Card, Element, Global, Link } from '../../components/globals';
import { CountryTypes, UserForgotPasswordInputs } from '../../constants';
import i18n from '../../i18n';
import { login, loginForgotPasswordCheck, loginForgotPasswordConfirm, loginForgotPasswordRequest, loginGigyaLogin } from '../../services/api';
import { getLocale } from '../../services/cache';
import { notifySuccess } from '../../utilies/notification';

export default function UserForgotPassword() {
  // Definitions
  const { t } = useTranslation();
  const { search } = useLocation();
  const [formRef] = Form.useForm();

  const params = new URLSearchParams(search);
  const code = params.get('code');

  const [pageState, setPageState] = useState({
    form: true,
    request: false,
    url: code !== null,
  });

  // Hooks
  useEffect(() => {
    let locale = getLocale();
    moment.locale(locale);
    i18n.changeLanguage(locale);
  }, []);

  useEffect(() => {
    if (search) {
      if (code != null) {
        setPageState((x) => ({ ...x, form: false, request: true }));

        loginForgotPasswordCheck({ code }, (status, res) => {
          if (status) {
            formRef.setFieldsValue({ code: res.data.item1, email: res.data.item2 });
          }
          setPageState((x) => ({ ...x, form: true, url: status }));
        });
      }
    }
  }, [search]);

  // Events
  const onRequestFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    await login(model, async (status, res) => {
      if (status) {
        let country = CountryTypes.find((x) => x.value === res.data);
        if (country.data.saml) {
          await loginGigyaLogin({}, (samlStatus, samlRes) => {
            if (samlStatus) {
              window.location = samlRes.data;
              return;
            }
          });
        } else {
          if (!pageState.request) {
            await loginForgotPasswordRequest(model, (status, res) => {
              if (status) {
                notifySuccess(t('ForgotPasswordRequestSended'));
              }
              setPageState((x) => ({ ...x, form: true, request: status }));
            });
          }

          if (!pageState.confirm && pageState.request) {
            await loginForgotPasswordConfirm(model, (status, res) => {
              if (status) {
                notifySuccess(t('ForgotPasswordConfirm'));
                window.location = '/login';
              }
              setPageState((x) => ({ ...x, form: true }));
            });
          }
        }
      } else {
        setPageState((x) => ({ ...x, form: true }));
      }
    });
  };

  // Render
  return (
    <Global>
      <Container>
        <Card ready={true} templates={['login']}>
          <Logo src="/assets/images/logo.svg" />

          <Element
            formRef={formRef}
            ready={pageState.form}
            onFinish={onRequestFinish}
            inputs={UserForgotPasswordInputs({ isUrl: pageState.url, isRequested: pageState.request })}
            columnSize={24}
          >
            <ActionPanel>
              <Link to="/login">{t('ReturnToLoginPage')}</Link>
            </ActionPanel>

            <Button htmlType="submit" templates={['colored']} ready={pageState.form} block={true}>
              {t('Confirm')}
            </Button>
          </Element>
        </Card>
      </Container>
    </Global>
  );
}

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 20px;
  min-height: 100vh;
`;

const Logo = styled.img`
  width: 150px;
  margin: 10px auto 20px;
  display: block;
`;

const ActionPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 44px;
`;
