const RoleInputType = {
  DailyDoseMg: 0,
  DailyDoseMl: 1,
  TotalBottle: 2,
  PreparedBottle: 3,
  BatchNumber: 4,
  ExternalId: 5,
  Cycle: 6,
  Note: 7
};

export default RoleInputType;