import { DatePicker } from 'antd';
import styled, { css } from 'styled-components';

export default function DatePickerBase({
  style,
  placeholder,
  format,
  disabledDate,
  disabledTime,
  disabled,
  inputReadOnly,
  showTime,
  allowClear,
  onChange,
  ...restProps
}) {
  return (
    <Container
      style={style}
      placeholder={placeholder}
      format={format}
      disabledDate={disabledDate}
      disabledTime={disabledTime}
      onChange={onChange}
      disabled={disabled}
      inputReadOnly={inputReadOnly}
      showTime={showTime}
      allowClear={allowClear}
      {...restProps}
    />
  );
}

const Container = styled(DatePicker)`
  height: 50px;
  overflow: hidden;
  border-radius: ${(x) => x.theme.global.borderRadius};

  border: 0px;
  border-color: transparent !important;
  box-shadow: 2px 2px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;

  .ant-input {
    background-color: transparent;
  }

  &.ant-picker-status-error {
    margin-bottom: 6px;
  }

  ${({ value }) =>
    value &&
    css`
      box-shadow: inset 4px 4px 4px 0px ${(x) => x.theme.colors.lavenderGray} !important;
    `}
`;
