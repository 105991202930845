import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ActionTypes, RoleActionInputs } from '../../../constants';
import { roleActionList, roleActionUpdate, roleUserRoles } from '../../../services/api';
import { groupByList } from '../../../utilies/expression';
import { notifySuccess } from '../../../utilies/notification';
import { Button, Element } from '../../globals';

export default function RoleActionUpdate({ country, roleId, onConfirm }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [actions, setActions] = useState([]);
  const [size, setSize] = useState({ width: window?.innerWidth, height: window?.innerHeight });

  const [filter, setFilter] = useState(null);

  // Hooks
  useEffect(() => {
    setFilter((x) => ({ ...x, country, roleId }));
  }, [roleId]);

  useEffect(() => {
    if (filter) {
      list();
    }
  }, [filter]);

  useEffect(() => {
    if (window === undefined) return;

    window.addEventListener('resize', () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    });
  }, []);

  // Functions
  const list = async () => {
    let [userRoleResponse, roleActionResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        roleUserRoles(country, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
      new Promise((resolve, reject) => {
        roleActionList(filter, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
    ]);

    let countryActions = ActionTypes.filter((x) => x.data.userRole == null || userRoleResponse?.data?.some((r) => x.data.userRole === r)).sort(
      (x, y) => x.order - y.order
    );
    setActions(countryActions);

    groupByList(countryActions, 'group')?.forEach((x) => {
      let values = [];
      x.values.forEach((v) => {
        if (roleActionResponse?.data?.some((a) => a.action === v.value)) {
          values.push(v.value);
        }
      });
      formRef.setFieldsValue({ [`ActionType${x.key}`]: values });
    });
  };

  const getColumnSize = () => {
    if (size?.width >= 1400) return 6;
    if (size?.width >= 1100) return 8;
    if (size?.width >= 800) return 12;
    return 24;
  };

  // Events
  const onChange = ({ key, values }) => {
    let actionKey = `ActionType${key}`;
    formRef.setFieldsValue({ [actionKey]: values });

    values?.forEach((value) => {
      let actionType = ActionTypes.find((x) => x.value === value);
      let parentType = ActionTypes.find((x) => x.name === actionType?.prompt);

      if (parentType !== undefined && values?.find((x) => x === parentType?.value) === undefined) {
        formRef.setFieldsValue({ [actionKey]: [...values, parentType?.value] });
      }
    });
  };

  const onFinish = async (model) => {
    let actions = [];
    groupByList(ActionTypes, 'group')?.forEach((x) => {
      model[`ActionType${x.key}`]?.forEach((v) => {
        if (v !== undefined) actions.push(v);
      });
    });

    await roleActionUpdate({ actions, roleId, country }, (status) => {
      if (status) {
        onConfirm();
        notifySuccess(t('UpdateCompleteSuccess'));
      }
    });
  };

  // Render
  return (
    <Container>
      <Description>{t('RoleActionDescription')}</Description>

      <Element formRef={formRef} onFinish={onFinish} inputs={RoleActionInputs({ actions, onChange })} columnSize={getColumnSize()} />

      <Button onClick={() => formRef.submit()} templates={['colored']}>
        {t('Confirm')}
      </Button>
    </Container>
  );
}

const Container = styled.div``;

const Description = styled.div`
  margin-bottom: 24px;
`;
