const ConferenceType = {
  Pending: 0,
  Available: 1,
  Waiting: 2,
  Upcoming: 3,
  Completed: 4,
  Rejected: 5,
  Expired: 6
};

export default ConferenceType;