import { QuestionCircleOutlined } from '@ant-design/icons';
import { Rate } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight, FaSmile } from 'react-icons/fa';
import { MdSick } from 'react-icons/md';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { ActionType } from '../../../constants';
import { scoreCreate, scoreUpdate } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { Button, Card, Link } from '../../globals';

export default function VAMSScore({ user, detail, parameter, onChanged }) {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();

  const points = user.checkAction(ActionType.VAMS1TO5) ? [1, 2, 3, 4, 5] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [response, setResponse] = useState(detail);
  const [userPoint, setUserPoint] = useState(detail);
  const [pageState, setPageState] = useState({ response: false, form: true, treatment: false });

  // Hooks
  useEffect(() => {
    if (detail) {
      setResponse(detail);
      setPageState((x) => ({ ...x, response: true }));
    }
  }, [detail]);

  // Events
  const onPointChange = async (point, isRelatedTreatment) => {
    if (user.checkAction(ActionType.VAMSTreatmentQuestion) && !pageState.treatment) {
      if (point <= (user.checkAction(ActionType.VAMS1TO5) ? 2 : 4)) {
        setUserPoint(point);
        setPageState((x) => ({ ...x, treatment: true }));
        return;
      }
    }

    setPageState((x) => ({ ...x, form: false, response: false }));

    let request = response ? scoreUpdate : scoreCreate;

    await request({ point, isRelatedTreatment }, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
        setResponse((x) => ({ ...x, point }));

        if (onChanged) {
          onChanged();
        }
      }
      setPageState((x) => ({ ...x, form: true, response: true }));
    });
  };

  // Render
  return (
    <Card
      ready={pageState.form}
      templates={['widget', 'score', 'with-header', 'header-information', 'header-colored']}
      information={t('VAMSScoreInformation')}
      title={t('VAMSScore')}
      right={
        !location.pathname.includes('journey') && (
          <Link to="/journey">
            <FaCaretRight />
          </Link>
        )
      }
    >
      {pageState.treatment && (
        <TreatmentContainer>
          <ScoreDescription>{t('IsRelatedYourTreatment')}</ScoreDescription>
          <TreatmentActions>
            <Button onClick={() => onPointChange(userPoint, true)} templates={['primary', 'width-120']}>
              {t('Yes')}
            </Button>
            <Button onClick={() => onPointChange(userPoint, false)} templates={['primary', 'width-120']}>
              {t('No')}
            </Button>
          </TreatmentActions>
        </TreatmentContainer>
      )}

      {pageState.response && (
        <Container>
          <ScorePoint oneToFive={points.length === 5} point={response.point}>
            {response.point}
          </ScorePoint>

          <ScoreTitle>{t('YourScoreToday')}</ScoreTitle>
          <ScoreDescription>{t('ThanksForSharingYourMood')}</ScoreDescription>

          {(parameter === undefined || parameter.isMyData) && (
            <Button onClick={() => setPageState((x) => ({ ...x, response: false }))} templates={['colored', 'vams-score']}>
              {t('Update')}
            </Button>
          )}
        </Container>
      )}

      {!pageState.response && !pageState.treatment && (
        <Container>
          <HowAreYouTodayIcon />
          <HowAreYouTodayTitle>{t('HowAreYouFeelingTodayTitle')}</HowAreYouTodayTitle>
          <ScoreContainer oneToFive={points.length === 5}>
            <MdSick />
            <ScoreRate oneToFive={points.length === 5} onChange={onPointChange} count={points.length} defaultValue={0} character={({ index }) => index + 1} />
            <FaSmile />
          </ScoreContainer>
        </Container>
      )}
    </Card>
  );
}

const Container = styled.div`
  height: calc(100% - 8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const HowAreYouTodayIcon = styled(QuestionCircleOutlined)`
  font-size: 100px;
  margin: 30px 0px;
  color: ${(x) => x.theme.colors.mediumSpringGreen};

  @media only screen and (max-width: 600px) {
    font-size: 70px;
  }
`;

const HowAreYouTodayTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 30px 0px;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    margin: 0px;
  }
`;

const ScoreRate = styled(Rate)`
  color: transparent;
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  ${({ oneToFive }) => {
    if (oneToFive) {
      return css`
        .ant-rate-star {
          margin: 0px;
          > div {
            border-radius: 10px;
            height: 60px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 600px) {
              font-size: 12px;
              height: 40px;
              width: 40px;
              margin: 0px;
            }
          }
        }
      `;
    } else {
      return css`
        .ant-rate-star {
          margin: 0px;
          > div {
            padding: 0px 8px;
            border-radius: 10px;
            height: 60px;
            display: flex;
            align-items: center;
          }
        }
      `;
    }
  }}

  ${({ oneToFive }) => {
    if (oneToFive) {
      return css`
        div[aria-posinset='1'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score0};
          }
        }
        div[aria-posinset='2'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score3};
          }
        }
        div[aria-posinset='3'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score5};
          }
        }
        div[aria-posinset='4'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score7};
          }
        }
        div[aria-posinset='5'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score9};
          }
        }

        .ant-rate-star-full div[aria-posinset='1'] {
          background-color: ${(x) => x.theme.colors.score0};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='2'] {
          background-color: ${(x) => x.theme.colors.score3};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='3'] {
          background-color: ${(x) => x.theme.colors.score5};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='4'] {
          background-color: ${(x) => x.theme.colors.score7};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='5'] {
          background-color: ${(x) => x.theme.colors.score9};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
      `;
    } else {
      return css`
        div[aria-posinset='1'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score0};
          }
        }
        div[aria-posinset='2'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score1};
          }
        }
        div[aria-posinset='3'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score2};
          }
        }
        div[aria-posinset='4'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score3};
          }
        }
        div[aria-posinset='5'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score4};
          }
        }
        div[aria-posinset='6'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score5};
          }
        }
        div[aria-posinset='7'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score6};
          }
        }
        div[aria-posinset='8'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score7};
          }
        }
        div[aria-posinset='9'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score8};
          }
        }
        div[aria-posinset='10'] {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.score9};
          }
        }

        .ant-rate-star-full div[aria-posinset='1'] {
          background-color: ${(x) => x.theme.colors.score0};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='2'] {
          background-color: ${(x) => x.theme.colors.score1};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='3'] {
          background-color: ${(x) => x.theme.colors.score2};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='4'] {
          background-color: ${(x) => x.theme.colors.score3};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='5'] {
          background-color: ${(x) => x.theme.colors.score4};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='6'] {
          background-color: ${(x) => x.theme.colors.score5};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='7'] {
          background-color: ${(x) => x.theme.colors.score6};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='8'] {
          background-color: ${(x) => x.theme.colors.score7};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='9'] {
          background-color: ${(x) => x.theme.colors.score8};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
        .ant-rate-star-full div[aria-posinset='10'] {
          background-color: ${(x) => x.theme.colors.score9};
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: ${(x) => x.theme.colors.white};
          }
        }
      `;
    }
  }}
`;

const ScorePoint = styled.div`
  border-radius: 50%;
  width: 100px;
  height: 100px;

  font-size: 60px;
  margin: 30px 0px;
  color: ${(x) => x.theme.colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    width: 50px;
    height: 50px;
    font-size: 30px;
    margin: 30px 0px 0px 0px;
  }

  ${({ oneToFive, point }) => {
    if (oneToFive) {
      switch (point) {
        case 1:
          return css`
            background-color: ${(x) => x.theme.colors.score0};
          `;
        case 2:
          return css`
            background-color: ${(x) => x.theme.colors.score3};
          `;
        case 3:
          return css`
            background-color: ${(x) => x.theme.colors.score5};
          `;
        case 4:
          return css`
            background-color: ${(x) => x.theme.colors.score7};
          `;
        case 5:
          return css`
            background-color: ${(x) => x.theme.colors.score9};
          `;
        default:
          return css``;
      }
    } else {
      switch (point) {
        case 1:
          return css`
            background-color: ${(x) => x.theme.colors.score0};
          `;
        case 2:
          return css`
            background-color: ${(x) => x.theme.colors.score1};
          `;
        case 3:
          return css`
            background-color: ${(x) => x.theme.colors.score2};
          `;
        case 4:
          return css`
            background-color: ${(x) => x.theme.colors.score3};
          `;
        case 5:
          return css`
            background-color: ${(x) => x.theme.colors.score4};
          `;
        case 6:
          return css`
            background-color: ${(x) => x.theme.colors.score5};
          `;
        case 7:
          return css`
            background-color: ${(x) => x.theme.colors.score6};
          `;
        case 8:
          return css`
            background-color: ${(x) => x.theme.colors.score7};
          `;
        case 9:
          return css`
            background-color: ${(x) => x.theme.colors.score8};
          `;
        case 10:
          return css`
            background-color: ${(x) => x.theme.colors.score9};
          `;

        default:
          return css``;
      }
    }
  }}
`;

const ScoreTitle = styled.div`
  font-weight: bold;
  margin: 12px 0px;
  font-size: 20px;
`;

const ScoreDescription = styled.div`
  margin: 12px 0px 32px 0px;
`;

const ScoreContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${({ oneToFive }) => {
    if (oneToFive) {
      return css`
        padding: 0px 24px;
      `;
    } else {
      return css`
        padding: 0px 12px;
      `;
    }
  }}

  svg {
    font-size: 30px;
    &:first-child {
      color: ${(x) => x.theme.colors.score0};
    }

    &:last-child {
      color: ${(x) => x.theme.colors.score9};
    }

    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
`;

const TreatmentContainer = styled.div`
  height: calc(100% - 8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TreatmentActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;
