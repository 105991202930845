import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Card, Element, Global } from '../../components/globals';
import { UserPasswordInputs } from '../../constants';
import i18n from '../../i18n';
import { loginCreatePasswordCheck, loginCreatePasswordConfirm } from '../../services/api';
import { getLocale } from '../../services/cache';
import colors from '../../styles/constants/colors';
import { notifySuccess } from '../../utilies/notification';

export default function UserCreatePassword() {
  // Definitions
  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [formRef] = Form.useForm();

  const params = new URLSearchParams(search);
  const code = params.get('code');

  const [passwordValue, setPasswordValue] = useState(false);
  const [pageState, setPageState] = useState({ page: true, form: true });
  const [isCodeAvailable, setIsCodeAvailable] = useState(true);

  // Hooks
  useEffect(() => {
    let locale = getLocale();
    moment.locale(locale);
    i18n.changeLanguage(locale);

    check();
  }, []);

  const check = async () => {
    setPageState((x) => ({ ...x, page: false }));
    await loginCreatePasswordCheck({ code }, (status, res) => {
      setIsCodeAvailable(res?.data ?? false);
      setPageState((x) => ({ ...x, page: true }));
    });
  };

  // Events
  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    model = { ...model, code };

    await loginCreatePasswordConfirm(model, (status, res) => {
      if (status) {
        notifySuccess(t('CreateCompleteSuccess'));
        navigate('/');
      } else {
        setIsCodeAvailable(res.data);
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  const onPasswordChange = ({ value }) => {
    setPasswordValue(value);
  };

  const onGeneratePasswordClick = () => {
    let randomPassword = Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);

    setPasswordValue(randomPassword);

    formRef.setFieldsValue({
      password: randomPassword,
      passwordAgain: randomPassword,
    });
  };

  // Render
  return (
    <Global>
      <Container>
        <Card ready={pageState.page} templates={['login']}>
          <Logo src="/assets/images/logo.svg" />
          <Panel>
            {!isCodeAvailable && <Information>{t('YourVerificationCodeIsWrong')}</Information>}
            <Element
              ready={pageState.form}
              formRef={formRef}
              onFinish={onFinish}
              inputs={UserPasswordInputs({ onPasswordChange, onGeneratePasswordClick })}
              columnSize={24}
              style={{ display: isCodeAvailable ? 'block' : 'none' }}
            >
              <PasswordBar
                scoreWords={[t('PasswordTooWeak'), t('PasswordWeak'), t('PasswordOkay'), t('PasswordGood'), t('PasswordStrong')]}
                barColors={[colors.lavenderGray, colors.rustyRed, colors.naplesYellow, colors.darkTurquoise, colors.mediumSpringGreen]}
                shortScoreWord={t('PasswordTooShort')}
                password={passwordValue}
                minLength={3}
              />

              {isCodeAvailable && (
                <Button onClick={() => formRef.submit()} templates={['colored']} block={true}>
                  {t('Confirm')}
                </Button>
              )}
            </Element>
          </Panel>
        </Card>
      </Container>
    </Global>
  );
}

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 20px;
  min-height: 100vh;
`;

const Logo = styled.img`
  width: 150px;
  margin: 10px auto 20px;
  display: block;
`;

const Panel = styled.div``;

const Information = styled.div`
  text-align: center;
  margin: 30px 0px;
`;

const PasswordBar = styled(PasswordStrengthBar)`
  margin-bottom: 24px;
`;
