import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router';
import { ContentUserWidgetColumns } from '../../../constants';
import { contentUserGroupPoint } from '../../../services/api';
import { Card, Link, Table } from '../../globals';

export default function MyContentUsers() {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [response, setResponse] = useState(null);
  const [columns, setColumns] = useState([]);

  const [pageState, setPageState] = useState({ list: true });

  // Hooks
  useEffect(() => {
    detail();
  }, []);

  // Functions
  const detail = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await contentUserGroupPoint((status, res) => {
      if (status && res?.data) {
        let contentColumns = [];
        res.data = res.data.map((x, i) => {
          x.id = `content-${i}`;

          x.contents.forEach((item) => {
            if (!contentColumns.some((x) => x.name === item.name)) contentColumns.push({ id: item.contentId, name: item.name });

            x[item.contentId] = item.point;
          });

          return x;
        });

        setColumns(contentColumns);

        setResponse(res);
      }

      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Render
  return (
    <Card
      ready={pageState.list}
      templates={['widget', 'score', 'with-header', 'header-information', 'header-colored']}
      information={t('MyPatientInformation')}
      title={t('MyPatients')}
      right={
        !location.pathname.includes('activity/patient') && (
          <Link to="/activity/patient">
            <FaCaretRight />
          </Link>
        )
      }
    >
      <Table ready={pageState.list} response={response} columns={ContentUserWidgetColumns({ contents: columns })} style={{ margin: '0px 8px' }} />
    </Card>
  );
}
