import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdVolunteerActivism } from 'react-icons/md';
import { Page, Table } from '../../components/globals';
import { ActivityPatientColumns, PatientActivityTypes } from '../../constants';
import { patientActivityList } from '../../services/api';

export default function PatientActivities() {
  // Definitions
  const { t } = useTranslation();

  const [response, setResponse] = useState(null);

  const [pageState, setPageState] = useState({
    list: true,
    form: true,
    delete: true,
  });

  const [filter, setFilter] = useState({ pageNumber: 1, dataLimit: 10, includeUser: true });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await patientActivityList(filter, (status, res) => {
      if (status) {
        res.data = res.data.map((x) => {
          x.typeData = PatientActivityTypes.find((f) => f.value === x.type);
          return x;
        });
        setResponse(res);
      }

      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Render
  return (
    <Page
      title={t('PatientActivities')}
      icon={<MdVolunteerActivism />}
      routes={[
        {
          name: t('PatientActivities'),
          breadcrumbName: t('PatientActivities'),
          icon: <MdVolunteerActivism />,
        },
      ]}
    >
      <Table ready={pageState.list} response={response} filter={filter} setFilter={setFilter} columns={ActivityPatientColumns()} />
    </Page>
  );
}
