import { useTranslation } from 'react-i18next';
import { LanguageTypes } from '../../constants';

export default function LocalizationInputs({ languages, isAllCountry, isUpdate }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    isUpdate && {
      type: 'hidden',
      name: 'currentKey',
    },
    {
      type: 'input',
      name: 'key',
      label: t('LocalizationKey'),
      placeholder: t('LocalizationKeyEmpty'),
      rules: [{ required: true, message: t('LocalizationKeyEmpty') }],
    },
    {
      name: 'localizations',
      templates: 'full-width',
      elements: (languages ? LanguageTypes.filter((x) => languages.some((s) => s === x.value)) : LanguageTypes).map((x) => ({
        type: 'input',
        name: x.value,
        label: t(`${x.name}Translation`),
        placeholder: t(`${x.name}TranslationEmpty`),
        rules: [{ required: true, message: t(`${x.name}TranslationEmpty`) }],
      })),
    },
    isAllCountry && {
      type: 'switch',
      name: 'isAllCountry',
      label: t('LocalizationIsAllCountry'),
      templates: ['horizontal-item'],
    },
  ];
}
