import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import { GiMedicines } from 'react-icons/gi';
import { useLocation, useNavigate } from 'react-router';
import styled, { css } from 'styled-components';
import { PatientActivityTypes } from '../../../constants';
import { patientActivityList } from '../../../services/api';
import { Card, Link } from '../../globals';

export default function MyPatients() {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState(null);

  const [pageState, setPageState] = useState({ list: true });

  const [filter] = useState({ pageNumber: 1, dataLimit: 10, includeUser: true });

  // Hooks
  useEffect(() => {
    detail();
  }, []);

  // Functions
  const detail = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await patientActivityList(filter, (status, res) => {
      if (status) {
        res.data = res.data.map((x) => {
          x.typeData = PatientActivityTypes.find((f) => f.value === x.type);
          return x;
        });
        setData(res.data);
      }

      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Render
  return (
    <Card
      ready={pageState.list}
      templates={['widget', 'score', 'with-header', 'header-information', 'header-colored']}
      information={t('MyPatientInformation')}
      title={t('MyPatients')}
      right={
        !location.pathname.includes('activity/patient') && (
          <Link to="/activity/patient">
            <FaCaretRight />
          </Link>
        )
      }
    >
      {data?.length > 0 ? (
        <Container>
          <List length={data.length}>
            {data.map((x, i) => (
              <ListItem key={`item-${i}`} onClick={() => navigate(`/journey/${x.user.userRole}/${x.user.country}/${x.user.id}`)}>
                <Header>
                  <Title>{x.user.fullName}</Title>

                  {!location.pathname.includes('journey') && (
                    <Right>
                      <GiMedicines /> <FaCaretRight />
                    </Right>
                  )}
                </Header>
                <Body>
                  <Detail>{t(x.typeData.displayName)}</Detail>
                  <Date>{moment(x.creationTime).format('ll')}</Date>
                </Body>
              </ListItem>
            ))}
          </List>
        </Container>
      ) : (
        <NotFound>{t('DataNotFoundException')}</NotFound>
      )}
    </Card>
  );
}

const Container = styled.div``;

const List = styled.div`
  ${({ length }) =>
    length > 4 &&
    css`
      max-height: 440px;
      overflow-y: scroll;
    `}
`;

const ListItem = styled.div`
  padding: 12px;
  margin: 8px;
  border: 1px solid ${(x) => x.theme.colors.darkTurquoiseOpacity20};
  border-radius: ${(x) => x.theme.global.borderRadius};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 2px 2px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Right = styled.div`
  color: ${(x) => x.theme.colors.mediumSpringGreen};
`;

const Body = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const Detail = styled.div``;

const Date = styled.div`
  font-size: 10px;
  font-weight: bold;
`;

const NotFound = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
