const NotificationGroupType = {
  BeforeStepComplete: 0,
  AfterStepComplete: 1,
  Reject: 2,
  RemainingDays: 3,
  Create: 4,
  Update: 5,
  Complete: 6,
  Delete: 7,
  Suspend: 8,
  Continue: 9,
  TreatmentUsage: 10,
  TreatmentUsageRemind: 11,
  TreatmentUsageMissed: 12,
  RemainingHours: 13,
  Approve: 14,
  Expire: 15,
  ConferenceJoin: 16,
  Assignment: 17,
  Unassignment: 18,
  LowScoreNotRelatedTreatment: 19,
  LowScoreRelatedTreatment: 20
};

export default NotificationGroupType;