import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTruck } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router';
import { checkDeliveryCalculatorInputs } from '../../calculators/doseCalculator';
import { Button, Card, Drawer, Modal, Page } from '../../components/globals';
import DeliveryCreateProcess from '../../components/pages/delivery/DeliveryCreateProcess';
import DeliveryDoseCalculator from '../../components/pages/delivery/DeliveryDoseCalculator';
import DeliverySuggestions from '../../components/pages/delivery/DeliverySuggestions';
import DeliveryUser from '../../components/pages/delivery/DeliveryUser';
import {
  ActionType,
  DeliveryActionType,
  DrugTypes,
  NotificationGroupType,
  RoleFieldDiseaseTypes,
  RoleFieldType,
  RoleInputCalculateTypes,
  RoleInputDomainType,
  RoleInputType,
  RoleInputTypes,
  UserRoleTypes,
} from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { deliveryCreate, deliveryFlowListGlobal, roleInputCache, userDetail } from '../../services/api';
import { notifyError, notifySuccess } from '../../utilies/notification';

export default function DeliveryCreate() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { countryType, userRoleType, id } = useParams();
  const [formRef] = Form.useForm();
  const [user] = useCacheUser();

  const [deliveryUser, setDeliveryUser] = useState(null);
  const [drugs, setDrugs] = useState(null);
  const [calculates, setCalculates] = useState(null);
  const [roleInputs, setRoleInputs] = useState(null);
  const [flows, setFlows] = useState(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [selectedCalculate, setSelectedCalculate] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [selectedFlowIsCreateTreatment, setSelectedFlowIsCreateTreatment] = useState(null);
  const [selectedDetail, setSelectedDetail] = useState(null);

  const [panelVisibility, setPanelVisibility] = useState(false);
  const [userPanelVisibility, setUserPanelVisibility] = useState(false);
  const [suggestionPanelVisibility, setSuggestionPanelVisibility] = useState(false);
  const [suggestionUserRoleDatas, setSuggestionUserRoleDatas] = useState([]);
  const [deliveryUsers, setDeliveryUsers] = useState([id, user.i]);

  const [pageState, setPageState] = useState({
    load: false,
    save: true,
  });

  const [roleInputFilter] = useState({
    orderBy: ['row|asc'],
    pageNumber: 1,
    dataLimit: 100,
    isDeleted: false,
    country: parseInt(countryType ?? user.c),
    roleId: user.r,
    domain: RoleInputDomainType.Delivery,
  });

  const [userFilter] = useState({
    id,
    includeFields: true,
    includeCity: true,
    country: parseInt(countryType ?? user.c),
    userRole: parseInt(userRoleType ?? user.u),
  });

  const [deliveryFlowFilter] = useState({
    country: parseInt(countryType ?? user.c),
    includeSteps: true,
    isDeleted: false,
  });

  // Hooks
  useEffect(() => {
    detail();
  }, [userFilter, deliveryFlowFilter]);

  // Functions
  const detail = async () => {
    setPageState((x) => ({ ...x, load: false }));

    if (!user?.dd) {
      notifyError(t('RoleDeliveryDrugNotFoundException'));
      navigate(`/`);
      return;
    }

    let [roleInputResponse, userResponse, deliveryFlowResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        roleInputCache(roleInputFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        userDetail(userFilter, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
      new Promise((resolve, reject) => {
        deliveryFlowListGlobal(deliveryFlowFilter, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
    ]);

    // Check Data
    if (roleInputResponse?.data == null) {
      notifyError(t('RoleInputNotFoundException'));
      navigate(`/delivery/list`);
      return;
    }

    if (userResponse?.data === null) {
      notifyError(t('UserNotFoundException'));
      navigate(`/delivery/list`);
      return;
    }

    if (deliveryFlowResponse === null || !deliveryFlowResponse.data?.length) {
      notifyError(t('DeliveryFlowNotFoundException'));
      navigate(`/delivery/list`);
      return;
    }

    // Generate Inputs
    setRoleInputs(roleInputResponse.data);

    // Flows
    setFlows(deliveryFlowResponse.data);

    // Generate User Data
    let diseaseTypes = userResponse.data.fields?.find((x) => x.fieldType === RoleFieldType.DiseaseTypes);

    if (diseaseTypes === undefined) {
      notifyError(t('DiseaseNotFoundException'));
      navigate(`/delivery/list`);
      return;
    }

    let userDrugs = [];
    let roleDrugs = [...new Set(roleInputResponse.data.map((x) => x.drug))];
    diseaseTypes.value.forEach((x) => {
      let diseaseTypeData = RoleFieldDiseaseTypes.find((f) => f.value === x);
      diseaseTypeData.data.drugs.forEach((d) => {
        let drugData = DrugTypes.find((f) => f.value === d);
        if (
          user.dd.some((s) => s === drugData.value) &&
          roleDrugs.some((s) => s === drugData.value) &&
          (userDrugs.length <= 0 || userDrugs.some((s) => s.value !== drugData.value))
        )
          userDrugs.push(drugData);
      });
    });

    if (userDrugs.length <= 0) {
      notifyError(t('RoleInputDrugNotFoundException'));
      navigate(`/delivery/list`);
      return;
    }

    setDrugs(userDrugs);

    let weight = userResponse.data.fields.find((x) => x.fieldType === RoleFieldType.Weight);
    let birthDate = userResponse.data.fields.find((x) => x.fieldType === RoleFieldType.BirthDate);
    let address = userResponse.data.fields.find((x) => x.fieldType === RoleFieldType.Address);
    let months = moment().diff(moment(birthDate.value), 'months', true);

    setDeliveryUser({ ...userResponse.data, months, birthDate: moment(birthDate.value), weight: weight?.value, address: address?.value });

    setPageState((x) => ({ ...x, load: true }));
  };

  const save = async (model, redirect) => {
    if (currentStep === 3 && redirect === null) {
      setCurrentStep(4);
      return;
    }

    model = { ...model, deliveryFlowStepId: redirect.id, deliveryUsers };

    await deliveryCreate(model, (status, res) => {
      if (status) {
        notifySuccess(t('CreateCompleteSuccess'));
        navigate(`/delivery/list`);
      } else {
        if (res?.data?.errors) {
          let relationError = res.data.errors.find((x) => x.code === 'DeliveryUserRelationNotFoundException');
          if (relationError) {
            let userRoleDatas = relationError.message.split(',').map((x) => UserRoleTypes.find((u) => u.value === parseInt(x)));
            setSuggestionUserRoleDatas(userRoleDatas);
            setSuggestionPanelVisibility(true);
          }
        }

        setCurrentStep(3);
      }
    });
  };

  // Events
  const onStepClick = (value) => {
    if (value < currentStep) setCurrentStep(value);

    switch (value) {
      default:
        break;
      case 0: // Flow
        setSelectedDrug(null);
        setSelectedCalculate(null);
        break;
      case 1: // Drug
        setSelectedCalculate(null);
        break;
      case 2: // Calculate
        break;
      case 3: // Detail
        break;
      case 4: // Redirect
        break;
    }
  };

  const onDrugClick = (value) => {
    setSelectedCalculate(null);
    setSelectedDrug(value);
    formRef.resetFields();

    let calculateTypes = [...new Set(roleInputs.filter((x) => x.drug === value.value).map((x) => x.calculate))];
    let calculateDatas = calculateTypes.map((x) => RoleInputCalculateTypes.find((f) => f.value === x));
    setCalculates(calculateDatas);

    setCurrentStep(2);
  };

  const onCalculateClick = (value) => {
    let calculateRoleInputs = roleInputs.filter((x) => x.drug === selectedDrug.value && x.calculate === value.value);

    var result = checkDeliveryCalculatorInputs(selectedDrug.value, calculateRoleInputs, value.value, selectedFlowIsCreateTreatment);
    if (result) {
      notifyError(result);
      return;
    }

    setSelectedCalculate(value);
    formRef.resetFields();
    setCurrentStep(3);
  };

  const onFlowClick = (value) => {
    setSelectedFlow(value);
    setSelectedDrug(null);
    formRef.resetFields();

    setSelectedFlowIsCreateTreatment(
      value.steps.some((x) => x.notifications.some((s) => s.group === NotificationGroupType.RemainingDays && x.action === DeliveryActionType.CompleteDelivery))
    );

    setCurrentStep(1);
  };

  const onDetailFinish = async (model) => {
    setPageState((x) => ({ ...x, save: false }));

    if (model.inputs) {
      Object.keys(model.inputs).forEach((x) => {
        let value = model.inputs[x];

        let roleInput = roleInputs.find((f) => f.id === x);
        if (roleInput) {
          let roleTypeData = RoleInputTypes.find((f) => f.value === roleInput.type);
          if (roleTypeData) {
            model[roleTypeData.name] = value;
            return;
          }
        }

        model[x] = value;
      });
    }

    setSelectedDetail(model);

    if (selectedFlow.steps.filter((x) => x.parentId === null).length > 1) {
      await save(model, null);
    } else {
      let redirect = selectedFlow.steps.find((x) => x.parentId === null);

      await save(model, redirect);
    }

    setPageState((x) => ({ ...x, save: true }));
  };

  const onRedirectClick = async (redirect) => {
    await save(selectedDetail, redirect);
  };

  const onConfirmDoseCalculator = (data, values, drugType) => {
    let mgInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMg);
    let mlInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMl);
    let totalBottleInput = roleInputs.find((x) => x.type === RoleInputType.TotalBottle);
    let model = formRef.getFieldsValue();

    switch (drugType) {
      case 'evrysdi-shelf-life':
        formRef.setFieldsValue({
          inputs: {
            ...model.inputs,
            birthDate: values.birthDate,
            weight: values.weight,
            treatmentDurationInDays: values.durationInDays,
            [mgInput?.id]: data.miligram,
            [mlInput?.id]: data.mililiter,
            [totalBottleInput.id]: data.bottle,
          },
        });
        break;
      case 'evrysdi-normal':
      default:
        formRef.setFieldsValue({
          inputs: {
            ...model.inputs,
            birthDate: values.birthDate,
            weight: values.weight,
            treatmentDurationInMonths: values.durationInMonths,
            [mgInput?.id]: data.miligram,
            [mlInput?.id]: data.mililiter,
            [totalBottleInput.id]: data.bottle,
          },
        });
        break;
    }

    setPanelVisibility(false);
  };

  // Render
  return (
    <Page
      parent={t('Deliveries')}
      title={t('AddNew')}
      icon={<FaPlus />}
      routes={[
        {
          path: `/delivery/list`,
          name: t('Deliveries'),
          breadcrumbName: t('Deliveries'),
          icon: <FaTruck />,
        },
        {
          name: t('AddNew'),
          breadcrumbName: `AddNew`,
          icon: <FaPlus />,
        },
      ]}
      extra={[
        currentStep === 3 &&
          (user?.checkAction(ActionType.CalculatorEvrysdiNormal) ||
            user?.checkAction(ActionType.CalculatorEvrysdiShelfLife) ||
            user?.checkAction(ActionType.CalculatorEnspryngNormal)) && (
            <Button key="calculator" templates={['primary']} onClick={() => setPanelVisibility(true)}>
              {t('Calculator')}
            </Button>
          ),
        currentStep === 3 && user.checkAction(ActionType.DeliveryUser) && (
          <Button key="users" templates={['primary']} onClick={() => setUserPanelVisibility(true)}>
            {t('DeliveryUsersPanel')}
          </Button>
        ),
        currentStep === 3 && selectedFlow.steps.filter((x) => x.parentId === null).length <= 1 && (
          <Button ready={pageState.save} key="start-delivery" templates={['colored']} onClick={() => formRef.submit()}>
            {t('StartTheDelivery')}
          </Button>
        ),
        currentStep === 3 && selectedFlow.steps.filter((x) => x.parentId === null).length > 1 && (
          <Button key="confirm" templates={['colored']} onClick={() => formRef.submit()}>
            {t('Confirm')}
          </Button>
        ),
      ]}
    >
      <Card ready={currentStep !== null && pageState.load} templates={['page']}>
        <DeliveryCreateProcess
          user={user}
          deliveryUser={deliveryUser}
          country={parseInt(countryType ?? user.c)}
          current={currentStep}
          roleInputs={roleInputs}
          drugs={drugs}
          flows={flows}
          calculates={calculates}
          onStepClick={onStepClick}
          onDrugClick={onDrugClick}
          selectedDrug={selectedDrug}
          onCalculateClick={onCalculateClick}
          selectedCalculate={selectedCalculate}
          onFlowClick={onFlowClick}
          selectedFlow={selectedFlow}
          selectedFlowIsCreateTreatment={selectedFlowIsCreateTreatment}
          onDetailFinish={onDetailFinish}
          onRedirectClick={onRedirectClick}
          formRef={formRef}
        />
      </Card>

      <Modal
        width={500}
        title={t('Suggestions')}
        visible={suggestionPanelVisibility}
        onCancelClick={() => setSuggestionPanelVisibility(false)}
        cancelText={t('Confirm')}
        templates={['colored']}
      >
        <DeliverySuggestions
          targetUserId={userFilter.id}
          suggestionUserRoleDatas={suggestionUserRoleDatas}
          onDeliveryCreateUserSelectionClick={() => {
            setUserPanelVisibility(true);
            setSuggestionPanelVisibility(false);
          }}
        />
      </Modal>

      <DeliveryUser visible={userPanelVisibility} onClose={() => setUserPanelVisibility(false)} deliveryUsers={deliveryUsers} setDeliveryUsers={setDeliveryUsers} />

      <Drawer width={400} visible={panelVisibility} onClose={() => setPanelVisibility(false)}>
        <DeliveryDoseCalculator
          user={{ ...user, birthDate: deliveryUser?.birthDate, weight: deliveryUser?.weight }}
          visible={panelVisibility}
          onConfirm={onConfirmDoseCalculator}
        />
      </Drawer>
    </Page>
  );
}
