import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiTemplate } from 'react-icons/hi';
import { RiEdit2Fill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router';
import { Button, Card, Element, Page } from '../../components/globals';
import { TemplateGroupTypes, TemplateInputs, TemplateType, TemplateTypes } from '../../constants';
import { templateDetail, templateUpdate } from '../../services/api';
import { notifyError, notifySuccess } from '../../utilies/notification';

export default function TemplateUpdate() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [formRef] = Form.useForm();

  const [templateCountry, setTemplateCountry] = useState(null);
  const [templateTypeData, setTemplateTypeData] = useState(null);
  const [templateGroupData, setTemplateGroupData] = useState(null);
  const [pageState, setPageState] = useState({ detail: false, form: true });

  const [filter] = useState({ id: id });

  // Hooks
  useEffect(() => {
    detail();
  }, [id, filter]);

  // Functions
  const detail = async () => {
    setPageState((x) => ({ ...x, detail: false }));

    if (id === undefined) {
      notifyError(t('TemplateNotFoundException'));
      setTimeout(() => navigate('/template/list'), 500);
      return;
    }

    await templateDetail(filter, (status, res) => {
      if (status) {
        if (res.data.country !== undefined) {
          res.data.templateCountry = res.data.country;
          setTemplateCountry(res.data.country);
        }

        setTemplateTypeData(TemplateTypes.find((x) => x.value === res.data.type));
        setTemplateGroupData(TemplateGroupTypes.find((x) => x.value === res.data.group));

        formRef.setFieldsValue(res.data);
      } else {
        setTimeout(() => navigate('/template/list'), 500);
      }

      setPageState((x) => ({ ...x, detail: status }));
    });
  };

  // Events
  const onFormFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    await templateUpdate(model, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
      }
      setPageState((x) => ({ ...x, form: true }));
    });
  };

  // Render
  return (
    <Page
      title={t('TemplateUpdate')}
      icon={<RiEdit2Fill />}
      routes={[
        {
          path: '/template/list',
          name: t('Templates'),
          breadcrumbName: 'Templates',
          icon: <HiTemplate />,
        },
        {
          name: t('TemplateUpdate'),
          breadcrumbName: 'TemplateUpdate',
          icon: <RiEdit2Fill />,
        },
      ]}
      extra={[
        <Button key="confirm" ready={pageState?.form} onClick={() => formRef.submit()} templates={['colored']}>
          {t('Confirm')}
        </Button>,
      ]}
    >
      <Card ready={pageState.detail} templates={['page']}>
        <Element
          formRef={formRef}
          inputs={TemplateInputs({ isUpdate: true, templateCountry, templateTypeData, templateGroupData })}
          onFinish={onFormFinish}
          columnSize={templateTypeData?.value === TemplateType.UserInvite ? (templateCountry === null ? 8 : 6) : templateCountry === null ? 12 : 8}
        />
      </Card>
    </Page>
  );
}
