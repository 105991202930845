import { Editor } from '@tinymce/tinymce-react';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { DefaultEmailTemplate } from '../../templates/email/DefaultEmailTemplate';

export default function EditorBase({ height, value, init, onChange }) {
  const editorRef = useRef(null);

  // https://www.tiny.cloud/docs/tinymce/6/menus-configuration-options/
  // https://www.tiny.cloud/docs/tinymce/6/toolbar-configuration-options/

  const getToolbar = () => {
    return [
      { name: 'history', items: ['undo', 'redo'] },
      { name: 'styles', items: ['styles'] },
      { name: 'formatting', items: ['bold', 'italic'] },
      { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'] },
      { name: 'indentation', items: ['outdent', 'indent'] },
    ];
  };

  const getMenu = () => {
    return {
      file: { title: 'File', items: 'newdocument restoredraft | preview | export print | deleteallconversations' },
      edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
      view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments' },
      insert: {
        title: 'Insert',
        items:
          'image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime',
      },
      format: {
        title: 'Format',
        items:
          'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat',
      },
      tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount' },
      table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
      help: { title: 'Help', items: 'help' },
    };
  };

  return (
    <Container
      tinymceScriptSrc={process.env.PUBLIC_URL + '/assets/libraries/tinymce/tinymce.min.js'}
      value={value}
      onInit={(event, editor) => {
        editorRef.current = editor;
      }}
      init={{
        ...init,
        selector: 'textarea',
        height: height ?? 500,
        menubar: true,
        menu: getMenu(),
        plugins: ['lists', 'code', 'template', 'link'],
        toolbar: getToolbar(),
        toolbar_mode: 'sliding',
        editable_class: 'editable-area',
        noneditable_class: 'non-editable-area',
        templates: [DefaultEmailTemplate],
        content_style: null,
        convert_urls: false,
        forced_root_block: 'p',
        pagebreak_separator: '<div style = "display:block; clear:both; page-break-after:always;"></div>',
      }}
      onEditorChange={(content, editor) => {
        onChange(content);
      }}
    />
  );
}

const Container = styled(Editor)``;
