const colors = {
  white: '#FFF',
  antiFlashWhite: '#F1F6F8',
  antiFlashWhiteDark: '#EDF3F5',
  deepKaomaru: '#2E2060',
  americanBlue: '#3E336D',
  robinEggBlue: '#01d9c8',
  mediumSpringGreen: '#02E3AB',
  darkTurquoise: '#00CFE4',
  darkTurquoiseHeader: '#06d7ef',
  darkTurquoiseOpacity50: 'rgba(0, 207, 228, 50%)',
  darkTurquoiseOpacity20: 'rgba(0, 207, 228, 20%)',
  lavenderGray: '#C7C8D7',
  rustyRed: '#D61E46',
  lightBlue: '#7dd3fc',
  naplesYellow: '#FAD860',
  naplesYellowOpacity10: 'rgb(250, 216, 96, 10%)',
  naplesYellowOpacity30: 'rgb(250, 216, 96, 30%)',
  score0: '#D61E46',
  score1: '#F5515F',
  score2: '#FA735E',
  score3: '#FF955C',
  score4: '#FAD860',
  score5: '#BEDD4E',
  score6: '#8CD944',
  score7: '#4ED944',
  score8: '#00DAA6',
  score9: '#3AF7CB',
};

export default colors;
