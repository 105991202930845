import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CountryTypes, UserRelationColumns, UserRelationType, UserRoleActionType, UserRoleTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { userList, userRelationList, userRelationUpdate } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { updateResponse } from '../../../utilies/stateManagement';
import { Drawer, Select, Table } from '../../globals';

export default function UserRelation({ visible, onClose, rowUser }) {
  // Definitions
  const { t } = useTranslation();

  const [user] = useCacheUser();
  const [response, setResponse] = useState(null);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: true,
    orderBy: ['modificationTime|desc'],
    country: rowUser.country,
    userRole: rowUser.userRole,
  });

  const [relationIdFilter, setRelationIdFilter] = useState({
    type: UserRelationType.Relation,
    currentUserRole: rowUser.userRole,
    relationUserRole: rowUser.userRole,
    country: rowUser.country,
    userId: rowUser.id,
    isDeleted: false,
  });

  const isAllCountry = user.checkUserRole(rowUser.userRole, UserRoleActionType.AllCountry);
  const userRoles = user.getUserRoles();

  // Hooks
  useEffect(() => {
    setFilter((x) => ({ ...x, country: rowUser.country, userRole: rowUser.userRole }));
    setRelationIdFilter((x) => ({ ...x, currentUserRole: rowUser.userRole, relationUserRole: rowUser.userRole, country: rowUser.country, userId: rowUser.id }));
  }, [rowUser]);

  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    let [relationResponse, userReponse] = await Promise.all([
      new Promise((resolve, reject) => {
        userRelationList(relationIdFilter, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
      new Promise((resolve, reject) => {
        userList(filter, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
    ]);

    userReponse.data = userReponse.data.map((x) => ({ ...x, isRelated: relationResponse.data.some((r) => x.id === r.relationUserId) }));

    setResponse(userReponse);

    setPageState((x) => ({ ...x, list: true }));
  };

  // Events
  const onRelationChange = async (val, row) => {
    setResponse(updateResponse(response, 'isRelated', val, true, row));

    let model = {
      country: filter.country,
      userId: rowUser.id,
      userRole: rowUser.userRole,
      relationUserId: row.id,
      relationUserRole: row.userRole,
      isActive: val,
    };

    await userRelationUpdate(model, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
      } else {
        val = !val;
      }

      setResponse(updateResponse(response, 'isRelated', val, false, row));
    });
  };

  const onCountryChange = (country) => {
    setFilter((x) => ({ ...x, country }));
    setRelationIdFilter((x) => ({ ...x, country }));
  };

  const onUserRoleChange = (userRole) => {
    setFilter((x) => ({ ...x, userRole }));
    setRelationIdFilter((x) => ({ ...x, relationUserRole: userRole }));
  };

  return (
    <Drawer width={700} visible={visible} onClose={onClose} title={t('RelationPanelTitle')}>
      {userRoles.length > 0 && (
        <RelationFilter>
          <FilterText>{t('Filter')}</FilterText>
          {isAllCountry && (
            <Select
              key="country-filter"
              data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
              onChange={onCountryChange}
              placeholder={t('SelectCountry')}
              defaultValue={filter.country}
              value={filter.country}
              allowClear={false}
            />
          )}

          <Select
            key="user-role-filter"
            data={UserRoleTypes.filter((x) => userRoles.some((u) => u === x.value)).map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={onUserRoleChange}
            placeholder={t('SelectUserRole')}
            defaultValue={filter.userRole}
            value={filter.userRole}
            allowClear={false}
          />
        </RelationFilter>
      )}

      <Table
        style={{ marginTop: 24 }}
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        columns={UserRelationColumns({ user, rowUserId: rowUser?.id, onRelationChange })}
        templates={['drawer']}
      />
    </Drawer>
  );
}

const RelationFilter = styled.div`
  position: relative;
  padding-top: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
  > * {
    width: 100%;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const FilterText = styled.div`
  position: absolute;
  top: 0px;
  font-weight: bold;
`;
