import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import { useLocation } from 'react-router';
import { AutoSizer, List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import styled, { css } from 'styled-components';
import { DrugType, TreatmentUsageStatusType } from '../../../constants';
import { treatmentUsageStatusUpdate } from '../../../services/api';
import { Button, Card, Link, Modal } from '../../globals';

export default function TreatmentDetail({ treatment }) {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [rowTreatment, setRowTreatment] = useState(null);

  const [selectedIndex] = useState(treatment.selectedIndex);
  const [selectedDate] = useState(moment());
  const [pageState, setPageState] = useState({ list: true });

  // Functions
  const getDoseText = (usage) => {
    switch (treatment.drug) {
      default:
      case DrugType.Evrysdi:
        return `${usage.dailyDoseMg}mg/${usage.dailyDoseMl}ml`;

      case DrugType.Enspryng:
        return `${usage.dailyDoseMg}mg`;
    }
  };

  // Events
  const treatmentUsageClick = (row) => {
    if (moment() > moment(row.time)) {
      setRowTreatment(row);
      setPanelVisibility(true);
    }
  };

  const treatmentUseClick = async (status) => {
    rowTreatment.status = status;

    setPageState((x) => ({ ...x, list: false }));
    await treatmentUsageStatusUpdate(rowTreatment, (status, res) => {
      if (status) {
        setPanelVisibility(false);
      }

      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Components
  const UsageAction = ({ usage }) => {
    switch (usage.status) {
      case TreatmentUsageStatusType.Pending:
        return (
          <Button templates={['colored', 'usage-icon']} onClick={() => treatmentUsageClick(usage)}>
            <RiEdit2Fill />
          </Button>
        );

      case TreatmentUsageStatusType.Used:
        return (
          <Button templates={['white', 'usage-icon']} onClick={() => treatmentUsageClick(usage)}>
            <RiEdit2Fill />
          </Button>
        );

      case TreatmentUsageStatusType.NotUsed:
        return (
          <Button templates={['white', 'usage-icon']} onClick={() => treatmentUsageClick(usage)}>
            <RiEdit2Fill />
          </Button>
        );

      default:
        return <>{t('NotImplemented')}</>;
    }
  };

  const RenderRow = ({ index, key, style }) => {
    let usage = treatment.usages[index];

    return (
      <UsageListItem key={key} style={style}>
        <UsageItem selected={selectedDate.isSame(usage.time, 'day')} status={usage.status}>
          <UsageDetail>
            <UsageTime>{moment(usage.time).format('llll')}</UsageTime>
            <UsageDose>
              {t(usage.drugData?.displayName)} - {getDoseText(usage)}
            </UsageDose>
          </UsageDetail>

          {moment() >= usage.time && <UsageAction usage={usage} />}
        </UsageItem>
      </UsageListItem>
    );
  };

  // Render
  return (
    <Card
      ready={pageState.list}
      templates={['widget', 'score', 'with-header', 'header-information', 'header-colored']}
      information={t('TreatmentDetailInformation')}
      title={t('TreatmentDetail')}
      right={
        !location.pathname.includes('journey') && (
          <Link to="/journey">
            <FaCaretRight />
          </Link>
        )
      }
    >
      <Header>
        <HeaderItem>
          <Name>{t('Reference')}</Name>
          <Value>{treatment.reference}</Value>
        </HeaderItem>
        <HeaderItem>
          <Name>{t('Drug')}</Name>
          <Value>{t(treatment.drugData.displayName)}</Value>
        </HeaderItem>
        <HeaderItem>
          <Name>{t('StartDate')}</Name>
          <Value>{t(treatment.startDate.format('lll'))}</Value>
        </HeaderItem>
        <HeaderItem>
          <Name>{t('EndDate')}</Name>
          <Value>{t(treatment.endDate.format('lll'))}</Value>
        </HeaderItem>
      </Header>

      <UsageItemList>
        <AutoSizer>
          {({ height, width }) => (
            <UsageList scrollToIndex={selectedIndex} height={height - 20} rowCount={treatment.usages.length} rowHeight={70} rowRenderer={RenderRow} width={width} />
          )}
        </AutoSizer>
      </UsageItemList>

      <Modal width={320} title={t('TreatmentUsage')} visible={panelVisibility} onCancelClick={() => setPanelVisibility(false)} footer={null} templates={['colored']}>
        <Button onClick={() => treatmentUseClick(TreatmentUsageStatusType.Used)} templates={['colored']} block={true}>
          {t('Used')}
        </Button>
        <p></p>
        <Button onClick={() => treatmentUseClick(TreatmentUsageStatusType.NotUsed)} templates={['colored']} block={true}>
          {t('NotUsed')}
        </Button>
      </Modal>
    </Card>
  );
}

const Header = styled.div`
  width: 100%;
  padding: 10px 10px 0px 10px;
  overflow: hidden;
  box-shadow: 0px 0px 6px 0px ${(x) => x.theme.colors.lavenderGray};
`;

const HeaderItem = styled.div`
  float: left;
  width: 50%;
  margin-bottom: 8px;
`;

const Name = styled.div`
  font-size: 10px;
`;

const Value = styled.div`
  font-weight: bold;
`;

const UsageItemList = styled.div`
  height: calc(100% - 100px);
`;

const UsageList = styled(List)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const UsageListItem = styled.div``;

const UsageItem = styled.div`
  position: relative;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
  width: calc(100% - 16px);
  transition: all 0.3s;
  margin-left: 8px;

  ${({ status }) => {
    switch (status) {
      case TreatmentUsageStatusType.NotUsed:
        return css`
          background-color: ${(x) => x.theme.colors.rustyRed};
          color: ${(x) => x.theme.colors.white};
        `;
      case TreatmentUsageStatusType.Used:
        return css`
          background-color: ${(x) => x.theme.colors.mediumSpringGreen};
          color: ${(x) => x.theme.colors.white};
        `;
      default:
        return css`
          background-color: transparent;
        `;
    }
  }}

  ${({ selected, status }) =>
    selected &&
    status === TreatmentUsageStatusType.Pending &&
    css`
      background-color: ${(x) => x.theme.colors.darkTurquoiseOpacity20};
    `}

  &:hover {
    transform: translateY(-0.5px);
    box-shadow: 2px 2px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;
    border-radius: ${(x) => x.theme.global.borderRadius};
  }
`;

const UsageDetail = styled.div`
  width: calc(100% - 70px);
`;

const UsageTime = styled.div`
  font-weight: bold;
`;

const UsageDose = styled.div``;
