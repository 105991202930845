import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import styled, { css } from 'styled-components';
import ValidFileTypes from '../types/ValidFileTypes';

export default function StorageInputs({ files, setFiles, onFolderCheckChange, onFolderNameTextChange, isNewFolder }) {
  const { t } = useTranslation();

  return [
    {
      type: 'upload',
      name: 'upload',
      files,
      setFiles,
      css: css`
        padding-bottom: 0px;
      `,
    },
    {
      type: 'component',
      name: 'validFileList',
      content: (
        <ValidExtensions>
          <ValidExtension key="title">
            <strong>{t('ValidExtensions')}</strong>
          </ValidExtension>

          {ValidFileTypes.extensions.split(',').map((x, i) => (
            <ValidExtension key={`ext-${i}`}>{x}</ValidExtension>
          ))}
        </ValidExtensions>
      ),
    },
    {
      type: 'input',
      name: 'title',
      label: t('Title'),
      placeholder: t('TitleEmpty'),
      rules: [
        { required: true, message: t('TitleEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
    {
      type: 'switch',
      name: 'folder',
      label: t('CreateNewFolder'),
      templates: ['horizontal-item'],
      css: `margin-bottom: 0px;`,
      onChange: onFolderCheckChange,
    },
    {
      type: 'input',
      name: 'path',
      label: t('Path'),
      disabled: true,
    },
    {
      type: 'input',
      name: 'folderName',
      label: t('FolderName'),
      placeholder: t('FolderNameEmpty'),
      onChange: onFolderNameTextChange,
      visible: isNewFolder,
      rules: [
        { required: isNewFolder, message: t('FolderNameEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
  ];
}

const ValidExtensions = styled.div`
  margin-bottom: 24px;
  display: block;
  overflow: hidden;
`;

const ValidExtension = styled.div`
  float: left;
  margin-right: 12px;
  font-size: 11px;
`;
