export const updateResponse = (response, name, val, loading, row) => {
  return {
    ...response,
    data: response?.data?.map((x) => {
      if (x.id === row.id) {
        x.loading = loading;
        x[name] = val;
      }
      return x;
    }),
  };
};
