const RoleInputTypes = [
  {
    value: 0,
    name: 'DailyDoseMg',
    displayName: 'DailyDoseMg',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {
      group: 7
    }
  },
  {
    value: 1,
    name: 'DailyDoseMl',
    displayName: 'DailyDoseMl',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {
      group: 7
    }
  },
  {
    value: 2,
    name: 'TotalBottle',
    displayName: 'TotalBottle',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {
      group: 4
    }
  },
  {
    value: 3,
    name: 'PreparedBottle',
    displayName: 'PreparedBottle',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {
      group: 4
    }
  },
  {
    value: 4,
    name: 'BatchNumber',
    displayName: 'BatchNumber',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {
      group: 5
    }
  },
  {
    value: 5,
    name: 'ExternalId',
    displayName: 'ExternalId',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {
      group: 5
    }
  },
  {
    value: 6,
    name: 'Cycle',
    displayName: 'Cycle',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {
      group: 4
    }
  },
  {
    value: 7,
    name: 'Note',
    displayName: 'Note',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 800,
    data: {
      group: 5
    }
  }
];

export default RoleInputTypes;