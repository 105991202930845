import { CloseOutlined } from '@ant-design/icons';
import { useContext, useEffect, useRef, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useTranslation } from 'react-i18next';
import { BsQuestionDiamondFill } from 'react-icons/bs';
import { FaBookReader, FaPodcast } from 'react-icons/fa';
import { GrArticle } from 'react-icons/gr';
import { HiDocumentSearch } from 'react-icons/hi';
import { RiSurveyFill, RiVideoFill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router';
import styled, { css } from 'styled-components';
import { ReduceContext } from '../../components/contexts/ReduceContext';
import { Card, Modal, Page } from '../../components/globals';
import { ContentType, ContentTypes } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { contentDetailView, contentListGlobal } from '../../services/api';
import { getLocale } from '../../services/cache';
import { getLanguageType } from '../../utilies/localization';

export default function ContentList() {
  // Definitions
  const { t } = useTranslation();
  const { contentType } = useParams();
  const navigate = useNavigate();
  const videoPlayer = useRef();
  const player = useRef();
  const [user] = useCacheUser();

  const [state, dispatch] = useContext(ReduceContext);
  const [response, setResponse] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [audioPlayerVisible, setAudioPlayerVisible] = useState(false);
  const [videoPlayerVisible, setVideoPlayerVisible] = useState(false);

  const [pageState, setPageState] = useState({ list: false });

  const [filter] = useState({
    pageNumber: 1,
    dataLimit: contentType ? 100 : 3,
    country: user.c,
    userCountry: user.c,
    userRole: user.u,
    includeCountries: true,
    includeUserRoles: true,
    includeDiseases: true,
    includeTherapeuticAreas: true,
    justParent: true,
  });

  // Hooks
  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (state?.language?.change) {
      load();
      dispatch({ ...state, language: { ...state.language, change: false } });
    }
  }, [state]);

  // Functions
  const load = async () => {
    setPageState((x) => ({ ...x, list: false }));

    let contentTypes = contentType
      ? [parseInt(contentType)]
      : [ContentType.Article, ContentType.Document, ContentType.Podcast, ContentType.Video, ContentType.Course, ContentType.Survey, ContentType.FAQ];

    let promiseList = contentTypes.map((x) => {
      let typeData = ContentTypes.find((c) => c.value === x);
      let contentFilter = { ...filter, type: x, language: getLanguageType(getLocale()) };

      if (user.fd?.length > 0) {
        contentFilter = { ...contentFilter, disease: user.fd };
      }

      if (user.ft) {
        contentFilter = { ...contentFilter, therapeuticArea: user.ft };
      }

      return new Promise((resolve, reject) => {
        contentListGlobal(contentFilter, (status, res) => {
          if (status && res?.data?.length > 0) {
            resolve({ data: res.data, type: typeData, count: res.count });
          } else {
            resolve(null);
          }
        });
      });
    });

    let result = await Promise.all(promiseList);

    setResponse(result.filter((x) => x !== null));
    setPageState((x) => ({ ...x, list: true }));
  };

  // Events
  const onDetailClick = async (row) => {
    setRowData(row);

    await contentDetailView({ url: row.url, userCountry: user.c }, (_status, _res) => {
      switch (row.type) {
        case ContentType.Document:
          setPanelVisibility(true);
          break;

        case ContentType.Video:
          if (row.mediaUrl.includes('youtube')) {
            setPanelVisibility(true);
          } else {
            if (videoPlayer?.current) {
              videoPlayer.current.src = row.mediaUrl;
              videoPlayer.current.firstChild.src = row.mediaUrl;
            }
            setVideoPlayerVisible(true);
          }

          break;

        case ContentType.Podcast:
          setAudioPlayerVisible(true);
          break;

        default:
          navigate(`/contents/${row.url}`);
          break;
      }
    });
  };

  const onPodcastCloseClick = () => {
    setAudioPlayerVisible(false);
    setRowData(null);
  };

  const onClosePanel = () => {
    if (videoPlayer?.current) {
      videoPlayer.current.pause();
      videoPlayer.current.currentTime = 0;
    }

    setPanelVisibility(false);
    setVideoPlayerVisible(false);
    setRowData(null);
  };

  const ContentTypeIcon = ({ type }) => {
    switch (type) {
      case ContentType.Document:
        return <HiDocumentSearch />;
      case ContentType.Podcast:
        return <FaPodcast />;
      case ContentType.Course:
        return <BsQuestionDiamondFill />;
      case ContentType.Video:
        return <RiVideoFill />;
      case ContentType.Survey:
        return <RiSurveyFill />;

      case ContentType.Article:
      default:
        return <GrArticle />;
    }
  };

  // Render
  return (
    <Page
      routes={[
        {
          name: t('Contents'),
          breadcrumbName: t('Contents'),
          icon: <FaBookReader />,
        },
      ]}
    >
      <Card ready={pageState?.list} templates={['page']}>
        {response?.length > 0 && (
          <Container>
            {response.map((res, typeIndex) => (
              <ContentTypeContainer key={`type-${typeIndex}`}>
                <ContentTypeName>
                  <ContentTypeIcon type={res.type.value} /> {t(res.type.displayName)}
                </ContentTypeName>
                <List>
                  {res.data.map((item, itemIndex) => (
                    <ListItem key={`type-${typeIndex}-${itemIndex}`} onClick={() => onDetailClick(item)}>
                      <Image src={item.thumbnailImageUrl} />
                      <Title>{item.title}</Title>
                      {item.description && <Description>{item.description}</Description>}
                    </ListItem>
                  ))}
                </List>
              </ContentTypeContainer>
            ))}
          </Container>
        )}
      </Card>

      <Modal visible={panelVisibility} onCancelClick={onClosePanel} footer={null} templates={['document-preview']}>
        <DocumentFrame src={rowData?.mediaUrl} id="doc-preview" />
      </Modal>

      <Modal visible={videoPlayerVisible} onCancelClick={onClosePanel} footer={null} templates={['document-preview']}>
        <VideoFrame ref={videoPlayer} controls={true} controlsList="nodownload">
          <source src={rowData?.mediaUrl} />
        </VideoFrame>
      </Modal>

      <PodcastFrame visible={audioPlayerVisible}>
        {rowData && rowData.type === ContentType.Podcast && (
          <AudioPlayer autoPlay={audioPlayerVisible} ref={player} src={rowData.mediaUrl} header={<PodcastClose onClick={onPodcastCloseClick} />} />
        )}
      </PodcastFrame>
    </Page>
  );
}

const Container = styled.div``;

const ContentTypeContainer = styled.div`
  min-height: 325px;
  padding-bottom: 24px;
`;

const ContentTypeName = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 20px;

  @media only screen and (max-width: 1600px) {
    grid-template-columns: repeat(2, auto);
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(1, auto);
  }
`;

const Description = styled.div`
  position: absolute;
  bottom: -100%;
  margin: 0px 10px;
  background-color: ${(x) => x.theme.colors.antiFlashWhite};
  max-height: 100px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  box-shadow: inset 0px -3px 4px 0px ${(x) => x.theme.colors.lavenderGray};
`;

const Image = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all ease-in-out 0.5s;
  width: calc(100% + 5px);
  height: 100%;
`;

const Title = styled.div`
  position: absolute;
  text-shadow: 0px 0px 6px #000;
  color: #fff;
  font-size: 20px;
  padding: 10px;
`;

const ListItem = styled.div`
  position: relative;
  overflow: hidden;

  height: 250px;
  width: 100%;

  float: left;

  border-radius: ${(x) => x.theme.global.borderRadius};
  box-shadow: 0px 0px 6px 0px ${(x) => x.theme.colors.deepKaomaru};

  cursor: pointer;

  &:not(:last-child) {
    margin-right: 24px;
  }

  &:hover {
    ${Description} {
      transition: all ease-in-out 0.5s;
      bottom: 0;
    }

    ${Image} {
      width: calc(100% + 20px);
      height: 100%;
    }
  }
`;

const DocumentFrame = styled.iframe`
  height: 100%;
  width: 100%;
`;

const VideoFrame = styled.video`
  height: 100%;
  width: 100%;
  background-color: #000;
`;

const PodcastFrame = styled.div`
  position: fixed;
  z-index: 999999;
  bottom: -100%;
  left: 0px;
  width: 100%;

  ${({ visible }) =>
    visible &&
    css`
      bottom: 0px;
    `}
`;

const PodcastClose = styled(CloseOutlined)`
  position: absolute;
  right: 6px;
  width: 40px;
  height: 40px;
  background-color: ${(x) => x.theme.colors.mediumSpringGreen};
  border-radius: 50px;
  top: -20px;
  color: ${(x) => x.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
