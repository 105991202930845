import { css } from 'styled-components';

export const popup = css`
  .ant-picker-dropdown {
    z-index: 99999;
  }

  .ant-tooltip {
    z-index: 99999;
  }

  .ant-modal-wrap {
    z-index: 99999;
  }

  .ant-popover {
    z-index: 9999999;
    .ant-popover-inner {
      border-radius: ${(x) => x.theme.global.borderRadius};
      .ant-popover-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          border: 1px dashed ${(x) => x.theme.colors.deepKaomaru};
          border-radius: ${(x) => x.theme.global.borderRadius};

          &.ant-btn-primary {
            border: 0px;
            color: ${(x) => x.theme.colors.white};
            background: linear-gradient(263deg, ${(x) => x.theme.colors.mediumSpringGreen} 0, ${(x) => x.theme.colors.darkTurquoise} 100%);
          }

          &:focus,
          &:hover,
          &:active {
            box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;

            &.ant-btn-default {
              color: ${(x) => x.theme.colors.white};
              border-color: ${(x) => x.theme.colors.deepKaomaru};
              background: ${(x) => x.theme.colors.deepKaomaru};
            }
          }
        }
      }
    }
  }

  .ant-dropdown {
    z-index: 99999;
    .ant-table-filter-dropdown {
      border-radius: 10px;
      > div {
        padding: 12px !important;
      }
    }
  }

  .tox-tinymce-aux {
    z-index: 9999 !important;
  }

  .ant-modal-mask {
    z-index: 9999;
  }

  .ant-tooltip-inner {
    min-width: 48px;
    text-align: center;
  }
`;
