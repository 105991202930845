import { Popconfirm } from 'antd';
import styled, { css } from 'styled-components';

export default function PopupConfirmBase({ children, title, icon, okText, cancelText, showCancel, disabled, onConfirm, placement, templates }) {
  return (
    <Container
      title={title}
      icon={icon}
      okText={okText}
      cancelText={cancelText}
      showCancel={showCancel}
      onConfirm={onConfirm}
      autoAdjustOverflow={false}
      placement={placement ?? 'bottom'}
      templates={templates}
      disabled={disabled}
    >
      {children}
    </Container>
  );
}

const Container = styled(Popconfirm)`
  ${({ templates }) =>
    templates?.includes('clickable') &&
    css`
      cursor: pointer;
    `}
`;
