import { DrugType, RoleInputCalculateType, RoleInputType } from '../constants';

export const convertEvrysdiMlToMg = (mililiter) => {
  let miligram = mililiter * 0.75;

  return (miligram > 5 ? 5 : miligram).toFixed(1);
};

export const convertEvrysdiMgToMl = (miligram) => {
  let mililiter = miligram / 0.75;

  return (mililiter > 6.5 ? 6.5 : mililiter).toFixed(1);
};

export const convertMlToMg = (value) => {
  return (value * 0.75).toFixed(1);
};

export const convertMgToMl = (value) => {
  return (value / 0.75).toFixed(1);
};

export const checkTreatmentCalculatorInputs = (drugType, calculateRoleInputs, calculateType) => {
  switch (drugType) {
    default:
      return 'NotImplemented';

    case DrugType.Evrysdi:
      switch (calculateType) {
        default:
        case RoleInputCalculateType.Manuel:
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMl)) {
            return 'DailyDoseMlNotInputMustAddCalculate';
          }
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMg)) {
            return 'DailyDoseMgNotInputMustAddCalculate';
          }
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.TotalBottle)) {
            return 'TotalBottleNotInputMustAddCalculate';
          }
          return null;
        case RoleInputCalculateType.Calculator:
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMl)) {
            return 'DailyDoseMlNotInputMustAddCalculate';
          }
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMg)) {
            return 'DailyDoseMgNotInputMustAddCalculate';
          }
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.TotalBottle)) {
            return 'TotalBottleNotInputMustAddCalculate';
          }
          return null;
        case RoleInputCalculateType.DeliveryTotalBottle:
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMl)) {
            return 'DailyDoseMlNotInputMustAddCalculate';
          }
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMg)) {
            return 'DailyDoseMgNotInputMustAddCalculate';
          }
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.TotalBottle)) {
            return 'TotalBottleNotInputMustAddCalculate';
          }
          return null;

        case RoleInputCalculateType.DeliveryPreparedBottle:
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMl)) {
            return 'DailyDoseMlNotInputMustAddCalculate';
          }
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMg)) {
            return 'DailyDoseMgNotInputMustAddCalculate';
          }
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.PreparedBottle)) {
            return 'PreparedBottleNotInputMustAddCalculate';
          }
          return null;
      }

    case DrugType.Enspryng:
      if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMg)) {
        return 'DailyDoseMgNotInputMustAddCalculate';
      }
  }
};

export const checkDeliveryCalculatorInputs = (drugType, calculateRoleInputs, calculateType, isCreateTreatment) => {
  switch (drugType) {
    default:
      return 'NotImplemented';

    case DrugType.Evrysdi:
      switch (calculateType) {
        default:
        case RoleInputCalculateType.Manuel:
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.TotalBottle)) {
            return 'TotalBottleNotInputMustAddCalculate';
          }

          if (isCreateTreatment) {
            if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMl)) {
              return 'DailyDoseMlNotInputMustAddCalculate';
            }
            if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMg)) {
              return 'DailyDoseMgNotInputMustAddCalculate';
            }
          }
          return null;
        case RoleInputCalculateType.Calculator:
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMl)) {
            return 'DailyDoseMlNotInputMustAddCalculate';
          }
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMg)) {
            return 'DailyDoseMgNotInputMustAddCalculate';
          }
          if (!calculateRoleInputs.some((x) => x.type === RoleInputType.TotalBottle)) {
            return 'TotalBottleNotInputMustAddCalculate';
          }
          return null;
      }

    case DrugType.Enspryng:
      if (!calculateRoleInputs.some((x) => x.type === RoleInputType.DailyDoseMg)) {
        return 'DailyDoseMgNotInputMustAddCalculate';
      }
  }
};
