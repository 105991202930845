import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';

export default function UserForgotPasswordInputs({ isUrl, isRequested }) {
  const { t } = useTranslation();

  return [
    {
      type: 'input',
      name: 'email',
      label: t('Email'),
      placeholder: t('EmailEmpty'),
      disabled: isUrl,
      rules: [
        { required: true, message: t('EmailEmpty') },
        {
          type: 'email',
          min: 3,
          max: 500,
          message: t('EmailInvalid'),
        },
      ],
      templates: ['grey'],
    },
    isRequested && {
      type: 'number',
      name: 'code',
      label: t('PasswordCode'),
      placeholder: t('PasswordCodeEmpty'),
      disabled: isUrl,
      maxLength: 6,
      numberRule: { min: 100000, max: 999999, message: t('PasswordCodeInvalid') },
      rules: [{ required: true, message: t('PasswordCodeEmpty') }],
      templates: ['grey'],
    },
    isRequested && {
      type: 'input-password',
      name: 'password',
      label: t('Password'),
      placeholder: t('PasswordEmpty'),
      rules: [
        { required: true, message: t('PasswordEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
      templates: ['grey'],
    },
    isRequested && {
      type: 'input-password',
      name: 'passwordAgain',
      label: t('PasswordAgain'),
      placeholder: t('PasswordAgainEmpty'),
      rules: [
        { required: true, message: t('PasswordAgainEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
      templates: ['grey'],
    },
  ];
}
