import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Card, Global } from '../../components/globals';

export default function NotFound() {
  // Definitions
  const { t } = useTranslation();

  // Render
  return (
    <Global>
      <Container>
        <Card ready={true} templates={['not-found']}>
          <Logo src="/assets/images/logo.svg" />
          <Message>{t('PageNotFound')}</Message>
          <Button onClick={() => (window.location.href = '/')} templates={['colored']} block={true}>
            {t('ReturnToHome')}
          </Button>
        </Card>
      </Container>
    </Global>
  );
}

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 100vh;
`;

const Logo = styled.img`
  width: 150px;
  margin: 10px auto 20px;
  display: block;
`;

const Message = styled.div`
  text-align: center;
  margin: 40px 0px 40px 0px;
`;
