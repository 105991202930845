import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import { Button } from '../../components/globals';
import { DeliveryStatusTypes, DrugTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function TreatmentDeliveryColumns({ filter, setFilter, onClick }) {
  const { t } = useTranslation();

  let columns = [
    {
      locale: '#',
      name: 'deliveryId',
      type: 'string',
      align: 'center',
      width: 90,
      render: (value, row) => (
        <Button key="update" onClick={() => onClick(row)} templates={['table']}>
          <FaCheck />
        </Button>
      ),
    },
    {
      locale: 'Reference',
      name: 'reference',
      type: 'string',
      searchable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Drug',
      name: 'drug',
      type: 'string',
      render: (value, row) => {
        let drug = DrugTypes.find((x) => x.value === value);
        return (
          <Tooltip placement="topLeft" title={t(drug.displayName)}>
            {t(drug.displayName)}
          </Tooltip>
        );
      },
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'date',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Status',
      name: 'status',
      type: 'string',
      render: (value, row) => {
        let status = DeliveryStatusTypes.find((x) => x.value === value);
        return (
          <Tooltip placement="topLeft" title={t(status.displayName)}>
            {t(status.displayName)}
          </Tooltip>
        );
      },
    },
    {
      locale: 'DeliverySender',
      name: 'user',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value.fullName}>
          {value.fullName}
        </Tooltip>
      ),
    },
    {
      locale: 'DeliveryRecipient',
      name: 'targetUser',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value.fullName}>
          {value.fullName}
        </Tooltip>
      ),
    },
    {
      locale: 'DeliveredDate',
      name: 'deliveredDate',
      type: 'date',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'TotalBottle',
      name: 'totalBottle',
      type: 'number',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
  ];

  return ColumnBase({ columns, filter, setFilter });
}
