import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import { useLocation } from 'react-router';
import { Card, LineChart, Link } from '../../globals';

export default function VAMSScoreChart({ ready, response, fullWidth }) {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();
  const [data, setData] = useState(null);

  // Hooks
  useEffect(() => {
    if (response?.data) {
      load();
    }
  }, [response]);

  // Functions
  const load = () => {
    setData(response.data.map((x) => ({ ...x, modificationTime: moment(x.modificationTime).format('ll') })));
  };

  // Render
  return (
    <Card
      ready={ready}
      templates={['widget', 'score-chart', 'with-header', 'header-information', 'header-colored', fullWidth ? 'full-width' : '']}
      information={t('VAMSScoreHistoryInformation')}
      title={t('VAMSScoreHistory')}
      right={
        !location.pathname.includes('journey') && (
          <Link to="/journey">
            <FaCaretRight />
          </Link>
        )
      }
    >
      {data && (
        <LineChart
          margin={{
            top: 20,
            right: 50,
            left: 5,
            bottom: 10,
          }}
          data={data}
          xAxisDataKey="modificationTime"
          lineDataKey="point"
        />
      )}
    </Card>
  );
}
