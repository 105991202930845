import { Tooltip } from 'antd';
import ColumnBase from './_ColumnBase';

export default function NumberOfUsersPerDrugColumns() {
  let columns = [
    {
      locale: 'Drug',
      name: 'drug',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Count',
      name: 'count',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
  ];

  return ColumnBase({ columns });
}
