import styled, { css } from 'styled-components';

const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  color: ${(x) => x.theme.colors.white};
  background: linear-gradient(263deg, ${(x) => x.theme.colors.mediumSpringGreen} 0, ${(x) => x.theme.colors.darkTurquoise} 100%);

  font-weight: bold;
  text-transform: uppercase;
  border: 0px;
  border-radius: 10px;
  height: 40px;
  width: 45px;
  margin: 0px auto;
  line-height: 0px;

  &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover,
  &:focus,
  &:hover,
  &:active {
    border-color: transparent;
    color: ${(x) => x.theme.colors.white};
    background: linear-gradient(263deg, ${(x) => x.theme.colors.mediumSpringGreen} 0, ${(x) => x.theme.colors.darkTurquoise} 100%);
    box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
  }
  ${({ disabed }) =>
    disabed &&
    css`
      background-color: red;
    `}
`;

export default EditButton;
