import { css } from 'styled-components';

export const upload = css`
  .ant-upload-list {
    .ant-upload-list-text-container {
      .ant-upload-list-item {
        height: 24px;
        .ant-upload-text-icon {
          .anticon {
            font-size: 24px;
          }
        }

        &:hover {
          cursor: pointer;
          .ant-upload-list-item-info {
            background-color: transparent;
          }
        }
      }
    }
  }
`;
