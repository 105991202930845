export const getRandomString = (length) => {
  let result = '';
  let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getRandomEmail = () => {
  return `coral-${getRandomString(3)}${getRandomString(3)}${getRandomString(3)}@p.coral.com`;
};
