import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { NoticeType } from '..';

export default function NoticeAdverseEventInputs({ users }) {
  const { t } = useTranslation();

  let result = [];
  if (users?.length > 0) {
    result = [
      ...result,
      {
        type: 'select',
        name: 'targetUserId',
        label: t('RolePatient'),
        placeholder: t('SelectRolePatient'),
        rules: [{ required: true, message: t('SelectRolePatientEmpty') }],
        data: users.map((x) => ({ value: x.id, text: x.fullName })),
      },
    ];
  }

  result = [
    ...result,
    {
      type: 'hidden',
      name: 'type',
      initialValue: NoticeType.AdverseEvent,
    },
  ];

  result = [
    ...result,
    {
      type: 'textarea',
      name: 'message',
      label: t('NoticeMessage'),
      placeholder: t('NoticeMessageEmpty'),
      rules: [
        { required: true, message: t('NoticeMessageEmpty') },
        { min: 3, max: 500, message: format(t('LengthValid'), 3, 500) },
      ],
    },
  ];

  return result;
}
