import moment from 'moment';
import { Area, Bar, CartesianGrid, ComposedChart, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { useCacheUser } from '../../../hooks/useCacheUser';

export default function ComposedChartBase({ width, height, data, actives }) {
  const [user] = useCacheUser();

  let activeHCPsChart = [];
  let allHCPsChart = [];
  if (actives) {
    for (const [key, value] of Object.entries(data.activeHCPs)) {
      activeHCPsChart.push({ date: moment(key).add(user.t, 'minutes').format('ll'), count: value });
    }
  } else {
    for (const [key, value] of Object.entries(data.hcPs)) {
      allHCPsChart.push({ date: moment(key).add(user.t, 'minutes').format('ll'), count: value });
    }
  }
  return (
    <>
      {actives ? (
        <Container
          width={width ?? 470}
          height={height ?? 320}
          data={activeHCPsChart}
          margin={{
            top: 10,
            right: 50,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid stroke="3 3" />
          <XAxis dataKey="date" label={{ value: 'Dates', position: 'insideBottomRight', offset: 0 }} scale="band" reversed />
          <YAxis label={{ value: 'Active HCP Count', angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          <Area type="monotone" dataKey="count" fill="#8884d8" stroke="#8884d8" tooltipType="none" />
          <Bar dataKey="count" barSize={20} fill="#00cfe4" />
        </Container>
      ) : (
        <Container
          width={width ?? 470}
          height={height ?? 320}
          data={allHCPsChart}
          margin={{
            top: 10,
            right: 50,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="date" label={{ value: 'Dates', position: 'insideBottomRight', offset: 0 }} scale="band" reversed />
          <YAxis label={{ value: 'HCP Count', angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          <Area type="monotone" dataKey="count" fill="#8884d8" stroke="#8884d8" tooltipType="none" />
          <Bar dataKey="count" barSize={20} fill="#00cfe4" />
        </Container>
      )}
    </>
  );
}

const Container = styled(ComposedChart)``;
