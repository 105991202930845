import { Steps } from 'antd';
import styled from 'styled-components';

const { Step } = Steps;

export default function StepBase({ current, items }) {
  return (
    <Container current={current}>
      <ContainerSteps>
        {items.map((x, i) => (
          <ContainerStep key={`step-${i}`} title={x.title} status={x.status ?? 'wait'} icon={x.icon} content={x.content} />
        ))}
      </ContainerSteps>

      <Content>{items[current].content}</Content>
    </Container>
  );
}

const Container = styled.div``;

const ContainerSteps = styled(Steps)``;

const ContainerStep = styled(Step)``;

const Content = styled.div``;
