import { Steps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GiMedicines } from 'react-icons/gi';
import { ImCalculator, ImTruck } from 'react-icons/im';
import { RiEdit2Fill } from 'react-icons/ri';
import { TiFlowChildren } from 'react-icons/ti';
import styled from 'styled-components';
import DeliveryCalculate from './DeliveryCalculate';
import DeliveryDetail from './DeliveryDetail';
import DeliveryDrug from './DeliveryDrug';
import DeliveryFlow from './DeliveryFlow';
import DeliveryRedirect from './DeliveryRedirect';
const { Step } = Steps;

export default function DeliveryCreateProcess({
  user,
  deliveryUser,
  country,
  current,
  roleInputs,
  drugs,
  flows,
  calculates,
  onStepClick,
  onDrugClick,
  selectedDrug,
  onCalculateClick,
  selectedCalculate,
  onFlowClick,
  selectedFlow,
  selectedFlowIsCreateTreatment,
  onDetailFinish,
  selectedDetail,
  onRedirectClick,
  formRef,
}) {
  // Definitions
  const { t } = useTranslation();

  // Components
  const GetCurrentSection = () => {
    switch (current) {
      case 0: // Flow
        return <DeliveryFlow flows={flows} onClick={onFlowClick} />;
      case 1: // Drug
        return <DeliveryDrug drugs={drugs} onClick={onDrugClick} />;
      case 2: // Calculate
        return <DeliveryCalculate calculates={calculates} onClick={onCalculateClick} />;
      case 3: // Detail
        return (
          <DeliveryDetail
            user={user}
            country={country}
            selectedDetail={selectedDetail}
            deliveryUser={deliveryUser}
            selectedDrug={selectedDrug}
            selectedCalculate={selectedCalculate}
            selectedFlow={selectedFlow}
            roleInputs={roleInputs.filter((x) => x.drug === selectedDrug.value && x.calculate === selectedCalculate.value)}
            isCreateTreatment={selectedFlowIsCreateTreatment}
            formRef={formRef}
            onFinish={onDetailFinish}
          />
        );
      case 4: // Redirect
        let redirects = selectedFlow.steps.filter((x) => x.parentId === null);

        return <DeliveryRedirect redirects={redirects} onClick={onRedirectClick} />;
      default: // Nothing
        return <>{t('NotImplemented')}</>;
    }
  };

  // Render
  return (
    <Container>
      <StepList current={current}>
        <StepItem
          key="flow"
          title={t('DeliveryStepFlow')}
          subTitle={selectedFlow ? t(selectedFlow.nameKey) : t('WaitingAction')}
          icon={<TiFlowChildren />}
          onClick={() => onStepClick(0)}
        />
        <StepItem
          key="drug"
          title={t('DeliveryStepDrug')}
          subTitle={selectedDrug ? t(selectedDrug.displayName) : t('WaitingAction')}
          icon={<GiMedicines />}
          onClick={() => onStepClick(1)}
        />
        <StepItem
          key="calculate"
          title={t('DeliveryStepCalculate')}
          subTitle={selectedCalculate ? t(selectedCalculate.displayName) : t('WaitingAction')}
          icon={<ImCalculator />}
          onClick={() => onStepClick(2)}
        />
        <StepItem
          key="detail"
          title={t('DeliveryStepDetail')}
          subTitle={selectedDetail ? t('Filled') : t('WaitingAction')}
          icon={<RiEdit2Fill />}
          onClick={() => onStepClick(3)}
        />
        {selectedFlow?.steps?.filter((x) => x.parentId === null).length > 1 && (
          <StepItem
            key="redirect"
            title={t('DeliveryStepRedirect')}
            subTitle={selectedDetail ? t('Filled') : t('WaitingAction')}
            icon={<ImTruck />}
            onClick={() => onStepClick(4)}
          />
        )}
      </StepList>

      <GetCurrentSection />
    </Container>
  );
}

const Container = styled.div`
  .ant-steps {
    .ant-steps-item {
      cursor: wait;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:not(.ant-steps-item-wait) {
        cursor: pointer;
        .ant-steps-icon {
          margin-top: 3px;
          color: ${(x) => x.theme.colors.mediumSpringGreen};
        }
      }

      .ant-steps-item-title {
        .ant-steps-item-subtitle {
          position: absolute;
          left: 0px;
          bottom: -18px;
          margin: 0px;
          font-size: 11px;
        }
      }
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
      background-color: ${(x) => x.theme.colors.mediumSpringGreen};
    }
  }
`;

const StepList = styled(Steps)`
  margin-bottom: 24px;
`;

const StepItem = styled(Step)`
  .ant-steps-icon {
    font-size: 30px !important;
  }
`;
