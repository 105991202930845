import axios from 'axios';
import { urlSerializer } from '../utilies/urlHelper';
import { getToken } from './cache';
import { getApiUrl } from './config';

const redirectToLogin = () => (typeof window !== 'undefined' && !window.location.pathname.includes('logout') ? (window.location.href = '/logout') : null);
const redirectToForbidden = () => (typeof window !== 'undefined' ? (window.location.href = '/forbidden') : null);

const client = axios.create({
  baseURL: getApiUrl(),
  timeout: 1000 * 60 * 60 * 2,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: urlSerializer,
});

client.interceptors.request.use(
  async (config) => {
    try {
      let token = getToken();
      if (token) config.headers.Authorization = `Bearer ${token}`;
    } catch (e) {}
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.config.baseURL.includes('localhost')) {
      if (error.response && error.response.status === 403) redirectToForbidden();
      if (error.response && error.response.status === 401) redirectToLogin();
    }

    return Promise.reject(error);
  }
);

export default client;
