import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { ContentType } from '../../../constants';
import ContentUserColumns from '../../../constants/columns/ContentUserColumns';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { contentListGlobal, contentUserList, contentUserMyList, contentUserRemind } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { Button, Card, Drawer, Element, Link, Modal, Table } from '../../globals';
import ContentPreview from '../content/ContentPreview';

export default function ContentUserList({ parameter, loaded }) {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();
  const [user] = useCacheUser();
  const [formRef] = Form.useForm();

  const [response, setResponse] = useState(null);
  const [preview, setPreview] = useState(null);
  const [previewWidth, setPreviewWidth] = useState('50%');

  const [panelVisibility, setPanelVisibility] = useState(false);
  const [remindPanelVisibility, setRemindPanelVisibility] = useState(false);
  const [contents, setContents] = useState([]);

  const [pageState, setPageState] = useState({
    list: true,
    form: true,
    delete: true,
    remind: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 5,
    orderBy: ['modificationTime|desc'],
    userId: parameter.id,
    isMyData: parameter.isMyData,
    includeParentContent: true,
    country: parseInt(user.c),
    userRole: parameter.userRole,
  });

  const [contentFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    country: user.c,
    userCountry: user.c,
    justParent: true,
    type: ContentType.Survey,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    let request = filter.isMyData ? contentUserMyList : contentUserList;

    await request(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }

      if (loaded) {
        loaded(res);
      }

      setPageState((x) => ({ ...x, list: true }));
    });
  };

  const loadContent = async () => {
    contentListGlobal(contentFilter, (status, res) => {
      if (status) {
        setContents(res.data);
      }
    });
  };

  // Events
  const onSeeDetailClick = (row) => {
    setPreview(row);
    setPanelVisibility(true);
  };

  const onCancelClick = () => {
    setPreview(null);
    setPanelVisibility(false);
  };

  const onWidthChange = (value) => {
    setPreviewWidth(value < 2 ? '50%' : value === 5 ? '80%' : '40%');
  };

  // Events
  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, remind: false }));
    model.userId = parameter.id;

    await contentUserRemind(model, (status, res) => {
      if (status) {
        notifySuccess(t('ContentRemindedSuccessfully'));
      }

      setPageState((x) => ({ ...x, remind: true }));
    });
  };

  return (
    <Card
      ready={pageState.list}
      templates={['widget', 'with-header', 'header-information', 'header-colored', 'full-width', 'visit']}
      information={t('ContentReportsInformation')}
      title={t('ContentReports')}
      right={
        <CardRight>
          {!location.pathname.includes('journey') && (
            <Link to="/journey">
              <FaCaretRight />
            </Link>
          )}
          <Button
            templates={['primary']}
            onClick={() => {
              setRemindPanelVisibility(true);
              loadContent();
            }}
          >
            {t('RemindContents')}
          </Button>
        </CardRight>
      }
    >
      <Table ready={pageState.list} response={response} filter={filter} setFilter={setFilter} columns={ContentUserColumns({ onSeeDetailClick })} scroll={700} />

      <Modal width={previewWidth} title={t('ContentPreview')} visible={panelVisibility} onCancelClick={onCancelClick} templates={['colored']} footer={null}>
        {preview && <ContentPreview parameter={parameter} id={preview.parentContent.id} period={preview.period} onWidthChange={onWidthChange} />}
      </Modal>

      <Drawer visible={remindPanelVisibility} onClose={() => setRemindPanelVisibility(false)}>
        <Element
          key="content-form"
          columnSize={24}
          ready={pageState.remind}
          formRef={formRef}
          onFinish={onFinish}
          inputs={[
            {
              type: 'select',
              name: 'contentId',
              label: t('Content'),
              placeholder: t('SelectContent'),
              data: contents?.map((x) => ({ value: x.id, text: x.title })),
              rules: [{ required: true, message: t('ContentEmpty') }],
            },
          ]}
          submit={t('Confirm')}
        />
      </Drawer>
    </Card>
  );
}

const CardRight = styled.div``;
