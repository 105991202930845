import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function EvrysdiCalcultorInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'date',
      name: 'birthDate',
      label: t('BirthDate'),
      showTime: false,
      placeholder: t('SelectBirthDate'),
      rules: [{ required: true, message: t('SelectBirthDateEmpty') }],
      disabledDate: (current) => current && current > moment().endOf('day'),
    },
    {
      type: 'number',
      name: 'weight',
      label: t('WeightInKg'),
      placeholder: t('WeightEmpty'),
      rules: [{ required: true, message: t('WeightEmpty') }],
      step: '0.1',
    },
    {
      type: 'number',
      name: 'durationInMonths',
      label: t('TreatmentDurationInMonths'),
      placeholder: t('TreatmentDurationInMonthsEmpty'),
      rules: [{ required: true, message: t('TreatmentDurationInMonthsEmpty') }],
    },
  ];
}
