import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';
import { Button, Card, Page } from '../../components/globals';
import UserField from '../../components/pages/user/UserField';
import { useCacheUser } from '../../hooks/useCacheUser';
import { loginRefresh, userMyDetail, userUpdate } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';
import { getUserFieldFormData } from '../../utilies/userField';

export default function UserProfile() {
  // Definitions
  const { t } = useTranslation();

  const [user, setUser] = useCacheUser();
  const [formRef] = Form.useForm();

  const [pageState, setPageState] = useState({ form: true });

  const [filter] = useState({
    includeFields: true,
    country: user.c,
    userRole: user.u,
  });

  // Hooks
  useEffect(() => {
    load();
  }, []);

  // Functions
  const load = async () => {
    await userMyDetail(filter, (status, res) => {
      res.data.fields = getUserFieldFormData(res.data.fields, user.t);
      formRef.setFieldsValue(res.data);
    });
  };

  // Events
  const onUserFormFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    await userUpdate({ ...model, isMyData: true }, async (status, res) => {
      await loginRefresh((refreshStatus, refreshRes) => {
        if (status && refreshStatus) {
          notifySuccess(t('UpdateCompleteSuccess'));
        }

        if (refreshRes) {
          setUser(refreshRes.data);
        }

        setPageState((x) => ({ ...x, form: true }));
      });
    });
  };

  // Render
  return (
    <Page
      title={t('Profile')}
      icon={<FaUserCircle />}
      routes={[
        {
          name: t(`Profile`),
          breadcrumbName: `Profile`,
          icon: <FaUserCircle />,
        },
      ]}
      extra={[
        <Button key="confirm" onClick={() => formRef.submit()} templates={['colored']}>
          {t('Confirm')}
        </Button>,
      ]}
    >
      <Card ready={pageState.form} templates={['page']}>
        <UserField user={user} userRoleType={filter.userRole} countryType={filter.country} formRef={formRef} onFinish={onUserFormFinish} />
      </Card>
    </Page>
  );
}
