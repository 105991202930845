import jwtDecode from 'jwt-decode';

export const getLocale = () => {
  return getItem('lcl') ?? 'en';
};

export const getToken = () => {
  return getItem('tkn');
};

export const getCountry = () => {
  return getItem('c');
};

export const getUserData = (ctx) => {
  try {
    let cookies = ctx?.req?.cookies;
    let token = cookies?.tkn ?? null;
    if (token === null) return null;

    let locale = cookies?.lcl ?? 'en';
    let user = jwtDecode(token);
    if (user && !(user?.exp < Date.now() / 1000)) {
      return { ...user, token, locale };
    }
  } catch {}
  return null;
};

export const setItem = (key, value, isCookie = true, expireDay = 7) => {
  if (isCookie) {
    if (typeof document === 'undefined') return;
    let date = new Date();
    date.setTime(date.getTime() + 24 * expireDay * 60 * 60 * 1000);
    let expires = 'expires=' + date.toUTCString();
    document.cookie = key + '=' + JSON.stringify(value) + ';' + expires + ';path=/';
  } else {
    if (typeof window === 'undefined') return;
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getItem = (key, isCookie = true) => {
  if (isCookie) {
    if (typeof document === 'undefined') return;
    let result = Object.fromEntries(document.cookie.split('; ').map((v) => v.split('=').map(decodeURIComponent)))[key];
    return result !== undefined ? JSON.parse(result) : null;
  } else {
    if (typeof window === 'undefined') return;
    return JSON.parse(localStorage.getItem(key));
  }
};

export const removeItem = (key, isCookie = true) => {
  if (isCookie) {
    if (typeof document === 'undefined') return;
    document.cookie = key + '=; expires=' + new Date().toUTCString() + '; path=/;';
  } else {
    if (typeof window === 'undefined') return;
    localStorage.removeItem(key);
  }
};

export const clearItems = (isCookie = true) => {
  if (isCookie) {
    if (typeof document === 'undefined') return;
    document.cookie.split(';').forEach((c) => {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  } else {
    if (typeof window === 'undefined') return;
    localStorage.clear();
  }
};
