//#region Infrastructure
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ReduceProvider } from './components/contexts/ReduceContext';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
//#endregion

//#region Design
import AgoraRTC from 'agora-rtc-react';
import 'antd/dist/antd.min.css';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/global';
import theme from './styles/theme';
//#endregion

if (window.self === window.top) {
  ReactDOM.createRoot(document.querySelector('coral')).render(
    <Suspense>
      <ReduceProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Routes />
          </ThemeProvider>
        </BrowserRouter>
      </ReduceProvider>
    </Suspense>
  );

  reportWebVitals();

  AgoraRTC.setLogLevel(4);
}
