import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { HiUsers } from 'react-icons/hi';
import { RiEdit2Fill } from 'react-icons/ri';
import { TiCancel } from 'react-icons/ti';
import styled from 'styled-components';
import EditButton from '../../components/globals/button/EditButton';
import { DeliveryStatusType, RoleColumnDomainType } from '../../constants';
import RoleInputDomainColumns from './RoleInputDomainColumns';
import ColumnBase from './_ColumnBase';

export default function DeliveryColumns({ roleColumns, filter, setFilter, onDeliveryUserClick, onRejectDelivery }) {
  const { t } = useTranslation();

  const menu = (row) => (
    <UpdateMenu>
      {onDeliveryUserClick && row.status === DeliveryStatusType.Pending && (
        <UpdateMenuItem key="delivery-users" onClick={() => onDeliveryUserClick(row)}>
          <HiUsers /> {t('DeliveryUsers')}
        </UpdateMenuItem>
      )}
      {onRejectDelivery && row.status === DeliveryStatusType.Pending && (
        <UpdateMenuItem key="reject-delivery" onClick={() => onRejectDelivery(row)}>
          <TiCancel /> {t('Reject')}
        </UpdateMenuItem>
      )}
    </UpdateMenu>
  );

  let columns = RoleInputDomainColumns(RoleColumnDomainType.Delivery, roleColumns);

  columns = [
    ...columns,
    (onDeliveryUserClick || onRejectDelivery) && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Dropdown overlay={menu(row)} placement="bottomRight" className="role">
          <EditButton disabed={row.status === DeliveryStatusType.Pending}>
            <RiEdit2Fill />
          </EditButton>
        </Dropdown>
      ),
    },
  ];

  return ColumnBase({ columns, filter, setFilter });
}

const UpdateMenu = styled(Menu)`
  padding: 0px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
`;

const UpdateMenuItem = styled(Menu.Item)`
  padding: 12px;

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;

    svg {
      color: ${(x) => x.theme.colors.robinEggBlue};
      margin-right: 10px;
    }
  }
`;
