const fonts = {
  families: {
    default: "'montserrat-vf', sans-serif",
  },
  sizes: {
    h1: '47px',
    h2: '37px',
    h3: '29px',
    h4: '23px',
    h5: '18px',
    h6: '14px',
    default: '14px',
  }, //https://grtcalculator.com
};

export default fonts;
