import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { GiMedicines } from 'react-icons/gi';
import { ImCalculator, ImTruck } from 'react-icons/im';
import { RiEdit2Fill } from 'react-icons/ri';
import styled from 'styled-components';
import TreatmentCalculate from './step/TreatmentCalculate';
import TreatmentDelivery from './step/TreatmentDelivery';
import TreatmentDetail from './step/TreatmentDetail';
import TreatmentDrug from './step/TreatmentDrug';
import TreatmentUsages from './step/TreatmentUsages';

const { Step } = Steps;

export default function TreatmentProcess({
  user,
  id,
  treatmentUser,
  country,
  userId,
  current,
  roleInputs,
  drugs,
  calculates,
  onStepClick,
  onDrugClick,
  selectedDrug,
  onCalculateClick,
  selectedCalculate,
  onDeliveryClick,
  selectedDelivery,
  usages,
  setUsages,
  selectedUsage,
  setSelectedUsage,
  formRef,
  onDetailFinish,
  selectedDetail,
}) {
  // Definitions
  const { t } = useTranslation();

  // Components
  const GetCurrentSection = () => {
    switch (current) {
      case 0: // Drug
        return <TreatmentDrug drugs={drugs} onClick={onDrugClick} />;
      case 1: // Calculate
        return <TreatmentCalculate calculates={calculates} onClick={onCalculateClick} />;
      case 2: // Delivery
      case 3: // Detail
        if (selectedCalculate?.name?.includes('Delivery') && current === 2) {
          return <TreatmentDelivery country={country} targetUserId={userId} onClick={onDeliveryClick} />;
        }

        return (
          <TreatmentDetail
            user={user}
            id={id}
            selectedDetail={selectedDetail}
            treatmentUser={treatmentUser}
            selectedDrug={selectedDrug}
            selectedCalculate={selectedCalculate}
            selectedDelivery={selectedDelivery}
            roleInputs={roleInputs.filter((x) => x.drug === selectedDrug.value && x.calculate === selectedCalculate.value)}
            formRef={formRef}
            onFinish={onDetailFinish}
          />
        );
      case 4: // Usages
        return <TreatmentUsages selectedDrug={selectedDrug} usages={usages} setUsages={setUsages} selectedUsage={selectedUsage} setSelectedUsage={setSelectedUsage} />;
      default: // Nothing
        return <>{t('NotImplemented')}</>;
    }
  };

  // Render
  return (
    <Container>
      <StepList current={current}>
        <StepItem
          key="drug"
          title={t('TreatmentDrug')}
          subTitle={selectedDrug ? t(selectedDrug.displayName) : t('WaitingAction')}
          icon={<GiMedicines />}
          onClick={() => onStepClick(0)}
        />
        <StepItem
          key="calculate"
          title={t('TreatmentCalculate')}
          subTitle={selectedCalculate ? t(selectedCalculate.displayName) : t('WaitingAction')}
          icon={<ImCalculator />}
          onClick={() => onStepClick(1)}
        />
        {selectedCalculate?.name?.includes('Delivery') && (
          <StepItem
            key="delivery"
            title={t('TreatmentDelivery')}
            subTitle={selectedDelivery ? selectedDelivery.reference : t('WaitingAction')}
            icon={<ImTruck />}
            onClick={() => onStepClick(2)}
          />
        )}
        <StepItem
          key="detail"
          title={t('TreatmentDetail')}
          subTitle={selectedDetail ? t('Filled') : t('WaitingAction')}
          icon={<RiEdit2Fill />}
          onClick={() => onStepClick(selectedDelivery ? 3 : 2)}
        />
        <StepItem
          key="usages"
          title={t('TreatmentDoseUsages')}
          subTitle={usages?.length > 0 ? t('Filled') : t('WaitingAction')}
          icon={<GiMedicines />}
          onClick={() => onStepClick(4)}
        />
      </StepList>

      <GetCurrentSection />
    </Container>
  );
}

const Container = styled.div`
  .ant-steps {
    .ant-steps-item {
      cursor: wait;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:not(.ant-steps-item-wait) {
        cursor: pointer;
        .ant-steps-icon {
          margin-top: 3px;
          color: ${(x) => x.theme.colors.mediumSpringGreen};
        }
      }

      .ant-steps-item-title {
        .ant-steps-item-subtitle {
          position: absolute;
          left: 0px;
          bottom: -18px;
          margin: 0px;
          font-size: 11px;
        }
      }
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
      background-color: ${(x) => x.theme.colors.mediumSpringGreen};
    }
  }
`;

const StepList = styled(Steps)`
  margin-bottom: 24px;
`;

const StepItem = styled(Step)`
  .ant-steps-icon {
    font-size: 30px !important;
  }
`;
