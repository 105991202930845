import { useTranslation } from 'react-i18next';
import { FaUserMd } from 'react-icons/fa';
import styled from 'styled-components';

export default function UserParents({ parents }) {
  // Definitions
  const { t } = useTranslation();

  // Render
  return (
    parents?.length > 0 && (
      <ParentSection key="relation-doctor">
        <ParentTitle>
          <FaUserMd /> {t('RelationDoctorNames')}
        </ParentTitle>
        <Parents>
          {parents.map((x, i) => (
            <Parent key={`parent-${i}`}>{x.user.fullName}</Parent>
          ))}
        </Parents>
      </ParentSection>
    )
  );
}

const ParentSection = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;

  @media only screen and (max-width: 500px) {
    position: relative;
    top: auto;
    right: auto;
  }
`;

const ParentTitle = styled.div`
  font-weight: bold;
  text-align: right;
  font-size: 20px;
`;

const Parents = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Parent = styled.div`
  margin-left: 5px;
`;
