import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { CountryType, CountryTypes, NoticeContactType, NoticeContactTypes, NoticeGroupType, NoticeType, NoticeTypes } from '..';

export default function NoticeInputs({ isExternal, type, isDetail, users, noticeContactType, onNoticeContactTypeChange, onCountryChange }) {
  const { t } = useTranslation();

  let result = [];

  switch (type) {
    case NoticeGroupType.AdverseEvent:
      if (!isDetail) {
        if (users?.length > 0) {
          result = [
            ...result,
            {
              type: 'select',
              name: 'targetUserId',
              label: t('RolePatient'),
              placeholder: t('SelectRolePatient'),
              rules: [{ required: true, message: t('SelectRolePatientEmpty') }],
              data: users.map((x) => ({ value: x.id, text: x.fullName })),
            },
          ];
        }

        result = [
          ...result,
          {
            type: 'hidden',
            name: 'type',
            initialValue: NoticeType.AdverseEvent,
          },
        ];
      }

      if (isDetail) {
        result = [
          ...result,
          {
            type: 'input',
            name: ['user', 'firstName'],
            label: t('UserFirstName'),
            readOnly: true,
          },
          {
            type: 'input',
            name: ['user', 'lastName'],
            label: t('UserLastName'),
            readOnly: true,
          },
        ];
      }
      break;

    default:
    case NoticeGroupType.AskForHelp:
      if (!isDetail) {
        result = [
          ...result,
          {
            type: 'select',
            name: 'type',
            label: t('NoticeType'),
            placeholder: t('SelectNoticeType'),
            rules: [{ required: true, message: t('NoticeTypeEmpty') }],
            data: NoticeTypes.filter((x) => x.data.group === NoticeGroupType.AskForHelp).map((x) => ({ ...x, text: t(x.displayName) })),
          },
          {
            type: 'hidden',
            name: 'type',
            initialValue: NoticeType.AskForHelpTechnicalProblem,
          },
        ];
      }

      if (isDetail && !isExternal) {
        result = [
          ...result,
          {
            type: 'input',
            name: ['user', 'firstName'],
            label: t('UserFirstName'),
            readOnly: true,
          },
          {
            type: 'input',
            name: ['user', 'lastName'],
            label: t('UserLastName'),
            readOnly: true,
          },
        ];
      }

      if (isExternal) {
        result = [
          ...result,
          !isDetail && {
            type: 'select',
            name: 'country',
            label: t('Country'),
            placeholder: t('SelectCountry'),
            rules: [{ required: true, message: t('CountryEmpty') }],
            data: CountryTypes.filter((x) => x.value !== CountryType.Global).map((x) => ({ ...x, text: t(x.displayName) })),
            onChange: onCountryChange,
          },
          !isDetail && {
            type: 'select',
            name: 'noticeContactType',
            label: t('ExternalNoticeContactType'),
            placeholder: t('SelectExternalNoticeContactType'),
            rules: [{ required: true, message: t('ExternalNoticeContactTypeEmpty') }],
            data: NoticeContactTypes.map((x) => ({ ...x, text: t(x.displayName) })),
            initialValue: noticeContactType,
            onChange: onNoticeContactTypeChange,
          },
          {
            type: 'input',
            name: 'contactFullName',
            label: t('ExternalNoticeContactFullName'),
            placeholder: t('ExternalNoticeContactFullNameEmpty'),
            rules: [
              { required: !isDetail, message: t('ExternalNoticeContactFullNameEmpty') },
              { min: 3, max: 100, message: format(t('LengthValid'), 3, 100) },
            ],
            readOnly: isDetail,
          },
          {
            type: noticeContactType === NoticeContactType.Phone ? 'phone' : 'input',
            name: 'contact',
            label: t('ExternalNoticeContactValue'),
            placeholder: t('ExternalNoticeContactEmpty'),
            rules: [
              { required: !isDetail, message: t('ExternalNoticeContactEmpty') },
              noticeContactType === NoticeContactType.Phone
                ? { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) }
                : {
                    type: 'email',
                    min: 3,
                    max: 500,
                    message: t('EmailInvalid'),
                  },
            ],
            readOnly: isDetail,
          },
        ];
      }
      break;
  }

  result = [
    ...result,
    {
      type: 'textarea',
      name: 'message',
      label: t('NoticeMessage'),
      placeholder: t('NoticeMessageEmpty'),
      rules: [
        { required: !isDetail, message: t('NoticeMessageEmpty') },
        { min: 3, max: 500, message: format(t('LengthValid'), 3, 500) },
      ],
      readOnly: isDetail,
    },
  ];

  if (type === NoticeGroupType.AskForHelp && !isDetail) {
    result = [
      ...result,
      {
        type: 'switch',
        name: 'isSelectedWarning1',
        label: t('WarningText1'),
        templates: ['horizontal-item'],
      },
      {
        type: 'switch',
        name: 'isSelectedWarning2',
        label: t('WarningText2'),
        templates: ['horizontal-item'],
      },
    ];
  }

  return result;
}
