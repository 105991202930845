import { UploadOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { HiTemplate } from 'react-icons/hi';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Button, Drawer, Element, Extra, FileInput, Page, Select, Table } from '../../components/globals';
import { ActionType, TemplateColumns, TemplateGroupTypes, TemplateInputs, TemplateTypes } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { templateCreate, templateDataExport, templateDataImport, templateList } from '../../services/api';
import { notifyError, notifySuccess } from '../../utilies/notification';

export default function Templates() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user] = useCacheUser();
  const [templateFormRef] = Form.useForm();
  const [importFormRef] = Form.useForm();

  const [response, setResponse] = useState(null);
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [templateTypeData, setTemplateTypeData] = useState(null);
  const [importPanelVisiblity, setImportPanelVisibility] = useState(false);
  const [importFiles, setImportFiles] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await templateList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onCreateClick = () => {
    templateFormRef.resetFields();
    setPanelVisibility(true);
  };

  const onTemplateTypeChange = ({ value }) => {
    setTemplateTypeData(TemplateTypes.find((x) => x.value === value));
  };

  const onUpdateClick = (row) => {
    setPageState((x) => ({ ...x, update: false }));
    navigate(`/template/update/${row.id}`);
  };

  const onTemplateFormFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    model.content = '-';
    await templateCreate(model, (status, res) => {
      if (status) {
        setPanelVisibility(false);
        notifySuccess(t('CreateCompleteSuccess'));
        list();
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  const onImportPanelClick = () => {
    setImportFiles([]);
    setImportPanelVisibility(true);
  };

  const onExportClick = async () => {
    setPageState((x) => ({ ...x, export: false }));

    await templateDataExport({ ids: selectedRowKeys, country: filter.country }, (status, res, fileName) => {
      if (status) {
        saveAs(res, fileName);
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, export: true }));
    });
  };

  const onImportClick = async () => {
    if (importFiles?.length <= 0) {
      notifyError(t('ImportFileRequired'));
      return;
    }

    const form = new FormData();
    form.append('File', importFiles[0]);
    form.append('country', filter.country);

    let formValues = importFormRef.getFieldsValue();
    for (let key of Object.keys(formValues)) {
      form.append(key, formValues[key]);
    }

    setPageState((x) => ({ ...x, import: false }));
    await templateDataImport(filter.country, form, (status, res) => {
      if (status) {
        list();
        notifySuccess(t('UpdateCompleteSuccess'));
        setImportPanelVisibility(false);
        setPanelVisibility(false);
        setImportFiles([]);
      }

      setPageState((x) => ({ ...x, import: true }));
    });
  };

  // Render
  return (
    <Page
      title={t('Templates')}
      icon={<HiTemplate />}
      subTitle={
        user.checkAction(ActionType.TemplateCreate) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Templates'),
          breadcrumbName: t('Templates'),
          icon: <HiTemplate />,
        },
      ]}
      templates={['default-2-filter-0']}
      extra={[
        <Select
          key="type-filter"
          data={TemplateTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
          onChange={(type) => setFilter((x) => ({ ...x, type }))}
          placeholder={t('SelectTemplateType')}
          value={filter.type}
          templates={['filter', 'width-500']}
        />,
        <Select
          key="group-filter"
          data={TemplateGroupTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
          onChange={(group) => setFilter((x) => ({ ...x, group }))}
          placeholder={t('SelectTemplateGroup')}
          value={filter.group}
          templates={['filter']}
        />,
        <Extra
          key="filter"
          pageState={pageState}
          selectedRowKeys={selectedRowKeys}
          onFilterChanged={() => setSelectedRowKeys([])}
          onExportClick={user.checkAction(ActionType.TemplateImportExport) && onExportClick}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        selectedRowKeys={user.checkAction(ActionType.TemplateImportExport) ? selectedRowKeys : null}
        onRowSelectChange={(values) => setSelectedRowKeys(values)}
        columns={TemplateColumns({ onUpdateClick: user.checkAction(ActionType.TemplateUpdate) && onUpdateClick })}
      />

      <Drawer title={t('AddNew')} visible={panelVisibility} onClose={() => setPanelVisibility(false)}>
        {user.checkAction(ActionType.TemplateImportExport) && (
          <Button key="import" onClick={onImportPanelClick} icon={<UploadOutlined />} templates={['colored', 'with-icon', 'full-width', 'mb-24']}>
            {t('ImportData')}
          </Button>
        )}

        <Element
          key="template-form"
          ready={pageState.create}
          formRef={templateFormRef}
          onFinish={onTemplateFormFinish}
          inputs={TemplateInputs({ templateTypeData, onTemplateTypeChange })}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>

      <Drawer title={t('ImportData')} visible={importPanelVisiblity} onClose={() => setImportPanelVisibility(false)}>
        <ImportDataDescription>{t('ImportDataDescription')}</ImportDataDescription>

        <FileInput files={importFiles} setFiles={setImportFiles} mimeTypes="application/json" />

        <Element key="import-form" ready={pageState.import} formRef={importFormRef} columnSize={24} style={{ margin: '12px 0px 24px' }} />

        <Button ready={pageState.upload} icon={<UploadOutlined />} onClick={onImportClick} block={true} templates={['colored']}>
          {t('ImportData')}
        </Button>
      </Drawer>
    </Page>
  );
}

const ImportDataDescription = styled.div`
  margin: 12px 0px 24px;
`;
