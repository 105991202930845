import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHandHoldingMedical, FaPlus } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router';
import { checkTreatmentCalculatorInputs } from '../../calculators/doseCalculator';
import { usageCalculator } from '../../calculators/usageCalculator';
import { Button, Card, Page } from '../../components/globals';
import TreatmentProcess from '../../components/pages/treatment/TreatmentProcess';
import {
  DrugType,
  DrugTypes,
  RoleFieldDiseaseTypes,
  RoleFieldType,
  RoleInputCalculateTypes,
  RoleInputDomainType,
  RoleInputTypes,
  TreatmentUsageStatusTypes,
  UserRoleType,
} from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { roleInputCache, treatmentCreate, treatmentDetail, treatmentUpdate, userDetail, userMyDetail } from '../../services/api';
import { toCamelCase } from '../../utilies/modelHelper';
import { notifyError, notifySuccess } from '../../utilies/notification';

export default function TreatmentCreateUpdate() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user] = useCacheUser();
  const [formRef] = Form.useForm();
  const { countryType, userId, id } = useParams();

  const [treatmentUser, setTreatmentUser] = useState(null);
  const [drugs, setDrugs] = useState(null);
  const [calculates, setCalculates] = useState(null);
  const [roleInputs, setRoleInputs] = useState(null);
  const [usages, setUsages] = useState([]);

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [selectedCalculate, setSelectedCalculate] = useState(null);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [selectedUsage, setSelectedUsage] = useState(null);

  const [pageState, setPageState] = useState({
    load: true,
    save: true,
  });

  const [roleInputFilter] = useState({
    orderBy: ['row|asc'],
    pageNumber: 1,
    dataLimit: 100,
    isDeleted: false,
    country: parseInt(user.c),
    roleId: user.r,
    domain: RoleInputDomainType.Treatment,
  });

  const [filter] = useState({
    id,
    isDeleted: false,
    includeUsages: true,
    includeDelivery: true,
    userId: userId ?? user.i,
    country: parseInt(countryType ?? user.c),
    userRole: UserRoleType.Patient,
  });

  const [userFilter] = useState({
    id: userId,
    includeFields: true,
    includeCity: true,
    country: parseInt(countryType ?? user.c),
    userRole: UserRoleType.Patient,
    isMyData: userId === undefined,
  });

  // Hooks
  useEffect(() => {
    load();
  }, [id]);

  // Functions
  const load = async () => {
    setPageState((x) => ({ ...x, load: false }));

    let [roleInputResponse, userResponse, detailResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        roleInputCache(roleInputFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        let request = userFilter.isMyData ? userMyDetail : userDetail;
        request(userFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        if (!id) {
          resolve(null);
          return;
        }

        treatmentDetail(filter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
    ]);

    // Check Data
    if (roleInputResponse?.data == null) {
      notifyError(t('RoleInputNotFoundException'));
      navigate(`/treatment/list`);
      return;
    }

    if (userResponse?.data === null) {
      notifyError(t('UserNotFoundException'));
      navigate(`/treatment/list`);
      return;
    }

    if (id && detailResponse === null) {
      notifyError(t('TreatmentNotFoundException'));
      navigate(`/treatment/list`);
      return;
    }

    // Generate Inputs
    setRoleInputs(roleInputResponse.data);

    // Generate User Data
    let birthDate = userResponse.data.fields.find((x) => x.fieldType === RoleFieldType.BirthDate);
    var diseaseTypes = userResponse.data.fields.find((x) => x.fieldType === RoleFieldType.DiseaseTypes);

    if (!birthDate) {
      notifyError(t('PatientBirthDateNotFoundException'));
      navigate(`/treatment/list`);
      return;
    }

    if (!diseaseTypes) {
      notifyError(t('RoleDrugNotFoundException'));
      navigate(`/treatment/list`);
      return;
    }

    let userDrugs = [];
    let roleDrugs = [...new Set(roleInputResponse.data.map((x) => x.drug))];
    diseaseTypes.value.forEach((x) => {
      let diseaseTypeData = RoleFieldDiseaseTypes.find((f) => f.value === x);
      diseaseTypeData.data.drugs.forEach((d) => {
        let drugData = DrugTypes.find((f) => f.value === d);
        if (
          user.d.some((s) => s === drugData.value) &&
          roleDrugs.some((s) => s === drugData.value) &&
          (userDrugs.length <= 0 || userDrugs.some((s) => s.value !== drugData.value))
        )
          userDrugs.push(drugData);
      });
    });

    if (userDrugs.length <= 0) {
      notifyError(t('RoleInputDrugNotFoundException'));
      navigate(`/treatment/list`);
      return;
    }

    setDrugs(userDrugs);

    let weight = userResponse.data.fields.find((x) => x.fieldType === RoleFieldType.Weight);
    let months = moment().diff(moment(birthDate.value), 'months', true);

    setTreatmentUser({ ...userResponse.data, months, birthDate: moment(birthDate.value), weight: weight?.value });

    if (detailResponse?.data) {
      let detailDrug = DrugTypes.find((x) => x.value === detailResponse.data.drug);
      setSelectedDrug(detailDrug);

      let detailCalculate = RoleInputCalculateTypes.find((x) => x.value === detailResponse.data.calculate);
      setSelectedCalculate(detailCalculate);

      if (detailResponse.data.delivery) {
        setSelectedDelivery(detailResponse.data.delivery);
        setCurrentStep(3);
      } else {
        setCurrentStep(2);
      }

      detailResponse.data.startDate = moment(detailResponse.data.startDate).add(user.t, 'minutes');
      detailResponse.data.birthDate = moment(birthDate.value).add(user.t, 'minutes');

      let detailRoleInputs = roleInputResponse.data.filter((x) => x.drug === detailDrug.value && x.calculate === detailCalculate.value);
      if (detailRoleInputs) {
        let detailInputs = {};
        detailRoleInputs.forEach((x) => {
          let roleInputType = RoleInputTypes.find((f) => f.value === x.type);
          detailInputs[x.id] = detailResponse.data[toCamelCase(roleInputType.name)];
        });

        detailResponse.data = {
          ...detailResponse.data,
          inputs: detailInputs,
        };
      }

      setUsages(
        detailResponse.data.usages.map((x) => ({
          ...x,
          time: moment(x.time).add(user.t, 'minutes'),
          statusData: TreatmentUsageStatusTypes.find((f) => f.value === x.status),
        }))
      );
      setSelectedDetail(detailResponse.data);
      formRef.setFieldsValue(detailResponse.data);
    }

    setPageState((x) => ({ ...x, load: true }));
  };

  // Events
  const onStepClick = (value) => {
    if (value < currentStep) {
      setCurrentStep(value);

      switch (value) {
        default:
          break;
        case 0: // Drug
          setSelectedDelivery(null);
          setSelectedCalculate(null);
          break;
        case 1: // Calculate
          setSelectedDelivery(null);
          break;
        case 2: // Delivery
          break;
        case 3: // Detail
          break;
        case 4: // Usages
          break;
      }
    }
  };

  const onDrugClick = (value) => {
    setSelectedDelivery(null);
    setSelectedCalculate(null);
    setSelectedDrug(value);
    formRef.resetFields();

    let calculateTypes = [...new Set(roleInputs.filter((x) => x.drug === value.value).map((x) => x.calculate))];
    let calculateDatas = calculateTypes.map((x) => RoleInputCalculateTypes.find((f) => f.value === x));
    setCalculates(calculateDatas);

    setCurrentStep(1);
  };

  const onCalculateClick = (value) => {
    let calculateRoleInputs = roleInputs.filter((x) => x.drug === selectedDrug.value && x.calculate === value.value);

    var result = checkTreatmentCalculatorInputs(selectedDrug.value, calculateRoleInputs, value.value);
    if (result) {
      notifyError(result);
      return;
    }

    setSelectedDelivery(null);
    setSelectedCalculate(value);
    formRef.resetFields();
    setCurrentStep(2);
  };

  const onDeliveryClick = (value) => {
    setSelectedDelivery(value);
    formRef.resetFields();
    setCurrentStep(3);
  };

  const onDetailFinish = (model) => {
    setPageState((x) => ({ ...x, save: false }));

    if (model.inputs) {
      Object.keys(model.inputs).forEach((x) => {
        let value = model.inputs[x];

        let roleInput = roleInputs.find((f) => f.id === x);
        if (roleInput) {
          let roleTypeData = RoleInputTypes.find((f) => f.value === roleInput.type);
          if (roleTypeData) {
            model[toCamelCase(roleTypeData.name)] = value;
            return;
          }
        }

        model[x] = value;
      });
    }

    if (model.preparedBottle !== null) if (model.totalBottle === undefined || model.totalBottle === null) model.totalBottle = model.preparedBottle;

    let result = usageCalculator(model, usages);

    if (result.length <= 0) {
      notifyError(t('TreatmentNotFoundException'));
      setPageState((x) => ({ ...x, save: true }));
      return;
    }
    setSelectedDetail(model);
    setUsages(result);
    setCurrentStep(4);

    setPageState((x) => ({ ...x, save: true }));
  };

  const onComplete = () => {
    let model = { id, ...selectedDetail, usages, userId: filter.userId, country: filter.country };

    let request = id === undefined ? treatmentCreate : treatmentUpdate;

    if (model.drug === DrugType.Enspryng) {
      model.dailyDoseMg = 120;
      model.totalBottle = model.usages.length;
    }

    setPageState((x) => ({ ...x, save: false }));
    request(model, (status, res) => {
      if (status) {
        notifySuccess(t(id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
        navigate(`/treatment/list`);
      }

      setPageState((x) => ({ ...x, save: true }));
    });
  };

  // Render
  return (
    <Page
      parent={t('Treatments')}
      title={t(id === undefined ? 'AddNew' : 'Update')}
      icon={id === undefined ? <FaPlus /> : <RiEdit2Fill />}
      routes={[
        {
          path: `/treatment/list`,
          name: t(`Treatments`),
          breadcrumbName: `Treatments`,
          icon: <FaHandHoldingMedical />,
        },
        {
          name: t(id === undefined ? 'AddNew' : 'Update'),
          breadcrumbName: `TreatmentCreateUpdate`,
          icon: id === undefined ? <FaPlus /> : <RiEdit2Fill />,
        },
      ]}
      extra={[
        currentStep === 4 && (
          <Button ready={pageState.save} key="confirm" onClick={onComplete} templates={['colored']}>
            {t('Confirm')}
          </Button>
        ),
        selectedCalculate && (selectedCalculate?.name?.includes('Delivery') ? currentStep === 3 : currentStep === 2) && (
          <Button ready={pageState.save} key="next" onClick={() => formRef.submit()} templates={['colored']}>
            {t('Confirm')}
          </Button>
        ),
      ]}
    >
      <Card ready={pageState.load} templates={['page']}>
        <TreatmentProcess
          user={user}
          id={filter.id}
          treatmentUser={treatmentUser}
          country={filter.country}
          userId={filter.userId}
          current={currentStep}
          roleInputs={roleInputs}
          drugs={drugs}
          calculates={calculates}
          usages={usages}
          setUsages={setUsages}
          onStepClick={onStepClick}
          onDrugClick={onDrugClick}
          selectedDrug={selectedDrug}
          onCalculateClick={onCalculateClick}
          selectedCalculate={selectedCalculate}
          onDeliveryClick={onDeliveryClick}
          selectedDelivery={selectedDelivery}
          onDetailFinish={onDetailFinish}
          selectedDetail={selectedDetail}
          selectedUsage={selectedUsage}
          setSelectedUsage={setSelectedUsage}
          formRef={formRef}
        />
      </Card>
    </Page>
  );
}
