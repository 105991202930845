import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import { RoleColumnColumns, RoleColumnDomainTypes, RoleColumnInputs } from '../../../constants';
import { roleColumnCreate, roleColumnList, roleColumnMultipleDeleteRestore, roleColumnUpdate } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { Button, Drawer, Element, Extra, Section, Select, Table } from '../../globals';

export default function RoleColumnUpdate({ country, roleId }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [isUpdate, setIsUpdate] = useState(null);
  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [domain, setDomain] = useState(null);

  const [pageState, setPageState] = useState({ data: true, sync: undefined });

  const [filter, setFilter] = useState({
    roleId,
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['row|asc'],
    country: country,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    if (filter.domain !== undefined) {
      onDomainChange({ value: filter.domain });
    }

    await roleColumnList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
    });
  };

  // Events
  const onCreateClick = () => {
    setIsUpdate(false);
    setPanelVisibility(true);
    formRef.resetFields();
    formRef.setFieldsValue({ roleId, domain: filter.domain });

    if (filter.domain !== undefined) {
      onDomainChange({ value: filter.domain });
    }
  };

  const onUpdateClick = (row) => {
    setIsUpdate(true);
    setPanelVisibility(true);
    formRef.setFieldsValue(row);
  };

  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, sync: null, data: false }));

    model.country = country;

    let request = model.id === undefined ? roleColumnCreate : roleColumnUpdate;
    await request(model, (status, res) => {
      if (status) {
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
        setPanelVisibility(false);
        list();
      }
      setPageState((x) => ({ ...x, sync: status, data: true }));
    });
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, sync: null, data: false }));

    await roleColumnMultipleDeleteRestore({ ids: selectedRowKeys, country }, (status, res) => {
      if (status) {
        list();
      }
      setPageState((x) => ({ ...x, sync: status, data: true }));
    });
  };

  const onDomainChange = ({ value }) => {
    let domainData = RoleColumnDomainTypes.find((x) => x.value === value);
    setDomain(domainData.name);
  };

  // Render
  return (
    <Container>
      <Description>{t('RoleColumnDescription')}</Description>

      <Section
        headerLeft={[
          <Button key="add" ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-text-title', 'with-icon']}>
            {t('AddNew')}
          </Button>,
        ]}
        headerRight={[
          <Select
            key="domain-filter"
            data={RoleColumnDomainTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(domain) => setFilter((x) => ({ ...x, domain }))}
            placeholder={t('SelectDomain')}
            templates={['filter']}
            defaultValue={filter.domain}
            value={filter.domain}
          />,
          <Extra
            pageState={pageState}
            filter={filter}
            setFilter={setFilter}
            key="filter"
            selectedRowKeys={selectedRowKeys}
            onFilterChanged={() => setSelectedRowKeys([])}
            onDeleteClick={onDeleteClick}
          />,
        ]}
        templates={['role-fields']}
      >
        <Table
          ready={pageState.data}
          response={response}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onRowSelectChange={(values) => setSelectedRowKeys(values)}
          columns={RoleColumnColumns({ onUpdateClick })}
        />

        <Drawer visible={panelVisibility} onClose={() => setPanelVisibility(false)}>
          <Element
            ready={pageState.data}
            formRef={formRef}
            onFinish={onFinish}
            inputs={RoleColumnInputs({ domain, onDomainChange, isUpdate })}
            columnSize={24}
            submit={t('Confirm')}
          />
        </Drawer>
      </Section>
    </Container>
  );
}

const Container = styled.div``;

const Description = styled.div`
  margin-bottom: 24px;
`;
