const NoticeTypes = [
  {
    value: 0,
    name: 'AskForHelpTechnicalIssues',
    displayName: 'AskForHelpTechnicalIssues',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {
      group: 1,
      emailTemplate: 6,
      contentTemplate: 8,
      isJustDisclaimer: false
    }
  },
  {
    value: 1,
    name: 'AskForHelpSupplyDeliveryIssues',
    displayName: 'AskForHelpSupplyDeliveryIssues',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {
      group: 1,
      emailTemplate: 6,
      contentTemplate: 9,
      isJustDisclaimer: false
    }
  },
  {
    value: 2,
    name: 'AskForHelpReportPotentialSideEffect',
    displayName: 'AskForHelpReportPotentialSideEffect',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {
      group: 1,
      emailTemplate: 6,
      contentTemplate: 10,
      isJustDisclaimer: true
    }
  },
  {
    value: 3,
    name: 'AskForHelpRequestProductInformation',
    displayName: 'AskForHelpRequestProductInformation',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {
      group: 1,
      emailTemplate: 6,
      contentTemplate: 11,
      isJustDisclaimer: true
    }
  },
  {
    value: 4,
    name: 'AskForHelpReportAPotentialProductDefect',
    displayName: 'AskForHelpReportAPotentialProductDefect',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {
      group: 1,
      emailTemplate: 6,
      contentTemplate: 12,
      isJustDisclaimer: true
    }
  },
  {
    value: 5,
    name: 'AdverseEvent',
    displayName: 'AdverseEvent',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {
      group: 0,
      emailTemplate: 7,
      contentTemplate: 13,
      isJustDisclaimer: false
    }
  }
];

export default NoticeTypes;