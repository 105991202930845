const ConferenceTypes = [
  {
    value: 0,
    name: 'Pending',
    displayName: 'Pending',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Available',
    displayName: 'Available',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'Waiting',
    displayName: 'Waiting',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'Upcoming',
    displayName: 'Upcoming',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  },
  {
    value: 4,
    name: 'Completed',
    displayName: 'Completed',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {}
  },
  {
    value: 5,
    name: 'Rejected',
    displayName: 'Rejected',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {}
  },
  {
    value: 6,
    name: 'Expired',
    displayName: 'Expired',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {}
  }
];

export default ConferenceTypes;