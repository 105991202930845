import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function EnterVisitDateInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'date',
      name: 'visitDate',
      label: 'VisitDate',
      rules: [{ required: true, message: t('VisitDateEmpty') }],
      disabledDate: (current) => current && current < moment().add(-1, 'days').endOf('day'),
    },
  ];
}
