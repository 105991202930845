import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import {
  DrugType,
  DrugTypes,
  NotificationGroupType,
  NotificationStatusType,
  NotificationStatusTypes,
  NotificationTypes,
  TreatmentUsageStatusType,
  TreatmentUsageStatusTypes,
} from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { treatmentUsageStatusUpdate } from '../../../services/api';
import { replaceParameters } from '../../../utilies/localization';
import { Button } from '../../globals';

export default function Treatment({ notification, load }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [data, setData] = useState(null);
  const [ready, setReady] = useState(true);

  // Hooks
  useEffect(() => {
    if (notification) {
      let result = { ...notification, detail: JSON.parse(notification.data) };

      if (result.detail) {
        result.detail.drugData = DrugTypes.find((x) => x.value === result.detail.drug);
        result.detail.statusData = TreatmentUsageStatusTypes.find((x) => x.value === result.detail.status);
      }

      result.statusData = NotificationStatusTypes.find((x) => x.value === result.status);
      result.typeData = NotificationTypes.find((x) => x.value === result.type);
      result.nameKey = replaceParameters(t(result.detail.nameKey), result.detail.parameters);
      result.descriptionKey = replaceParameters(t(result.detail.descriptionKey), result.detail.parameters);

      setData(result);
    }
  }, [notification]);

  // Events
  const treatmentUseClick = async (status) => {
    setReady(false);
    await treatmentUsageStatusUpdate({ id: data.detail.treatmentUsageId, status, notificationId: notification.id }, (status, res) => {
      if (status && load) {
        load();
      }
      setReady(true);
    });
  };

  // Components
  const GetDrugTitle = ({ detail }) => {
    switch (detail.drug) {
      case DrugType.Enspryng:
        return <Title>{detail.dailyDoseMg}mg</Title>;

      default:
        return (
          <Title>
            {detail.dailyDoseMg}mg/{detail.dailyDoseMl}ml
          </Title>
        );
    }
  };

  // Render
  return (
    data && (
      <Container>
        <Type>{t(data.typeData?.displayName)}</Type>
        <Date>{moment(data.creationTime).add(user.t, 'minutes').format('llll')}</Date>

        <Body>
          <BodyHeader>
            <DrugName>{t(data?.detail?.drugData?.displayName)}</DrugName>
            <GetDrugTitle detail={data?.detail} />
            {data.detail.status !== TreatmentUsageStatusType.Pending && data.detail.userId === user.i && (
              <UsageStatus status={data.detail.status}>{t(data.detail.statusData.displayName)}</UsageStatus>
            )}
          </BodyHeader>
          <Detail>{data.nameKey}</Detail>
          <Detail>{data.descriptionKey}</Detail>

          {data.detail.group === NotificationGroupType.TreatmentUsageRemind && data.status === NotificationStatusType.ActionPending && data.detail.userId === user.i && (
            <Actions>
              <Button ready={ready} onClick={() => treatmentUseClick(TreatmentUsageStatusType.Used)} templates={['colored', 'width-120']}>
                {t('Used')}
              </Button>
              <Button ready={ready} onClick={() => treatmentUseClick(TreatmentUsageStatusType.NotUsed)} templates={['primary', 'width-120']}>
                {t('NotUsed')}
              </Button>
            </Actions>
          )}
        </Body>
      </Container>
    )
  );
}

const Container = styled.div`
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Type = styled.div``;

const Date = styled.div``;

const Detail = styled.div``;

const UsageStatus = styled.div`
  font-weight: bold;

  ${({ status }) => {
    switch (status) {
      case TreatmentUsageStatusType.Used:
        return css`
          color: ${(x) => x.theme.colors.mediumSpringGreen};
        `;
      case TreatmentUsageStatusType.NotUsed:
        return css`
          color: ${(x) => x.theme.colors.rustyRed};
        `;

      default:
        return css`
          color: ${(x) => x.theme.colors.lavenderGray};
        `;
    }
  }}
`;

const Body = styled.div`
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const BodyHeader = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const DrugName = styled.div``;

const Title = styled.div`
  font-weight: bold;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;

  > *:first-child {
    margin-right: 12px;
  }

  > *:last-child {
    margin-left: 12px;
  }
`;
