import { DragOutlined, SwapOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { FaBell, FaPlus, FaTrash } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import styled from 'styled-components';
import { Button, PopupConfirm } from '../../globals';

export default function DeliveryFlowStep({ step, onCreateClick, onEditClick, onNotificationClick, onDeleteClick }) {
  const { t } = useTranslation();

  return (
    step && (
      <Container>
        <MoveIcon />

        <Button onClick={() => onCreateClick(step)} style={{ position: 'absolute', right: 168, top: 6, height: 30 }} templates={['primary']}>
          <FaPlus />
        </Button>

        <Button onClick={() => onEditClick(step)} style={{ position: 'absolute', right: 114, top: 6, height: 30 }} templates={['primary']}>
          <RiEdit2Fill />
        </Button>

        <Button onClick={() => onNotificationClick(step)} style={{ position: 'absolute', right: 60, top: 6, height: 30 }} templates={['primary']}>
          <FaBell />
        </Button>

        <PopupConfirm
          key="delete-confirm"
          title={t('AreYouSureWantToDelete')}
          onConfirm={() => onDeleteClick(step)}
          okText={t('Confirm')}
          cancelText={t('Cancel')}
          placement="bottomRight"
        >
          <Button style={{ position: 'absolute', right: 6, top: 6, height: 30 }} templates={['primary']}>
            <FaTrash />
          </Button>
        </PopupConfirm>

        {step.redirectId && <RedirectIcon />}

        <Header>
          <Group>{t(step.actionData.group)}</Group>
          <span>-</span>
          <Action>{t(step.actionData.displayName)}</Action>
          <span>-</span>
          <UserRole>{t(step.userRoleData.displayName)}</UserRole>
        </Header>
        <Detail>
          <NameValue>
            <Name>{t('NameKey')}: </Name>
            {step.nameKey} ({t(step.nameKey ?? 'N/A')})
          </NameValue>
          <NameValue>
            <Name>{t('DescriptionKey')}: </Name>
            {step.descriptionKey} ({t(step.descriptionKey ?? 'N/A')})
          </NameValue>
          <NameValue>
            <Name>{t('CompletedNameKey')}: </Name>
            {step.completedNameKey} ({t(step.completedNameKey ?? 'N/A')})
          </NameValue>
          <NameValue>
            <Name>{t('CompletedDescriptionKey')}: </Name>
            {step.completedDescriptionKey} ({t(step.completedDescriptionKey ?? 'N/A')})
          </NameValue>
        </Detail>
      </Container>
    )
  );
}

const Container = styled.div`
  position: relative;
  border: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
  border-radius: ${(x) => x.theme.global.borderRadius};
  padding: 6px 6px 6px 32px;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  span {
    padding: 0px 8px;
  }
`;

const Group = styled.div`
  font-weight: bold;
`;

const Action = styled.div``;

const UserRole = styled.div`
  font-weight: bold;
`;

const Detail = styled.div``;

const NameValue = styled.div``;

const Name = styled.strong`
  color: ${(x) => x.theme.colors.darkTurquoise};
`;

const RedirectIcon = styled(SwapOutlined)`
  position: absolute;
  bottom: 6px;
  right: 6px;
  font-size: 24px;
  color: #1890ff;
`;

const MoveIcon = styled(DragOutlined)`
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
`;
