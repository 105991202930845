import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Element } from '../../globals';

export default function ContentRow({ content, formRef }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (content) {
      formRef.setFieldsValue({ row: content.row });
    }
  }, [content]);

  return (
    <Container>
      <Element
        formRef={formRef}
        inputs={[
          {
            type: 'number',
            name: 'row',
            label: t('ContentRow'),
            placeholder: t('ContentRowEmpty'),
            rules: [{ required: true, message: t('ContentRowEmpty') }],
            initialValue: content?.row ?? 0,
          },
        ]}
        columnSize={24}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-left: -22px;
  .ant-form-item {
    margin: 0px;
    padding: 0px;
  }
`;
