import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { DrugTypes, TreatmentUsageStatusTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { treatmentList } from '../../../services/api';
import TreatmentDetail from './TreatmentDetail';

export default function TreatmentDetails() {
  // Definitions
  const [user] = useCacheUser();

  const [data, setData] = useState(null);

  const [filter] = useState({
    userId: user.i,
    country: user.c,
    includeUsages: true,
    isDeleted: false,
  });

  // Hooks
  useEffect(() => {
    load();
  }, []);

  // Functions
  const load = async () => {
    await treatmentList(filter, (status, res) => {
      if (status && res?.data?.length > 0) {
        let result = res.data
          .filter((x) => x.usages?.length > 0)
          .map((x) => {
            x.drugData = DrugTypes.find((d) => d.value === x.drug);
            x.startDate = moment(x.startDate).add(user.t, 'minutes');
            x.endDate = moment(x.endDate).add(user.t, 'minutes');

            if (x.suspendTime) {
              x.usages = x.usages.filter((u) => moment(x.suspendTime).add(user.t, 'minutes') > moment(u.time).add(user.t, 'minutes') && !u.isDeleted);
            }

            x.usages = x.usages
              .filter((u) => !u.isDeleted)
              .map((u, ui) => {
                u.index = ui;
                u.drugData = DrugTypes.find((d) => d.value === x.drug);
                u.time = moment(u.time).add(user.t, 'minutes');
                u.statusData = TreatmentUsageStatusTypes.find((f) => f.value === u.status);

                return u;
              });

            let todayUsage = x.usages.find((x) => x.time.isSame(moment(), 'day'));
            if (todayUsage) {
              x.selectedIndex = todayUsage.index + 2 >= x.usages.length ? x.usages.length - 1 : todayUsage.index + 2;
            } else {
              x.selectedIndex = 0;
            }

            return x;
          });

        setData(result);
      }
    });
  };

  // Render
  return (
    data && (
      <Fragment>
        {data.map((x, i) => (
          <TreatmentDetail key={`treatment-detail-${i}`} treatment={x} />
        ))}
      </Fragment>
    )
  );
}
