import { Dropdown, Menu, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { AiFillNotification } from 'react-icons/ai';
import { FaCheckDouble, FaFlag, FaUserTie, FaUserTimes } from 'react-icons/fa';
import { ImList2 } from 'react-icons/im';
import { RiEdit2Fill, RiMedicineBottleFill } from 'react-icons/ri';
import styled from 'styled-components';
import { Button, PopupConfirm } from '../../components/globals';
import EditButton from '../../components/globals/button/EditButton';
import { CountryTypes, UserRoleTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function RoleColumns({
  isAllCountry,
  onDeliveryDrugUpdateClick,
  onDrugUpdateClick,
  onLanguageUpdateClick,
  onParentUpdateClick,
  onFieldUpdateClick,
  onInputUpdateClick,
  onColumnUpdateClick,
  onNotificationUpdateClick,
  onActionUpdateClick,
  onLogoutUserClick,
}) {
  const { t } = useTranslation();

  const menu = (row) => (
    <UpdateMenu>
      {onActionUpdateClick && (
        <UpdateMenuItem key="action-update" onClick={() => onActionUpdateClick(row)}>
          <FaCheckDouble /> {t('Actions')}
        </UpdateMenuItem>
      )}
      {onFieldUpdateClick && (
        <UpdateMenuItem key="field-update" onClick={() => onFieldUpdateClick(row)}>
          <ImList2 /> {t('Fields')}
        </UpdateMenuItem>
      )}
      {onInputUpdateClick && (
        <UpdateMenuItem key="input-update" onClick={() => onInputUpdateClick(row)}>
          <ImList2 /> {t('Inputs')}
        </UpdateMenuItem>
      )}
      {onColumnUpdateClick && (
        <UpdateMenuItem key="column-update" onClick={() => onColumnUpdateClick(row)}>
          <ImList2 /> {t('Columns')}
        </UpdateMenuItem>
      )}
      {onParentUpdateClick && (
        <UpdateMenuItem key="parent-update" onClick={() => onParentUpdateClick(row)}>
          <FaUserTie /> {t('RoleParents')}
        </UpdateMenuItem>
      )}
      {onNotificationUpdateClick && (
        <UpdateMenuItem key="notification-update" onClick={() => onNotificationUpdateClick(row)}>
          <AiFillNotification /> {t('Notifications')}
        </UpdateMenuItem>
      )}
      {onLanguageUpdateClick && (
        <UpdateMenuItem key="language-update" onClick={() => onLanguageUpdateClick(row)}>
          <FaFlag /> {t('Languages')}
        </UpdateMenuItem>
      )}
      {onDrugUpdateClick && (
        <UpdateMenuItem key="drug-update" onClick={() => onDrugUpdateClick(row)}>
          <RiMedicineBottleFill /> {t('Drugs')}
        </UpdateMenuItem>
      )}
      {onDeliveryDrugUpdateClick && (
        <UpdateMenuItem key="delivery-drug-update" onClick={() => onDeliveryDrugUpdateClick(row)}>
          <RiMedicineBottleFill /> {t('DeliveryDrugs')}
        </UpdateMenuItem>
      )}
    </UpdateMenu>
  );

  let columns = [
    isAllCountry && {
      locale: 'Country',
      name: 'country',
      type: 'string',
      render: (value, row) => {
        let country = CountryTypes.find((x) => x.value === value);

        return country ? (
          <Tooltip placement="topLeft" title={t(country.displayName)}>
            {t(country.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'UserRole',
      name: 'userRole',
      type: 'string',
      render: (value, row) => {
        let userRole = UserRoleTypes.find((x) => x.value === value);

        return userRole ? (
          <Tooltip placement="topLeft" title={t(userRole.displayName)}>
            {t(userRole.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Dropdown overlay={menu(row)} placement="bottomRight" className="role">
          <EditButton>
            <RiEdit2Fill />
          </EditButton>
        </Dropdown>
      ),
    },
    onLogoutUserClick && {
      locale: 'Logout',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <PopupConfirm
          key="logout"
          title={t('RoleUsersLoginAgain')}
          onConfirm={() => onLogoutUserClick(row)}
          okText={t('Confirm')}
          cancelText={t('Cancel')}
          placement="bottomRight"
        >
          <Button key="logout" templates={['table']}>
            <FaUserTimes />
          </Button>
        </PopupConfirm>
      ),
    },
  ];

  return ColumnBase({ columns });
}

const UpdateMenu = styled(Menu)`
  padding: 0px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
`;

const UpdateMenuItem = styled(Menu.Item)`
  padding: 12px;

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;

    svg {
      color: ${(x) => x.theme.colors.robinEggBlue};
      margin-right: 10px;
    }
  }
`;
