import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { TreatmentStageTypes, TreatmentStatusTypes } from '../../constants';

export default function DeliveryEnspryngInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'number',
      name: 'duration',
      label: t('TreatmentDurationInMonths'),
      placeholder: t('TreatmentDurationInMonthsEmpty'),
      rules: [{ required: true, message: t('TreatmentDurationInMonthsEmpty') }],
      numberRule: { min: 1, max: 240, message: format(t('LengthValidNumber'), 1, 240) },
    },
    {
      type: 'select',
      name: 'status',
      label: t('TreatmentStatus'),
      placeholder: t('SelectTreatmentStatus'),
      rules: [{ required: true, message: t('TreatmentStatusEmpty') }],
      data: TreatmentStatusTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'select',
      name: 'stage',
      label: t('TreatmentStage'),
      placeholder: t('SelectTreatmentStage'),
      rules: [{ required: true, message: t('TreatmentStageEmpty') }],
      data: TreatmentStageTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'number',
      name: 'lastCount',
      label: t('NumberOfLastInjection'),
      placeholder: t('NumberOfLastInjectionEmpty'),
      rules: [{ required: true, message: t('NumberOfLastInjectionEmpty') }],
    },
    {
      type: 'select',
      name: 'lastStatus',
      label: t('TreatmentLastStatus'),
      placeholder: t('SelectTreatmentLastStatus'),
      rules: [{ required: true, message: t('TreatmentLastStatusEmpty') }],
    },
  ];
}
