import { useTranslation } from 'react-i18next';
import { ImTruck } from 'react-icons/im';
import styled from 'styled-components';
import { Button } from '../../globals';

export default function DeliveryRedirect({ redirects, onClick }) {
  const { t } = useTranslation();

  return (
    redirects && (
      <Container>
        {redirects.map((x, i) => (
          <Item key={`item-${i}`} onClick={() => onClick(x)}>
            <IconWrapper>
              <ImTruck style={{ fontSize: 60 }} />
            </IconWrapper>
            <Title>{t(x.nameKey)}</Title>
            <Description>{t(x.descriptionKey)}</Description>
            <Button templates={['colored']} style={{ width: '100%' }}>
              {t('Select')}
            </Button>
          </Item>
        ))}
      </Container>
    )
  );
}

const Container = styled.div``;

const Item = styled.div`
  cursor: pointer;
  width: calc(25% - 20px);
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 24px;
  margin: 0px 10px 20px;

  padding: 12px;
  border: 1px solid ${(x) => x.theme.colors.mediumSpringGreen};
  border-radius: 12px;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
    border-radius: ${(x) => x.theme.global.borderRadius};
  }
`;

const IconWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(x) => x.theme.colors.mediumSpringGreen};
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Description = styled.div`
  min-height: 130px;
`;
