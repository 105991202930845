import { useTranslation } from 'react-i18next';
import { RoleFieldTypes } from '../../constants';

export default function RoleFieldInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'hidden',
      name: 'roleId',
    },
    {
      type: 'select',
      name: 'type',
      label: t('FieldType'),
      placeholder: t('SelectFieldType'),
      rules: [{ required: true, message: t('FieldTypeEmpty') }],
      data: RoleFieldTypes.sort((x, y) => x.order - y.order).map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'number',
      name: 'row',
      label: t('FieldRow'),
      placeholder: t('FieldRowEmpty'),
      rules: [{ required: true, message: t('FieldRowEmpty') }],
      initialValue: 0,
    },
    {
      type: 'number',
      name: 'minimumValue',
      label: t('FieldMinimumValue'),
      placeholder: t('FieldMinimumValueEmpty'),
      rules: [{ required: true, message: t('FieldMinimumValueEmpty') }],
      initialValue: 0,
    },
    {
      type: 'number',
      name: 'maximumValue',
      label: t('FieldMaximumValue'),
      placeholder: t('FieldMaximumValueEmpty'),
      rules: [{ required: true, message: t('FieldMaximumValueEmpty') }],
      initialValue: 0,
    },
    {
      type: 'select',
      name: 'isRequired',
      label: t('IsRequired'),
      placeholder: t('SelectRequirement'),
      rules: [{ required: true, message: t('IsRequiredEmpty') }],
      data: [
        { value: true, text: t('Required') },
        { value: false, text: t('NotRequired') },
      ],
      initialValue: true,
    },
  ];
}
