import { useTranslation } from 'react-i18next';
import { groupByList } from '../../utilies/expression';

export default function RoleActionInputs({ actions, onChange }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'roleId',
    },
    ...groupByList(actions, 'group')?.map((x, i) => ({
      type: 'checkbox-group',
      name: `ActionType${x.key}`,
      label: t(x.key),
      onChange: ({ values }) => onChange({ key: x.key, values }),
      data: x.values.map((v) => ({ ...v, label: t(v.displayName) })),
      css: `
        .ant-checkbox-group-item {
          width: 100%;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
        }
        .ant-form-item-label{
          margin-bottom:8px;
        }
      `,
    })),
  ];
}
