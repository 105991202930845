import { Form } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaUserAlt, FaUserEdit, FaUserMd, FaUserNurse, FaUserShield, FaUserTag, FaUserTie } from 'react-icons/fa';
import { RiAdminFill, RiUserHeartFill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router';
import { format } from 'react-string-format';
import { ReduceContext } from '../../components/contexts/ReduceContext';
import { Button, Drawer, Element, Extra, Page, Select, Table } from '../../components/globals';
import UserRelation from '../../components/pages/user/UserRelation';
import { CountryTypes, UserRoleActionType, UserRoleType, UserRoleTypes } from '../../constants';
import UserColumns from '../../constants/columns/UserColumns';
import UserStatusInputs from '../../constants/inputs/UserStatusInputs';
import { useCacheUser } from '../../hooks/useCacheUser';
import { userAnonymize, userList, userMultipleDeleteRestore, userStatusUpdate, userUpdateActivity } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';
import { updateResponse } from '../../utilies/stateManagement';

export default function Users() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userRoleType } = useParams();
  const userRole = UserRoleTypes.find((x) => x.value === parseInt(userRoleType));

  const [user] = useCacheUser();
  const [state] = useContext(ReduceContext);
  const [userStatusFormRef] = Form.useForm();
  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [relationPanelVisiblity, setRelationPanelVisibility] = useState(false);
  const [statusPanelVisibility, setStatusPanelVisibility] = useState(false);
  const [rowData, setRowData] = useState(null);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
    userRole: userRole?.value,
    country: user.c,
  });

  const isAllCountry = user.checkUserRole(userRole?.value, UserRoleActionType.AllCountry);

  // Hooks
  useEffect(() => {
    setFilter((x) => ({ ...x, userRole: userRoleType }));
  }, [userRoleType]);

  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    if (!user.checkUserRole(userRole?.value, UserRoleActionType.List)) {
      setTimeout(() => navigate(`/`, { error: 'UserForbiddenException' }), 500);
      return;
    }

    setPageState((x) => ({ ...x, list: false }));

    await userList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onCreateClick = () => {
    setPageState((x) => ({ ...x, create: false }));

    if (isAllCountry) {
      navigate(`/user/create/${userRole.value}/${filter.country}`);
    } else {
      navigate(`/user/create/${userRole.value}`);
    }
  };

  const onUpdateClick = (row) => {
    setPageState((x) => ({ ...x, create: false }));

    if (isAllCountry) {
      navigate(`/user/update/${userRole.value}/${row.country}/${row.id}`);
    } else {
      navigate(`/user/update/${userRole.value}/${row.id}`);
    }
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, delete: false }));

    await userMultipleDeleteRestore({ userRole: filter.userRole, ids: selectedRowKeys, country: filter.country }, (status, res) => {
      if (status) {
        list();

        notifySuccess(format(t(filter.isDeleted ? 'DataRestoreSuccess' : 'DataDeleteSuccess'), <strong>{res.data}</strong>));
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const onRelationClick = async (row) => {
    setRowData(row);
    setRelationPanelVisibility(true);
  };

  const onStatusChangeClick = async (row) => {
    if (!row.isActive) {
      await userUpdateActivity({ userRole: filter.userRole, id: row.id, isActive: true, country: filter.country }, (status, res) => {
        if (status) {
          notifySuccess(t('UpdateCompleteSuccess'));
        }

        setPageState((x) => ({ ...x, update: true }));
        setResponse(updateResponse(response, 'isActive', true, false, row));
      });

      return;
    }
    userStatusFormRef.setFieldsValue({ userRole: filter.userRole, userId: row.id, country: filter.country });
    setRowData(row);
    setStatusPanelVisibility(true);
  };

  const onStatusChangeFormFinish = async (model) => {
    setPageState((x) => ({ ...x, update: false }));

    await userStatusUpdate(model, (status, res) => {
      if (status) {
        list();
        setStatusPanelVisibility(false);
        notifySuccess(t('UpdateCompleteSuccess'));
      }
      setPageState((x) => ({ ...x, update: true }));
    });
  };

  const onJourneyPageClick = (row) => {
    navigate(`/journey/${filter.userRole}/${filter.country}/${row.id}`);
  };

  const onAnonymizeClick = async (row) => {
    setPageState((x) => ({ ...x, delete: false }));

    await userAnonymize({ id: row.id, userRole: filter.userRole, country: filter.country }, (status, res) => {
      if (status) {
        list();

        notifySuccess(t('AnonymizeCompleteSuccess'));
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  // Components
  const getRoleIcon = (role) => {
    switch (role) {
      case UserRoleType.Owner:
      case UserRoleType.Administrator:
      case UserRoleType.Manager:
        return <RiAdminFill />;
      case UserRoleType.Editor:
        return <FaUserEdit />;
      case UserRoleType.PSP:
        return <FaUserTie />;
      case UserRoleType.Doctor:
        return <FaUserMd />;
      case UserRoleType.Nurse:
        return <FaUserNurse />;
      case UserRoleType.Pharmacist:
        return <FaUserTag />;
      case UserRoleType.Warehouse:
      case UserRoleType.IMS:
        return <FaUserShield />;
      case UserRoleType.Patient:
        return <RiUserHeartFill />;

      default:
        return <FaUserAlt />;
    }
  };

  // Render
  return (
    <Page
      title={t(`Menu${userRole?.displayName}`)}
      icon={getRoleIcon(userRole.value)}
      subTitle={
        user.checkUserRole(userRole?.value, UserRoleActionType.Create) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t(`Menu${userRole.displayName}`),
          breadcrumbName: 'UserCreateUpdate',
          icon: getRoleIcon(userRole.value),
        },
      ]}
      templates={[isAllCountry ? 'default-1-filter-4' : 'default-0-filter-4']}
      extra={[
        isAllCountry && (
          <Select
            key="country-filter"
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            placeholder={t('SelectCountry')}
            defaultValue={filter.country}
            value={filter.country}
            allowClear={false}
            templates={['filter']}
          />
        ),
        <Extra
          key="filter"
          pageState={pageState}
          filter={filter}
          setFilter={setFilter}
          activityFilter={true}
          selectedRowKeys={selectedRowKeys}
          onFilterChanged={() => setSelectedRowKeys([])}
          onDeleteClick={user.checkUserRole(userRole?.value, UserRoleActionType.Delete) && onDeleteClick}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        selectedRowKeys={selectedRowKeys}
        onRowSelectChange={(values) => setSelectedRowKeys(values)}
        columns={UserColumns({
          viewerIdList: state?.viewers,
          isAllCountry,
          onUpdateClick: !filter.isDeleted && user.checkUserRole(userRole?.value, UserRoleActionType.Update) && onUpdateClick,
          onRelationClick:
            !filter.isDeleted && userRole?.value !== UserRoleType.Patient && user.checkUserRole(userRole?.value, UserRoleActionType.Relation) && onRelationClick,
          onStatusChangeClick:
            !filter.isDeleted && userRole?.value === UserRoleType.Patient && user.checkUserRole(userRole?.value, UserRoleActionType.Update) && onStatusChangeClick,
          onJourneyPageClick: !filter.isDeleted && user.checkUserRole(userRole?.value, UserRoleActionType.JourneyPage) && onJourneyPageClick,
          onAnonymizeClick: filter.isDeleted && user.checkUserRole(userRole?.value, UserRoleActionType.Anonymize) && onAnonymizeClick,
        })}
      />

      <Drawer visible={statusPanelVisibility} onClose={() => setStatusPanelVisibility(false)}>
        <Element
          key="user-status-form"
          ready={pageState.form}
          formRef={userStatusFormRef}
          onFinish={onStatusChangeFormFinish}
          inputs={UserStatusInputs()}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>

      {rowData && <UserRelation visible={relationPanelVisiblity} onClose={() => setRelationPanelVisibility(false)} rowUser={rowData} />}
    </Page>
  );
}
