import { useState } from 'react';
import { getItem, setItem } from '../services/cache';

export function useCache(key, initialValue) {
  const [cacheValue, setCacheValue] = useState(getItem(key) ?? initialValue);

  const setValue = (value) => {
    const valueToCache = value instanceof Function ? value(cacheValue) : value;
    setCacheValue(valueToCache);
    setItem(key, valueToCache);
  };
  return [cacheValue, setValue];
}
