import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { format } from 'react-string-format';
import styled, { css } from 'styled-components';
import { UserRoleActionType, UserRoleType } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { replaceParameters } from '../../../utilies/localization';

export default function DeliverySuggestions({ targetUserId, suggestionUserRoleDatas, isDeliveryUserSelection, onDeliveryCreateUserSelectionClick }) {
  // Definitions
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user] = useCacheUser();

  // Render
  return (
    <Fragment>
      <SuggestionsTitle>
        {replaceParameters(t('DeliveryUserRelationNotFoundException'), {
          'UserRole.Names': suggestionUserRoleDatas.map((x) => t(x.displayName)).join(','),
        })}
      </SuggestionsTitle>

      {isDeliveryUserSelection && (
        <SuggestionsText key="delivery-user-selection">
          <FaCircle />

          {format(
            replaceParameters(t('AddUserRoleDeliveryUsers'), {
              'UserRole.Names': '{0}',
              'Delivery.Users.Section': '{1}',
            }),
            <SuggestionsBold>{suggestionUserRoleDatas.map((x) => t(x.displayName)).join(', ')}</SuggestionsBold>,
            <SuggestionsBold onClick={() => navigate('/delivery/list')} type="redirect">
              {t('DeliveryUsersPanel')}
            </SuggestionsBold>
          )}
        </SuggestionsText>
      )}

      {onDeliveryCreateUserSelectionClick && (
        <SuggestionsText key="delivery-user-selection-click">
          <FaCircle />

          {format(
            replaceParameters(t('AddUserRoleDeliveryUsers'), {
              'UserRole.Names': '{0}',
              'Delivery.Users.Section': '{1}',
            }),
            <SuggestionsBold>{suggestionUserRoleDatas.map((x) => t(x.displayName)).join(', ')}</SuggestionsBold>,
            <SuggestionsBold onClick={onDeliveryCreateUserSelectionClick} type="redirect">
              {t('DeliveryUsersPanel')}
            </SuggestionsBold>
          )}
        </SuggestionsText>
      )}

      {user.checkUserRole(UserRoleType.Patient, UserRoleActionType.Update) &&
        suggestionUserRoleDatas.map(
          (x, i) =>
            user.checkUserRole(x.value, UserRoleActionType.List) && (
              <SuggestionsText key={`suggestion-role-${i}`}>
                <FaCircle />
                {format(
                  replaceParameters(t('AddUserParentPatient'), {
                    'UserRole.Names': '{0}',
                    'Patient.Update': '{1}',
                  }),
                  <SuggestionsBold>{suggestionUserRoleDatas.map((x) => t(x.displayName)).join(', ')}</SuggestionsBold>,
                  <SuggestionsBold onClick={() => navigate(`/user/update/${UserRoleType.Patient}/${user.c}/${targetUserId}`)} type="redirect">
                    {t('PatientUpdate')}
                  </SuggestionsBold>
                )}
              </SuggestionsText>
            )
        )}

      {suggestionUserRoleDatas.map(
        (x, i) =>
          user.checkUserRole(x.value, UserRoleActionType.List) &&
          user.checkUserRole(x.value, UserRoleActionType.Update) && (
            <SuggestionsText key={`suggestion-action-${i}`}>
              <FaCircle />
              {format(
                replaceParameters(t('AddUserRelationUsers'), {
                  'UserRole.Names': '{0}',
                }),
                <SuggestionsBold>
                  {suggestionUserRoleDatas.map((x, i) => (
                    <SuggestionsBold key={`suggesstion-bold-${i}`} onClick={() => navigate(`/user/list/${x.value}`)} type="redirect">
                      {t(`Menu${x.displayName}`)}
                      {i < suggestionUserRoleDatas.length - 1 && <SuggestionsBold type="text">, </SuggestionsBold>}
                    </SuggestionsBold>
                  ))}
                </SuggestionsBold>
              )}
            </SuggestionsText>
          )
      )}
    </Fragment>
  );
}

const SuggestionsTitle = styled.div`
  color: ${(x) => x.theme.colors.rustyRed};
  font-weight: bold;
  margin-bottom: 20px;
`;

const SuggestionsText = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  svg {
    color: ${(x) => x.theme.colors.darkTurquoise};
    padding-top: 5px;
    margin-right: 2px;
  }
`;

const SuggestionsBold = styled.span`
  font-weight: bold;

  ${({ type }) =>
    type === 'redirect' &&
    css`
      cursor: pointer;
      color: ${(x) => x.theme.colors.darkTurquoise};
    `}

  ${({ type }) =>
    type === 'text' &&
    css`
      color: ${(x) => x.theme.colors.deepKaomaru};
    `}
`;
