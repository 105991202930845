import { Form } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { format } from 'react-string-format';
import styled from 'styled-components';
import { ConferenceStatusType, NotificationGroupType, NotificationStatusType, NotificationStatusTypes, NotificationTypes, UserRoleType } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { conferenceUpdateStatus } from '../../../services/api';
import { replaceParameters } from '../../../utilies/localization';
import { notifySuccess } from '../../../utilies/notification';
import { ReduceContext } from '../../contexts/ReduceContext';
import { Button, Element, Modal } from '../../globals';

export default function Conference({ notification, load, onClose }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();
  const [user] = useCacheUser();
  const navigate = useNavigate();

  const [state, dispatch] = useContext(ReduceContext);
  const [data, setData] = useState(null);
  const [panelVisibility, setPanelVisibility] = useState(false);

  // Hooks
  useEffect(() => {
    if (notification) {
      let result = { ...notification, detail: JSON.parse(notification.data) };

      if (result.detail?.dates) {
        result.detail.dates = result.detail.dates.map((x) => moment(x).add(user.t, 'minutes'));
      }

      result.statusData = NotificationStatusTypes.find((x) => x.value === result.status);
      result.typeData = NotificationTypes.find((x) => x.value === result.type);
      result.nameKey = replaceParameters(t(result.detail.nameKey), result.detail.parameters);
      result.descriptionKey = replaceParameters(t(result.detail.descriptionKey), result.detail.parameters);
      if (result.detail.parameters['content.Id']) {
        result.content = {
          id: result.detail.parameters['content.Id'],
          title: result.detail.parameters['content.Title'],
          url: result.detail.parameters['content.Url'],
        };
      }

      setData(result);
    }
  }, [notification]);

  // Events
  const onFinish = async (model) => {
    model = { ...model, id: data.detail.id, notificationId: notification.id, status: ConferenceStatusType.Approved };

    await conferenceUpdateStatus(model, (status, res) => {
      if (status) {
        load();
        dispatch({ ...state, conference: { list: true } });
        notifySuccess(t('UpdateCompleteSuccess'));
      } else {
        setPanelVisibility(false);
        load();
      }
    });
  };

  const onRejectClick = async () => {
    let model = { id: data.detail.id, notificationId: notification.id, status: ConferenceStatusType.Rejected };

    await conferenceUpdateStatus(model, (status, res) => {
      if (status) {
        load();
        notifySuccess(t('UpdateCompleteSuccess'));
      }
    });
  };

  const onClickSurvey = () => {
    navigate(`/contents/${data.content.url}`);
    onClose();
  };

  // Components
  const RenderBody = () => {
    if (data.detail.group === NotificationGroupType.Create) {
      if (data.status === NotificationStatusType.Accepted) {
        return (
          <Body>
            {data.detail.userId === user.i ? (
              <Detail>{format(t('ConferenceHasAcceptedYourInvitation'), data.detail?.targetUserFullName)}</Detail>
            ) : (
              <Detail>{format(t('ConferenceHasAccepted'), data.detail?.userFullName)}</Detail>
            )}

            {data.content && (
              <SurveyDetail>
                <SurveyText>{format(t('CompleteSurveyConference'), <SurveyRedirect onClick={onClickSurvey}>{data.content.title}</SurveyRedirect>)}</SurveyText>
              </SurveyDetail>
            )}
          </Body>
        );
      }

      if (data.status === NotificationStatusType.Rejected) {
        return (
          <Body>
            {data.detail.userId === user.i ? (
              <Detail>{format(t('ConferenceHasRejectedYourInvitation'), data.detail?.targetUserFullName)}</Detail>
            ) : (
              <Detail>{format(t('ConferenceHasRejected'), data.detail?.userFullName)}</Detail>
            )}
          </Body>
        );
      }

      let isAvailable = data.detail.dates.some((x) => moment() < x);
      if (!isAvailable) {
        return (
          <Body>
            <Detail>{format(t('ConferenceIsNotAvailable'), <strong>{data.detail?.userFullName}</strong>)}</Detail>
          </Body>
        );
      }

      return (
        <Body>
          <Detail>{data.nameKey}</Detail>
          <Detail>{data.descriptionKey}</Detail>

          {data.status === NotificationStatusType.ActionPending && (user.u === UserRoleType.Patient || user.u === UserRoleType.Relative) && (
            <Actions>
              <Button key="show" onClick={() => setPanelVisibility(true)} templates={['colored', 'width-120']}>
                {t('Show')}
              </Button>
              <Button key="reject" onClick={onRejectClick} templates={['primary', 'width-120']}>
                {t('Reject')}
              </Button>
            </Actions>
          )}
        </Body>
      );
    }

    return (
      <Body>
        <Detail>{data.nameKey}</Detail>
        <Detail>{data.descriptionKey}</Detail>
      </Body>
    );
  };

  // Render
  return (
    data && (
      <Container>
        <Type>{t(data.typeData?.displayName)}</Type>
        <Date>{moment(data.creationTime).add(user.t, 'minutes').format('llll')}</Date>

        <RenderBody />

        <Modal
          width={320}
          title={t(data.typeData?.displayName)}
          visible={panelVisibility}
          onCancelClick={() => setPanelVisibility(false)}
          footer={null}
          templates={['colored']}
        >
          <Element
            key="conference-form"
            columnSize={24}
            formRef={formRef}
            onFinish={onFinish}
            inputs={[
              {
                type: 'radio-group',
                name: `date`,
                label: t('SelectDate'),
                rules: [{ required: true, message: t('DateEmpty') }],
                data: data?.detail?.dates?.map((x) => ({ label: moment(x).format('llll'), value: moment(x).format() })),
                css: `
                  .ant-radio-wrapper-in-form-item {
                    width: 100%;
                    margin-bottom: 10px;
                  }
                  `,
              },
            ]}
            submit={t('Confirm')}
          />
        </Modal>
      </Container>
    )
  );
}

const Container = styled.div`
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Type = styled.div``;

const Date = styled.div``;

const Body = styled.div`
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Detail = styled.div``;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`;

const SurveyDetail = styled.div``;

const SurveyRedirect = styled.strong`
  font-weight: bold;
  cursor: pointer;
  color: ${(x) => x.theme.colors.darkTurquoise};
`;

const SurveyText = styled.div``;
