import { Global } from '../../components/globals';
import StaticContentDetail from '../../components/pages/static/StaticContentDetail';
import { ContentGroupType } from '../../constants';

export default function PrivacyPolicy() {
  // Render
  return (
    <Global language={false}>
      <StaticContentDetail group={ContentGroupType.PrivacyPolicy} />
    </Global>
  );
}
