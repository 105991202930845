import { ActionType, DrugType } from '../../../constants';
import EnspryngCalculator from '../../globals/calculator/EnspryngCalculator';
import EvrysdiCalculator from '../../globals/calculator/EvrysdiCalculator';
import ShelfLifeEvrysdiCalculator from '../../globals/calculator/ShelfLifeEvrysdiCalculator';

export default function DeliveryDoseCalculator({ user, drug, visible, onConfirm }) {
  switch (drug) {
    default:
    case DrugType.Evrysdi:
      if (user?.checkAction(ActionType.CalculatorEvrysdiShelfLife)) {
        return <ShelfLifeEvrysdiCalculator user={user} templates={['delivery']} visible={visible} onConfirm={onConfirm} />;
      }

      return <EvrysdiCalculator user={user} templates={['delivery']} visible={visible} onConfirm={onConfirm} />;

    case DrugType.Enspryng:
      return <EnspryngCalculator user={user} templates={['delivery']} visible={visible} onConfirm={onConfirm} />;
  }
}
