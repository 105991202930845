import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import { Button, Drawer, Element, Extra, Section, Select, Table } from '../../components/globals';
import {
  DeliveryFlowStepNotificationColumns,
  DeliveryFlowStepNotificationInputs,
  NotificationChannelType,
  NotificationChannelTypes,
  NotificationGroupTypes,
  NotificationUserRoleType,
  NotificationUserRoleTypes,
  TextlocalSmsTemplateTypes,
} from '../../constants';
import {
  deliveryFlowStepNotificationCreate,
  deliveryFlowStepNotificationList,
  deliveryFlowStepNotificationMultipleDeleteRestore,
  deliveryFlowStepNotificationUpdate,
} from '../../services/api';
import { notifySuccess } from '../../utilies/notification';

export default function DeliveryFlowStepNotification({ deliveryFlowStep, country }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [notificationGroup, setNotificationGroup] = useState(null);
  const [notificationUserRole, setNotificationUserRole] = useState(null);
  const [notificationChannel, setNotificationChannel] = useState(null);
  const [smsTemplate, setSmsTemplate] = useState(null);

  const [pageState, setPageState] = useState({ list: true, form: true, delete: true });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['row|asc'],
    country: country,
    deliveryFlowStepId: deliveryFlowStep.id,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    await deliveryFlowStepNotificationList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
    });
  };

  // Events
  const onGroupChange = ({ value }) => {
    setNotificationGroup(value);
  };

  const onUserRoleChange = ({ value }) => {
    setNotificationUserRole(value);
  };

  const onChannelChange = ({ value }) => {
    setNotificationChannel(value);

    if (notificationUserRole === NotificationUserRoleType.Special) {
      formRef.setFieldsValue({ value: null });
    }
  };

  const onSmsTemplateChange = ({ value }) => {
    setSmsTemplate(TextlocalSmsTemplateTypes.find((x) => x.value === value));
  };

  const onCreateClick = () => {
    formRef.resetFields();
    setPanelVisibility(true);
    setNotificationUserRole(null);
    setNotificationChannel(null);
    setNotificationGroup(null);
    setSmsTemplate(null);

    formRef.setFieldsValue({ nameKey: deliveryFlowStep.nameKey, descriptionKey: deliveryFlowStep.descriptionKey });
  };

  const onUpdateClick = (row) => {
    formRef.setFieldsValue(row);
    setPanelVisibility(true);
    setNotificationUserRole(row.userRole);
    setNotificationChannel(row.channel);
    setNotificationGroup(row.group);
    onSmsTemplateChange({ value: row.smsTemplateTypeId });
  };

  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    model = { ...model, country, deliveryFlowStepId: deliveryFlowStep.id };

    let request = model.id === undefined ? deliveryFlowStepNotificationCreate : deliveryFlowStepNotificationUpdate;
    await request(model, (status, res) => {
      if (status) {
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
        setPanelVisibility(false);
        list();
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await deliveryFlowStepNotificationMultipleDeleteRestore({ ids: selectedRowKeys, country, deliveryFlowStepId: deliveryFlowStep.id }, (status, res) => {
      if (status) {
        list();
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Render
  return (
    <Container>
      <Description>{t('DeliveryFlowStepNotificationDescription')}</Description>

      <Section
        headerLeft={[
          <Button key="add" ready={pageState.form} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-text-title', 'with-icon']}>
            {t('AddNew')}
          </Button>,
        ]}
        headerRight={[
          <Select
            key="group-filter"
            data={NotificationGroupTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(group) => setFilter((x) => ({ ...x, group }))}
            placeholder={t('SelectGroup')}
            templates={['filter']}
            defaultValue={filter.group}
            value={filter.group}
          />,
          <Select
            key="user-role-filter"
            data={NotificationUserRoleTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(userRole) => setFilter((x) => ({ ...x, userRole }))}
            placeholder={t('SelectUserRole')}
            templates={['filter']}
            defaultValue={filter.userRole}
            value={filter.userRole}
          />,
          <Select
            key="channel-filter"
            data={NotificationChannelTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(channel) => setFilter((x) => ({ ...x, channel }))}
            placeholder={t('SelectChannel')}
            templates={['filter']}
            defaultValue={filter.channel}
            value={filter.channel}
          />,
          <Extra
            pageState={pageState}
            filter={filter}
            setFilter={setFilter}
            key="filter"
            selectedRowKeys={selectedRowKeys}
            onFilterChanged={() => setSelectedRowKeys([])}
            onDeleteClick={onDeleteClick}
          />,
        ]}
        templates={['role-fields']}
      >
        <Table
          ready={pageState.list}
          response={response}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onRowSelectChange={(values) => setSelectedRowKeys(values)}
          columns={DeliveryFlowStepNotificationColumns({ country, onUpdateClick })}
        />
        <Drawer visible={panelVisibility} onClose={() => setPanelVisibility(false)}>
          <Element
            ready={pageState.form}
            formRef={formRef}
            onFinish={onFinish}
            inputs={DeliveryFlowStepNotificationInputs({
              stepAction: deliveryFlowStep.action,
              country,
              onGroupChange,
              group: notificationGroup,
              onUserRoleChange,
              userRole: notificationUserRole,
              onChannelChange,
              channel: notificationChannel,
              onSmsTemplateChange,
            })}
            columnSize={24}
            submit={t('Confirm')}
          >
            {smsTemplate && notificationChannel === NotificationChannelType.Sms && <SmsTemplateDescription>{t(smsTemplate.description)}</SmsTemplateDescription>}
          </Element>
        </Drawer>
      </Section>
    </Container>
  );
}

const Container = styled.div``;

const Description = styled.div`
  margin-bottom: 24px;
`;

const SmsTemplateDescription = styled.div`
  padding-bottom: 12px;
  margin-bottom: 24px;
`;
