import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  ActionType,
  CountryType,
  NoticeAskForHelpInputs,
  NoticeContactType,
  NoticeGroupType,
  NoticeType,
  NoticeTypes,
  TemplateGroupType,
  TemplateType,
} from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { noticeCreate, noticeExternalCreate, templateDetailContent } from '../../../services/api';
import { isMobile } from '../../../utilies/deviceHelper';
import { translate } from '../../../utilies/localization';
import { notifySuccess } from '../../../utilies/notification';
import { convertUrl, isUrl } from '../../../utilies/urlHelper';
import { Drawer, Element } from '../../globals';

export default function AskForHelp({ visibility, setVisibility, isExternal }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();
  const [user] = useCacheUser();

  const [formDisabled, setFormDisabled] = useState(true);
  const [content, setContent] = useState(null);
  const [pageState, setPageState] = useState({ form: true });
  const [noticeContactType, setNoticeContactType] = useState(NoticeContactType.Email);
  const [noticeType, setNoticeType] = useState(NoticeType.AskForHelpTechnicalIssues);

  const [templateFilter, setTemplateFilter] = useState({
    country: user?.c ?? CountryType.Russian,
    group: TemplateGroupType.ContentTemplate,
    type: TemplateType.AskForHelpTechnicalDisclaimer,
  });

  // Hooks
  useEffect(() => {
    clear();
  }, [visibility]);

  useEffect(() => {
    load();
  }, [visibility, templateFilter]);

  // Functions
  const clear = () => {
    formRef.resetFields();
    setContent(null);
  };

  const load = async () => {
    if (templateFilter.type === null || noticeType === null || !visibility) return;

    await templateDetailContent(templateFilter, (status, res) => {
      if (status && res?.data) {
        let body = translate(res.data.content);

        let bodyText = convertUrl(body);
        if (isUrl(bodyText)) {
          setNoticeType(null);
          formRef.setFieldsValue({ type: null });
          setTemplateFilter((x) => ({ ...x, type: TemplateType.AskForHelpTechnicalDisclaimer }));
          setVisibility(false);

          if (window) {
            if (isMobile()) {
              window.location = bodyText;
            } else {
              window.open(bodyText, '_blank');
            }
          }
          return;
        }

        setContent(body);
      } else {
        setContent(null);
      }
    });
  };

  // Events
  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    model = { ...model, group: NoticeGroupType.AskForHelp };

    if (!isExternal) {
      model = { ...model, country: user.c };
    }

    let request = isExternal ? noticeExternalCreate : noticeCreate;
    await request(model, (status, res) => {
      if (status) {
        notifySuccess(t('MessageSendCompleteSuccess'));
        setVisibility(false);
        clear();
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  const onNoticeTypeChange = ({ value }) => {
    setNoticeType(value);

    if (value !== null) {
      let noticeTypeData = NoticeTypes.find((x) => x.value === value);
      if (noticeTypeData) setTemplateFilter((x) => ({ ...x, type: noticeTypeData.data.contentTemplate }));
    }
  };

  const onNoticeContactTypeChange = ({ value }) => {
    setNoticeContactType(value);
  };

  const onCountryChange = ({ country }) => {
    setTemplateFilter((x) => ({ ...x, country }));
  };

  const onValuesChange = (value, values) => {
    setFormDisabled(!(values.isSelectedWarning1 && values.isSelectedWarning2 && values.message?.length >= 3));
  };

  // Render
  return (
    <Drawer title={t('AskForHelp')} visible={visibility} onClose={() => setVisibility(false)}>
      <Element
        key="notice-ask-for-help-form"
        onFinish={onFinish}
        ready={pageState.form}
        formRef={formRef}
        inputs={NoticeAskForHelpInputs({
          noticeType,
          onNoticeTypeChange,
          isExternal,
          noticeContactType,
          onNoticeContactTypeChange,
          onCountryChange,
          showDisclaimers: user?.checkAction(ActionType.AskForHelpShowDisclaimer),
        })}
        columnSize={24}
        submit={noticeType === NoticeType.AskForHelpTechnicalIssues || noticeType === NoticeType.AskForHelpSupplyDeliveryIssues ? t('Confirm') : null}
        onValuesChange={onValuesChange}
        disabled={formDisabled}
      />

      {content && <Disclaimer dangerouslySetInnerHTML={{ __html: content }} />}
    </Drawer>
  );
}

const Disclaimer = styled.div`
  margin-top: 24px;
`;
