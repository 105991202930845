import { createContext, useReducer } from 'react';
import { LanguageType } from '../../constants';

export const ReduceContext = createContext({
  state: {
    conference: {
      applicationId: null,
      token: null,
      leave: null,
      list: null,
    },
    notification: {
      list: null,
    },
    online: {
      users: null,
    },
    language: {
      value: LanguageType.English,
      change: false,
    },
  },
  dispatch: () => null,
});

export const ReduceProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, data) => ({ ...state, ...data }), null);

  return <ReduceContext.Provider value={[state, dispatch]}>{children}</ReduceContext.Provider>;
};
