import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Card, Global } from '../../components/globals';
import EvrysdiCalculator from '../../components/globals/calculator/EvrysdiCalculator';

export default function CalculatorEvrysdi() {
  const [searchParams] = useSearchParams();

  const [lang] = useState(searchParams.get('lang') ?? 'en');

  // Render
  return (
    <Global lang={lang}>
      <Container>
        <Card ready={true} templates={['calculator']}>
          <Logo src="/assets/images/logo.svg" />

          <EvrysdiCalculator />
        </Card>
      </Container>
    </Global>
  );
}

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 100vh;
`;

const Logo = styled.img`
  width: 150px;
  margin: 10px auto 20px;
  display: block;
`;
