import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaEye, FaTrash } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button, PopupConfirm } from '../../components/globals';
import ColumnBase from './_ColumnBase';

export default function VitalColumns({ isDeleted, isUserVisible, onUpdateClick, onDetailClick, onDeleteClick }) {
  const { t } = useTranslation();

  let columns = [
    isUserVisible && {
      locale: 'UserFullName',
      name: 'user',
      type: 'string',
      render: (value, row) => {
        return (
          <Tooltip placement="topLeft" title={row.user.fullName}>
            {row.user.fullName}
          </Tooltip>
        );
      },
    },
    {
      locale: 'Pulse',
      name: 'pulse',
      type: 'number',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'BloodPressureDiastolic',
      name: 'bloodPressureDiastolic',
      type: 'number',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'BloodPressureSystolic',
      name: 'bloodPressureSystolic',
      type: 'number',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    !isDeleted &&
      onUpdateClick && {
        locale: 'Update',
        align: 'center',
        width: 120,
        render: (v, row) => (
          <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
            <RiEdit2Fill />
          </Button>
        ),
      },
    onDetailClick && {
      locale: 'Detail',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="detail" onClick={() => onDetailClick(row)} templates={['table']}>
          <FaEye />
        </Button>
      ),
    },
    !isDeleted &&
      onDeleteClick && {
        locale: 'Delete',
        align: 'center',
        width: 120,
        render: (v, row) => (
          <PopupConfirm
            key="delete-confirm"
            title={t('AreYouSureWantToDelete')}
            onConfirm={() => onDeleteClick(row)}
            okText={t('Confirm')}
            cancelText={t('Cancel')}
            placement="bottomRight"
          >
            <Button templates={['table']}>
              <FaTrash />
            </Button>
          </PopupConfirm>
        ),
      },
  ];

  return ColumnBase({ columns });
}
