const CountryTypes = [
  {
    value: 0,
    name: 'Global',
    displayName: 'CountryGlobal',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {
      code: 'GLOBAL',
      name: 'Global',
      language: 0
    }
  },
  {
    value: 1,
    name: 'Russian',
    displayName: 'CountryRussian',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {
      code: 'RU',
      name: 'Russian',
      language: 1
    }
  },
  {
    value: 2,
    name: 'India',
    displayName: 'CountryIndia',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {
      code: 'IN',
      name: 'India',
      language: 0
    }
  },
  {
    value: 3,
    name: 'Chile',
    displayName: 'CountryChile',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {
      code: 'CL',
      name: 'Chile',
      language: 2,
      saml: true
    }
  },
  {
    value: 4,
    name: 'Serbia',
    displayName: 'CountrySerbia',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {
      code: 'RS',
      name: 'Serbia',
      language: 3
    }
  },
  {
    value: 5,
    name: 'Taiwan',
    displayName: 'CountryTaiwan',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {
      code: 'TW',
      name: 'Taiwan',
      language: 4
    }
  },
  {
    value: 6,
    name: 'Bangladesh',
    displayName: 'CountryBangladesh',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {
      code: 'BD',
      name: 'Bangladesh',
      language: 0
    }
  },
  {
    value: 7,
    name: 'Kazakhstan',
    displayName: 'CountryKazakhstan',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 800,
    data: {
      code: 'KZ',
      name: 'Kazakhstan',
      language: 5
    }
  }
];

export default CountryTypes;