import { SearchOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '../../components/globals';
import { useCacheUser } from '../../hooks/useCacheUser';

export default function ColumnBase({ columns, filter, setFilter }) {
  const { t } = useTranslation();
  const [searchInput] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [clickInput, setClickInput] = useState(true);
  const [columnSettings, setColumnSettings] = useState({});
  const [user] = useCacheUser();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={filter[dataIndex] != null && clickInput === false ? filter[dataIndex] : undefined}
          placeholder={t('Search')}
          onChange={(e) => setSearchText(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            confirm({ closeDropdown: false });
            setFilter({ ...filter, [dataIndex]: searchText });
          }}
          style={{ marginBottom: 8, display: 'block' }}
          onClick={() => setClickInput(true)}
        />
        <Space>
          <Button
            templates={['primary']}
            onClick={() => {
              setClickInput(false);
              confirm({ closeDropdown: false });
              setFilter({ ...filter, [dataIndex]: searchText });
            }}
            icon={<SearchOutlined />}
          >
            {' '}
            {t('Search')}
          </Button>

          <Button
            templates={['primary']}
            onClick={() => {
              setClickInput(false);
              setFilter({ ...filter, [dataIndex]: null });
            }}
          >
            {t('Reset')}
          </Button>
        </Space>
      </div>
    ),
    filterDropdownOpen: true,
    filterIcon: (filtered) => {
      return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />;
    },
    onFilter: (value, row) => {
      return row[dataIndex] ? row[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '';
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 1000);
      }
    },
  });

  return columns
    .filter((x) => x)
    ?.map((x, i) => {
      let result = {
        title: <div className="column-title">{t(x.locale)}</div>,
        dataIndex: x.name,
        key: `column-${i}`,
        width: columnSettings[x.name]?.width ?? x.width,
        type: x.type ?? 'string',
        sortDirections: ['descend', 'ascend'],
        align: x.align ?? 'left',
        fixed: x.fixed,
        ellipsis: {
          showTitle: false,
        },
      };

      if (x.searchable && filter && setFilter) result = { ...result, ...getColumnSearchProps(x.name) };

      if (x.sortable)
        result = {
          ...result,
          sorter: (a, b) => {
            switch (x.type) {
              case 'datetime':
              case 'date':
              case 'int':
              case 'double':
              case 'bool':
                return a[x.name] > b[x.name] ? 1 : -1;
              case 'string':
                return a[x.name]?.localeCompare(b[x.name], 'en', { sensivity: 'base' });
              default:
                return null;
            }
          },
        };

      if (x.render) {
        result = {
          ...result,
          render: (value, row) => {
            if (value !== null && value !== undefined && (x.type === 'date' || x.type === 'datetime' || x.type === 'time')) {
              let timeMoment = moment(value).add(user.t, 'minutes');

              if (x.type === 'date') return x.render(timeMoment.format('LL'), row);
              if (x.type === 'datetime') return x.render(timeMoment.format('LLL'), row);
              if (x.type === 'time') return x.render(timeMoment.format('HH:mm'), row);
            }

            return x.render(value, row);
          },
        };
      } else {
        result = {
          ...result,
          render: (value, row) => {
            return value;
          },
        };
      }

      if (x.onCell) {
        result = {
          ...result,
          onCell: x.onCell,
        };
      }

      if (x.columnDragable) {
        result = {
          ...result,
          onHeaderCell: (column) => {
            return {
              onMouseDown: (e) => {
                setColumnSettings((c) => ({ ...c, [x.name]: { ...columnSettings[x.name], mouseDownX: e.clientX, beginDrag: true } }));
              },
              onMouseUp: () => {
                setColumnSettings((c) => ({ ...c, [x.name]: { ...columnSettings[x.name], beginDrag: false } }));
              },
              onMouseMove: (e) => {
                if (columnSettings[x.name]?.beginDrag) {
                  let data = {
                    ...columnSettings[x.name],
                    width: (columnSettings[x.name]?.width ?? 120) + Math.round((e.clientX - columnSettings[x.name].mouseDownX) * 0.05),
                  };
                  setColumnSettings((c) => ({ ...c, [x.name]: data }));
                }
              },
            };
          },
        };
      }

      return result;
    });
}
