import { AutoComplete, Input, InputNumber } from 'antd';
import styled, { css } from 'styled-components';

const { Search } = Input;

export default function InputBase({ style, placeholder, onChange, disabled, readOnly, allowClear, templates, ...restProps }) {
  return (
    <InputTextContainer
      allowClear={allowClear}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      readOnly={readOnly}
      templates={templates}
      {...restProps}
    />
  );
}

InputBase.Search = ({ style, placeholder, onChange, onSearch, disabled, loading, readOnly, allowClear, templates, ...restProps }) => (
  <InputSearchContainer
    allowClear={allowClear}
    style={style}
    placeholder={placeholder}
    onChange={onChange}
    disabled={disabled}
    readOnly={readOnly}
    loading={loading}
    templates={templates}
    onSearch={onSearch}
    {...restProps}
  />
);

InputBase.Password = ({ style, placeholder, onChange, disabled, readOnly, allowClear, templates, ...restProps }) => (
  <InputPasswordContainer
    allowClear={allowClear}
    style={style}
    placeholder={placeholder}
    onChange={onChange}
    disabled={disabled}
    readOnly={readOnly}
    templates={templates}
    {...restProps}
  />
);

InputBase.TextArea = ({ rows, style, placeholder, onChange, disabled, readOnly, allowClear, templates, ...restProps }) => (
  <InputTextAreaContainer
    allowClear={allowClear}
    rows={rows}
    style={style}
    placeholder={placeholder}
    onChange={onChange}
    disabled={disabled}
    readOnly={readOnly}
    templates={templates}
    {...restProps}
  />
);

InputBase.Number = ({ style, placeholder, onChange, disabled, readOnly, min, max, step, maxLength, templates, ...restProps }) => (
  <InputNumberContainer
    placeholder={placeholder}
    onChange={onChange}
    style={style}
    min={min}
    max={max}
    step={step}
    disabled={disabled ?? false}
    readOnly={readOnly ?? false}
    maxLength={maxLength}
    templates={templates}
    {...restProps}
  />
);

InputBase.AutoComplete = ({ style, allowClear, placeholder, onChange, disabled, options, templates, ...restProps }) => (
  <InputAutoCompleteContainer
    allowClear={allowClear}
    placeholder={placeholder}
    onChange={onChange}
    style={style}
    options={options}
    disabled={disabled ?? false}
    templates={templates}
    {...restProps}
  />
);

const Grey = css`
  ${({ templates }) =>
    templates?.includes('grey') &&
    css`
      background-color: ${(x) => x.theme.colors.antiFlashWhiteDark} !important;

      .ant-input {
        background-color: transparent !important;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${(x) => x.theme.colors.antiFlashWhiteDark} inset !important;
      }
    `}
`;

const Default = css`
  height: 50px;
  overflow: hidden;
  border-radius: ${(x) => x.theme.global.borderRadius};

  border: 0px;
  border-color: transparent !important;
  box-shadow: 2px 2px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;

  .ant-input {
    background-color: transparent;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  ${Grey}

  ${({ value }) =>
    value !== undefined &&
    value !== null &&
    value !== '' &&
    css`
      box-shadow: inset 4px 4px 4px 0px ${(x) => x.theme.colors.lavenderGray} !important;
    `}
`;

const InputTextContainer = styled(Input)`
  ${Default}
`;

const InputSearchContainer = styled(Search)`
  ${Default};

  width: 100%;

  .ant-input-affix-wrapper {
    height: 100%;
    border: 0px;
    &.ant-input-affix-wrapper-focused,
    &:focus {
      box-shadow: none;
    }
  }

  .ant-input-wrapper {
    height: 100%;
    .ant-input {
      height: 100%;
      border: 0px;

      &:focus,
      &:hover,
      &:active {
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }

    .ant-input-group-addon {
      background: transparent;
      button {
        border: 0px;
      }
    }
  }

  ${({ templates }) =>
    templates?.includes('storage') &&
    css`
      margin-bottom: 24px;
    `}

  ${({ value }) =>
    value &&
    css`
      box-shadow: inset 4px 4px 4px 0px ${(x) => x.theme.colors.lavenderGray} !important;
    `}

    ${({ templates }) =>
    templates?.includes('filter') &&
    css`
      box-shadow: none !important;
      border: 1px solid #c7c8d7 !important;
    `}

  ${({ templates }) =>
    templates?.includes('value') &&
    css`
      border: 1px solid #02e3ab !important;
    `}
`;

const InputPasswordContainer = styled(Input.Password)`
  ${Default}
`;

const InputTextAreaContainer = styled(Input.TextArea)`
  ${Default}

  height: inherit;
`;

const InputAutoCompleteContainer = styled(AutoComplete)`
  ${Default}

  .ant-select-selector {
    height: 100% !important;
    border: 0px;
    border-color: transparent !important;
    .ant-select-selection-search-input {
      height: 100% !important;
    }
    .ant-select-selection-placeholder {
      line-height: 50px;
    }
  }

  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: transparent !important;
  }

  ${({ value }) =>
    value !== undefined &&
    value !== null &&
    value !== '' &&
    css`
      box-shadow: none !important;
      .ant-select-selector {
        box-shadow: inset 4px 4px 4px 0px ${(x) => x.theme.colors.lavenderGray} !important;
      }
    `}
`;

const InputNumberContainer = styled(InputNumber)`
  ${Default}

  width:100%;
  .ant-input-number-input-wrap {
    height: 100%;
    .ant-input-number-input {
      height: 100%;
    }
  }

  ${({ templates }) =>
    templates?.includes('grey') &&
    css`
      .ant-input-number-handler-wrap {
        background-color: ${(x) => x.theme.colors.antiFlashWhiteDark};
      }

      &.ant-input-number-focused {
        box-shadow: 0px 0px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;
      }

      &.ant-input-number-affix-wrapper-focused {
        box-shadow: 0px 0px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;
      }
    `}

  .ant-input-number {
    background-color: transparent !important;
    box-shadow: none !important;
    transition: none;
  }

  .ant-input-number-suffix {
    transition: right ease-in-out 0.2s;
  }

  &.ant-input-number-affix-wrapper-focused {
    .ant-input-number-suffix {
      right: 20px;
    }
  }

  &:hover {
    .ant-input-number-suffix {
      right: 20px;
    }
  }
`;
