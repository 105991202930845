import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { PatientActivityTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function ActivityPatientColumns() {
  const { t } = useTranslation();

  let columns = [
    {
      locale: 'User',
      name: 'user',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={row.user.fullName}>
          {row.user.fullName}
        </Tooltip>
      ),
    },
    {
      locale: 'ActivityType',
      name: 'type',
      type: 'string',
      render: (value, row) => {
        let patientActivityTypeData = PatientActivityTypes.find((x) => x.value === row.type);

        return (
          <Tooltip placement="topLeft" title={t(patientActivityTypeData.displayName)}>
            {t(patientActivityTypeData.displayName)}
          </Tooltip>
        );
      },
    },
    {
      locale: 'CreationTime',
      name: 'creationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
  ];

  return ColumnBase({ columns });
}
