import { Tooltip } from 'antd';
import DrugTypes from '../types/DrugTypes';
import ColumnBase from './_ColumnBase';

export default function ContentViewPerDrugColumns() {
  let columns = [
    {
      locale: 'ContentName',
      name: 'contentName',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    ...DrugTypes.map((x) => ({
      locale: x.displayName,
      name: x.name,
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    })),
  ];

  return ColumnBase({ columns });
}
