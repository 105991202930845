import { Tooltip } from 'antd';
import ColumnBase from './_ColumnBase';

export default function ContentUserWidgetColumns({ contents }) {
  let columns = contents?.map((x) => ({
    locale: x.name,
    name: x.id,
    type: 'string',
    render: (value, row) => (
      <Tooltip placement="topLeft" title={value}>
        {value ? 'test' : 'qwe'}
      </Tooltip>
    ),
  }));

  columns = [
    {
      locale: 'FullName',
      name: 'userFullName',
      type: 'string',
      width: 100,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    ...columns,
  ];

  return ColumnBase({ columns });
}
