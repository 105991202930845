const NotificationUserRoleTypes = [
  {
    value: 0,
    name: 'Special',
    displayName: 'StepNotificationStatusSpecial',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Owner',
    displayName: 'RoleOwner',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {
      userRole: 0
    }
  },
  {
    value: 2,
    name: 'Administrator',
    displayName: 'RoleAdministrator',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {
      userRole: 1
    }
  },
  {
    value: 3,
    name: 'Manager',
    displayName: 'RoleManager',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {
      userRole: 2
    }
  },
  {
    value: 4,
    name: 'Editor',
    displayName: 'RoleEditor',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {
      userRole: 3
    }
  },
  {
    value: 5,
    name: 'PSP',
    displayName: 'RolePSP',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {
      userRole: 4
    }
  },
  {
    value: 6,
    name: 'Doctor',
    displayName: 'RoleDoctor',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {
      userRole: 5
    }
  },
  {
    value: 7,
    name: 'Nurse',
    displayName: 'RoleNurse',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 800,
    data: {
      userRole: 6
    }
  },
  {
    value: 8,
    name: 'Pharmacist',
    displayName: 'RolePharmacist',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 900,
    data: {
      userRole: 7
    }
  },
  {
    value: 9,
    name: 'Warehouse',
    displayName: 'RoleWarehouse',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1000,
    data: {
      userRole: 8
    }
  },
  {
    value: 10,
    name: 'IMS',
    displayName: 'RoleIMS',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1100,
    data: {
      userRole: 9
    }
  },
  {
    value: 11,
    name: 'Patient',
    displayName: 'RolePatient',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1200,
    data: {
      userRole: 10
    }
  },
  {
    value: 12,
    name: 'Relative',
    displayName: 'RoleRelative',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1300,
    data: {
      userRole: 11
    }
  }
];

export default NotificationUserRoleTypes;