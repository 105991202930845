import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { TbEdit } from 'react-icons/tb';
import { useNavigate } from 'react-router';
import { format } from 'react-string-format';
import { Button, Element, Extra, Modal, Page, Select, Table } from '../../components/globals';
import { ActionType, ContentColumns, ContentTypes, CountryTypes, NotificationGroupTypes } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { contentList, contentMultipleDeleteRestore, contentNotify, contentUpdateActivity } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';
import { updateResponse } from '../../utilies/stateManagement';

export default function Contents() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const isAllCountry = user.checkAction(ActionType.ContentAllCountry);

  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [notifyPanelVisibility, setNotifyPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
    notify: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['row|asc'],
    justParent: true,
    country: isAllCountry ? null : user.c,
    userCountry: user.c,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await contentList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onCreateClick = () => {
    setPageState((x) => ({ ...x, create: false }));
    navigate(`/content/create`);
  };

  const onUpdateClick = (row) => {
    setPageState((x) => ({ ...x, update: false }));
    navigate(`/content/update/${row.id}`);
  };

  const onActivityChange = async (val, row) => {
    setPageState((x) => ({ ...x, update: false }));
    setResponse(updateResponse(response, 'isActive', val, true, row));

    await contentUpdateActivity({ id: row.id, isActive: val, country: row.country, userCountry: user.c }, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
      } else {
        val = !val;
      }

      setPageState((x) => ({ ...x, update: true }));
      setResponse(updateResponse(response, 'isActive', val, false, row));
    });
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, delete: false }));

    await contentMultipleDeleteRestore({ ids: selectedRowKeys, country: user.c, userCountry: user.c }, (status, res) => {
      if (status) {
        list();

        notifySuccess(format(t(filter.isDeleted ? 'DataRestoreSuccess' : 'DataDeleteSuccess'), <strong>{res.data}</strong>));
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const onNotifyClick = async (row) => {
    setRowData(row);
    setNotifyPanelVisibility(true);
  };

  const onNotifySubmit = async (model) => {
    setPageState((x) => ({ ...x, notify: false }));

    model = { ...rowData, ...model, userCountry: user.c };

    await contentNotify(model, (status, res) => {
      if (status) {
        setNotifyPanelVisibility(false);
        notifySuccess(t('ContentNotifiedSuccess'));
      }

      setPageState((x) => ({ ...x, notify: true }));
    });
  };

  // Render
  return (
    <Page
      title={t('Contents')}
      icon={<TbEdit />}
      subTitle={
        user.checkAction(ActionType.ContentCreate) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Contents'),
          breadcrumbName: t('Contents'),
          icon: <TbEdit />,
        },
      ]}
      templates={[isAllCountry ? 'default-3-filter-4' : 'default-2-filter-4']}
      extra={[
        <Select
          key="parent-type-filter"
          data={[
            { value: false, text: t('All') },
            { value: true, text: t('JustParent') },
          ]}
          onChange={(value) => setFilter((x) => ({ ...x, justParent: value }))}
          placeholder={t('SelectParentStatus')}
          defaultValue={filter.justParent}
          value={filter.justParent}
          templates={['filter']}
        />,
        isAllCountry && (
          <Select
            key="country-filter"
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            placeholder={t('SelectCountry')}
            defaultValue={filter.country}
            value={filter.country}
            templates={['filter']}
          />
        ),
        <Select
          key="content-type-filter"
          data={ContentTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
          onChange={(type) => setFilter((x) => ({ ...x, type }))}
          placeholder={t('SelectContentType')}
          defaultValue={filter.type}
          value={filter.type}
          templates={['filter']}
        />,
        <Extra
          key="filter"
          pageState={pageState}
          filter={filter}
          setFilter={setFilter}
          activityFilter={true}
          selectedRowKeys={selectedRowKeys}
          onFilterChanged={() => setSelectedRowKeys([])}
          onDeleteClick={user.checkAction(ActionType.ContentDelete) && onDeleteClick}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        selectedRowKeys={selectedRowKeys}
        onRowSelectChange={(values) => setSelectedRowKeys(values)}
        columns={ContentColumns({
          load: list,
          onUpdateClick: user.checkAction(ActionType.ContentUpdate) && onUpdateClick,
          onNotifyClick: user.checkAction(ActionType.ContentUpdate) && onNotifyClick,
          onActivityChange: user.checkAction(ActionType.ContentUpdate) && onActivityChange,
        })}
      />

      <Modal
        width={320}
        title={t('ContentNotify')}
        visible={notifyPanelVisibility}
        onCancelClick={() => setNotifyPanelVisibility(false)}
        footer={null}
        templates={['colored']}
      >
        <Element
          key="conference-form"
          columnSize={24}
          ready={pageState.notify}
          onFinish={onNotifySubmit}
          inputs={[
            {
              type: 'select',
              name: 'notificationGroup',
              label: t('NotificationGroup'),
              placeholder: t('SelectNotificationGroup'),
              rules: [{ required: true, message: t('NotificationGroupEmpty') }],
              data: NotificationGroupTypes.filter((x) => x.group?.includes('Content'))?.map((x) => ({ ...x, text: t(x.displayName) })) ?? [],
              allowClear: false,
            },
          ]}
          submit={t('Confirm')}
        />
      </Modal>
    </Page>
  );
}
