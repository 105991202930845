import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserRelationColumns, UserRoleType, UserRoleTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { deliveryUserIdList, deliveryUserUpdate, userList } from '../../../services/api';
import { notifyError, notifySuccess } from '../../../utilies/notification';
import { updateResponse } from '../../../utilies/stateManagement';
import { Drawer, Select, Table } from '../../globals';

export default function DeliveryUser({ visible, onClose, delivery, deliveryUsers, setDeliveryUsers }) {
  // Definitions
  const { t } = useTranslation();

  const [user] = useCacheUser();
  const [response, setResponse] = useState(null);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    country: user?.c,
  });

  const userRoles = user.getUserRoles();

  // Hooks
  useEffect(() => {
    if (visible) {
      list();
    }
  }, [filter, delivery]);

  useEffect(() => {
    if (!visible) {
      setFilter((x) => ({ ...x, userRole: undefined }));
      setResponse(null);
    }
  }, [visible]);

  // Functions
  const list = async () => {
    if (filter.userRole === undefined) return;

    setPageState((x) => ({ ...x, list: false }));

    let [deliveryUserIdResponse, userReponse] = await Promise.all([
      new Promise((resolve, reject) => {
        if (delivery === undefined) {
          resolve(null);
          return;
        }
        deliveryUserIdList({ id: delivery.id, userRole: filter.userRole, country: filter.country, isDeleted: false }, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
      new Promise((resolve, reject) => {
        userList(filter, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
    ]);

    if (delivery !== undefined) {
      userReponse.data = userReponse.data.map((x) => ({ ...x, isRelated: deliveryUserIdResponse.data.some((r) => x.id === r) }));
    }

    if (deliveryUsers !== undefined) {
      userReponse.data = userReponse.data.map((x) => ({ ...x, isRelated: deliveryUsers.some((r) => x.id === r) }));
    }

    setResponse(userReponse);

    setPageState((x) => ({ ...x, list: true }));
  };

  // Events
  const onRelationChange = async (val, row) => {
    if (delivery === undefined) {
      if (val && response.data.filter((x) => x.isRelated).length > 0) {
        notifyError(t('DeliveryUserRoleAlreadyExistsException'));
        return;
      }

      setResponse(updateResponse(response, 'isRelated', val, false, row));

      if (val) {
        setDeliveryUsers((x) => [...x, row.id]);
      } else {
        setDeliveryUsers(deliveryUsers.filter((x) => x !== row.id));
      }

      return;
    }

    setResponse(updateResponse(response, 'isRelated', val, true, row));

    let model = { country: filter.country, deliveryId: delivery.id, userRole: filter.userRole, userId: row.id, isActive: val };

    await deliveryUserUpdate(model, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
      } else {
        val = !val;
      }

      setResponse(updateResponse(response, 'isRelated', val, false, row));
    });
  };

  return (
    <Drawer width={700} visible={visible} onClose={onClose} title={t('RelationPanelTitle')}>
      <RelationFilter>
        <FilterText>{t('Filter')}</FilterText>
        <Select
          key="user-role-filter"
          data={UserRoleTypes.filter((x) => userRoles.some((u) => u === x.value) && x.value !== UserRoleType.Patient).map((x) => ({ ...x, text: t(x.displayName) }))}
          onChange={userRoles.length > 0 && ((userRole) => setFilter((x) => ({ ...x, userRole })))}
          placeholder={t('SelectUserRole')}
          defaultValue={filter.userRole}
          value={filter.userRole}
          allowClear={false}
        />
      </RelationFilter>

      <Table
        style={{ marginTop: 24, marginBottom: 24 }}
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        columns={UserRelationColumns({ user, rowUserId: delivery?.id, onRelationChange })}
        templates={['drawer']}
      />
    </Drawer>
  );
}

const RelationFilter = styled.div`
  position: relative;
  padding-top: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
  > * {
    width: 100%;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const FilterText = styled.div`
  position: absolute;
  top: 0px;
  font-weight: bold;
`;
