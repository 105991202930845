import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import styled from 'styled-components';

export default function SwitchBase({ style, checked, loading, checkedChildren, unCheckedChildren, defaultChecked, onChange, ...restProps }) {
  return (
    <Container
      style={style}
      checked={checked}
      loading={loading}
      checkedChildren={checkedChildren ?? <CheckOutlined />}
      unCheckedChildren={unCheckedChildren ?? <CloseOutlined />}
      defaultChecked={defaultChecked}
      onChange={onChange}
      {...restProps}
    />
  );
}

const Container = styled(Switch)`
  background: ${(x) => x.theme.colors.lavenderGray};
  &.ant-switch-checked {
    background: linear-gradient(263deg, ${(x) => x.theme.colors.mediumSpringGreen} 0, ${(x) => x.theme.colors.darkTurquoise} 100%);
  }
`;
