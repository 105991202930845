const TreatmentStageTypes = [
  {
    value: 0,
    name: 'StandartTreatment',
    displayName: 'StandartTreatment',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {
      drug: 1
    }
  },
  {
    value: 1,
    name: 'DelayedSkippedInjections',
    displayName: 'DelayedSkippedInjections',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {
      drug: 1
    }
  },
  {
    value: 2,
    name: 'IncreasedLevelsASTorALT',
    displayName: 'IncreasedLevelsASTorALT',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {
      drug: 1
    }
  }
];

export default TreatmentStageTypes;