const NotificationGroupTypes = [
  {
    value: 0,
    name: 'BeforeStepComplete',
    displayName: 'NotificationGroupBeforeStepComplete',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 1600,
    data: {}
  },
  {
    value: 1,
    name: 'AfterStepComplete',
    displayName: 'NotificationGroupAfterStepComplete',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 1700,
    data: {}
  },
  {
    value: 2,
    name: 'Reject',
    displayName: 'NotificationGroupReject',
    group: 'Delivery,Conference',
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  },
  {
    value: 3,
    name: 'RemainingDays',
    displayName: 'NotificationGroupRemainingDays',
    group: 'Delivery,Treatment,Conference',
    shortName: null,
    description: null,
    prompt: null,
    order: 1200,
    data: {}
  },
  {
    value: 4,
    name: 'Create',
    displayName: 'NotificationGroupCreate',
    group: 'Treatment,Conference,Content',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 5,
    name: 'Update',
    displayName: 'NotificationGroupUpdate',
    group: 'Treatment,Content',
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 6,
    name: 'Complete',
    displayName: 'NotificationGroupComplete',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 900,
    data: {}
  },
  {
    value: 7,
    name: 'Delete',
    displayName: 'NotificationGroupDelete',
    group: 'Treatment,Conference',
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 8,
    name: 'Suspend',
    displayName: 'NotificationGroupSuspend',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {}
  },
  {
    value: 9,
    name: 'Continue',
    displayName: 'NotificationGroupContinue',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {}
  },
  {
    value: 10,
    name: 'TreatmentUsage',
    displayName: 'NotificationGroupTreatmentUsage',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 1300,
    data: {}
  },
  {
    value: 11,
    name: 'TreatmentUsageRemind',
    displayName: 'NotificationGroupTreatmentUsageRemind',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 1400,
    data: {}
  },
  {
    value: 12,
    name: 'TreatmentUsageMissed',
    displayName: 'NotificationGroupTreatmentUsageMissed',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 1500,
    data: {}
  },
  {
    value: 13,
    name: 'RemainingHours',
    displayName: 'NotificationGroupRemainingHours',
    group: 'Conference',
    shortName: null,
    description: null,
    prompt: null,
    order: 1100,
    data: {}
  },
  {
    value: 14,
    name: 'Approve',
    displayName: 'NotificationGroupApprove',
    group: 'Conference',
    shortName: null,
    description: null,
    prompt: null,
    order: 310,
    data: {}
  },
  {
    value: 15,
    name: 'Expire',
    displayName: 'NotificationGroupExpire',
    group: 'Conference',
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {}
  },
  {
    value: 16,
    name: 'ConferenceJoin',
    displayName: 'NotificationGroupConferenceJoin',
    group: 'Conference',
    shortName: null,
    description: null,
    prompt: null,
    order: 1000,
    data: {}
  },
  {
    value: 17,
    name: 'Assignment',
    displayName: 'NotificationGroupAssignment',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 1800,
    data: {}
  },
  {
    value: 18,
    name: 'Unassignment',
    displayName: 'NotificationGroupUnassignment',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 1900,
    data: {}
  },
  {
    value: 19,
    name: 'LowScoreNotRelatedTreatment',
    displayName: 'NotificationGroupLowScoreNotRelatedTreatment',
    group: 'WAMS',
    shortName: null,
    description: null,
    prompt: null,
    order: 2000,
    data: {}
  },
  {
    value: 20,
    name: 'LowScoreRelatedTreatment',
    displayName: 'NotificationGroupLowScoreRelatedTreatment',
    group: 'WAMS',
    shortName: null,
    description: null,
    prompt: null,
    order: 2100,
    data: {}
  }
];

export default NotificationGroupTypes;