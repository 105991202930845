import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { TreatmentRemindStatusType, TreatmentRemindStatusTypes } from '../../constants';

export default function TreatmentInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'hidden',
      name: 'drug',
    },
    {
      type: 'hidden',
      name: 'calculate',
    },
    {
      type: 'hidden',
      name: 'deliveryId',
    },
    {
      type: 'hidden',
      name: 'drugCalculate',
    },
    {
      type: 'select',
      name: 'remindStatus',
      label: t('RemindStatus'),
      placeholder: t('SelectRemindStatus'),
      rules: [{ required: true, message: t('RemindStatusEmpty') }],
      data: TreatmentRemindStatusTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: TreatmentRemindStatusType.Remind,
    },
    {
      type: 'date',
      showTime: true,
      name: 'startDate',
      label: t('TreatmentStartDate'),
      placeholder: t('SelectTreatmentStartDate'),
      rules: [{ required: true, message: t('TreatmentStartDateEmpty') }],
    },
    {
      type: 'number',
      name: 'durationInMonths',
      label: t('TreatmentDurationInMonths'),
      placeholder: t('TreatmentDurationInMonthsEmpty'),
      rules: [{ required: false, message: t('TreatmentDurationInMonthsEmpty') }],
      numberRule: { min: 1, max: 240, message: format(t('LengthValidNumber'), 1, 240) },
      visible: false,
    },
    {
      type: 'number',
      name: 'durationInDays',
      label: t('TreatmentDurationInDays'),
      placeholder: t('TreatmentDurationInDaysEmpty'),
      rules: [{ required: false, message: t('TreatmentDurationInDaysEmpty') }],
      numberRule: { min: 1, max: 730, message: format(t('LengthValidNumber'), 1, 730) },
      visible: false,
    },
  ];
}
