import { UploadOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { TiFlowChildren } from 'react-icons/ti';
import { useNavigate } from 'react-router';
import { format } from 'react-string-format';
import styled from 'styled-components';
import { Button, Drawer, Element, Extra, FileInput, Page, Select, Table } from '../../components/globals';
import { ActionType, CountryTypes, DeliveryFlowColumns, DeliveryFlowInputs } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import {
  deliveryFlowCreate,
  deliveryFlowDataExport,
  deliveryFlowDataImport,
  deliveryFlowList,
  deliveryFlowMultipleDeleteRestore,
  deliveryFlowUpdate,
} from '../../services/api';
import { notifyError, notifySuccess } from '../../utilies/notification';

export default function DeliveryFlows() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user] = useCacheUser();
  const [createFormRef] = Form.useForm();
  const [countryFormRef] = Form.useForm();
  const [importFormRef] = Form.useForm();

  const isAllCountry = user.checkAction(ActionType.DeliveryFlowAllCountry);

  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisiblity, setPanelVisibility] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [importPanelVisiblity, setImportPanelVisibility] = useState(false);
  const [importFiles, setImportFiles] = useState([]);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await deliveryFlowList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onCreateClick = () => {
    setIsUpdate(false);
    setPanelVisibility(true);
    createFormRef.resetFields();
    createFormRef.setFieldsValue({ country: filter.country });
  };

  const onUpdateClick = (row) => {
    setIsUpdate(true);
    createFormRef.setFieldsValue(row);
    setPanelVisibility(true);
  };

  const onStepUpdateClick = (row) => {
    setPageState((x) => ({ ...x, update: false }));

    if (isAllCountry) {
      navigate(`/delivery/flow/update/${row.country}/${row.id}`);
    } else {
      navigate(`/delivery/flow/update/${row.id}`);
    }
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, delete: false }));

    await deliveryFlowMultipleDeleteRestore({ ids: selectedRowKeys, country: filter.country }, (status, res) => {
      if (status) {
        list();

        notifySuccess(format(t(filter.isDeleted ? 'DataRestoreSuccess' : 'DataDeleteSuccess'), <strong>{res.data}</strong>));
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const onCreateFormFinish = async (model) => {
    setPageState((x) => ({ ...x, create: false }));

    let request = model.id === undefined ? deliveryFlowCreate : deliveryFlowUpdate;

    await request(model, (status) => {
      if (status) {
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));

        if (filter.country !== model.country) {
          setFilter((x) => ({ ...x, country: model.country }));
          countryFormRef.setFieldsValue({ country: model.country });
        } else {
          list();
        }

        setPanelVisibility(false);
      }
      setPageState((x) => ({ ...x, create: true }));
    });
  };

  const onImportPanelClick = () => {
    setImportFiles([]);
    setImportPanelVisibility(true);
  };

  const onExportClick = async () => {
    setPageState((x) => ({ ...x, export: false }));

    await deliveryFlowDataExport({ ids: selectedRowKeys, country: filter.country }, (status, res, fileName) => {
      if (status) {
        saveAs(res, fileName);
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, export: true }));
    });
  };

  const onImportClick = async () => {
    if (importFiles?.length <= 0) {
      notifyError(t('ImportFileRequired'));
      return;
    }

    const form = new FormData();
    form.append('File', importFiles[0]);
    form.append('country', filter.country);

    let formValues = importFormRef.getFieldsValue();
    for (let key of Object.keys(formValues)) {
      form.append(key, formValues[key]);
    }

    setPageState((x) => ({ ...x, import: false }));
    await deliveryFlowDataImport(filter.country, form, (status, res) => {
      if (status) {
        list();
        notifySuccess(t('UpdateCompleteSuccess'));
        setImportPanelVisibility(false);
        setPanelVisibility(false);
        setImportFiles([]);
      }

      setPageState((x) => ({ ...x, import: true }));
    });
  };

  // Render
  return (
    <Page
      title={t('DeliveryFlows')}
      icon={<TiFlowChildren />}
      subTitle={
        user.checkAction(ActionType.DeliveryFlowCreate) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('DeliveryFlows'),
          breadcrumbName: t('DeliveryFlows'),
          icon: <TiFlowChildren />,
        },
      ]}
      templates={[isAllCountry ? 'default-1-filter-4' : 'default-0-filter-4']}
      extra={[
        isAllCountry && (
          <Select
            key="country-filter"
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            placeholder={t('SelectCountry')}
            defaultValue={filter.country}
            value={filter.country}
            templates={['filter']}
            allowClear={false}
          />
        ),
        <Extra
          key="filter"
          pageState={pageState}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onFilterChanged={() => setSelectedRowKeys([])}
          onDeleteClick={user.checkAction(ActionType.DeliveryFlowDelete) && onDeleteClick}
          onExportClick={user.checkAction(ActionType.DeliveryFlowImportExport) && onExportClick}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        selectedRowKeys={selectedRowKeys}
        onRowSelectChange={(values) => setSelectedRowKeys(values)}
        columns={DeliveryFlowColumns({
          isAllCountry,
          onUpdateClick: user.checkAction(ActionType.DeliveryFlowUpdate) && onUpdateClick,
          onStepUpdateClick: user.checkAction(ActionType.DeliveryFlowUpdate) && onStepUpdateClick,
        })}
      />

      <Drawer visible={panelVisiblity} onClose={() => setPanelVisibility(false)}>
        {user.checkAction(ActionType.DeliveryFlowImportExport) && (
          <Button key="import" onClick={onImportPanelClick} icon={<UploadOutlined />} templates={['colored', 'with-icon', 'full-width', 'mb-24']}>
            {t('ImportData')}
          </Button>
        )}

        <Element
          key="user-status-form"
          ready={pageState.create}
          formRef={createFormRef}
          onFinish={onCreateFormFinish}
          inputs={DeliveryFlowInputs({ country: filter.country, isAllCountry, isUpdate })}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>

      <Drawer title={t('ImportData')} visible={importPanelVisiblity} onClose={() => setImportPanelVisibility(false)}>
        <ImportDataDescription>{t('ImportDataDescription')}</ImportDataDescription>

        <FileInput files={importFiles} setFiles={setImportFiles} mimeTypes="application/json" />

        <Element key="import-form" ready={pageState.import} formRef={importFormRef} columnSize={24} style={{ margin: '12px 0px 24px' }} />

        <Button ready={pageState.upload} icon={<UploadOutlined />} onClick={onImportClick} block={true} templates={['colored']}>
          {t('ImportData')}
        </Button>
      </Drawer>
    </Page>
  );
}

const ImportDataDescription = styled.div`
  margin: 12px 0px 24px;
`;
