import { DownOutlined, InfoCircleOutlined, LogoutOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Menu } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ActionType } from '../../../constants';
import { Button, LanguageSelect, Notification } from '../../globals';
import AdverseEvent from '../../pages/layout/AdverseEvent';
import AskForHelp from '../../pages/layout/AskForHelp';

const { Header } = Layout;

export default function HeaderBase({ user, size, onLanguageClick }) {
  const { t } = useTranslation();

  const [askForHelpVisibility, setAskForHelpVisibility] = useState(null);
  const [adverseEventVisible, setAdverseEventVisibility] = useState(null);

  const profileMenu = (
    <ProfileDropdownMenu
      items={[
        {
          key: 'profile',
          icon: <FaUserCircle />,
          label: <Link to="/profile">{t('Profile')}</Link>,
        },
        {
          key: 'logout-user',
          icon: <LogoutOutlined />,
          label: <a href="/logout">{t('Logout')}</a>,
        },
      ]}
    />
  );

  return (
    <Container>
      <Left>
        <Notification />
      </Left>
      <Right>
        {(user.checkAction(ActionType.AskForHelpAllowSendReports) || user.checkAction(ActionType.AskForHelpShowDisclaimer)) && (
          <Button icon={<QuestionCircleOutlined />} onClick={() => setAskForHelpVisibility(true)} templates={['navigation', 'primary', 'header-icon']}>
            {t('Help')}
          </Button>
        )}
        {(user.checkAction(ActionType.AdverseEventAllowSendReports) || user.checkAction(ActionType.AdverseEventShowDisclaimer)) && (
          <Button icon={<InfoCircleOutlined />} onClick={() => setAdverseEventVisibility(true)} templates={['navigation', 'red-color', 'header-icon']}>
            {t('ReportAdverseEvent')}
          </Button>
        )}
        <ProfileDropdown overlay={profileMenu}>
          <UserProfile>
            <User>
              <UserNameArea>
                <UserProfileIcon /> {size?.width > 800 && <UserName>{user?.n ?? 'Panel'}</UserName>}
              </UserNameArea>
              {size?.width > 800 && <DownOutlined />}
            </User>
          </UserProfile>
        </ProfileDropdown>
        <LanguageSelect languages={user.ls} onClick={onLanguageClick} />
      </Right>

      <AskForHelp visibility={askForHelpVisibility} setVisibility={setAskForHelpVisibility} />

      <AdverseEvent visibility={adverseEventVisible} setVisibility={setAdverseEventVisibility} />
    </Container>
  );
}

const Container = styled(Header)`
  position: fixed;
  overflow: hidden;
  top: 0px;
  z-index: 999;
  padding: 0;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px 0px 6px 0px ${(x) => x.theme.colors.deepKaomaru};
  background-color: ${(x) => x.theme.colors.white};
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  > * {
    margin-right: 10px;
  }
`;

const ProfileDropdown = styled(Dropdown)`
  background-color: white;
  color: black;
  cursor: pointer;
`;

const UserProfile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  min-width: 200px;
  height: 64px;

  @media only screen and (max-width: 800px) {
    min-width: 1px;
  }
`;

const User = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  line-height: 18px;

  .anticon.anticon-user {
    margin-right: 4px;
  }
  .anticon.anticon-down {
    margin-left: 4px;
  }
`;

const UserNameArea = styled.div`
  display: flex;
  align-items: center;
`;

const UserProfileIcon = styled(FaUserCircle)`
  margin-right: 8px;
  font-size: 40px;
  color: ${(x) => x.theme.colors.deepKaomaru};

  @media only screen and (max-width: 800px) {
    margin-right: 0px;
  }
`;

const ProfileDropdownMenu = styled(Menu)`
  border-radius: ${(x) => x.theme.global.borderRadius};
  padding: 0px;
  overflow: hidden;
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 8px;
  }
`;

const UserName = styled.div`
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
`;
