import { useTranslation } from 'react-i18next';
import { DrugTypes, RoleInputDomainTypes, RoleInputTypes } from '../../constants';

export default function RoleInputInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'hidden',
      name: 'roleId',
    },
    {
      type: 'select',
      name: 'drug',
      label: t('InputDrug'),
      placeholder: t('SelectInputDrug'),
      rules: [{ required: true, message: t('InputDrugEmpty') }],
      data: DrugTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'select',
      name: 'domain',
      label: t('InputDomain'),
      placeholder: t('SelectInputDomain'),
      rules: [{ required: true, message: t('InputDomainEmpty') }],
      data: RoleInputDomainTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'select',
      name: 'calculate',
      label: t('InputCalculate'),
      placeholder: t('SelectInputCalculate'),
      rules: [{ required: true, message: t('InputCalculateEmpty') }],
    },
    {
      type: 'select',
      name: 'type',
      label: t('InputType'),
      placeholder: t('SelectInputType'),
      rules: [{ required: true, message: t('InputTypeEmpty') }],
      data: RoleInputTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'number',
      name: 'row',
      label: t('InputRow'),
      placeholder: t('InputRowEmpty'),
      rules: [{ required: true, message: t('InputRowEmpty') }],
      initialValue: 0,
    },
    {
      type: 'number',
      name: 'minimumValue',
      label: t('InputMinimumValue'),
      placeholder: t('InputMinimumValueEmpty'),
      rules: [{ required: true, message: t('InputMinimumValueEmpty') }],
      initialValue: 0,
    },
    {
      type: 'number',
      name: 'maximumValue',
      label: t('InputMaximumValue'),
      placeholder: t('InputMaximumValueEmpty'),
      rules: [{ required: true, message: t('InputMaximumValueEmpty') }],
      initialValue: 0,
    },
    {
      type: 'select',
      name: 'isRequired',
      label: t('IsRequired'),
      placeholder: t('SelectRequirement'),
      rules: [{ required: true, message: t('IsRequiredEmpty') }],
      data: [
        { value: true, text: t('Required') },
        { value: false, text: t('NotRequired') },
      ],
      initialValue: true,
    },
  ];
}
