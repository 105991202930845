const global = {
  xsmallBorderRadius: '3px',
  smallBorderRadius: '6px',
  borderRadius: '10px',
  largeBorderRadius: '20px',
  xlargeBorderRadius: '30px',
  xxlargeBorderRadius: '40px',
  xxxlargeBorderRadius: '50px',
};

export default global;
