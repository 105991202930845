import { useTranslation } from 'react-i18next';

export default function EnspryngInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'row',
    },
    {
      type: 'date',
      name: 'time',
      label: 'Time',
      rules: [{ required: true, message: 'TimeEmpty' }],
    },
    {
      type: 'number',
      name: 'dailyDoseMg',
      label: t('DailyDoseMg'),
      placeholder: t('DailyDoseMgEmpty'),
      rules: [{ required: true, message: t('DailyDoseMgEmpty') }],
      disabled: true,
    },
  ];
}
