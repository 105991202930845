import { t } from 'i18next';
import styled from 'styled-components';

export default function ImageHeader({ user }) {
  return (
    user && (
      <Container>
        <Message>
          <Hello>
            {t('Hello')} {user.n}
          </Hello>
          <Welcome>{t('WelcomeToCoral')}</Welcome>
        </Message>
        <Image src={`/assets/images/home/header/${user.u}.png`} />
      </Container>
    )
  );
}

const Container = styled.section`
  position: absolute;
  top: 22px;
  right: 0px;

  height: 240px;
  width: 100%;

  margin-right: 0px !important;
  text-align: right;

  @media only screen and (max-width: 1000px) {
    height: 160px;
  }

  @media only screen and (max-width: 600px) {
    height: 119px;
  }

  &::after {
    content: '';
    position: absolute;

    left: 0px;
    top: 42px;

    width: 100%;
    height: 198px;
    background-color: ${(x) => x.theme.colors.darkTurquoiseHeader};

    @media only screen and (max-width: 1000px) {
      top: 36px;
      height: 124px;
    }

    @media only screen and (max-width: 600px) {
      top: 36px;
      height: 83px;
    }
  }
`;

const Image = styled.img`
  height: 240px;
  position: absolute;
  z-index: 9;
  right: 0px;

  @media only screen and (max-width: 1000px) {
    height: 150px;
    top: 10px;
  }

  @media only screen and (max-width: 600px) {
    height: 100px;
    top: 19px;
  }

  @media only screen and (max-width: 500px) {
    right: -80px;
  }
`;

const Message = styled.div`
  position: absolute;
  z-index: 99;

  height: 100%;
  padding-top: 42px;
  padding-left: 24px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  color: ${(x) => x.theme.colors.white};

  @media only screen and (max-width: 1000px) {
    top: 36px;
    height: 124px;
    padding-top: 0px;
  }

  @media only screen and (max-width: 600px) {
    height: 83px;
  }
`;

const Hello = styled.div`
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 20px;

  @media only screen and (max-width: 1000px) {
    font-size: 25px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 15px;
    margin-bottom: 0px;
  }
`;

const Welcome = styled.div`
  font-size: 20px;

  @media only screen and (max-width: 1000px) {
    font-size: 15px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;
