import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';

export default function DeliveryEvrysdiCalculatorInputs({ birthDate, weight }) {
  const { t } = useTranslation();

  return [
    {
      type: 'date',
      name: 'birthDate',
      label: t('BirthDate'),
      showTime: false,
      placeholder: t('SelectBirthDate'),
      rules: [{ required: true, message: t('SelectBirthDateEmpty') }],
      initialValue: birthDate,
      disabledDate: (current) => current && current > moment().endOf('day'),
    },
    {
      type: 'number',
      name: 'weight',
      label: t('WeightInKg'),
      placeholder: t('WeightEmpty'),
      rules: [{ required: true, message: t('WeightEmpty') }],
      initialValue: weight,
      step: '0.1',
    },
    {
      type: 'number',
      name: 'treatmentDurationInMonths',
      label: t('TreatmentDurationInMonths'),
      placeholder: t('TreatmentDurationInMonthsEmpty'),
      rules: [{ required: false, message: t('TreatmentDurationInMonthsEmpty') }],
      numberRule: { min: 1, max: 240, message: format(t('LengthValidNumber'), 1, 240) },
      visible: false,
    },
    {
      type: 'number',
      name: 'treatmentDurationInDays',
      label: t('TreatmentDurationInDays'),
      placeholder: t('TreatmentDurationInDaysEmpty'),
      rules: [{ required: false, message: t('TreatmentDurationInDaysEmpty') }],
      numberRule: { min: 1, max: 730, message: format(t('LengthValidNumber'), 1, 730) },
      visible: false,
    },
  ];
}
