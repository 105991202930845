import { Col, Row } from 'antd';
import React, { useState } from 'react';
import StorageDirectories from './StorageDirectories';
import StorageFiles from './StorageFiles';

export default function StoragePanel({ onSelectImageClick }) {
  // Definitions
  const [path, setPath] = useState('/');
  const [isRefresh, setIsRefresh] = useState(false);

  // Events
  const onDirectorySelect = (value) => {
    setPath(value[value.length - 1]);
  };

  return (
    <Row gutter={16}>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <StorageDirectories onSelect={onDirectorySelect} isRefresh={isRefresh} setIsRefresh={setIsRefresh} />
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 18 }}>
        <StorageFiles path={path} onSelectImageClick={onSelectImageClick} refresh={() => setIsRefresh(true)} />
      </Col>
    </Row>
  );
}
