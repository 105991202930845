import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EnterDeliveryDateInputs from '../../../../constants/inputs/delivery/step/EnterDeliveryDateInputs';
import { deliveryStepUpdate } from '../../../../services/api';
import { notifySuccess } from '../../../../utilies/notification';
import { Element } from '../../../globals';

export default function EnterDeliveryDate({ next, country, delivery, step, complete, onError }) {
  // Definitions
  const { t } = useTranslation();
  const [ready, setReady] = useState(true);

  const onFinish = async (model) => {
    model = {
      ...next,
      currentStepId: step.id,
      country,
      deliveryId: delivery.id,
      targetUserId: delivery.targetUserId,
      data: JSON.stringify(model.deliveryDate.utc(false).format('YYYY-MM-DD 00:00')),
    };

    setReady(false);
    await deliveryStepUpdate(model, (status, res) => {
      if (status) {
        complete();
        notifySuccess(t('UpdateCompleteSuccess'));
      } else if (res?.data?.errors && onError) {
        onError(res.data.errors);
      }
      setReady(true);
    });
  };

  // Render
  return step && <Element ready={ready} key="enter-delivery-date" columnSize={24} onFinish={onFinish} inputs={EnterDeliveryDateInputs()} submit={t('Confirm')} />;
}
