import { Alert } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import styled from 'styled-components';
import { convertEvrysdiMgToMl, convertEvrysdiMlToMg, convertMgToMl, convertMlToMg } from '../../../calculators/doseCalculator';
import { evrysdiCalculateWeightBottle, evrysdiCalculateWeightDuration, shelfLifeEvrysdiCalculateWeightDuration } from '../../../calculators/evrysdiCalculators';
import { usageCalculator } from '../../../calculators/usageCalculator';
import {
  ActionType,
  DeliveryEnspryngInputs,
  DeliveryEvrysdiCalculatorInputs,
  DeliveryInputs,
  DrugCalculateType,
  DrugType,
  RoleFieldGroupType,
  RoleFieldGroupTypes,
  RoleInputCalculateType,
  RoleInputType,
  RoleInputTypes,
  TreatmentLastStatusTypes,
  TreatmentStageType,
  TreatmentStatusType,
} from '../../../constants';
import { Element } from '../../globals';
import DeliveryUserDetail from './DeliveryUserDetail';

export default function DeliveryDetail({ user, country, deliveryUser, selectedDrug, selectedCalculate, selectedFlow, roleInputs, isCreateTreatment, formRef, onFinish }) {
  // Definitions
  const { t } = useTranslation();
  const defaultInputs = DeliveryInputs({
    country,
    drug: selectedDrug.value,
    calculate: selectedCalculate.value,
    deliveryFlowId: selectedFlow.id,
    targetUserId: deliveryUser.id,
    targetUserAddress: deliveryUser.address,
  });
  const defaultEvrysdiCalculatorInputs = DeliveryEvrysdiCalculatorInputs({ birthDate: deliveryUser.birthDate, weight: deliveryUser.weight });
  const defaultEnspryngInputs = DeliveryEnspryngInputs();
  const [inputs, setInputs] = useState(defaultInputs);

  const [enspryngStatus, setEnspryngStatus] = useState(null);
  const [enspryngStage, setEnspryngStage] = useState(null);

  // Hooks
  useEffect(() => {
    load();
  }, []);

  // Functions
  const load = () => {
    if (!selectedDrug || !selectedCalculate || !selectedFlow) return;

    let detailInputs = [];

    // Evrysdi & Calculator / Manuel
    if (selectedDrug.value === DrugType.Evrysdi && (selectedCalculate.value === RoleInputCalculateType.Calculator || isCreateTreatment)) {
      detailInputs = [
        ...detailInputs,
        ...defaultEvrysdiCalculatorInputs.map((x) => {
          if (user.checkAction(ActionType.CalculatorEvrysdiShelfLife)) {
            if (x.name === 'treatmentDurationInDays') {
              x.disabled = true;
              x.visible = true;
              x.rules = x.rules.map((r) => (r.required === false ? { ...r, required: true } : r));
            }
          } else {
            if (x.name === 'treatmentDurationInMonths') {
              x.visible = true;

              if (selectedCalculate.value === RoleInputCalculateType.Manuel) {
                x.disabled = true;
              }
              x.rules = x.rules.map((r) => (r.required === false ? { ...r, required: true } : r));
            }
          }

          return x;
        }),
      ];
    }

    // Enspryng Default Inputs
    if (selectedDrug.value === DrugType.Enspryng) {
      defaultEnspryngInputs.forEach((x) => {
        if (x.name === 'status') {
          x.onChange = onEnspryngStatusChange;
          defaultInputs.push(x);
        }

        if (enspryngStatus === TreatmentStatusType.Continue) {
          if (x.name === 'stage') {
            x.onChange = onEnspryngStageChange;
            defaultInputs.push(x);
          }

          if (enspryngStage !== null) {
            if (enspryngStage === TreatmentStageType.StandartTreatment) {
              if (x.name === 'lastCount') {
                defaultInputs.push(x);
              }
            } else {
              if (x.name === 'lastStatus') {
                x.data = TreatmentLastStatusTypes.filter((x) => x.data.stage === enspryngStage).map((x) => ({ ...x, text: t(x.displayName) }));
                defaultInputs.push(x);
              }
            }
          }
        }
      });
    }

    detailInputs = [
      ...detailInputs,
      ...roleInputs.map((x) => {
        let input = RoleInputTypes.find((f) => f.value === x.type);
        let fieldGroup = RoleFieldGroupTypes.find((g) => g.value === input.data.group);

        let result = {
          inputValue: input.value,
          type: fieldGroup.name.toLowerCase(),
          name: x.id,
          label: t(input.displayName),
          placeholder: t(`${input.displayName}Empty`),
          rules: [],
        };

        if (input.data.group === RoleFieldGroupType.MultiSelect) {
          result = { ...result, type: 'select', mode: 'multiple' };
        }

        if (x.isRequired) {
          result.rules.push({ required: x.isRequired, message: t(`${input.displayName}Empty`) });
        }

        if (input.data.group === RoleFieldGroupType.Double) {
          result = { ...result, type: 'number', step: '0.1' };
        }

        if (x.minimumValue > 0 && x.maximumValue > 0) {
          switch (input.data.group) {
            case RoleFieldGroupType.Number:
              result.numberRule = { min: x.minimumValue, max: x.maximumValue, message: format(t('LengthValidNumber'), x.minimumValue, x.maximumValue) };
              break;
            case RoleFieldGroupType.Input:
            default:
              result.rules.push({ min: x.minimumValue, max: x.maximumValue, message: format(t('LengthValid'), x.minimumValue, x.maximumValue) });
              break;
          }
        }

        // Evrysdi & Calculator
        if (selectedDrug.value === DrugType.Evrysdi && (selectedCalculate.value === RoleInputCalculateType.Calculator || isCreateTreatment)) {
          if (user.checkAction(ActionType.CalculatorEvrysdiShelfLife)) {
            if (x.type === RoleInputType.DailyDoseMg || x.type === RoleInputType.DailyDoseMl || x.type === RoleInputType.TotalBottle) {
              result = { ...result, disabled: true };
            }
          } else {
            if (x.type === RoleInputType.DailyDoseMg || x.type === RoleInputType.DailyDoseMl) {
              result = { ...result, disabled: true };
            }
          }
        }

        return result;
      }),
    ];

    setInputs(() => [
      {
        name: 'inputs',
        templates: ['contents'],
        elements: detailInputs,
      },
      ...defaultInputs.map((x) => {
        if (x.name === 'treatmentDrugCalculate') {
          if (user.checkAction(ActionType.CalculatorEvrysdiShelfLife)) {
            x.initialValue = DrugCalculateType.EvrysdiShelfLife;
          } else if (user.checkAction(ActionType.CalculatorEnspryngNormal)) {
            x.initialValue = DrugCalculateType.EvrysdiNormal;
          } else {
            x.initialValue = null;
          }
        }

        return x;
      }),
    ]);

    formRef.setFieldsValue({ drug: selectedDrug.value, calculate: selectedCalculate.value, deliveryFlowId: selectedFlow.id, targetUserId: deliveryUser.id });

    setTimeout(() => {
      onValuesChange(null, formRef.getFieldsValue());
    }, 1000);
  };

  // Events
  const onEnspryngStatusChange = ({ value }) => {
    setEnspryngStatus(value);
    setEnspryngStage(null);
  };

  const onEnspryngStageChange = ({ value }) => {
    setEnspryngStage(value);
  };

  const onValuesChange = (input, model) => {
    // Manuel
    if (selectedCalculate.value === RoleInputCalculateType.Manuel && !isCreateTreatment) {
      let mgInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMg);
      let mlInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMl);

      if (mgInput && mlInput) {
        if (Object.keys(model.inputs).some((x) => x === mgInput.id)) {
          let mgValue = model.inputs[mgInput.id];
          if (mgValue !== undefined) {
            if (selectedDrug.value === DrugType.Evrysdi) {
              // Manuel Evrysdi
              formRef.setFieldsValue({ inputs: { ...model.inputs, [mlInput.id]: convertEvrysdiMgToMl(mgValue) } });
            } else {
              formRef.setFieldsValue({ inputs: { ...model.inputs, [mlInput.id]: convertMgToMl(mgValue) } });
            }
            return;
          }
        }

        if (Object.keys(model.inputs).some((x) => x === mlInput.id)) {
          let mlValue = model.inputs[mlInput.id];
          if (mlValue !== undefined) {
            if (selectedDrug.value === DrugType.Evrysdi) {
              // Manuel Evrysdi
              formRef.setFieldsValue({ inputs: { ...model.inputs, [mgInput.id]: convertEvrysdiMlToMg(mlValue) } });
            } else {
              formRef.setFieldsValue({ inputs: { ...model.inputs, [mgInput.id]: convertMlToMg(mlValue) } });
            }
            return;
          }
        }
      }

      return;
    }

    // Enspryng
    if (selectedDrug.value === DrugType.Enspryng && selectedCalculate.value === RoleInputCalculateType.Calculator) {
      if (model.inputs.treatmentDurationInMonths > 0 && model.startDate) {
        let result = usageCalculator(model);

        let totalBottleInput = roleInputs.find((x) => x.type === RoleInputType.TotalBottle);

        formRef.setFieldsValue({
          inputs: {
            ...model.inputs,
            [totalBottleInput.id]: result?.length ?? null,
          },
        });
      }
    }

    // Evrysdi & Calculator
    if (selectedDrug.value === DrugType.Evrysdi && (selectedCalculate.value === RoleInputCalculateType.Calculator || isCreateTreatment)) {
      let months = moment().diff(moment(model.inputs.birthDate), 'months', true);

      let mgInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMg);
      let mlInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMl);
      let totalBottleInput = roleInputs.find((x) => x.type === RoleInputType.TotalBottle);

      if (user.checkAction(ActionType.CalculatorEvrysdiShelfLife)) {
        let evrysdiAutoResult = shelfLifeEvrysdiCalculateWeightDuration(model.inputs.weight, months);

        formRef.setFieldsValue({
          inputs: {
            ...model.inputs,
            treatmentDurationInDays: evrysdiAutoResult.durationInDays,
            [mgInput?.id]: evrysdiAutoResult.miligram,
            [mlInput?.id]: evrysdiAutoResult.mililiter,
            [totalBottleInput.id]: evrysdiAutoResult.bottle,
          },
        });
      } else {
        if (model.inputs.weight > 0 && model.inputs.birthDate !== undefined) {
          let totalBottleFormInput = input.inputs[totalBottleInput.id];
          if (totalBottleFormInput) {
            let evrysdiAutoResult = evrysdiCalculateWeightBottle(moment(), model.inputs.weight, totalBottleFormInput, months);

            formRef.setFieldsValue({
              inputs: {
                ...model.inputs,
                treatmentDurationInMonths: evrysdiAutoResult.durationInMonths,
                [mgInput?.id]: evrysdiAutoResult.miligram,
                [mlInput?.id]: evrysdiAutoResult.mililiter,
              },
            });
          } else if (model.inputs.treatmentDurationInMonths > 0) {
            let evrysdiAutoResult = evrysdiCalculateWeightDuration(moment(), model.inputs.weight, model.inputs.treatmentDurationInMonths, months);

            formRef.setFieldsValue({
              inputs: {
                ...model.inputs,
                [mgInput?.id]: evrysdiAutoResult.miligram,
                [mlInput?.id]: evrysdiAutoResult.mililiter,
                [totalBottleInput.id]: evrysdiAutoResult.bottle,
              },
            });
          }

          return;
        }
      }
    }
  };

  return (
    <Container>
      {isCreateTreatment && <Alert message={t('ImportantNote')} description={t('DeliveryTreatmentRemainingDays')} type="info" style={{ marginBottom: 24 }} />}

      <FormContainer>
        <DeliveryUserDetail user={deliveryUser} />
        <Element formRef={formRef} onFinish={onFinish} inputs={inputs} columnSize={8} onValuesChange={onValuesChange} />
      </FormContainer>
    </Container>
  );
}

const Container = styled.div``;

const FormContainer = styled.div`
  display: flex;

  .ant-form {
    width: 100%;
  }
`;
