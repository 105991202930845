import { useTranslation } from 'react-i18next';
import { RiUploadCloudFill } from 'react-icons/ri';
import { Page } from '../../components/globals';
import StoragePanel from '../../components/pages/storage/StoragePanel';

export default function Storages() {
  const { t } = useTranslation();

  return (
    <Page
      title={t('Storage')}
      icon={<RiUploadCloudFill />}
      routes={[
        {
          name: t('Storage'),
          breadcrumbName: t('Storage'),
          icon: <RiUploadCloudFill />,
        },
      ]}
    >
      <StoragePanel type="page" />
    </Page>
  );
}
