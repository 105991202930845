import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Conference from '../../../components/pages/conference/Conference';
import { ActionType, DrugType } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { Card } from '../../globals';
import EnspryngCalculator from '../../globals/calculator/EnspryngCalculator';
import EvrysdiCalculator from '../../globals/calculator/EvrysdiCalculator';
import ShelfLifeEvrysdiCalculator from '../../globals/calculator/ShelfLifeEvrysdiCalculator';
import MyContentUsers from '../widget/MyContentUsers';
import MyPatients from '../widget/MyPatients';
import TreatmentDetails from '../widget/TreatmentDetails';
import VAMSScore from '../widget/VAMSScore';
import ImageHeader from './ImageHeader';

export default function GlobalDashboard() {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  // Render
  return (
    <Container>
      <ImageHeader user={user} />
      {user?.checkAction(ActionType.HomeWidgetVAMS) && <VAMSScore />}
      {user?.checkAction(ActionType.ConferenceList) && <Conference />}

      {user?.checkAction(ActionType.HomeWidgetDoseCalculator) &&
        user?.checkAction(ActionType.CalculatorEvrysdiNormal) &&
        user?.d?.some((x) => x === DrugType.Evrysdi) && (
          <Card
            ready={true}
            templates={['widget', 'dose-calculator', 'with-header', 'header-information', 'header-colored']}
            information={t('DoseCalculatorEvrysdiInformation')}
            title={t('DoseCalculatorEvrysdi')}
          >
            <EvrysdiCalculator />
          </Card>
        )}

      {user?.checkAction(ActionType.HomeWidgetDoseCalculator) &&
        user?.checkAction(ActionType.CalculatorEvrysdiShelfLife) &&
        user?.d?.some((x) => x === DrugType.Evrysdi) && (
          <Card
            ready={true}
            templates={['widget', 'dose-calculator', 'with-header', 'header-information', 'header-colored']}
            information={t('DoseCalculatorEvrysdiInformation')}
            title={t('DoseCalculatorEvrysdi')}
          >
            <ShelfLifeEvrysdiCalculator />
          </Card>
        )}

      {user?.checkAction(ActionType.HomeWidgetDoseCalculator) &&
        user?.checkAction(ActionType.CalculatorEnspryngNormal) &&
        user?.d?.some((x) => x === DrugType.Enspryng) && (
          <Card
            ready={true}
            templates={['widget', 'dose-calculator', 'with-header', 'header-information', 'header-colored']}
            information={t('DoseCalculatorEnspryngInformation')}
            title={t('DoseCalculatorEnspryng')}
          >
            <EnspryngCalculator />
          </Card>
        )}

      {user?.checkAction(ActionType.HomeWidgetPatientActivities) && <MyPatients />}

      {user?.checkAction(ActionType.HomeWidgetUserContents) && <MyContentUsers />}

      {user?.checkAction(ActionType.TreatmentList) && <TreatmentDetails />}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  padding-top: 200px;

  @media only screen and (max-width: 1000px) {
    padding-top: 130px;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 90px;
  }

  > *:not(section) {
    float: left;
    margin-bottom: 24px;

    &:not(:nth-child(4n)) {
      margin-right: 16px;
    }

    @media only screen and (max-width: 1600px) {
      &:not(:nth-child(4n)) {
        margin-right: auto;
      }

      &:not(:nth-child(3n)) {
        margin-right: 16px;
      }
    }

    @media only screen and (max-width: 1200px) {
      &:not(:nth-child(3n)) {
        margin-right: auto;
      }
    }
  }
`;
