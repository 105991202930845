import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function EnterDeliveryDateInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'date',
      name: 'deliveryDate',
      label: t('DeliveryDate'),
      rules: [{ required: true, message: t('DeliveryDateEmpty') }],
      disabledDate: (current) => current && current < moment().add(-1, 'days').endOf('day'),
      css: `margin: 0px;`,
    },
  ];
}
