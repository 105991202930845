import { Calendar } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { Button, Card, Link } from '../../globals';

export default function CalendarBase({ title, information, value, onSelect, onPanelChange, dateCellRender, dateFullCellRender, monthCellRender, templates }) {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(value ?? moment());

  // Hooks
  useEffect(() => {
    if (value) {
      setSelectedDate(value);
    }
  }, [value]);

  // Events
  const onPreviousClick = () => {
    let date = moment(selectedDate).set('date', 1);
    setSelectedDate(date.subtract(1, 'months'));
  };

  const onNextClick = () => {
    let date = moment(selectedDate).set('date', 1);
    setSelectedDate(date.add(1, 'months'));
  };

  // Components
  const headerRender = () => {
    return (
      <Header className="jsFixedHeader">
        <Left>
          <Button templates={['colored', 'calendar-icon']} onClick={onPreviousClick}>
            <FaChevronLeft />
          </Button>
          <Button templates={['colored', 'calendar-icon']} onClick={onNextClick}>
            <FaChevronRight />
          </Button>
        </Left>
        {selectedDate && <Right>{selectedDate.format('LL')}</Right>}
      </Header>
    );
  };

  // Render
  return (
    <Card
      ready={true}
      templates={[...(templates ?? []), 'widget', 'calendar', 'with-header', 'header-information', 'header-colored']}
      information={information ?? t('CalendarInformation')}
      title={title ?? t('Calendar')}
      right={
        !location.pathname.includes('journey') &&
        !location.pathname.includes('treatment') && (
          <Link to="/journey">
            <FaCaretRight />
          </Link>
        )
      }
    >
      <Container
        value={selectedDate}
        onSelect={onSelect}
        onPanelChange={onPanelChange}
        dateCellRender={dateCellRender}
        dateFullCellRender={dateFullCellRender}
        monthCellRender={monthCellRender}
        headerRender={headerRender}
        templates={templates}
      />
    </Card>
  );
}

const Container = styled(Calendar)`
  .ant-picker-calendar-date-content {
    height: initial !important;
    min-height: 100px;
    max-height: 100px;
    padding: 0px 5px;
  }

  &.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
  &.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
  &.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
  &.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
    background: ${(x) => x.theme.colors.darkTurquoiseOpacity20};
  }

  &.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: ${(x) => x.theme.colors.mediumSpringGreen};
  }

  &.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
  &.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
  &.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
  &.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value {
    font-weight: bold;
    color: ${(x) => x.theme.colors.mediumSpringGreen};
  }

  ${({ templates }) =>
    templates?.includes('treatment-usage') &&
    css`
      .ant-picker-calendar-date-content {
        height: initial !important;
        min-height: 60px;
      }
    `}

  @media only screen and (max-width: 1200px) {
    .ant-picker-calendar-date-content {
      height: initial !important;
      min-height: 30px !important;
      max-height: 100% !important;
      padding: 0px 5px 12px;
    }

    table {
      thead {
        display: none;
      }
      tbody {
        td:not(.ant-picker-cell-in-view) {
          display: none;
        }

        td.ant-picker-cell-in-view {
          display: block;
          float: left;
          width: 100%;
        }
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;

  @media only screen and (max-width: 1200px) {
    width: 90px;
  }
`;

const Right = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: ${(x) => x.theme.colors.mediumSpringGreen};

  @media only screen and (max-width: 1200px) {
    font-size: 20px;
  }
`;
