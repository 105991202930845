import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import { useLocation } from 'react-router';
import { ConferenceColumns, ConferenceStatusTypes, ConferenceType, ConferenceTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { conferenceList } from '../../../services/api';
import { combineOnlineUsers } from '../../../utilies/conferenceHelper';
import { Card, Link, Table } from '../../globals';

export default function ConferenceList({ parameter, loaded }) {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();
  const [user] = useCacheUser();

  const [response, setResponse] = useState(null);

  const [pageState, setPageState] = useState({
    list: true,
    form: true,
    delete: true,
  });

  const [filter] = useState({
    pageNumber: 1,
    dataLimit: 5,
    isDeleted: false,
    orderBy: ['modificationTime|asc'],
    currentUserId: parameter?.id ?? user.i,
    includeUser: true,
    includeTargetUser: true,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await conferenceList(filter, (status, res) => {
      if (status) {
        res.data = combineOnlineUsers(
          res.data.map((x) => ({
            ...x,
            date: moment(x.date).add(user.t, 'minutes').format('YYYY-MM-DD'),
            statusData: ConferenceStatusTypes.find((c) => c.value === x.status),
            dates: JSON.parse(x.data),
            conferenceType: ConferenceType.Pending,
            conferenceTypeData: ConferenceTypes.find((x) => x.value === ConferenceType.Pending),
          })),
          user
        );

        if (loaded) {
          loaded(res);
        }

        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  return (
    <Card
      ready={pageState.list}
      templates={['widget', 'with-header', 'header-information', 'header-colored', 'full-width', 'visit']}
      information={t('ConferenceInformation')}
      title={t('Conferences')}
      right={
        !location.pathname.includes('journey') && (
          <Link to="/journey">
            <FaCaretRight />
          </Link>
        )
      }
    >
      <Table ready={pageState.list} response={response} columns={ConferenceColumns({ user, isDeleted: filter.isDeleted })} />
    </Card>
  );
}
