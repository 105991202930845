import { css } from 'styled-components';

export const calendar = css`
  .calendar-fixed-header {
    position: fixed;
    top: 62px;
    left: 60px;
    background-color: #fff;
    z-index: 99;
    width: calc(100% - 60px);
    padding: 15px;
    box-shadow: 0px 0px 6px 0px #2e2060;
  }
`;
