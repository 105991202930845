import React, { useEffect, useState } from 'react';
import { Tree } from './../../globals';

export default function DeliveryFlowSteps({ steps, setSteps, onDropped, keys }) {
  // Definitions
  const [expandedKeys, setExpandedKeys] = useState(keys);

  // Hooks
  useEffect(() => {
    setExpandedKeys(keys);
  }, [keys]);

  // Functions
  const loop = (data, key, callback) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }

      if (data[i].children) {
        loop(data[i].children, key, callback);
      }
    }
  };

  const getList = (data) => {
    let list = [];
    let row = 0;

    const getInnerList = (inner, parentId) => {
      inner.forEach((x) => {
        row++;
        list.push({ id: x.key, parentId, row });
        if (x.children) {
          getInnerList(x.children, x.key);
        }
      });
    };

    getInnerList(data);

    return list;
  };

  // Events
  const onDragEnter = (info) => {
    setExpandedKeys(info.expandedKeys);
  };

  const onDrop = (info) => {
    let dropKey = info.node.key;
    let dragKey = info.dragNode.key;
    let dropPos = info.node.pos.split('-');
    let dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    let stepList = [...steps];

    let dragObj;
    loop(stepList, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(stepList, dropKey, (item) => {
        item.children = item.children || [];

        item.children.unshift(dragObj);
      });
    } else if ((info.node.children || []).length > 0 && info.node.expanded && dropPosition === 1) {
      loop(stepList, dropKey, (item) => {
        item.children = item.children || [];

        item.children.unshift(dragObj);
      });
    } else {
      let ar = [];
      let i;
      loop(stepList, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });

      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    if (onDropped) {
      onDropped(getList(stepList));
    }

    setSteps(stepList);
  };

  return <Tree treeData={steps} expandedKeys={expandedKeys} draggable={true} blockNode={true} onDragEnter={onDragEnter} onDrop={onDrop} templates={['delivery-flow']} />;
}
