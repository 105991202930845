import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorageInputs } from '../../../constants';
import { storageUpload } from '../../../services/api';
import { notifyError, notifySuccess } from '../../../utilies/notification';
import slugify from '../../../utilies/slugify';
import { Drawer, Element } from '../../globals';

export default function StorageUpload({ path, visibility, setVisibility, refresh }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [files, setFiles] = useState([]);
  const [isNewFolder, setIsNewFolder] = useState(false);

  const [state, setState] = useState({ upload: true });

  // Hooks
  useEffect(() => {
    if (visibility) {
      let result = path.startsWith('/') ? path : `/${path}`;

      formRef.setFieldsValue({ path: result });
    }
  }, [path, visibility]);

  useEffect(() => {
    if (files?.length > 0) {
      let file = files[0];
      formRef.setFieldsValue({ title: file.name.substr(0, file.name.lastIndexOf('.')) });
    }
  }, [files]);

  // Events
  const onFolderCheckChange = ({ value }) => {
    setIsNewFolder(value);
    if (!value) {
      let result = path.startsWith('/') ? path : `/${path}`;

      formRef.setFieldsValue({ path: result, folderName: null });
    }
  };

  const onFolderNameTextChange = ({ value }) => {
    let result = path.endsWith('/') ? path.slice(0, -1) : path;
    if (result) {
      result = result.startsWith('/') ? result : `/${result}`;
    }

    formRef.setFieldsValue({ path: `${result}/${slugify(value)}` });
  };

  const onFormFinish = async () => {
    if (files.length <= 0) {
      notifyError(t('FileNotFound'));
      return;
    }
    const form = new FormData();
    form.append('File', files[0]);

    let formValues = formRef.getFieldsValue();
    for (let key of Object.keys(formValues)) {
      form.append(key, formValues[key]);
    }

    setState((x) => ({ ...x, upload: false }));

    await storageUpload(form, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
        setVisibility(false);
        refresh();
        closePanel();
      }

      setState((x) => ({ ...x, upload: true }));
    });
  };

  const closePanel = () => {
    setVisibility(false);
    setIsNewFolder(false);
    setFiles([]);
    formRef.resetFields();
  };

  // Render
  return (
    <Drawer title={t('FileUpload')} visible={visibility} onClose={closePanel} width={500} templates={['upload']}>
      <Element
        ready={state.upload}
        formRef={formRef}
        inputs={StorageInputs({ files, setFiles, onFolderCheckChange, onFolderNameTextChange, isNewFolder })}
        onFinish={onFormFinish}
        columnSize={24}
        submit={t('Confirm')}
      />
    </Drawer>
  );
}
