import { Cell, Legend, Pie, PieChart } from 'recharts';
import styled from 'styled-components';

export default function PieChartBase({ data }) {
  return (
    <Container
      width={250}
      height={300}
      margin={{
        top: -80,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <Pie data={data} cx={120} cy={200} innerRadius={60} outerRadius={80} fill="#8884d8" label paddingAngle={5} dataKey="value">
        {data?.map((x, index) => (
          <Cell key={`cell-${index}`} fill={x.color} />
        ))}
      </Pie>
      <Legend />
    </Container>
  );
}

const Container = styled(PieChart)``;
