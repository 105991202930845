import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { CountryTypes } from '../../constants';

export default function CityInputs({ country, isAllCountry, isUpdate }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    !isAllCountry && {
      type: 'hidden',
      name: 'country',
      initialValue: country && parseInt(country),
    },
    isAllCountry && {
      type: 'select',
      name: 'country',
      label: t('Country'),
      placeholder: t('SelectCountry'),
      rules: [{ required: true, message: t('CountryEmpty') }],
      data: CountryTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: country && parseInt(country),
      disabled: isUpdate,
    },
    {
      type: 'input',
      name: 'name',
      label: t('Name'),
      placeholder: t('NameEmpty'),
      rules: [
        { required: true, message: t('NameEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
    {
      type: 'number',
      name: 'latitude',
      label: t('Latitude'),
      placeholder: t('LatitudeEmpty'),
      rules: [{ required: false, message: t('LatitudeEmpty') }],
    },
    {
      type: 'number',
      name: 'longitude',
      label: t('Longitude'),
      placeholder: t('LongitudeEmpty'),
      rules: [{ required: false, message: t('LongitudeEmpty') }],
    },
  ];
}
