import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import {
  CountryType,
  DeliveryActionType,
  NotificationChannelType,
  NotificationChannelTypes,
  NotificationGroupType,
  NotificationGroupTypes,
  NotificationUserRoleType,
  NotificationUserRoleTypes,
  TextlocalSmsTemplateTypes,
} from '../../constants';

export default function DeliveryFlowStepNotificationInputs({
  stepAction,
  country,
  onGroupChange,
  group,
  onUserRoleChange,
  userRole,
  onChannelChange,
  channel,
  onSmsTemplateChange,
}) {
  const { t } = useTranslation();

  const [smsTemplates, setSmsTemplates] = useState(null);

  useEffect(() => {
    if (country === CountryType.India) {
      setSmsTemplates(TextlocalSmsTemplateTypes.filter((x) => x.prompt === 'Delivery').map((x) => ({ ...x, text: t(x.displayName) })));
    }
  }, [country]);

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'hidden',
      name: 'deliveryFlowStepId',
    },
    {
      type: 'select',
      name: 'group',
      label: t('NotificationGroup'),
      placeholder: t('SelectNotificationGroup'),
      rules: [{ required: true, message: t('NotificationGroupEmpty') }],
      data: NotificationGroupTypes.filter((x) => {
        if (stepAction === DeliveryActionType.CompleteDelivery && x.value === NotificationGroupType.BeforeStepComplete) return false;

        return x.group.includes('Delivery');
      }).map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onGroupChange,
    },
    group === NotificationGroupType.RemainingDays && {
      type: 'number',
      name: 'restDays',
      label: t('NotificationRestDays'),
      placeholder: t('NotificationRestDaysEmpty'),
      rules: [{ required: true, message: t('NotificationRestDaysEmpty') }],
    },
    {
      type: 'select',
      name: 'userRole',
      label: t('NotificationUserRole'),
      placeholder: t('SelectNotificationUserRole'),
      rules: [{ required: true, message: t('NotificationUserRoleEmpty') }],
      data: NotificationUserRoleTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onUserRoleChange,
    },
    {
      type: 'select',
      name: 'channel',
      label: t('NotificationChannel'),
      placeholder: t('SelectNotificationChannel'),
      rules: [{ required: true, message: t('NotificationChannelEmpty') }],
      data: NotificationChannelTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onChannelChange,
    },
    !(channel === NotificationChannelType.Sms && country === CountryType.India)
      ? {
          type: 'input',
          name: 'nameKey',
          label: t('NameKey'),
          placeholder: t('NameKeyEmpty'),
          rules: [
            { required: true, message: t('NameKeyEmpty') },
            { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
          ],
        }
      : null,
    !(channel === NotificationChannelType.Sms && country === CountryType.India)
      ? {
          type: 'input',
          name: 'descriptionKey',
          label: t('DescriptionKey'),
          placeholder: t('DescriptionKeyEmpty'),
          rules: [
            { required: true, message: t('DescriptionKeyEmpty') },
            { min: 3, max: 500, message: format(t('LengthValid'), 3, 500) },
          ],
        }
      : null,
    channel === NotificationChannelType.Email && {
      type: 'input',
      name: 'mailSubjectKey',
      label: t('MailSubjectKey'),
      placeholder: t('MailSubjectKeyEmpty'),
      rules: [
        { required: true, message: t('MailSubjectKeyEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
    smsTemplates &&
      channel === NotificationChannelType.Sms && {
        type: 'select',
        name: 'smsTemplateTypeId',
        label: t('DeliveryNotificationSmsTemplateType'),
        placeholder: t('SelectDeliveryNotificationSmsTemplateType'),
        rules: [{ required: true, message: t('DeliveryNotificationSmsTemplateTypeEmpty') }],
        onChange: onSmsTemplateChange,
        data: smsTemplates,
      },
    userRole === NotificationUserRoleType.Special &&
      channel !== NotificationChannelType.Mobile &&
      channel !== NotificationChannelType.Notification && {
        type: channel === NotificationChannelType.Sms ? 'phone' : 'input',
        name: 'value',
        label: t('DeliveryNotificationValue'),
        placeholder: t('DeliveryNotificationValueEmpty'),
        rules: [
          { required: true, message: t('DeliveryNotificationValueEmpty') },
          { min: 3, max: 500, message: format(t('LengthValid'), 3, 500) },
        ],
      },
  ];
}
