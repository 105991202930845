import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import replacer from 'react-string-replace';
import styled from 'styled-components';
import { Card, Global, Modal } from '../../components/globals';
import { ContentGroupType, ContentType } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import i18n from '../../i18n';
import { contentStaticDetail, loginApprove, loginGigyaLogin } from '../../services/api';
import { getLocale } from '../../services/cache';
import { getLanguageType } from '../../utilies/localization';

export default function SSO() {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const code = params.get('code');

  const [, setUser] = useCacheUser();

  const [contentData, setContentData] = useState(null);
  const [userData, setUserData] = useState(null);

  const [panelVisibility, setPanelVisibility] = useState(false);
  const [contentPanelVisibility, setContentPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({ form: false, country: false });

  const [contentFilter, setContentFilter] = useState({ type: ContentType.Static });

  // Hooks
  useEffect(() => {
    let locale = getLocale();
    moment.locale(locale);
    i18n.changeLanguage(locale);
    setPageState((x) => ({ ...x, form: true }));
  }, []);

  useEffect(() => {
    if (code) {
      loginUser(code);
    } else {
      redirectSso();
    }
  }, [code]);

  // Functions
  const redirectSso = async () => {
    await loginGigyaLogin({}, (samlStatus, samlRes) => {
      if (samlStatus) {
        window.location = samlRes.data;
      } else {
        setPageState((x) => ({ ...x, form: true }));
      }
    });
  };

  const loginUser = (token) => {
    let result = jwtDecode(token);
    if (result.v === 0) {
      setUserData({ ...result, token });
      setPageState((x) => ({ ...x, form: true }));
      setPanelVisibility(true);
      setContentFilter((x) => ({ ...x, country: result.c }));
    } else {
      setUser(token);
      window.location = location?.state?.path ?? '/';
    }
  };

  // Events
  const onStaticClick = async (group) => {
    await contentStaticDetail({ ...contentFilter, group, language: getLanguageType(getLocale()) }, (status, res) => {
      if (status) {
        setContentPanelVisibility(true);
        setContentData(res.data);
      }
    });
  };

  const onApproveConfirmClick = async () => {
    setPageState((x) => ({ ...x, form: false }));

    await loginApprove({ ...userData, country: userData.c }, (status, res) => {
      if (status) {
        setUser(res.data);
        setPanelVisibility(false);
        window.location = location?.state?.from?.pathname ?? '/';
      } else {
        setPageState((x) => ({ ...x, form: true }));
      }
    });
  };

  // Components
  const ApprovePanel = () => {
    let text = t('UserApproveText');

    let replacements = [
      {
        key: '{Static.PrivacyPolicy}',
        value: (
          <TextLink key="privacy-policy" onClick={() => onStaticClick(ContentGroupType.PrivacyPolicy)}>
            {t('PrivacyPolicy')}
          </TextLink>
        ),
      },
      {
        key: '{Static.TermsOfUse}',
        value: (
          <TextLink key="terms-of-use" onClick={() => onStaticClick(ContentGroupType.TermsOfUse)}>
            {t('TermsOfUse')}
          </TextLink>
        ),
      },
      {
        key: '{Static.PersonalDataProcessing}',
        value: (
          <TextLink key="personal-data-processing" onClick={() => onStaticClick(ContentGroupType.PersonalDataProcessing)}>
            {t('PersonalDataProcessing')}
          </TextLink>
        ),
      },
    ];

    replacements.forEach((x) => {
      text = replacer(text, x.key, () => x.value);
    });

    return text;
  };

  // Render
  return (
    <Global>
      <Container>
        <Card ready={pageState.form} templates={['login']}>
          <Logo src="/assets/images/logo.png" />
        </Card>

        <Modal
          width={320}
          title={t('ApproveUser')}
          visible={panelVisibility}
          onCancelClick={() => setPanelVisibility(false)}
          confirmText={t('Confirm')}
          onConfirmClick={onApproveConfirmClick}
          templates={['colored']}
        >
          <ApprovePanel />
        </Modal>

        {contentData && (
          <Modal
            width="90%"
            title={t(contentData.title)}
            visible={contentPanelVisibility}
            onCancelClick={() => setContentPanelVisibility(false)}
            footer={null}
            templates={['colored']}
          >
            <ContentBody dangerouslySetInnerHTML={{ __html: contentData.body }}></ContentBody>
          </Modal>
        )}
      </Container>
    </Global>
  );
}

const TextLink = styled.a`
  color: blue;
`;

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 100vh;
`;

const Logo = styled.img`
  width: 150px;
  margin: 10px auto 20px;
  display: block;
`;

const ContentBody = styled.div``;
