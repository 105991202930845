const NotificationChannelTypes = [
  {
    value: 0,
    name: 'Notification',
    displayName: 'StepNotificationTypeNotification',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Email',
    displayName: 'StepNotificationTypeEmail',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'Sms',
    displayName: 'StepNotificationTypeSms',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'Mobile',
    displayName: 'StepNotificationTypeMobile',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  }
];

export default NotificationChannelTypes;