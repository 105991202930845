import { Skeleton } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { contentDetailGlobal, contentUserList, contentUserMyList } from '../../../services/api';
import { generateDynamicColor } from '../../../styles/color';
import theme from '../../../styles/theme';
import { groupByList } from '../../../utilies/expression';
import { BiaxialBarChart, CustomActiveShapePieChart, Select } from '../../globals';
import BiaxialBarChartDetail from '../../globals/tool/BiaxialBarChartDetail';

export default function ContentPreview({ parameter, id, period, onWidthChange }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [contentDetail, setContentDetail] = useState(null);
  const [contentUser, setContentUser] = useState(null);
  const [comparation, setComparation] = useState(null);

  const [listData, setListData] = useState([]);
  const [listGroup, setListGroup] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [lineGroup, setLineGroup] = useState([]);
  const [chartDetailData, setChartDetailData] = useState(null);
  const [design, setDesign] = useState(0);

  const [contentFilter] = useState({
    pageNumber: 1,
    dataLimit: 1,
    country: user.c,
    userCountry: user.c,
    includeChildren: true,
    includeGroups: true,
    orderBy: ['modificationTime|desc'],
  });

  const [contentUserFilter] = useState({
    userId: parameter.id,
    isMyData: parameter.isMyData,
    includeParentContent: true,
    includeSelections: true,
    country: parseInt(user.c),
    userRole: parameter.userRole,
    parentContentId: id,
    dataLimit: 4,
    orderBy: ['period|desc'],
  });

  // Hooks
  useEffect(() => {
    load();
  }, [id, period]);

  // Functions
  const load = async () => {
    let [contentDetailResponse, contentUserListResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        contentDetailGlobal({ ...contentFilter, id }, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        let request = parameter.isMyData ? contentUserMyList : contentUserList;

        request(contentUserFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
    ]);

    if (contentDetailResponse?.data && contentUserListResponse?.data?.length > 0) {
      // Questions
      contentDetailResponse.data.children = contentDetailResponse.data.children.map((questionItem) => {
        if (questionItem.children?.length > 0) {
          // Question Answers
          questionItem.children = questionItem.children.map((answerItem) => {
            // Content User List
            contentUserListResponse.data = contentUserListResponse.data.map((contentUserItem) => {
              if (contentUserItem.selections.includes(answerItem.id)) {
                if (contentUserItem.period === period) {
                  questionItem.point = answerItem.point;
                  answerItem.checked = true;
                }
                if (!contentUserItem.contentGroups) contentUserItem.contentGroups = [];
                contentUserItem.contentGroups = [...contentUserItem.contentGroups, ...questionItem.groups.map((x) => ({ ...x, point: answerItem.point }))];
              }

              return contentUserItem;
            });

            return answerItem;
          });
        }

        return questionItem;
      });

      // Prepare Name
      contentUserListResponse.data = contentUserListResponse.data.map((contentUserItem) => {
        contentUserItem.contentGroups = groupByList(contentUserItem.contentGroups, 'title').map((x) => ({
          title: x.key,
          point: x.values.map((v) => v.point).reduce((a, b) => a + b, 0),
          color: generateDynamicColor(),
        }));

        let selectionStartTime = moment(contentUserItem.creationTime).format('MMM YYYY');
        let selectionEndTime = moment(contentUserItem.modificationTime).format('MMM YYYY');
        contentUserItem.name = selectionStartTime !== selectionEndTime ? `${selectionStartTime} ~ ${selectionEndTime}` : selectionStartTime;

        return contentUserItem;
      });

      // Name Check and Point Combine
      let sameNames = [];
      let comparationListData = [];
      let contentUserListData = contentUserListResponse.data.reverse();
      contentUserListResponse.data = contentUserListData.map((contentUserItem, contentUserIndex) => {
        if (contentUserListResponse.data.some((x) => x.id !== contentUserItem.id && x.name === contentUserItem.name)) {
          sameNames.push(contentUserItem.name);
        }

        if (sameNames.some((x) => x === contentUserItem.name)) contentUserItem.name = `${contentUserItem.name} (${contentUserIndex + 1})`;

        if (contentUserItem.contentGroups?.length > 0) {
          contentUserItem.contentGroups.forEach((contentGroupItem) => {
            let contentGroupListDataItem = {
              title: contentGroupItem.title,
              [contentUserItem.name]: contentGroupItem.point,
            };

            if (comparationListData.some((x) => x.title === contentGroupListDataItem.title)) {
              comparationListData = comparationListData.map((x) => {
                if (x.title === contentGroupListDataItem.title) {
                  x[contentUserItem.name] = contentGroupItem.point;
                }

                return x;
              });
            } else {
              comparationListData.push(contentGroupListDataItem);
            }
          });
        }

        return contentUserItem;
      });

      // Compare Previous
      let comparationGroup = contentUserListResponse.data.map((x) => x.name);

      if (comparationGroup.length > 2) {
        let lastGroup = comparationGroup[comparationGroup.length - 1];
        let previousGroup = comparationGroup[comparationGroup.length - 2];

        console.log(lastGroup, previousGroup);

        comparationListData = comparationListData.map((x) => {
          if (x[lastGroup] > x[previousGroup]) x.color = theme.colors.rustyRed;

          return x;
        });
      }

      setComparation({ group: comparationGroup, data: comparationListData });

      let lastContentUserItem = contentUserListResponse.data.find((x) => x.period === period);
      setContentUser(lastContentUserItem);

      contentDetailResponse.data.totalPoint = lastContentUserItem.totalPoint;
      setContentDetail(contentDetailResponse.data);
    }

    console.log(contentDetailResponse);
    console.log(contentUserListResponse);
  };

  // Events
  const onDesignChange = (value) => {
    setDesign(value);
    if (onWidthChange) {
      onWidthChange(value);
    }
  };

  // Components
  const onUpdateTooltipContent = ({ label, items }) => {
    setChartDetailData({ label, items });
  };

  // Render
  return contentDetail ? (
    <Container>
      <Header>
        <Left>
          <Title>{contentDetail.title}</Title>
          <ScoreText>
            {t('TotalPoint')}: <strong>{contentDetail.totalPoint ?? 0}</strong>
          </ScoreText>
        </Left>
        <Right>
          <Select
            key="design-filter"
            data={[
              {
                text: t('PreviewFullDetailDesign'),
                value: 0,
              },
              {
                text: t('PreviewQuestionAnswerSingleGroupDesign'),
                value: 3,
              },
              {
                text: t('PreviewQuestionAnswerPeriodicGroupDesign'),
                value: 4,
              },
            ]}
            allowClear={false}
            onChange={onDesignChange}
            placeholder={t('SelectDesign')}
            value={design}
            templates={['filter', 'width-300']}
          />
        </Right>
      </Header>

      {contentDetail.legendText && <LegendTextBody dangerouslySetInnerHTML={{ __html: contentDetail.legendText }} />}

      <PreviewDetail type={design}>
        {contentDetail.children && design < 3 && (
          <Items>
            {contentDetail.children.map((question, qIndex) => (
              <Item key={`question-${qIndex}`}>
                <Question>
                  <QuestionTitle>{question.title}</QuestionTitle>
                  {question.groups && (
                    <QuestionGroups>
                      {question.groups.map((group, groupIndex) => (
                        <QuestionGroupItem key={`group-${groupIndex}`}>#{group.title}</QuestionGroupItem>
                      ))}
                    </QuestionGroups>
                  )}

                  {design === 0 && <QuestionBody dangerouslySetInnerHTML={{ __html: question.body }} />}
                </Question>
                {question.children && (
                  <Answers>
                    {question.children.map((answer, aIndex) => (
                      <Answer key={`answer-${aIndex}`} checked={answer.checked} correct={answer.isCorrect}>
                        {design < 2 && <AnswerTitle>{answer.title}</AnswerTitle>}
                        {answer.point > 0 && (
                          <Point>
                            <PointTitle>{t('Point')}:</PointTitle>
                            <PointText>{answer.point}</PointText>
                          </Point>
                        )}
                      </Answer>
                    ))}
                  </Answers>
                )}
              </Item>
            ))}
          </Items>
        )}

        {(design === 3 || design === 5) && (
          <ContentGroupResultsContainer>
            {contentUser.contentGroups && (
              <ContentGroupResults>
                {contentUser.contentGroups.map((x, i) => (
                  <ContentGroupResultItem key={`item-${i}`}>
                    <ContentGroupResultTitle color={x.color}>#{x.title}</ContentGroupResultTitle>
                    <ContentGroupResultCount>{x.point}</ContentGroupResultCount>
                  </ContentGroupResultItem>
                ))}
              </ContentGroupResults>
            )}

            {contentUser.contentGroups && <CustomActiveShapePieChart data={contentUser.contentGroups.map((x) => ({ name: x.title, value: x.point, color: x.color }))} />}
          </ContentGroupResultsContainer>
        )}

        {(design === 4 || design === 5) && (
          <ContentGroupPeriodicResults>
            <BiaxialBarChartDetail data={chartDetailData} />

            <BiaxialBarChart data={comparation.data} groups={comparation.group} onUpdateTooltipContent={onUpdateTooltipContent} />
          </ContentGroupPeriodicResults>
        )}
      </PreviewDetail>
    </Container>
  ) : (
    <Skeleton />
  );
}

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Left = styled.div``;

const Right = styled.div``;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

const ScoreText = styled.div``;

const PreviewDetail = styled.div`
  ${({ type }) => {
    switch (type) {
      case 5:
        return css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `;
      default:
        break;
    }
  }}
`;

const Items = styled.div``;

const Item = styled.div`
  margin-top: 24px;
`;

const Question = styled.div`
  margin-bottom: 8px;
`;

const QuestionTitle = styled.div`
  font-weight: bold;
`;

const QuestionGroups = styled.div`
  display: block;
  overflow: hidden;
`;

const QuestionGroupItem = styled.div`
  float: left;
  margin-right: 8px;
  font-style: italic;
  color: ${(x) => x.theme.colors.lavenderGray};
`;

const QuestionBody = styled.div``;

const Answers = styled.div`
  display: flex;
`;

const Answer = styled.div`
  margin-right: 12px;
  border: 2px solid ${(x) => x.theme.colors.darkTurquoise};
  padding: 5px 10px;
  border-radius: 10px;

  ${({ correct, checked }) =>
    checked && correct
      ? css`
          background-color: ${(x) => x.theme.colors.mediumSpringGreen};
          color: ${(x) => x.theme.colors.white};
        `
      : checked &&
        css`
          border: 2px solid ${(x) => x.theme.colors.rustyRed};
          background-color: ${(x) => x.theme.colors.rustyRed};
          color: ${(x) => x.theme.colors.white};
        `}

  ${({ correct }) =>
    correct &&
    css`
      border: 2px solid ${(x) => x.theme.colors.mediumSpringGreen};
    `}
`;

const Point = styled.div`
  display: flex;
  opacity: 0.8;
`;

const PointTitle = styled.div``;

const PointText = styled.div`
  font-weight: bold;
  margin-left: 8px;
`;

const AnswerTitle = styled.div``;

const ContentGroupResultsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentGroupResults = styled.div`
  margin-top: 24px;
`;

const ContentGroupResultItem = styled.div`
  margin-bottom: 12px;
`;

const ContentGroupResultTitle = styled.div`
  font-weight: bold;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

const ContentGroupResultCount = styled.div``;

const ContentGroupPeriodicResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 24px;
`;

const ContentGroupLieanerResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 500px;
  position: relative;
`;

const LegendTextBody = styled.div`
  padding-top: 24px;
`;
