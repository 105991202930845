import { Tree, TreeSelect } from 'antd';
import styled, { css } from 'styled-components';

const { DirectoryTree } = Tree;

export default function TreeBase({ style, treeData, expandedKeys, draggable, blockNode, onDragEnter, onDrop, onChange, templates, ...restProps }) {
  return (
    <TreeContainer
      style={style}
      treeData={treeData}
      onChange={onChange}
      expandedKeys={expandedKeys}
      draggable={draggable}
      blockNode={blockNode}
      onDragEnter={onDragEnter}
      onDrop={onDrop}
      templates={templates}
      {...restProps}
    />
  );
}

TreeBase.Select = ({ style, treeData, dropdownStyle, placeholder, showSearch, allowClear, treeDefaultExpandAll, onChange, ...restProps }) => (
  <SelectContainer
    style={style}
    treeData={treeData}
    dropdownStyle={dropdownStyle}
    placeholder={placeholder}
    showSearch={showSearch}
    allowClear={allowClear}
    onChange={onChange}
    treeDefaultExpandAll={treeDefaultExpandAll}
    {...restProps}
  />
);

TreeBase.Directory = ({ treeData, defaultExpandAll, expandedKeys, autoExpandParent, expandAction, onExpand, onSelect, disabled, templates, ...restProps }) => (
  <DirectoryContainer
    treeData={treeData}
    defaultExpandAll={defaultExpandAll}
    expandedKeys={expandedKeys}
    autoExpandParent={autoExpandParent}
    expandAction={expandAction}
    onExpand={onExpand}
    onSelect={onSelect}
    disabled={disabled}
    templates={templates}
    {...restProps}
  />
);

const TreeContainer = styled(Tree)`
  ${({ templates }) =>
    templates?.includes('delivery-flow') &&
    css`
      .ant-tree-treenode {
        border-radius: 6px;
        padding: 0px;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      .ant-tree-node-content-wrapper {
        padding: 0px;
        &:hover {
          background-color: transparent;
        }
        &.ant-tree-node-selected {
          background-color: transparent;
        }
      }

      &.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging:after {
        border: 0px;
      }

      .ant-tree-switcher {
        display: none;
      }

      .ant-tree-treenode-draggable .ant-tree-draggable-icon {
        display: none;
      }
    `}
`;

const SelectContainer = styled(TreeSelect)`
  height: 50px;
  overflow: hidden;
  border-radius: ${(x) => x.theme.global.borderRadius};

  border: 0px;
  border-color: transparent !important;
  box-shadow: 2px 2px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;

  .ant-select-selector {
    height: 50px !important;
    border: 0px !important;

    .ant-select-selection-search,
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  .ant-select-arrow {
    background: ${(x) => x.theme.colors.white};
  }

  ${({ value }) =>
    value !== undefined &&
    value !== null &&
    css`
      box-shadow: none !important;
      .ant-select-selector {
        box-shadow: inset 4px 4px 4px 0px ${(x) => x.theme.colors.lavenderGray} !important;
      }

      .ant-select-arrow {
        background: transparent;
      }
    `}
`;

const DirectoryContainer = styled(DirectoryTree)`
  background-color: transparent;

  border-radius: 10px;
  overflow: hidden;
  border: 1px solid ${(x) => x.theme.colors.darkTurquoise};

  .ant-tree-list {
    .ant-tree-treenode {
      align-items: center;
      min-height: 50px;

      .ant-tree-switcher,
      .ant-tree-node-content-wrapper {
        height: 50px;
        display: flex;
        align-items: center;
        padding-left: 12px;
      }

      &:hover {
        &::before {
          background: ${(x) => x.theme.colors.darkTurquoise};
        }

        .ant-tree-switcher,
        .ant-tree-node-content-wrapper {
          color: ${(x) => x.theme.colors.white};
        }
      }

      .ant-tree-iconEle {
        line-height: 28px;
      }
    }

    .ant-tree-treenode-selected:hover::before,
    .ant-tree-treenode-selected::before {
      background: ${(x) => x.theme.colors.darkTurquoise};
    }

    .ant-tree-treenode::before {
      bottom: 0px !important;
    }
  }
`;
