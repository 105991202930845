import AgoraRTC from 'agora-rtc-react';
import { AgoraProvider } from '../../contexts/AgoraContext';
import ConferencePlayer from './ConferencePlayer';

export default function ConferencePanel({ user, conference, setConference }) {
  const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

  // Render
  return (
    <AgoraProvider client={client} appId={conference.applicationId}>
      <ConferencePlayer user={user} conference={conference} setConference={setConference} />
    </AgoraProvider>
  );
}
