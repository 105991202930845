const ContentGroupType = {
  NoGrouping: 0,
  PhysicalField: 1,
  PsychologicalField: 2,
  SocialField: 3,
  PrivacyPolicy: 4,
  TermsOfUse: 5,
  PersonalDataProcessing: 6
};

export default ContentGroupType;