import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { scoreDetail, scoreList, scoreMyDetail, scoreMyList } from '../../../services/api';
import VAMSScoreBox from './VAMSScoreBox';
import VAMSScoreChart from './VAMSScoreChart';

export default function VAMSScore({ parameter, includeBox, includeChart, loaded }) {
  // Definitions
  const [user] = useCacheUser();

  const [response, setResponse] = useState({ detail: null, list: null });
  const [pageState, setPageState] = useState({ list: true });

  const [filter] = useState({
    userId: parameter?.id ?? user.i,
    isMyData: parameter?.isMyData ?? true,
    country: parameter?.country ?? user.c,
    userRole: parameter?.userRole ?? user.u,
  });

  // Hooks
  useEffect(() => {
    load();
  }, [filter]);

  // Functions
  const load = async () => {
    setPageState((x) => ({ ...x, list: false }));

    let [detailResponse, listResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        let request = filter.isMyData ? scoreMyDetail : scoreDetail;
        request(filter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        let request = filter.isMyData ? scoreMyList : scoreList;
        request(filter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
    ]);

    let result = { detail: detailResponse?.data, data: listResponse?.data };

    if (loaded) {
      loaded(result);
    }

    setResponse(result);
    setPageState((x) => ({ ...x, list: true }));
  };

  const onChanged = () => {
    load();
  };

  // Render
  return (
    pageState.list &&
    (includeChart ? (
      <Container includeChart={includeChart}>
        {includeBox && <VAMSScoreBox user={user} detail={response.detail} parameter={parameter} onChanged={onChanged} />}
        {includeChart && <VAMSScoreChart ready={pageState.list} response={response} fullWidth={!includeBox} />}
      </Container>
    ) : (
      <VAMSScoreBox user={user} detail={response.detail} parameter={parameter} onChanged={onChanged} />
    ))
  );
}

const Container = styled.div`
  ${({ includeChart }) =>
    includeChart &&
    css`
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      > *:not(:last-child) {
        margin-right: 24px;
      }

      @media only screen and (max-width: 1200px) {
        flex-direction: column;

        > div {
          width: 100%;
          margin-right: 0px !important;

          &:first-child {
            margin-bottom: 24px;
          }
        }
      }
    `}
`;
