const ContentGroupTypes = [
  {
    value: 0,
    name: 'NoGrouping',
    displayName: 'ContentGroupNoGrouping',
    group: 'Answer',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'PhysicalField',
    displayName: 'ContentGroupPhysicalField',
    group: 'Answer',
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'PsychologicalField',
    displayName: 'ContentGroupPsychologicalField',
    group: 'Answer',
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'SocialField',
    displayName: 'ContentGroupSocialField',
    group: 'Answer',
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  },
  {
    value: 4,
    name: 'PrivacyPolicy',
    displayName: 'ContentGroupPrivacyPolicy',
    group: 'Static',
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {}
  },
  {
    value: 5,
    name: 'TermsOfUse',
    displayName: 'ContentGroupTermsOfUse',
    group: 'Static',
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {}
  },
  {
    value: 6,
    name: 'PersonalDataProcessing',
    displayName: 'ContentGroupPersonalDataProcessing',
    group: 'Static',
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {}
  }
];

export default ContentGroupTypes;