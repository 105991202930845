import antLocaleTR from 'antd/es/locale/tr_TR';
import antLocaleEN from 'antd/lib/locale/en_GB';
import antLocaleES from 'antd/lib/locale/es_ES';
import antLocaleRU from 'antd/lib/locale/ru_RU';
import antLocaleSR from 'antd/lib/locale/sr_RS';
import antLocaleTW from 'antd/lib/locale/zh_TW';
import momentLocaleEN from 'moment/locale/en-gb';
import momentLocaleES from 'moment/locale/es';
import momentLocaleRU from 'moment/locale/ru';
import momentLocaleSR from 'moment/locale/sr';
import momentLocaleTR from 'moment/locale/tr';
import momentLocaleTW from 'moment/locale/zh-tw';
import { LanguageType, LanguageTypes } from '../constants';
import i18next from '../i18n';
import { getItem } from '../services/cache';

export const getTranslate = (key) => {
  return i18next.t(key);
};

export const getMomentLocalization = (code) => {
  switch (code ?? getItem('lcl')) {
    case 'es':
      return momentLocaleES;
    case 'ru':
      return momentLocaleRU;
    case 'sr':
      return momentLocaleSR;
    case 'tw':
      return momentLocaleTW;
    case 'tr':
      return momentLocaleTR;
    default:
    case 'en':
      return momentLocaleEN;
  }
};

export const getAntLocalization = (code) => {
  switch (code ?? getItem('lcl')) {
    case 'es':
      return antLocaleES;
    case 'ru':
      return antLocaleRU;
    case 'sr':
      return antLocaleSR;
    case 'tw':
      return antLocaleTW;
    case 'tr':
      return antLocaleTR;
    default:
    case 'en':
      return antLocaleEN;
  }
};

function replaceAll(value, parameter, replace) {
  try {
    return value.replace(new RegExp(parameter, 'g'), replace);
  } catch (e) {
    return value;
  }
}

export const getLanguageType = (code) => {
  let result = LanguageTypes.find((x) => x.data.code === code);
  if (result) return result.value;

  return LanguageType.English;
};

export const getParameters = (text) => {
  return text.match(/{([^}]+)}/g) ?? [];
};

export const cleanParameter = (text) => {
  return text.substr(0, text.length - 1).substr(1);
};

export const replaceParameters = (text, parameters) => {
  if (!parameters) return text;

  let textParameters = text.match(/{([^}]+)}/g) ?? [];
  if (textParameters?.length > 0) {
    textParameters.forEach((x) => {
      text = replaceAll(text, x, x.toLocaleUpperCase());
    });
  }

  Object.keys(parameters).forEach((x) => {
    let value = parameters[x];
    let parameter = `{${x.toLocaleUpperCase()}}`;

    if (text?.includes(parameter)) text = replaceAll(text, parameter, translate(value));
  });

  return text;
};

export const translate = (text) => {
  let parameters = text.match(/{([^}]+)}/g) ?? [];

  parameters.forEach((x) => {
    if (text !== x) text = replaceAll(text, x, i18next.t(cleanParameter(x)));
  });

  return text;
};

export const formatNumber = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
