const ActionType = {
  RoleList: 0,
  RoleAllCountry: 1,
  RoleCreate: 2,
  RoleUpdate: 3,
  RoleDelete: 4,
  OwnerList: 5,
  OwnerAllCountry: 6,
  OwnerAll: 7,
  OwnerCreate: 8,
  OwnerUpdate: 9,
  OwnerDelete: 10,
  AdministratorList: 11,
  AdministratorAllCountry: 12,
  AdministratorAll: 13,
  AdministratorCreate: 14,
  AdministratorUpdate: 15,
  AdministratorDelete: 16,
  ManagerList: 17,
  ManagerAllCountry: 18,
  ManagerAll: 19,
  ManagerCreate: 20,
  ManagerUpdate: 21,
  ManagerDelete: 22,
  EditorList: 23,
  EditorAllCountry: 24,
  EditorAll: 25,
  EditorCreate: 26,
  EditorUpdate: 27,
  EditorDelete: 28,
  PSPList: 29,
  PSPAllCountry: 30,
  PSPAll: 31,
  PSPCreate: 32,
  PSPUpdate: 33,
  PSPDelete: 34,
  DoctorList: 35,
  DoctorAllCountry: 36,
  DoctorAll: 37,
  DoctorCreate: 38,
  DoctorUpdate: 39,
  DoctorDelete: 40,
  NurseList: 41,
  NurseAllCountry: 42,
  NurseAll: 43,
  NurseCreate: 44,
  NurseUpdate: 45,
  NurseDelete: 46,
  PharmacistList: 47,
  PharmacistAllCountry: 48,
  PharmacistAll: 49,
  PharmacistCreate: 50,
  PharmacistUpdate: 51,
  PharmacistDelete: 52,
  WarehouseList: 53,
  WarehouseAllCountry: 54,
  WarehouseAll: 55,
  WarehouseCreate: 56,
  WarehouseUpdate: 57,
  WarehouseDelete: 58,
  IMSList: 59,
  IMSAllCountry: 60,
  IMSAll: 61,
  IMSCreate: 62,
  IMSUpdate: 63,
  IMSDelete: 64,
  PatientList: 65,
  PatientAllCountry: 66,
  PatientAll: 67,
  PatientJourney: 68,
  PatientCreate: 69,
  PatientUpdate: 70,
  PatientDelete: 71,
  CityList: 72,
  CityAllCountry: 73,
  CityCreate: 74,
  CityUpdate: 75,
  CityDelete: 76,
  LocalizationList: 77,
  LocalizationAllCountry: 78,
  LocalizationExcelImportExport: 79,
  LocalizationCreate: 80,
  LocalizationUpdate: 81,
  LocalizationDelete: 82,
  TemplateList: 83,
  TemplateCreate: 84,
  TemplateUpdate: 85,
  NoticeList: 86,
  NoticeAllCountry: 87,
  NoticeContactList: 88,
  NoticeContactAllCountry: 89,
  NoticeContactCreate: 90,
  NoticeContactUpdate: 91,
  NoticeContactDelete: 92,
  ConferenceList: 93,
  ConferenceCreate: 94,
  ConferenceUpdate: 95,
  ConferenceDelete: 96,
  HomeWidgetVAMS: 97,
  HomeWidgetDoseCalculator: 98,
  HomeWidgetPatientActivities: 99,
  JourneyPage: 100,
  ContentPage: 101,
  DeliveryFlowList: 102,
  DeliveryFlowAllCountry: 103,
  DeliveryFlowCreate: 104,
  DeliveryFlowUpdate: 105,
  DeliveryFlowDelete: 106,
  DeliveryList: 107,
  DeliveryAllCountry: 108,
  DeliveryAll: 109,
  DeliveryUser: 110,
  DeliveryCreate: 111,
  DeliveryUpdate: 112,
  DeliveryReject: 113,
  ContentList: 114,
  ContentAllCountry: 115,
  ContentCreate: 116,
  ContentUpdate: 117,
  ContentDelete: 118,
  StorageList: 119,
  StorageUpdate: 120,
  StorageDelete: 121,
  TreatmentList: 122,
  TreatmentAllCountry: 123,
  TreatmentAll: 124,
  TreatmentCreate: 125,
  TreatmentUpdate: 126,
  TreatmentDelete: 127,
  UserInviteList: 128,
  UserInviteAllCountry: 129,
  UserInviteAll: 130,
  UserInviteCreate: 131,
  UserInviteUpdate: 132,
  UserInviteDelete: 133,
  VisitList: 134,
  VisitAllCountry: 135,
  VisitAll: 136,
  VisitCreate: 137,
  VisitUpdate: 138,
  VisitDelete: 139,
  VitalList: 140,
  VitalAllCountry: 141,
  VitalAll: 142,
  VitalCreate: 143,
  VitalUpdate: 144,
  VitalDelete: 145,
  OwnerRelation: 146,
  AdministratorRelation: 147,
  ManagerRelation: 148,
  EditorRelation: 149,
  PSPRelation: 150,
  DoctorRelation: 151,
  NurseRelation: 152,
  PharmacistRelation: 153,
  WarehouseRelation: 154,
  IMSRelation: 155,
  PatientParent: 156,
  VAMS1TO5: 157,
  VAMSTreatmentQuestion: 158,
  AdverseEventAllowSendReports: 159,
  AdverseEventShowDisclaimer: 160,
  AskForHelpAllowSendReports: 161,
  AskForHelpShowDisclaimer: 162,
  ConferenceShareScreen: 163,
  CalculatorEvrysdiNormal: 164,
  CalculatorEvrysdiShelfLife: 165,
  CalculatorEnspryngNormal: 166,
  OwnerAnonymize: 167,
  AdministratorAnonymize: 168,
  ManagerAnonymize: 169,
  EditorAnonymize: 170,
  PSPAnonymize: 171,
  DoctorAnonymize: 172,
  NurseAnonymize: 173,
  PharmacistAnonymize: 174,
  WarehouseAnonymize: 175,
  IMSAnonymize: 176,
  PatientAnonymize: 177,
  ConferenceAttachSurvey: 178,
  RoleImportExport: 179,
  DeliveryFlowImportExport: 180,
  TemplateImportExport: 181,
  OwnerViewer: 182,
  AdministratorViewer: 183,
  ManagerViewer: 184,
  EditorViewer: 185,
  PSPViewer: 186,
  DoctorViewer: 187,
  NurseViewer: 188,
  PharmacistViewer: 189,
  WarehouseViewer: 190,
  IMSViewer: 191,
  PatientViewer: 192,
  RelativeList: 193,
  RelativeAllCountry: 194,
  RelativeAll: 195,
  RelativeRelation: 196,
  RelativeViewer: 197,
  RelativeCreate: 198,
  RelativeUpdate: 199,
  RelativeDelete: 200,
  RelativeAnonymize: 201,
  HomeWidgetUserContents: 202
};

export default ActionType;