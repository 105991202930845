const RoleInputCalculateTypes = [
  {
    value: 0,
    name: 'Manuel',
    displayName: 'CalculateByManuel',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Calculator',
    displayName: 'CalculateByCalculator',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'DeliveryTotalBottle',
    displayName: 'CalculateByDeliveryTotalBottle',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'DeliveryPreparedBottle',
    displayName: 'CalculateByDeliveryPreparedBottle',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  }
];

export default RoleInputCalculateTypes;