import { css } from 'styled-components';

export const datepicker = css`
  .ant-picker-dropdown {
    .ant-picker-panel-container {
      border-radius: 10px;

      .ant-picker-panel {
        .ant-picker-footer {
          .ant-picker-today-btn {
            color: ${(x) => x.theme.colors.darkTurquoise};
          }

          .ant-btn-primary {
            color: #fff;
            border-color: ${(x) => x.theme.colors.darkTurquoise};
            background: ${(x) => x.theme.colors.darkTurquoise};
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
          }
        }
      }
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: ${(x) => x.theme.colors.darkTurquoise};
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: 1px solid ${(x) => x.theme.colors.darkTurquoise};
  }

  .ant-picker-header-view button:hover {
    color: ${(x) => x.theme.colors.darkTurquoise};
  }

  .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 5px;
  }

  .ant-picker-now-btn {
    color: ${(x) => x.theme.colors.darkTurquoise};
  }
`;
