import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { VisitReasonTypes } from '../../constants';

export default function VisitInputs({ users, isCreate }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    users?.length > 0 && isCreate
      ? {
          type: 'select',
          name: 'userId',
          label: t('User'),
          placeholder: t('SelectUser'),
          rules: [{ required: true, message: t('UserEmpty') }],
          data: users.map((x) => ({ value: x.id, text: x.fullName })),
        }
      : {
          type: 'hidden',
          name: 'userId',
        },
    {
      type: 'select',
      name: 'reason',
      label: t('VisitReason'),
      placeholder: t('SelectVisitReason'),
      rules: [{ required: true, message: t('VisitReasonEmpty') }],
      data: VisitReasonTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'date',
      name: 'date',
      label: t('VisitDate'),
      placeholder: t('VisitDateEmpty'),
      rules: [{ required: true, message: t('VisitDateEmpty') }],
      showTime: true,
    },
    {
      type: 'textarea',
      name: 'message',
      label: t('Message'),
      placeholder: t('MessageEmpty'),
      rules: [
        { required: true, message: t('MessageEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
  ];
}
