import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button } from '../../components/globals';
import { LanguageTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function LocalizationColumns({ filter, setFilter, isAllCountry, onUpdateClick }) {
  const { t } = useTranslation();

  let columns = [
    {
      locale: 'LocalizationKey',
      name: 'key',
      type: 'string',
      searchable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    ...(filter.languages ? LanguageTypes.filter((x) => filter.languages.some((s) => s === x.value)) : LanguageTypes).map((x) => ({
      locale: t(x.displayName),
      name: `lang-${x.value}`,
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    })),
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onUpdateClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
          <RiEdit2Fill />
        </Button>
      ),
    },
  ];

  return ColumnBase({ filter, setFilter, columns });
}
