const TreatmentUsageStatusTypes = [
  {
    value: 0,
    name: 'Pending',
    displayName: 'TreatmentUsageStatusPending',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Used',
    displayName: 'TreatmentUsageStatusUsed',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'NotUsed',
    displayName: 'TreatmentUsageStatusNotUsed',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  }
];

export default TreatmentUsageStatusTypes;