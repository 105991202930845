const UserRoleType = {
  Owner: 0,
  Administrator: 1,
  Manager: 2,
  Editor: 3,
  PSP: 4,
  Doctor: 5,
  Nurse: 6,
  Pharmacist: 7,
  Warehouse: 8,
  IMS: 9,
  Patient: 10,
  Relative: 11
};

export default UserRoleType;