import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';

export default function VitalInputs({
  isCreate,
  isPreview,
  userId,
  users,
  isAnyBleedingEpisodes,
  onIsAnyBleedingEpisodesChange,
  isAnyNewMedication,
  onIsAnyNewMedicationChange,
  isAnyAdverseEvent,
  onIsAnyAdverseEventChange,
}) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'hidden',
      name: 'type',
    },
    users?.length > 0 && isCreate
      ? {
          type: 'select',
          name: 'userId',
          label: t('User'),
          placeholder: t('SelectUser'),
          rules: [{ required: true, message: t('UserEmpty') }],
          data: users.map((x) => ({ value: x.id, text: x.fullName })),
          disabled: isPreview,
        }
      : {
          type: 'hidden',
          name: 'userId',
          initialValue: userId,
        },
    {
      type: 'number',
      name: 'pulse',
      label: t('Pulse'),
      placeholder: t('PulseEmpty'),
      rules: [{ required: true, message: t('PulseEmpty') }],
      initialValue: 0.1,
      step: '0.1',
      disabled: isPreview,
    },
    {
      type: 'number',
      name: 'bloodPressureDiastolic',
      label: t('BloodPressureDiastolic'),
      placeholder: t('BloodPressureDiastolicEmpty'),
      rules: [{ required: true, message: t('BloodPressureDiastolicEmpty') }],
      initialValue: 0.1,
      step: '0.1',
      disabled: isPreview,
    },
    {
      type: 'number',
      name: 'bloodPressureSystolic',
      label: t('BloodPressureSystolic'),
      placeholder: t('BloodPressureSystolicEmpty'),
      rules: [{ required: true, message: t('BloodPressureSystolicEmpty') }],
      initialValue: 0.1,
      step: '0.1',
      disabled: isPreview,
    },
    {
      type: 'select',
      name: 'isAnyBleedingEpisodes',
      label: t('IsAnyBleedingEpisodes'),
      placeholder: t('SelectIsAnyBleedingEpisodes'),
      rules: [{ required: true, message: t('IsIsAnyBleedingEpisodesEmpty') }],
      onChange: onIsAnyBleedingEpisodesChange,
      data: [
        { value: true, text: t('Yes') },
        { value: false, text: t('No') },
      ],
      disabled: isPreview,
    },
    isAnyBleedingEpisodes && {
      type: 'number',
      name: 'bleedingMajorEpisodes',
      label: t('BleedingMajorEpisodes'),
      placeholder: t('BleedingMajorEpisodesEmpty'),
      rules: [{ required: true, message: t('BleedingMajorEpisodesEmpty') }],
      initialValue: 0.1,
      step: '0.1',
      disabled: isPreview,
    },
    isAnyBleedingEpisodes && {
      type: 'number',
      name: 'bleedingMinorEpisodes',
      label: t('BleedingMinorEpisodes'),
      placeholder: t('BleedingMinorEpisodesEmpty'),
      rules: [{ required: true, message: t('BleedingMinorEpisodesEmpty') }],
      initialValue: 0.1,
      step: '0.1',
      disabled: isPreview,
    },
    {
      type: 'select',
      name: 'isAnyNewMedication',
      label: t('IsAnyNewMedication'),
      placeholder: t('SelectIsAnyNewMedication'),
      rules: [{ required: true, message: t('IsAnyNewMedicationEmpty') }],
      onChange: onIsAnyNewMedicationChange,
      data: [
        { value: true, text: t('Yes') },
        { value: false, text: t('No') },
      ],
      disabled: isPreview,
    },
    isAnyNewMedication && {
      type: 'input',
      name: 'recordMedicationDose',
      label: t('RecordMedicationDose'),
      placeholder: t('RecordMedicationDoseEmpty'),
      rules: [
        { required: true, message: t('RecordMedicationDoseEmpty') },
        { min: 3, max: 500, message: format(t('LengthValid'), 3, 500) },
      ],
      disabled: isPreview,
    },
    {
      type: 'select',
      name: 'isAnyAdverseEvent',
      label: t('IsAnyAdverseEvent'),
      placeholder: t('SelectIsAnyAdverseEvent'),
      rules: [{ required: true, message: t('IsAnyAdverseEventEmpty') }],
      onChange: onIsAnyAdverseEventChange,
      data: [
        { value: true, text: t('Yes') },
        { value: false, text: t('No') },
      ],
      disabled: isPreview,
    },
    isAnyAdverseEvent && {
      type: 'textarea',
      name: 'adverseEventMessage',
      label: t('AdverseEventMessage'),
      placeholder: t('AdverseEventMessageEmpty'),
      rules: [
        { required: true, message: t('AdverseEventMessageEmpty') },
        { min: 3, max: 500, message: format(t('LengthValid'), 3, 500) },
      ],
      disabled: isPreview,
    },
  ];
}
