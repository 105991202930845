import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { CountryType, CountryTypes, NoticeContactType, NoticeContactTypes, NoticeGroupType, NoticeType, NoticeTypes } from '../../constants';

export default function NoticeAskForHelpInputs({
  noticeType,
  onNoticeTypeChange,
  isExternal,
  noticeContactType,
  onNoticeContactTypeChange,
  onCountryChange,
  showDisclaimers,
}) {
  const { t } = useTranslation();

  let result = [
    {
      type: 'select',
      name: 'type',
      label: t('NoticeType'),
      placeholder: t('SelectNoticeType'),
      rules: [{ required: true, message: t('NoticeTypeEmpty') }],
      data: NoticeTypes.filter((x) => {
        if (!showDisclaimers && x.data.isJustDisclaimer) return false;

        return x.data.group === NoticeGroupType.AskForHelp;
      }).map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: NoticeType.AskForHelpTechnicalIssues,
      onChange: onNoticeTypeChange,
    },
  ];

  if (noticeType !== NoticeType.AskForHelpTechnicalIssues && noticeType !== NoticeType.AskForHelpSupplyDeliveryIssues) {
    return result;
  }

  if (isExternal) {
    result = [
      ...result,
      {
        type: 'select',
        name: 'country',
        label: t('Country'),
        placeholder: t('SelectCountry'),
        rules: [{ required: true, message: t('CountryEmpty') }],
        data: CountryTypes.filter((x) => x.value !== CountryType.Global).map((x) => ({ ...x, text: t(x.displayName) })),
        onChange: onCountryChange,
      },
      {
        type: 'select',
        name: 'noticeContactType',
        label: t('ExternalNoticeContactType'),
        placeholder: t('SelectExternalNoticeContactType'),
        rules: [{ required: true, message: t('ExternalNoticeContactTypeEmpty') }],
        data: NoticeContactTypes.map((x) => ({ ...x, text: t(x.displayName) })),
        initialValue: noticeContactType,
        onChange: onNoticeContactTypeChange,
      },
      {
        type: 'input',
        name: 'contactFullName',
        label: t('ExternalNoticeContactFullName'),
        placeholder: t('ExternalNoticeContactFullNameEmpty'),
        rules: [
          { required: true, message: t('ExternalNoticeContactFullNameEmpty') },
          { min: 3, max: 100, message: format(t('LengthValid'), 3, 100) },
        ],
      },
      {
        type: noticeContactType === NoticeContactType.Phone ? 'phone' : 'input',
        name: 'contact',
        label: t('ExternalNoticeContactValue'),
        placeholder: t('ExternalNoticeContactEmpty'),
        rules: [
          { required: true, message: t('ExternalNoticeContactEmpty') },
          noticeContactType === NoticeContactType.Phone
            ? { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) }
            : {
                type: 'email',
                min: 3,
                max: 500,
                message: t('EmailInvalid'),
              },
        ],
      },
    ];
  }

  result = [
    ...result,
    {
      type: 'textarea',
      name: 'message',
      label: t('NoticeMessage'),
      placeholder: t('NoticeMessageEmpty'),
      rules: [
        { required: true, message: t('NoticeMessageEmpty') },
        { min: 3, max: 500, message: format(t('LengthValid'), 3, 500) },
      ],
    },
  ];

  result = [
    ...result,
    {
      type: 'switch',
      name: 'isSelectedWarning1',
      label: <p dangerouslySetInnerHTML={{ __html: t('WarningText1') }} />,
      templates: ['horizontal-item'],
    },
    {
      type: 'switch',
      name: 'isSelectedWarning2',
      label: <p dangerouslySetInnerHTML={{ __html: t('WarningText2') }} />,
      templates: ['horizontal-item'],
    },
  ];

  return result;
}
