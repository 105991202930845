import { css } from 'styled-components';

export const treeSelect = css`
  .ant-select-tree {
    .ant-select-tree-node-content-wrapper {
      min-height: 50px;
      line-height: 50px;
    }
    .ant-select-tree-switcher {
      line-height: 50px;
      width: 32px;
      &:not(.ant-select-tree-switcher-noop):hover {
        background-color: ${(x) => x.theme.colors.antiFlashWhiteDark};
      }
    }
  }
`;
