import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import jwtDecode from 'jwt-decode';
import { initReactI18next } from 'react-i18next';
import { getCountry, getLocale, getToken } from './services/cache';
import { getApiUrl } from './services/config';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: ['tr'],
    load: 'unspecific',
    backend: {
      loadPath: () => {
        let token = getToken();
        let country = getCountry();
        let user = token && jwtDecode(token);
        let language = getLocale();

        return `${getApiUrl()}/localization/list/${language}/${user?.c ?? country ?? ''}`;
      },
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
