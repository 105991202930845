import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { convertEvrysdiMgToMl, convertEvrysdiMlToMg, convertMgToMl, convertMlToMg } from '../../../../calculators/doseCalculator';
import {
  evrysdiCalculateWeightBottle,
  evrysdiCalculateWeightDuration,
  shelfLifeEvrysdiCalculateWeightBottle,
  shelfLifeEvrysdiCalculateWeightDuration,
} from '../../../../calculators/evrysdiCalculators';
import { usageCalculator } from '../../../../calculators/usageCalculator';
import {
  ActionType,
  DrugCalculateType,
  DrugType,
  RoleFieldGroupType,
  RoleFieldGroupTypes,
  RoleInputCalculateType,
  RoleInputType,
  RoleInputTypes,
  TreatmentEnspryngInputs,
  TreatmentEvrysdiCalculatorInputs,
  TreatmentEvrysdiDeliveryInputs,
  TreatmentInputs,
  TreatmentLastStatusTypes,
  TreatmentStageType,
  TreatmentStatusType,
} from '../../../../constants';
import { Element } from '../../../globals';

export default function TreatmentDetail({ user, id, selectedDetail, treatmentUser, selectedDrug, selectedCalculate, selectedDelivery, roleInputs, formRef, onFinish }) {
  // Definitions
  const { t } = useTranslation();
  const defaultInputs = TreatmentInputs();
  const defaultEvrysdiCalculatorInputs = TreatmentEvrysdiCalculatorInputs({
    birthDate: treatmentUser.birthDate,
    weight: treatmentUser?.weight ?? selectedDetail?.weight,
  });
  const defaultEvrysdiDeliveryInputs = TreatmentEvrysdiDeliveryInputs({ deliveryId: selectedDelivery?.id });
  const defaultEnspryngInputs = TreatmentEnspryngInputs();
  const [inputs, setInputs] = useState(defaultInputs);

  const [enspryngStatus, setEnspryngStatus] = useState(selectedDetail?.status);
  const [enspryngStage, setEnspryngStage] = useState(selectedDetail?.stage);

  // Hooks
  useEffect(() => {
    load();
  }, [enspryngStatus, enspryngStage]);

  useEffect(() => {
    if (selectedDetail) formRef.setFieldsValue({ ...selectedDetail, drug: selectedDrug?.value, calculate: selectedCalculate?.value, deliveryId: selectedDelivery?.id });
  }, [selectedDetail]);

  // Functions
  const load = () => {
    if (!selectedDrug || !selectedCalculate) return;

    let detailInputs = [];

    // Evrysdi & Calculator
    if (selectedDrug.value === DrugType.Evrysdi && selectedCalculate.value !== RoleInputCalculateType.Manuel) {
      detailInputs = [...detailInputs, ...defaultEvrysdiCalculatorInputs];
    }

    // Evrysdi & Delivery
    if (selectedDrug.value === DrugType.Evrysdi && selectedCalculate?.name?.includes('Delivery')) {
      detailInputs = [...detailInputs, ...defaultEvrysdiDeliveryInputs];
    }

    // Enspryng Default Inputs
    if (selectedDrug.value === DrugType.Enspryng) {
      defaultEnspryngInputs.forEach((x) => {
        if (x.name === 'status') {
          x.onChange = onEnspryngStatusChange;
          defaultInputs.push(x);
        }

        if (enspryngStatus === TreatmentStatusType.Continue) {
          if (x.name === 'stage') {
            x.onChange = onEnspryngStageChange;
            defaultInputs.push(x);
          }

          if (enspryngStage !== null) {
            if (enspryngStage === TreatmentStageType.StandartTreatment) {
              if (x.name === 'lastCount') {
                defaultInputs.push(x);
              }
            } else {
              if (x.name === 'lastStatus') {
                x.data = TreatmentLastStatusTypes.filter((x) => x.data.stage === enspryngStage).map((x) => ({ ...x, text: t(x.displayName) }));
                defaultInputs.push(x);
              }
            }
          }
        }
      });
    }

    detailInputs = [
      ...detailInputs,
      ...roleInputs.map((x) => {
        let input = RoleInputTypes.find((f) => f.value === x.type);
        let fieldGroup = RoleFieldGroupTypes.find((g) => g.value === input.data.group);

        let result = {
          inputValue: input.value,
          type: fieldGroup.name.toLowerCase(),
          name: x.id,
          label: t(input.displayName),
          placeholder: t(`${input.displayName}Empty`),
          rules: [],
        };

        if (input.data.group === RoleFieldGroupType.MultiSelect) {
          result = { ...result, type: 'select', mode: 'multiple' };
        }

        if (x.isRequired) {
          result.rules.push({ required: x.isRequired, message: t(`${input.displayName}Empty`) });
        }

        if (input.data.group === RoleFieldGroupType.Double) {
          result = { ...result, type: 'number', step: '0.1' };
        }

        if (x.minimumValue > 0 && x.maximumValue > 0) {
          switch (input.data.group) {
            case RoleFieldGroupType.Number:
              result.numberRule = { min: x.minimumValue, max: x.maximumValue, message: format(t('LengthValidNumber'), x.minimumValue, x.maximumValue) };
              break;
            case RoleFieldGroupType.Input:
            default:
              result.rules.push({ min: x.minimumValue, max: x.maximumValue, message: format(t('LengthValid'), x.minimumValue, x.maximumValue) });
              break;
          }
        }

        // Evrysdi & Calculator
        if (selectedDrug.value === DrugType.Evrysdi && selectedCalculate.value === RoleInputCalculateType.Calculator) {
          if (x.type === RoleInputType.DailyDoseMg || x.type === RoleInputType.DailyDoseMl) {
            result = { ...result, disabled: true };
          }
        }

        // Evrysdi & Delivery
        if (
          selectedDelivery &&
          selectedDrug.value === DrugType.Evrysdi &&
          (selectedCalculate.value === RoleInputCalculateType.DeliveryTotalBottle || selectedCalculate.value === RoleInputCalculateType.DeliveryPreparedBottle)
        ) {
          if (x.type === RoleInputType.DailyDoseMg || x.type === RoleInputType.DailyDoseMl) {
            result = { ...result, disabled: true };
          }

          if (x.type === RoleInputType.TotalBottle) {
            result = { ...result, initialValue: selectedDelivery.totalBottle };
          }
        }

        // Default Enspryng
        if (selectedDrug.value === DrugType.Enspryng) {
          if (x.type === RoleInputType.DailyDoseMg) {
            result = { ...result, initialValue: 120 };
          }

          if (x.type === RoleInputType.DailyDoseMg || x.type === RoleInputType.TotalBottle) {
            result = { ...result, disabled: true };
          }

          // Enspryng Delivery
          if (selectedCalculate.value === RoleInputCalculateType.DeliveryTotalBottle || selectedCalculate.value === RoleInputCalculateType.DeliveryPreparedBottle) {
            if (x.type === RoleInputType.TotalBottle) {
              result = { ...result, initialValue: selectedDelivery.totalBottle };
            }
          }
        }

        return result;
      }),
    ];

    setInputs(() => [
      ...defaultInputs.map((x) => {
        // Default Inputs Delivery for Evrsydi
        if (
          selectedDelivery &&
          selectedDrug.value === DrugType.Evrysdi &&
          (selectedCalculate.value === RoleInputCalculateType.DeliveryTotalBottle || selectedCalculate.value === RoleInputCalculateType.DeliveryPreparedBottle)
        ) {
          if (x.name === 'durationInMonths') {
            x = { ...x, disabled: true };
          }
        }

        // Evrysdi Shelf Life
        if (user.checkAction(ActionType.CalculatorEvrysdiShelfLife)) {
          if (x.name === 'durationInDays') {
            x.visible = true;
            x.rules = x.rules.map((r) => (r.required === false ? { ...r, required: true } : r));
          }
          // Evrysdi Normal
        } else if (user.checkAction(ActionType.CalculatorEvrysdiNormal)) {
          if (x.name === 'durationInMonths') {
            x.rules = x.rules.map((r) => (r.required === false ? { ...r, required: true } : r));
            x.visible = true;
          }
          // Another Calculator
        } else {
          if (x.name === 'durationInMonths') {
            x.visible = true;
            x.rules = x.rules.map((r) => (r.required === false ? { ...r, required: true } : r));
          }
        }

        return x;
      }),
      {
        name: 'inputs',
        templates: ['contents'],
        elements: detailInputs,
      },
    ]);

    if (!selectedDetail) formRef.setFieldsValue({ id, drug: selectedDrug.value, calculate: selectedCalculate.value, deliveryId: selectedDelivery?.id });
  };

  // Events
  const onEnspryngStatusChange = ({ value }) => {
    setEnspryngStatus(value);
    setEnspryngStage(null);
  };

  const onEnspryngStageChange = ({ value }) => {
    setEnspryngStage(value);
  };

  const onValuesChange = (input, model) => {
    // Manuel
    if (selectedCalculate.value === RoleInputCalculateType.Manuel) {
      let mgInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMg);
      let mlInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMl);

      if (mgInput && mlInput) {
        if (Object.keys(input.inputs).some((x) => x === mgInput.id)) {
          let mgValue = input.inputs[mgInput.id];
          if (selectedDrug.value === DrugType.Evrysdi) {
            // Manuel Evrysdi
            formRef.setFieldsValue({ inputs: { ...model.inputs, [mlInput.id]: convertEvrysdiMgToMl(mgValue) } });
          } else {
            formRef.setFieldsValue({ inputs: { ...model.inputs, [mlInput.id]: convertMgToMl(mgValue) } });
          }
        }

        if (Object.keys(input.inputs).some((x) => x === mlInput.id)) {
          let mlValue = input.inputs[mlInput.id];
          if (selectedDrug.value === DrugType.Evrysdi) {
            // Manuel Evrysdi
            formRef.setFieldsValue({ inputs: { ...model.inputs, [mgInput.id]: convertEvrysdiMlToMg(mlValue) } });
          } else {
            formRef.setFieldsValue({ inputs: { ...model.inputs, [mgInput.id]: convertMlToMg(mlValue) } });
          }
        }
      }

      /// TODO Will be refactored!
      if (selectedDrug.value === DrugType.Evrysdi && user.checkAction(ActionType.CalculatorEvrysdiShelfLife)) {
        formRef.setFieldsValue({ drugCalculate: DrugCalculateType.EvrysdiShelfLife });
      }

      if (selectedDrug.value === DrugType.Evrysdi && user.checkAction(ActionType.CalculatorEvrysdiNormal)) {
        formRef.setFieldsValue({ drugCalculate: DrugCalculateType.EvrysdiShelfLife });
      }

      if (selectedDrug.value === DrugType.Enspryng && user.checkAction(ActionType.CalculatorEnspryngNormal)) {
        formRef.setFieldsValue({ drugCalculate: DrugCalculateType.EnspryngNormal });
      }

      return;
    }

    // Enspryng
    if (selectedDrug.value === DrugType.Enspryng && selectedCalculate.value === RoleInputCalculateType.Calculator) {
      if (model.duration > 0 && model.startDate) {
        let result = usageCalculator(model);

        let totalBottleInput = roleInputs.find((x) => x.type === RoleInputType.TotalBottle);

        formRef.setFieldsValue({
          drugCalculate: DrugCalculateType.EnspryngNormal,
          inputs: {
            ...model.inputs,
            [totalBottleInput.id]: result?.length ?? null,
          },
        });
      }
    }

    // Evrysdi & Calculator
    if (selectedDrug.value === DrugType.Evrysdi && selectedCalculate.value === RoleInputCalculateType.Calculator) {
      let months = moment().diff(moment(model.inputs.birthDate), 'months', true);

      let mgInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMg);
      let mlInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMl);
      let totalBottleInput = roleInputs.find((x) => x.type === RoleInputType.TotalBottle);

      // Calculator Evrysdi Shelf Life
      if (user.checkAction(ActionType.CalculatorEvrysdiShelfLife)) {
        let evrysdiAutoResult = shelfLifeEvrysdiCalculateWeightDuration(model.inputs.weight, months);

        formRef.setFieldsValue({
          drugCalculate: DrugCalculateType.EvrysdiShelfLife,
          durationInDays: evrysdiAutoResult.durationInDays > 0 ? evrysdiAutoResult.durationInDays : null,
          inputs: {
            ...model.inputs,
            [mgInput.id]: evrysdiAutoResult.miligram,
            [mlInput.id]: evrysdiAutoResult.mililiter,
            [totalBottleInput.id]: evrysdiAutoResult.bottle,
          },
        });
      } else {
        if (model.inputs.weight > 0 && model.startDate && model.inputs.birthDate !== undefined) {
          var inputTotalBottle = input.inputs ? input.inputs[totalBottleInput.id] : undefined;
          if (inputTotalBottle !== undefined) {
            // Calculate by Total Bottle
            let evrysdiAutoResult = evrysdiCalculateWeightBottle(model.startDate, model.inputs.weight, inputTotalBottle, months);
            formRef.setFieldsValue({
              drugCalculate: DrugCalculateType.EvrysdiNormal,
              durationInMonths: evrysdiAutoResult.durationInMonths > 0 ? evrysdiAutoResult.durationInMonths : null,
              inputs: {
                ...model.inputs,
                [mgInput.id]: evrysdiAutoResult.miligram,
                [mlInput.id]: evrysdiAutoResult.mililiter,
                [totalBottleInput.id]: evrysdiAutoResult.bottle,
              },
            });
          } else {
            // Calculate by Duration
            let evrysdiAutoResult = evrysdiCalculateWeightDuration(model.startDate, model.inputs.weight, model.durationInMonths, months);
            formRef.setFieldsValue({
              drugCalculate: DrugCalculateType.EvrysdiNormal,
              durationInMonths: evrysdiAutoResult.durationInMonths > 0 ? evrysdiAutoResult.durationInMonths : null,
              inputs: {
                ...model.inputs,
                [mgInput.id]: evrysdiAutoResult.miligram,
                [mlInput.id]: evrysdiAutoResult.mililiter,
                [totalBottleInput.id]: evrysdiAutoResult.bottle,
              },
            });
          }

          return;
        }
      }
    }

    // Evrysdi & Delivery Total Bottle
    if (selectedDrug.value === DrugType.Evrysdi && selectedCalculate.value === RoleInputCalculateType.DeliveryTotalBottle) {
      if (model.inputs.weight > 0 && model.startDate && model.inputs.birthDate !== undefined) {
        let months = moment().diff(moment(model.inputs.birthDate), 'months', true);

        let mgInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMg);
        let mlInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMl);
        let totalBottleInput = roleInputs.find((x) => x.type === RoleInputType.TotalBottle);

        if (user.checkAction(ActionType.CalculatorEvrysdiShelfLife)) {
          let evrysdiAutoResult = shelfLifeEvrysdiCalculateWeightBottle(model.inputs.weight, model.inputs[totalBottleInput.id], months);

          formRef.setFieldsValue({
            drugCalculate: DrugCalculateType.EvrysdiShelfLife,
            durationInDays: evrysdiAutoResult.durationInDays > 0 ? evrysdiAutoResult.durationInDays : null,
            inputs: {
              ...model.inputs,
              [mgInput.id]: evrysdiAutoResult.miligram,
              [mlInput.id]: evrysdiAutoResult.mililiter,
              [totalBottleInput.id]: evrysdiAutoResult.bottle,
            },
          });
        } else {
          let evrysdiAutoResult = evrysdiCalculateWeightBottle(model.startDate, model.inputs.weight, model.inputs[totalBottleInput.id], months);

          formRef.setFieldsValue({
            drugCalculate: DrugCalculateType.EvrysdiNormal,
            durationInMonths: evrysdiAutoResult.durationInMonths > 0 ? evrysdiAutoResult.durationInMonths : null,
            inputs: {
              ...model.inputs,
              [mgInput.id]: evrysdiAutoResult.miligram,
              [mlInput.id]: evrysdiAutoResult.mililiter,
              [totalBottleInput.id]: evrysdiAutoResult.bottle,
            },
          });
        }

        return;
      }
    }

    // Evrysdi & Delivery Prepared Bottle
    if (selectedDrug.value === DrugType.Evrysdi && selectedCalculate.value === RoleInputCalculateType.DeliveryPreparedBottle) {
      if (model.inputs.weight > 0 && model.startDate && model.inputs.birthDate !== undefined) {
        let months = moment().diff(moment(model.inputs.birthDate), 'months', true);

        let mgInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMg);
        let mlInput = roleInputs.find((x) => x.type === RoleInputType.DailyDoseMl);
        let preparedBottleInput = roleInputs.find((x) => x.type === RoleInputType.PreparedBottle);

        if (user.checkAction(ActionType.CalculatorEvrysdiShelfLife)) {
          let evrysdiAutoResult = shelfLifeEvrysdiCalculateWeightBottle(model.inputs.weight, model.inputs[preparedBottleInput.id], months);

          formRef.setFieldsValue({
            drugCalculate: DrugCalculateType.EvrysdiShelfLife,
            durationInDays: evrysdiAutoResult.durationInDays > 0 ? evrysdiAutoResult.durationInDays : null,
            inputs: {
              ...model.inputs,
              [mgInput.id]: evrysdiAutoResult.miligram,
              [mlInput.id]: evrysdiAutoResult.mililiter,
              [preparedBottleInput.id]: evrysdiAutoResult.bottle,
            },
          });
        } else {
          let evrysdiAutoResult = evrysdiCalculateWeightBottle(model.startDate, model.inputs.weight, model.inputs[preparedBottleInput.id], months);

          formRef.setFieldsValue({
            drugCalculate: DrugCalculateType.EvrysdiNormal,
            durationInMonths: evrysdiAutoResult.durationInMonths > 0 ? evrysdiAutoResult.durationInMonths : null,
            inputs: {
              ...model.inputs,
              [mgInput.id]: evrysdiAutoResult.miligram,
              [mlInput.id]: evrysdiAutoResult.mililiter,
              [preparedBottleInput.id]: evrysdiAutoResult.bottle,
            },
          });
        }

        return;
      }
    }
  };

  return <Element formRef={formRef} onFinish={onFinish} inputs={inputs} columnSize={8} onValuesChange={onValuesChange} />;
}
