import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeliveryRedirectInputs from '../../../../constants/inputs/delivery/step/DeliveryRedirectInputs';
import { deliveryStepUpdate } from '../../../../services/api';
import { notifySuccess } from '../../../../utilies/notification';
import { Element } from '../../../globals';

export default function RedirectDelivery({ country, delivery, step, redirects, complete, onError }) {
  // Definitions
  const { t } = useTranslation();
  const [ready, setReady] = useState(true);

  const onFinish = async (model) => {
    let redirect = redirects.find((x) => x.id === model.id);

    model = { ...redirect, currentStepId: step.id, country, deliveryId: delivery.id, targetUserId: delivery.targetUserId };

    setReady(false);
    await deliveryStepUpdate(model, (status, res) => {
      if (status) {
        complete();
        notifySuccess(t('UpdateCompleteSuccess'));
      } else if (res?.data?.errors && onError) {
        onError(res.data.errors);
      }
      setReady(true);
    });
  };

  // Render
  return (
    step && <Element ready={ready} key="redirect-delivery" columnSize={24} onFinish={onFinish} inputs={DeliveryRedirectInputs({ redirects })} submit={t('Confirm')} />
  );
}
