import { CloseOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import styled, { css } from 'styled-components';
import { ContentType } from '../../../constants';
import { Modal } from '../../globals';

export default function ContentItemList({ type, response }) {
  // Definitions
  const player = useRef();

  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [audioPlayerVisible, setAudioPlayerVisible] = useState(false);

  // Hooks
  useEffect(() => {
    let result = response?.filter((x) => x.type !== ContentType.Question && x.type !== ContentType.Answer && x.type !== ContentType.FAQ && x.type !== ContentType.Static);
    setData(result);
  }, [response]);

  // Events
  const onDetailClick = (row) => {
    setRowData(row);

    switch (row.type) {
      case ContentType.Document:
      case ContentType.Video:
        setPanelVisibility(true);
        break;

      case ContentType.Podcast:
        setAudioPlayerVisible(true);
        break;

      default:
        window.location = `/contents/${row.url}`;
        break;
    }
  };

  const onPodcastCloseClick = () => {
    setAudioPlayerVisible(false);
    setRowData(null);
  };

  // Render
  return (
    <Container>
      {data.map((item, itemIndex) => (
        <ListItem key={`type-${itemIndex}`} onClick={() => onDetailClick(item)}>
          <Image src={item.thumbnailImageUrl} />
          <Title>{item.title}</Title>
          {item.description && <Description>{item.description}</Description>}
        </ListItem>
      ))}

      <Modal visible={panelVisibility} onCancelClick={() => setPanelVisibility(false)} footer={null} templates={['document-preview']}>
        {rowData && <DocumentFrame src={rowData.mediaUrl} />}
      </Modal>

      <PodcastFrame visible={audioPlayerVisible}>
        {rowData && <AudioPlayer autoPlay={true} ref={player} src={rowData.mediaUrl} header={<PodcastClose onClick={onPodcastCloseClick} />} />}
      </PodcastFrame>
    </Container>
  );
}

const Container = styled.div`
  display: block;
  overflow: hidden;
  margin-top: 24px;
`;

const Image = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 5px);
`;

const Title = styled.div`
  position: absolute;
  text-shadow: 0px 0px 6px #000;
  color: #fff;
  font-size: 20px;
  padding: 10px;
`;

const Description = styled.div`
  position: absolute;
  bottom: -100%;
  margin: 0px 10px;
  background-color: ${(x) => x.theme.colors.antiFlashWhite};
  max-height: 100px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  box-shadow: inset 0px -3px 4px 0px ${(x) => x.theme.colors.lavenderGray};
`;

const ListItem = styled.div`
  position: relative;
  overflow: hidden;

  height: 250px;
  width: calc(33.33% - 16px);

  float: left;

  border-radius: ${(x) => x.theme.global.borderRadius};
  box-shadow: 0px 0px 6px 0px ${(x) => x.theme.colors.deepKaomaru};

  cursor: pointer;

  &:not(:last-child) {
    margin-right: 24px;
  }

  &:hover {
    ${Description} {
      transition: all ease-in-out 0.5s;
      bottom: 0;
    }

    ${Image} {
      transition: all ease-in-out 0.5s;
      width: calc(100% + 20px);
    }
  }
`;

const DocumentFrame = styled.iframe`
  height: 100%;
  width: 100%;
`;

const PodcastFrame = styled.div`
  position: fixed;
  z-index: 999;
  bottom: -100%;
  left: 0px;
  width: 100%;

  ${({ visible }) =>
    visible &&
    css`
      bottom: 0px;
    `}
`;

const PodcastClose = styled(CloseOutlined)`
  position: absolute;
  right: 6px;
  width: 40px;
  height: 40px;
  background-color: ${(x) => x.theme.colors.mediumSpringGreen};
  border-radius: 50px;
  top: -20px;
  color: ${(x) => x.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
