import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaCheckDouble, FaPaperPlane, FaTruck } from 'react-icons/fa';
import { GiCancel } from 'react-icons/gi';
import styled, { css } from 'styled-components';
import { DeliveryStatusType } from '../../../constants';
import { formatNumber } from '../../../utilies/localization';

export default function DeliveryReportHeader({ reports, onClick }) {
  // Definitions
  const { t } = useTranslation();

  // Components
  const Icon = ({ status }) => {
    switch (status) {
      case DeliveryStatusType.Pending:
        return <FaPaperPlane />;

      case DeliveryStatusType.Approved:
        return <FaCheckDouble />;

      case DeliveryStatusType.Rejected:
        return <GiCancel />;

      default:
        return <FaTruck />;
    }
  };

  // Render
  return (
    <Row gutter={16}>
      {reports &&
        reports.map((x, i) => (
          <Col key={`report-${i}`} xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 6 }}>
            <Box status={x.value} onClick={() => onClick(x.value)}>
              <Title>
                <Icon status={x.value} /> {t(x.displayName)}
              </Title>
              <Count>{formatNumber(x.count)}</Count>
            </Box>
          </Col>
        ))}
    </Row>
  );
}

const Box = styled.div`
  font-size: 16px;
  font-weight: bold;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  border-radius: 10px;
  margin: 12px 0px 8px;
  box-shadow: 0px 0px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;
  cursor: pointer;

  span svg,
  span:last-child {
    ${({ status }) => {
      switch (status) {
        case DeliveryStatusType.Pending:
          return css`
            color: ${(x) => x.theme.colors.naplesYellow};
          `;
        case DeliveryStatusType.Approved:
          return css`
            color: ${(x) => x.theme.colors.mediumSpringGreen};
          `;
        case DeliveryStatusType.Rejected:
          return css`
            color: ${(x) => x.theme.colors.rustyRed};
          `;
        default:
          return css`
            color: ${(x) => x.theme.colors.darkTurquoise};
          `;
      }
    }}
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
    border-radius: ${(x) => x.theme.global.borderRadius};
  }
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-right: 12px;
    font-size: 26px;
  }
`;

const Count = styled.span`
  font-size: 24px;
`;
