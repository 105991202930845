import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { CountryTypes, NoticeContactType, NoticeContactTypes, NoticeTypes } from '../../constants';

export default function NoticeContactInputs({ country, isAllCountry, isUpdate, onTypeChange, type }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    isAllCountry && {
      type: 'select',
      name: 'country',
      label: t('Country'),
      placeholder: t('SelectCountry'),
      rules: [{ required: true, message: t('CountryEmpty') }],
      data: CountryTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: country && parseInt(country),
      disabled: isUpdate,
    },
    {
      type: 'select',
      name: 'noticeType',
      label: t('NoticeType'),
      placeholder: t('SelectNoticeType'),
      rules: [{ required: true, message: t('NoticeTypeEmpty') }],
      data: NoticeTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'select',
      name: 'type',
      label: t('NoticeContactType'),
      placeholder: t('SelectNoticeContactType'),
      rules: [{ required: true, message: t('NoticeContactTypeEmpty') }],
      data: NoticeContactTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onTypeChange,
    },
    {
      type: type === NoticeContactType.Phone ? 'phone' : 'input',
      name: 'value',
      label: t('NoticeContactValue'),
      placeholder: t('NoticeContactValueEmpty'),
      rules: [
        { required: true, message: t('NoticeContactValueEmpty') },
        type === NoticeContactType.Phone
          ? { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) }
          : {
              type: 'email',
              min: 3,
              max: 500,
              message: t('EmailInvalid'),
            },
      ],
    },
  ];
}
