import { Alert } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveryStatusType, DeliveryStatusTypes, DrugTypes, TreatmentDeliveryColumns } from '../../../../constants';
import { deliveryList } from '../../../../services/api';
import { Table } from '../../../globals';

export default function TreatmentDelivery({ country, targetUserId, onClick }) {
  // Definitions
  const { t } = useTranslation();
  const [response, setResponse] = useState(null);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    country,
    targetUserId,
    pageNumber: 1,
    dataLimit: 4,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    includeUser: true,
    includeTargetUser: true,
    status: DeliveryStatusType.Approved,
  });

  // Hooks
  useEffect(() => {
    load();
  }, [filter]);

  // Functions
  const load = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await deliveryList(filter, (status, res) => {
      if (status) {
        res.data = res.data
          .filter((f) => f.targetUser.id === targetUserId)
          .map((x) => {
            x.drugData = DrugTypes.find((f) => f.value === x.drug);
            x.statusData = DeliveryStatusTypes.find((f) => f.value === x.status);

            return x;
          });

        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Render
  return (
    <Fragment>
      <Alert message={t('ImportantNote')} description={t('DeliveryPrescriptionMessage')} type="info" style={{ marginBottom: 24 }} />

      <Table ready={pageState.list} response={response} filter={filter} setFilter={setFilter} columns={TreatmentDeliveryColumns({ filter, setFilter, onClick })} />
    </Fragment>
  );
}
