import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaPause, FaPlay, FaTrash } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import styled from 'styled-components';
import { Button, PopupConfirm } from '../../components/globals';
import EditButton from '../../components/globals/button/EditButton';
import RoleColumnDomainType from '../enums/RoleColumnDomainType';
import RoleInputDomainColumns from './RoleInputDomainColumns';
import ColumnBase from './_ColumnBase';

export default function TreatmentColumns({ roleColumns, filter, setFilter, onRelatedClick, onUpdateClick, onSuspendClick, onDeleteClick, isDeleted }) {
  const { t } = useTranslation();

  const menu = (row) => (
    <UpdateMenu>
      {onRelatedClick && row.deliveryId && (
        <UpdateMenuItem key="delivery" onClick={() => onRelatedClick(row)}>
          <RiEdit2Fill /> {t('Relateds')}
        </UpdateMenuItem>
      )}
      {onSuspendClick && (
        <UpdateMenuItem key="suspend" onClick={() => onSuspendClick(row)}>
          {row.suspendTime ? <FaPlay /> : <FaPause />} {t('Suspend')}
        </UpdateMenuItem>
      )}
      {onUpdateClick && (
        <UpdateMenuItem key="update" onClick={() => onUpdateClick(row)}>
          <RiEdit2Fill /> {t('Update')}
        </UpdateMenuItem>
      )}
    </UpdateMenu>
  );

  let columns = RoleInputDomainColumns(RoleColumnDomainType.Treatment, roleColumns, onRelatedClick);

  columns = [
    ...columns,
    onUpdateClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Dropdown overlay={menu(row)} placement="bottomRight" className="role">
          <EditButton disabed={isDeleted}>
            <RiEdit2Fill />
          </EditButton>
        </Dropdown>
      ),
    },
    !isDeleted &&
      onDeleteClick && {
        locale: 'Delete',
        align: 'center',
        width: 120,
        render: (v, row) => (
          <PopupConfirm
            key="delete-confirm"
            title={t('AreYouSureWantToDelete')}
            onConfirm={() => onDeleteClick(row)}
            okText={t('Confirm')}
            cancelText={t('Cancel')}
            placement="bottomRight"
          >
            <Button templates={['table']}>
              <FaTrash />
            </Button>
          </PopupConfirm>
        ),
      },
  ];

  return ColumnBase({ columns, filter, setFilter });
}

const UpdateMenu = styled(Menu)`
  padding: 0px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
`;

const UpdateMenuItem = styled(Menu.Item)`
  padding: 12px;

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;

    svg {
      color: ${(x) => x.theme.colors.robinEggBlue};
      margin-right: 10px;
    }
  }
`;
