import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';

export default function ContentGroupInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'hidden',
      name: 'type',
    },
    {
      type: 'hidden',
      name: 'language',
    },
    {
      type: 'input',
      name: 'title',
      label: t('Title'),
      placeholder: t('TitleEmpty'),
      rules: [
        { required: true, message: t('TitleEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
  ];
}
