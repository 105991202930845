import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GiMedicines } from 'react-icons/gi';
import { useNavigate, useParams } from 'react-router';
import { Page } from '../../components/globals';
import ConferenceList from '../../components/pages/journey/ConferenceList';
import ContentUserList from '../../components/pages/journey/ContentUserList';
import JourneyCalendar from '../../components/pages/journey/JourneyCalendar';
import UserParents from '../../components/pages/journey/UserParents';
import VisitList from '../../components/pages/journey/VisitList';
import VitalList from '../../components/pages/journey/VitalList';
import VAMSScore from '../../components/pages/widget/VAMSScore';
import { ActionType, DrugTypes, UserRoleActionType, UserRoleType } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { treatmentList, userDetail, userMyDetail, userRelationList, userRelationMyList } from '../../services/api';

export default function Journey() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { countryType, userRoleType, id } = useParams();
  const [user] = useCacheUser();

  const [parameter, setParameter] = useState(null);
  const [pageState, setPageState] = useState({ list: false });

  const [response, setResponse] = useState({ parents: null });

  const isAllCountry = user.checkUserRole(parseInt(userRoleType), UserRoleActionType.AllCountry);

  // Hooks
  useEffect(() => {
    setParameter({
      isMyData: id === undefined,
      id: id ?? user.i,
      country: parseInt(countryType ?? user.c),
      userRole: parseInt(userRoleType ?? user.u),
    });
  }, [countryType, userRoleType, id]);

  useEffect(() => {
    load();
  }, [parameter]);

  // Functions
  const load = async () => {
    if (parameter === null) return;

    if (
      (id === undefined && !user.checkAction(ActionType.JourneyPage)) ||
      (id !== undefined && !user.checkUserRole(parameter.userRole, UserRoleActionType.JourneyPage)) ||
      (!isAllCountry && user.c !== parameter.country)
    ) {
      setTimeout(() => navigate(`/`, { error: 'UserForbiddenException' }), 500);
      return;
    }

    setPageState((x) => ({ ...x, list: false }));

    let [userResponse, userParentResponse, treatmentResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        let request = parameter.isMyData ? userMyDetail : userDetail;
        request({ ...parameter, includeRoleActions: true }, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        let request = parameter.isMyData ? userRelationMyList : userRelationList;

        request(
          {
            ...parameter,
            currentUserRole: UserRoleType.Patient,
            userRole: UserRoleType.Doctor,
            relationUserRole: UserRoleType.Patient,
            relationUserId: parameter.id,
            includeUser: true,
            isDeleted: false,
          },
          (status, res) => {
            resolve(status ? res : null);
          }
        );
      }),
      new Promise((resolve, reject) => {
        treatmentList(
          {
            ...parameter,
            id: undefined,
            userId: parameter.id,
            includeUsages: true,
            isDeleted: false,
          },
          (status, res) => {
            resolve(status ? res : null);
          }
        );
      }),
    ]);

    if (!userResponse?.data) {
      setTimeout(() => navigate(`/`, { error: 'UserForbiddenException' }), 500);
      return;
    }

    if (treatmentResponse?.data) {
      let ongoingTreatments = treatmentResponse.data.map((x) => {
        if (x.suspendTime) {
          x.usages = x.usages.filter((u) => moment(x.suspendTime).add(user.t, 'minutes') > moment(u.time).add(user.t, 'minutes') && !u.isDeleted);
        }

        x.usages = x.usages.map((u) => ({ ...u, drugData: DrugTypes.find((d) => d.value === x.drug) }));

        return x;
      });

      let usages = ongoingTreatments.flatMap((x) => x.usages);
      treatmentResponse.data = usages.filter((x) => !x.isDeleted).map((x) => ({ ...x, time: moment(x.time).add(user.t, 'minutes').format('YYYY-MM-DD') }));
    }

    setResponse({ user: userResponse.data, parents: userParentResponse?.data, treatment: treatmentResponse });
    setPageState((x) => ({ ...x, list: true }));
  };

  // Events
  const onVisitLoaded = (visit) => {
    setResponse((x) => ({ ...x, visit }));
  };

  const onContentUserLoaded = (contentUser) => {
    setResponse((x) => ({ ...x, contentUser }));
  };

  const onVAMSLoaded = (vams) => {
    setResponse((x) => ({ ...x, vams }));
  };

  const onConferenceLoaded = (conference) => {
    setResponse((x) => ({ ...x, conference }));
  };

  const onVitalLoaded = (vital) => {
    setResponse((x) => ({ ...x, vital }));
  };

  // Render
  return (
    parameter && (
      <Page
        title={id === undefined ? t('Journey') : response?.user?.fullName}
        icon={<GiMedicines />}
        routes={[
          {
            name: t('Journey'),
            breadcrumbName: t('Journey'),
            icon: <GiMedicines />,
          },
        ]}
        templates={['journey-page']}
        extra={[<UserParents key="parents" parents={response.parents} />]}
      >
        {pageState?.list && (
          <Fragment>
            <JourneyCalendar parameter={parameter} response={response} />
            {response.user.actions.some((a) => a === ActionType.VisitList) && user.checkAction(ActionType.VisitList) && (
              <VisitList parameter={parameter} loaded={onVisitLoaded} />
            )}
            {response.user.actions.some((a) => a === ActionType.VitalList) && user.checkAction(ActionType.VitalList) && (
              <VitalList parameter={parameter} loaded={onVitalLoaded} />
            )}
            {response.user.actions.some((a) => a === ActionType.ContentPage) && user.checkAction(ActionType.ContentPage) && user.u !== UserRoleType.Patient && (
              <ContentUserList parameter={parameter} loaded={onContentUserLoaded} />
            )}
            {response.user.actions.some((a) => a === ActionType.HomeWidgetVAMS) && (
              <VAMSScore parameter={parameter} includeChart={true} includeBox={parameter.isMyData} loaded={onVAMSLoaded} />
            )}
            {response.user.actions.some((a) => a === ActionType.ConferenceList) && user.checkAction(ActionType.ConferenceList) && (
              <ConferenceList parameter={parameter} loaded={onConferenceLoaded} />
            )}
          </Fragment>
        )}
      </Page>
    )
  );
}
