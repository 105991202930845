import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import { ContentGroupColumns, ContentGroupInputs } from '../../../constants';
import { contentGroupCreate, contentGroupList, contentGroupMultipleDeleteRestore, contentGroupUpdate } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { Button, Drawer, Element, Extra, Section, Table } from '../../globals';
import { format } from 'react-string-format';

export default function ContentGroup({ type, language }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisibility, setPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({ data: true, sync: undefined });

  const [filter, setFilter] = useState({
    type,
    language,
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['row|asc'],
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    await contentGroupList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
    });
  };

  // Events
  const onCreateClick = () => {
    setPanelVisibility(true);
    formRef.resetFields();
    formRef.setFieldsValue({ type, language });
  };

  const onUpdateClick = (row) => {
    setPanelVisibility(true);
    formRef.setFieldsValue(row);
  };

  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, sync: null, data: false }));

    let request = model.id === undefined ? contentGroupCreate : contentGroupUpdate;
    await request(model, (status, res) => {
      if (status) {
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
        setPanelVisibility(false);
        list();
      }
      setPageState((x) => ({ ...x, sync: status, data: true }));
    });
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, sync: null, data: false }));

    await contentGroupMultipleDeleteRestore({ ids: selectedRowKeys }, (status, res) => {
      if (status) {
        list();

        notifySuccess(format(t(filter.isDeleted ? 'DataRestoreSuccess' : 'DataDeleteSuccess'), <strong>{res.data}</strong>));
      }
      setPageState((x) => ({ ...x, sync: status, data: true }));
    });
  };

  // Render
  return (
    <Container>
      <Description>{t('ContentGroupDescription')}</Description>

      <Section
        headerLeft={[
          <Button key="add" ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-text-title', 'with-icon']}>
            {t('AddNew')}
          </Button>,
        ]}
        headerRight={[
          <Extra
            pageState={pageState}
            filter={filter}
            setFilter={setFilter}
            key="filter"
            selectedRowKeys={selectedRowKeys}
            onFilterChanged={() => setSelectedRowKeys([])}
            onDeleteClick={onDeleteClick}
          />,
        ]}
        templates={['role-fields']}
      >
        <Table
          ready={pageState.data}
          response={response}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onRowSelectChange={(values) => setSelectedRowKeys(values)}
          columns={ContentGroupColumns({ onUpdateClick })}
        />

        <Drawer visible={panelVisibility} onClose={() => setPanelVisibility(false)} templates={['groups']}>
          <Element ready={pageState.data} formRef={formRef} onFinish={onFinish} inputs={ContentGroupInputs()} columnSize={24} submit={t('Confirm')} />
        </Drawer>
      </Section>
    </Container>
  );
}

const Container = styled.div``;

const Description = styled.div`
  margin-bottom: 24px;
`;
