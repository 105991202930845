import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ContentType } from '../../../constants';
import { contentStaticDetail } from '../../../services/api';
import { getLocale } from '../../../services/cache';
import { getLanguageType } from '../../../utilies/localization';
import { Card } from '../../globals';

export default function StaticContentDetail({ group }) {
  // Definitions
  const [pageState, setPageState] = useState({ detail: true });
  const [filter] = useState({ type: ContentType.Static, group });
  const [response, setResponse] = useState(null);

  useEffect(() => {
    load();
  }, [filter]);

  // Functions
  const load = async () => {
    setPageState((x) => ({ ...x, detail: false }));

    await contentStaticDetail({ ...filter, language: getLanguageType(getLocale()) }, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, detail: true }));
    });
  };

  // Render
  return (
    <Container>
      <Logo onClick={() => (window.location = '/')} src="/assets/images/logo.png" />
      <Card ready={pageState.detail} templates={['static-content']}>
        {response?.data && <Body dangerouslySetInnerHTML={{ __html: response.data.body }} />}
      </Card>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 100vh;
`;

const Logo = styled.img`
  width: 150px;
  margin-top: 60px;
  display: block;
  cursor: pointer;
`;
const Body = styled.div``;
