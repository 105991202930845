import { Form, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaBell } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button, PopupConfirm, Switch } from '../../components/globals';
import ContentRow from '../../components/pages/content/ContentRow';
import { ContentTypes, LanguageTypes } from '../../constants';
import { contentUpdateRow } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';
import ColumnBase from './_ColumnBase';

export default function ContentColumns({ load, onActivityChange, onUpdateClick, onNotifyClick }) {
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const onRowClick = async (content) => {
    let row = formRef.getFieldValue('row');
    let model = { ...content, row };

    await contentUpdateRow(model, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));

        if (load) {
          load();
        }
      }
    });
  };

  let columns = [
    {
      locale: 'ContentType',
      name: 'type',
      type: 'string',
      render: (value, row) => {
        let type = ContentTypes.find((x) => x.value === value);

        return type ? (
          <Tooltip placement="topLeft" title={t(type.displayName)}>
            {t(type.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'LanguageType',
      name: 'language',
      type: 'string',
      render: (value, row) => {
        let result = LanguageTypes.find((x) => x.value === value);

        return result ? (
          <Tooltip placement="topLeft" title={t(result.displayName)}>
            {t(result.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'Title',
      name: 'title',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'ContentRow',
      name: 'row',
      type: 'number',
      sortable: true,
      render: (value, row) => (
        <PopupConfirm
          key="row-change"
          title={<ContentRow content={row} formRef={formRef} />}
          icon={null}
          onConfirm={() => onRowClick(row)}
          okText={t('Confirm')}
          cancelText={t('Cancel')}
          placement="bottom"
          templates={['clickable']}
        >
          {value}
        </PopupConfirm>
      ),
    },
    onActivityChange && {
      locale: 'Activity',
      name: 'isActive',
      render: (value, row) => <Switch key="isActive" checked={value} loading={row.loading} onChange={(val) => onActivityChange(val, row)} />,
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'ContentTime',
      name: 'contentTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onNotifyClick && {
      locale: 'Notify',
      align: 'center',
      width: 120,
      render: (v, row) =>
        row.isActive &&
        !row.isDeleted && (
          <Button key="update" onClick={() => onNotifyClick(row)} templates={['table']}>
            <FaBell />
          </Button>
        ),
    },
    onUpdateClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
          <RiEdit2Fill />
        </Button>
      ),
    },
  ];

  return ColumnBase({ columns });
}
