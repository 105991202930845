import colors from './constants/colors';
import fonts from './constants/fonts';
import global from './constants/global';

const theme = {
  colors,
  fonts,
  global,
};

export default theme;
