import { useTranslation } from 'react-i18next';
import { CountryTypes, UserRoleTypes } from '../../constants';

export default function RoleInputs({ country, isAllCountry, isUpdate }) {
  const { t } = useTranslation();

  return [
    !isAllCountry && {
      type: 'hidden',
      name: 'country',
      initialValue: country && parseInt(country),
    },
    isAllCountry && {
      type: 'select',
      name: 'country',
      label: t('Country'),
      placeholder: t('SelectCountry'),
      rules: [{ required: true, message: t('CountryEmpty') }],
      data: CountryTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: country && parseInt(country),
      disabled: isUpdate,
    },
    {
      type: 'select',
      name: 'userRole',
      label: t('UserRole'),
      placeholder: t('SelectUserRole'),
      rules: [{ required: true, message: t('RoleEmpty') }],
      data: UserRoleTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      disabled: isUpdate,
    },
  ];
}
