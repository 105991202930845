export const getFileExtension = (value) => {
  return value.split('.').pop();
};

export const getFileName = (value) => {
  let fileName = value.split('.').slice(0, -1).join('.');

  return fileName?.length >= 10 ? fileName?.substring(0, 10) + '..' : fileName;
};

export const isImage = (value) => {
  let extension = getFileExtension(value);

  return ['png', 'jpeg', 'jpg', 'bmp', 'gif'].includes(extension);
};

export const convertFileToBase64 = (data, name, type) => {
  return new File([new Blob([data])], name, { type });
};

export const convertFileToUrl = async (url, name, type, callback) => {
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => callback(new File([buf], name, { type })));
};
