import { useTranslation } from 'react-i18next';

export default function DeliveryRedirectInputs({ redirects }) {
  const { t } = useTranslation();

  return [
    {
      type: 'select',
      name: 'id',
      label: t('DeliveryRedirect'),
      placeholder: t('SelectDeliveryRedirect'),
      rules: [{ required: true, message: t('DeliveryRedirectEmpty') }],
      data: redirects.map((x) => ({ value: x.id, text: t(x.nameKey) })),
      css: `margin: 0px;`,
    },
  ];
}
