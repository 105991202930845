import { useTranslation } from 'react-i18next';
import { RoleColumnDomainTypes, RoleColumnTypes } from '../../constants';

export default function RoleColumnInputs({ domain, onDomainChange, isUpdate }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'hidden',
      name: 'roleId',
    },
    {
      type: 'select',
      name: 'domain',
      label: t('ColumnDomain'),
      placeholder: t('SelectColumnDomain'),
      rules: [{ required: true, message: t('ColumnDomainEmpty') }],
      data: RoleColumnDomainTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onDomainChange,
      disabled: isUpdate,
    },
    {
      type: 'select',
      name: 'type',
      label: t('ColumnType'),
      placeholder: t('SelectColumnType'),
      rules: [{ required: true, message: t('ColumnTypeEmpty') }],
      data: RoleColumnTypes.sort((x, y) => x.order - y.order)
        .filter((x) => x.group.includes(domain))
        .map((x) => ({ ...x, text: t(x.displayName) })),
      disabled: isUpdate,
    },
    {
      type: 'number',
      name: 'row',
      label: t('ColumnRow'),
      placeholder: t('ColumnRowEmpty'),
      rules: [{ required: true, message: t('ColumnRowEmpty') }],
      initialValue: 0,
    },
  ];
}
