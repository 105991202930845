import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DeliveryStatusType, DeliveryStatusTypes, DrugCalculateType, DrugType, DrugTypes, RoleColumnDomainType, RoleColumnType, RoleColumnTypes } from '../../constants';

export default function RoleInputDomainColumns(domain, roleColumns, onRelatedClick) {
  const { t } = useTranslation();

  const getColumn = (type) => {
    let typeData = RoleColumnTypes.find((x) => x.value === type);

    switch (type) {
      case RoleColumnType.Reference:
        return {
          locale: 'Reference',
          name: 'reference',
          type: 'string',
          searchable: true,
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        };
      case RoleColumnType.ExternalId:
        return {
          locale: 'ExternalId',
          name: 'externalId',
          type: 'string',
          searchable: true,
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        };
      case RoleColumnType.Drug:
        return {
          locale: 'Drug',
          name: 'drug',
          type: 'string',
          render: (value, row) => {
            let drug = DrugTypes.find((x) => x.value === value);
            return (
              <Tooltip placement="topLeft" title={t(drug.displayName)}>
                {t(drug.displayName)}
              </Tooltip>
            );
          },
        };
      case RoleColumnType.DailyDoseMg:
        return {
          locale: 'DailyDoseMg',
          name: 'dailyDoseMg',
          type: 'string',
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        };
      case RoleColumnType.DailyDoseMl:
        return {
          locale: 'DailyDoseMl',
          name: 'dailyDoseMl',
          type: 'string',
          render: (value, row) =>
            row.drug !== DrugType.Enspryng ? (
              <Tooltip placement="topLeft" title={value}>
                {value}
              </Tooltip>
            ) : (
              'N/A'
            ),
        };
      case RoleColumnType.DrugAmount:
        return {
          locale: 'DrugAmount',
          name: 'totalBottle',
          type: 'string',
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        };
      case RoleColumnType.TotalBottle:
        if (domain === RoleColumnDomainType.Delivery) {
          return {
            locale: 'TotalBottle',
            name: 'totalBottle',
            type: 'string',
            render: (value, row) => (
              <Tooltip placement="topLeft" title={value ?? 0}>
                {value ?? 0}
              </Tooltip>
            ),
          };
        } else {
          return {
            locale: 'TotalBottle',
            name: 'totalBottle',
            type: 'string',
            render: (value, row) => (
              <Tooltip placement="topLeft" title={row?.delivery?.totalBottle ?? 0}>
                {row?.delivery?.totalBottle ?? 0}
              </Tooltip>
            ),
          };
        }
      case RoleColumnType.PreparedBottle:
        return {
          locale: 'PreparedBottle',
          name: 'preparedBottle',
          type: 'string',
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value ?? 0}>
              {value ?? 0}
            </Tooltip>
          ),
        };
      case RoleColumnType.UserFullName:
        return {
          locale: 'UserFullName',
          name: 'user',
          type: 'string',
          render: (value, row) => {
            return (
              <Tooltip placement="topLeft" title={row.user.fullName ?? row.user.reference}>
                {row.user.fullName ?? row.user.reference}
              </Tooltip>
            );
          },
        };
      case RoleColumnType.UserReference:
        return {
          locale: 'Reference',
          name: 'user',
          type: 'string',
          render: (value, row) => {
            return (
              <Tooltip placement="topLeft" title={row.user.reference}>
                {row.user.reference}
              </Tooltip>
            );
          },
        };
      case RoleColumnType.StartDate:
        return {
          locale: 'StartDate',
          name: 'startDate',
          type: 'datetime',
          sortable: true,
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        };
      case RoleColumnType.EndDate:
        return {
          locale: 'EndDate',
          name: 'endDate',
          type: 'date',
          sortable: true,
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        };
      case RoleColumnType.Duration:
        return {
          locale: 'Duration',
          name: 'duration',
          type: 'string',
          render: (value, row) => {
            if (row.drugCalculate === DrugCalculateType.EvrysdiShelfLife) {
              return (
                <Tooltip placement="topLeft" title={`${row.durationInDays} ${t('Days')}`}>
                  {`${row.durationInDays} ${t('Days')}`}
                </Tooltip>
              );
            }

            return (
              <Tooltip placement="topLeft" title={`${row.durationInMonths} ${t('Months')}`}>
                {`${row.durationInMonths} ${t('Months')}`}
              </Tooltip>
            );
          },
        };
      case RoleColumnType.BatchNumber:
        return {
          locale: 'BatchNumber',
          name: 'batchNumber',
          type: 'string',
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        };
      case RoleColumnType.Status:
        return {
          locale: 'Status',
          name: 'status',
          type: 'string',
          render: (value, row) => {
            let status = DeliveryStatusTypes.find((x) => x.value === value);
            return (
              <Tooltip placement="topLeft" title={t(status.displayName)}>
                {t(status.displayName)}
              </Tooltip>
            );
          },
        };
      case RoleColumnType.DeliveryFlow:
        return {
          locale: 'DeliveryFlow',
          name: 'deliveryFlow',
          type: 'string',
          render: (value, row) => {
            return (
              <Tooltip placement="topLeft" title={t(row.deliveryFlow.nameKey)}>
                {t(row.deliveryFlow.nameKey)}
              </Tooltip>
            );
          },
        };
      case RoleColumnType.CurrentStep:
        return {
          locale: 'CurrentStep',
          name: 'currentStep',
          type: 'string',
          render: (value, row) => {
            return (
              <Tooltip placement="topLeft" title={t(row.currentStep.nameKey)}>
                {row.status !== DeliveryStatusType.Rejected ? t(row.currentStep.nameKey) : t('Rejected')}
              </Tooltip>
            );
          },
        };
      case RoleColumnType.Sender:
        return {
          locale: 'DeliverySender',
          name: 'user',
          type: 'string',
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value.fullName ?? value.reference}>
              {value.fullName ?? value.reference}
            </Tooltip>
          ),
        };
      case RoleColumnType.Recipient:
        return {
          locale: 'DeliveryRecipient',
          name: 'targetUser',
          type: 'string',
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value.fullName ?? value.reference}>
              {value.fullName ?? value.reference}
            </Tooltip>
          ),
        };
      case RoleColumnType.DeliveredDate:
        return {
          locale: 'DeliveredDate',
          name: 'deliveredDate',
          type: 'date',
          sortable: true,
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        };
      case RoleColumnType.DeliveryReference:
        return {
          locale: 'DeliveryReference',
          name: 'deliveryReference',
          type: 'string',
          render: (value, row) => (
            <Tooltip placement="topLeft" title={row?.delivery?.reference ?? ''}>
              {onRelatedClick ? <DeliveryLink onClick={() => onRelatedClick(row)}>{row?.delivery?.reference ?? ''}</DeliveryLink> : row?.delivery?.reference ?? ''}
            </Tooltip>
          ),
        };
      case RoleColumnType.Cycle:
        return {
          locale: 'Cycle',
          name: 'cycle',
          type: 'string',
          sortable: true,
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        };
      case RoleColumnType.CreationTime:
        return {
          locale: 'CreationTime',
          name: 'creationTime',
          type: 'date',
          sortable: true,
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        };
      case RoleColumnType.ModificationTime:
        return {
          locale: 'ModificationTime',
          name: 'modificationTime',
          type: 'date',
          sortable: true,
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        };
      default:
        return {
          locale: 'Unknown',
          name: 'unknown',
          type: 'string',
          render: (value, row) => (
            <Tooltip placement="topLeft" title={value}>
              {value} {typeData.displayName}
            </Tooltip>
          ),
        };
    }
  };

  let columns = [];

  if (roleColumns?.length > 0) {
    columns = [...columns, ...roleColumns.map((x) => getColumn(x.type))];
  }

  return columns;
}

const DeliveryLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
