const DeliveryActionTypes = [
  {
    value: 0,
    name: 'RedirectDelivery',
    displayName: 'RedirectDelivery',
    group: 'Completion',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'ConfirmDelivery',
    displayName: 'ConfirmDelivery',
    group: 'Completion',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 2,
    name: 'CompleteDelivery',
    displayName: 'CompleteDelivery',
    group: 'Completion',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 3,
    name: 'EnterDeliveryDate',
    displayName: 'EnterDeliveryDate',
    group: 'Definitions',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 4,
    name: 'EnterVisitDate',
    displayName: 'EnterVisitDate',
    group: 'Definitions',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 5,
    name: 'EnterPatientVitals',
    displayName: 'EnterPatientVitals',
    group: 'Definitions',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  }
];

export default DeliveryActionTypes;