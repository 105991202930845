import { css } from 'styled-components';
import colors from '../constants/colors';

export const notification = css`
  .ant-notification {
    z-index: 99999999;
  }
  .ant-notification-notice {
    border-radius: 10px !important;

    .ant-notification-notice-with-icon {
      .ant-notification-notice-message {
        margin-bottom: 0px;
      }
    }

    .ant-notification-notice-close {
      width: 30px;
      height: 30px;
      top: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 13px;
      border-radius: 5px;
    }
  }

  .ant-alert {
    .ant-alert-message {
      font-weight: bold;
    }
    &.ant-alert-info {
      background-color: ${colors.naplesYellowOpacity10} !important;
      border: 1px solid ${colors.naplesYellowOpacity30} !important;
      border-radius: 10px;
      padding: 8px 12px 8px;
      display: flex;
      align-items: center;
    }

    .ant-alert-icon {
      margin-right: 12px;
      color: ${colors.deepKaomaru};
    }
  }
`;
