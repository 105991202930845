export const groupByList = (list, key, valuesKey = 'values') => {
  let data = [];
  list.forEach((x) => {
    let name = x[key];
    let result = data.find((x) => x.key === name);
    if (result === undefined) {
      data.push({ key: name, [valuesKey]: [x] });
    } else {
      result[valuesKey].push(x);
    }
  });
  return data;
};

export const getMaxOfList = (list, key) => {
  let result = {};
  list.forEach((x, i) => {
    if (list[i][key] >= x[key]) result = x;
  });
  return result;
};

export const getMinOfList = (list, key) => {
  let result = {};
  list.forEach((x, i) => {
    if (list[i][key] <= x[key]) result = x;
  });
  return result;
};
