import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import {
  CountryType,
  NotificationChannelType,
  NotificationChannelTypes,
  NotificationDomainTypes,
  NotificationGroupType,
  NotificationGroupTypes,
  NotificationUserRoleType,
  NotificationUserRoleTypes,
} from '../../constants';

export default function RoleNotificationInputs({ country, onDomainChange, domainData, onGroupChange, group, onUserRoleChange, userRole, onChannelChange, channel }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'hidden',
      name: 'roleId',
    },
    {
      type: 'select',
      name: 'domain',
      label: t('NotificationDomain'),
      placeholder: t('SelectNotificationDomain'),
      rules: [{ required: true, message: t('NotificationDomainEmpty') }],
      data: NotificationDomainTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onDomainChange,
    },
    domainData !== null && {
      type: 'select',
      name: 'group',
      label: t('NotificationGroup'),
      placeholder: t('SelectNotificationGroup'),
      rules: [{ required: true, message: t('NotificationGroupEmpty') }],
      data: NotificationGroupTypes.sort((x, y) => x.order - y.order)
        .filter((x) => x.group.includes(domainData.name))
        .map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onGroupChange,
    },
    group === NotificationGroupType.RemainingDays && {
      type: 'number',
      name: 'restDays',
      label: t('NotificationRestDays'),
      placeholder: t('NotificationRestDaysEmpty'),
      rules: [{ required: true, message: t('NotificationRestDaysEmpty') }],
    },
    group === NotificationGroupType.RemainingHours && {
      type: 'number',
      name: 'restHours',
      label: t('NotificationRestHours'),
      placeholder: t('NotificationRestHoursEmpty'),
      rules: [{ required: true, message: t('NotificationRestHoursEmpty') }],
    },
    {
      type: 'select',
      name: 'userRole',
      label: t('NotificationUserRole'),
      placeholder: t('SelectNotificationUserRole'),
      rules: [{ required: true, message: t('NotificationUserRoleEmpty') }],
      data: NotificationUserRoleTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onUserRoleChange,
    },
    {
      type: 'select',
      name: 'channel',
      label: t('NotificationChannel'),
      placeholder: t('SelectNotificationChannel'),
      rules: [{ required: true, message: t('NotificationChannelEmpty') }],
      data: NotificationChannelTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onChannelChange,
    },
    !(channel === NotificationChannelType.Sms && country === CountryType.India)
      ? {
          type: 'input',
          name: 'nameKey',
          label: t('NameKey'),
          placeholder: t('NameKeyEmpty'),
          rules: [
            { required: true, message: t('NameKeyEmpty') },
            { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
          ],
        }
      : null,
    !(channel === NotificationChannelType.Sms && country === CountryType.India)
      ? {
          type: 'input',
          name: 'descriptionKey',
          label: t('DescriptionKey'),
          placeholder: t('DescriptionKeyEmpty'),
          rules: [
            { required: true, message: t('DescriptionKeyEmpty') },
            { min: 3, max: 500, message: format(t('LengthValid'), 3, 500) },
          ],
        }
      : null,
    channel === NotificationChannelType.Email && {
      type: 'input',
      name: 'mailSubjectKey',
      label: t('MailSubjectKey'),
      placeholder: t('MailSubjectKeyEmpty'),
      rules: [
        { required: true, message: t('MailSubjectKeyEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
    userRole === NotificationUserRoleType.Special &&
      (channel === NotificationChannelType.Email || channel === NotificationChannelType.Sms) && {
        type: channel === NotificationChannelType.Sms ? 'phone' : 'input',
        name: 'value',
        label: t('DeliveryNotificationValue'),
        placeholder: t('DeliveryNotificationValueEmpty'),
        rules: [
          { required: true, message: t('DeliveryNotificationValueEmpty') },
          { min: 3, max: 500, message: format(t('LengthValid'), 3, 500) },
        ],
      },
  ];
}
