import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import { UserRoleTypes, UserViewerColumns } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { userList, userViewerUpdate } from '../../../services/api';
import { notifyError, notifySuccess } from '../../../utilies/notification';
import { updateResponse } from '../../../utilies/stateManagement';
import { Button, Drawer, Section, Select, Table } from '../../globals';

export default function UserViewer({ isCreate, userId, userRole, country, userViewers, setUserViewers }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [data, setData] = useState(userViewers);
  const [userListResponse, setUserListResponse] = useState(null);
  const [panelVisibility, setPanelVisibility] = useState(null);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: true,
    orderBy: ['modificationTime|desc'],
    country: country,
  });

  const [userFilter, setUserFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: true,
    orderBy: ['modificationTime|desc'],
    country: country,
  });

  const userViewerRoles = user.getUserViewerRoles();

  // Hooks
  useEffect(() => {
    loadUsers();
  }, [userFilter]);

  useEffect(() => {
    loadUserViewers();
  }, [filter, userViewers]);

  // Functions
  const loadUsers = async () => {
    if (userFilter.userRole === undefined) {
      return;
    }

    setPageState((x) => ({ ...x, list: false }));

    await userList(userFilter, (status, res) => {
      if (status) {
        res.data = res.data.map((x) => ({ ...x, isViewer: userViewers.some((r) => x.id === r.id) }));
        setUserListResponse(res);
      }
    });

    setPageState((x) => ({ ...x, list: true }));
  };

  const loadUserViewers = () => {
    if (filter.userRole === undefined) {
      setData(userViewers);
      return;
    }

    setData(userViewers.filter((x) => x.userRole === filter.userRole));
  };

  // Events
  const onViewerChange = async (val, row) => {
    if (val && userViewers.find((x) => x.userRole === row.userRole)) {
      notifyError(t('UserRelationRoleAlreadyExistsException'));
      return;
    }

    if (isCreate) {
      setUserListResponse(updateResponse(userListResponse, 'isViewer', val, false, row));

      if (val) {
        setUserViewers((x) => [...x, row]);
      } else {
        setUserViewers(userViewers.filter((x) => x.id !== row.id));
      }

      return;
    }

    setUserListResponse(updateResponse(userListResponse, 'isViewer', val, true, row));

    let model = {
      country: country,
      userId: row.id,
      userRole: row.userRole,
      relationUserId: userId,
      relationUserRole: userRole,
      isActive: val,
    };

    await userViewerUpdate(model, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));

        if (val) {
          setUserViewers((x) => [...x, row]);
        } else {
          setUserViewers(userViewers.filter((x) => x.id !== row.id));
        }
      } else {
        val = !val;
      }

      setUserListResponse(updateResponse(userListResponse, 'isViewer', val, false, row));
    });
  };

  return (
    <Section
      pageState={pageState}
      setPageState={setPageState}
      headerLeft={[
        <Title key="title">
          {t('UserViewers')}
          <Button ready={pageState?.create} onClick={() => setPanelVisibility(true)} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        </Title>,
      ]}
      headerRight={[
        userViewerRoles.length > 0 && (
          <Select
            key="user-role-filter"
            data={UserRoleTypes.filter((x) => userViewerRoles.some((u) => u === x.value)).map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(userRole) => setFilter((x) => ({ ...x, userRole }))}
            placeholder={t('SelectUserRole')}
            defaultValue={filter.userRole}
            value={filter.userRole}
            allowClear={true}
            templates={['filter']}
          />
        ),
      ]}
    >
      <Table
        style={{ marginTop: 24 }}
        ready={pageState.list}
        response={{ data }}
        filter={userFilter}
        setFilter={setUserFilter}
        columns={UserViewerColumns({ user, userRoleFilter: filter.userRole, onViewerChange })}
        templates={['drawer']}
      />

      <Drawer width={700} title={t('Users')} visible={panelVisibility} onClose={() => setPanelVisibility(false)}>
        {userViewerRoles.length > 0 && (
          <RelationFilter>
            <FilterText>{t('Filter')}</FilterText>

            <Select
              key="user-role-filter"
              data={UserRoleTypes.filter((x) => userViewerRoles.some((u) => u === x.value)).map((x) => ({ ...x, text: t(x.displayName) }))}
              onChange={userViewerRoles.length > 0 && ((userRole) => setUserFilter((x) => ({ ...x, userRole })))}
              placeholder={t('SelectUserRole')}
              defaultValue={userFilter.userRole}
              value={userFilter.userRole}
              allowClear={false}
            />
          </RelationFilter>
        )}

        <Table
          style={{ marginTop: 24 }}
          ready={pageState.list}
          response={userListResponse}
          filter={userFilter}
          setFilter={setUserFilter}
          columns={UserViewerColumns({ user, onViewerChange })}
          templates={['drawer']}
        />
      </Drawer>
    </Section>
  );
}

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const RelationFilter = styled.div`
  position: relative;
  padding-top: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
  > * {
    width: 100%;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const FilterText = styled.div`
  position: absolute;
  top: 0px;
  font-weight: bold;
`;
