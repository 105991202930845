import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginLogout } from '../../services/api';
import { removeItem } from '../../services/cache';

export default function Logout() {
  // Definitions
  const navigate = useNavigate();

  // Hooks
  useEffect(() => {
    logout();
  }, []);

  // Functions
  const logout = async () => {
    await loginLogout({}, (status, res) => {
      removeItem('tkn');

      if (status && res?.data && res.data.startsWith('http')) {
        window.location = res.data;
      } else {
        setTimeout(() => navigate('/login'), 500);
      }
    });
  };

  // Render
  return <></>;
}
