import { lazy, Suspense } from 'react';
import styled from 'styled-components';
import { DrugType } from '../../../../constants';

export default function TreatmentUsage({ formRef, selectedDrug, data, onFinish }) {
  const getUsagePath = () => {
    switch (selectedDrug?.value) {
      default:
      case DrugType.Evrysdi:
        return `Evrysdi`;

      case DrugType.Enspryng:
        return `Enspryng`;
    }
  };

  const Usage = lazy(() => import(`../usage/${getUsagePath()}`));

  return (
    <Container>
      <Usage formRef={formRef} data={data} onFinish={onFinish} />
    </Container>
  );
}

const Container = styled(Suspense)``;
