import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import {
  NotificationChannelTypes,
  NotificationDomainType,
  NotificationDomainTypes,
  NotificationGroupTypes,
  NotificationUserRoleType,
  NotificationUserRoleTypes,
  RoleNotificationColumns,
  RoleNotificationInputs,
} from '../../../constants';
import { roleNotificationCreate, roleNotificationList, roleNotificationMultipleDeleteRestore, roleNotificationUpdate } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { Button, Drawer, Element, Extra, Section, Select, Table } from '../../globals';

export default function RoleNotificationUpdate({ country, roleId }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [notificationDomainData, setNotificationDomainData] = useState(null);
  const [notificationGroup, setNotificationGroup] = useState(null);
  const [notificationUserRole, setNotificationUserRole] = useState(null);
  const [notificationChannel, setNotificationChannel] = useState(null);
  const [panelVisibility, setPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({ data: true, sync: undefined });

  const [filter, setFilter] = useState({
    roleId,
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['row|asc'],
    country: country,
    domain: NotificationDomainType.Treatment,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    await roleNotificationList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
    });
  };

  // Events
  const onDomainChange = ({ value }) => {
    setNotificationDomainData(NotificationDomainTypes.find((x) => x.value === value));
  };

  const onGroupChange = ({ value }) => {
    setNotificationGroup(value);
  };

  const onUserRoleChange = ({ value }) => {
    setNotificationUserRole(value);
  };

  const onChannelChange = ({ value }) => {
    setNotificationChannel(value);

    if (notificationUserRole === NotificationUserRoleType.Special) {
      formRef.setFieldsValue({ value: null });
    }
  };

  const onCreateClick = () => {
    formRef.resetFields();
    setPanelVisibility(true);
    setNotificationDomainData(null);
    setNotificationUserRole(null);
    setNotificationChannel(null);
    setNotificationGroup(null);

    formRef.setFieldsValue({ roleId });
  };

  const onUpdateClick = (row) => {
    setPanelVisibility(true);
    formRef.setFieldsValue(row);
    setNotificationDomainData(NotificationDomainTypes.find((x) => x.value === row.domain));
    setNotificationUserRole(row.userRole);
    setNotificationChannel(row.channel);
    setNotificationGroup(row.group);
  };

  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, sync: null, data: false }));

    model.country = country;

    let request = model.id === undefined ? roleNotificationCreate : roleNotificationUpdate;
    await request(model, (status, res) => {
      if (status) {
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
        setPanelVisibility(false);
        list();
      }
      setPageState((x) => ({ ...x, sync: status, data: true }));
    });
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, sync: null, data: false }));

    await roleNotificationMultipleDeleteRestore({ ids: selectedRowKeys, country, roleId }, (status, res) => {
      if (status) {
        list();
      }
      setPageState((x) => ({ ...x, sync: status, data: true }));
    });
  };

  // Render
  return (
    <Container>
      <Description>{t('RoleNotificationDescription')}</Description>

      <Section
        headerLeft={[
          <Button key="add" ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-text-title', 'with-icon']}>
            {t('AddNew')}
          </Button>,
        ]}
        headerRight={[
          <Select
            key="domain-filter"
            data={NotificationDomainTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(domain) => setFilter((x) => ({ ...x, domain }))}
            placeholder={t('SelectDomain')}
            templates={['filter']}
            defaultValue={filter.domain}
            value={filter.domain}
            allowClear={false}
          />,
          filter.domain !== undefined && (
            <Select
              key="group-filter"
              data={NotificationGroupTypes.sort((x, y) => x.order - y.order)
                .filter((x) => {
                  let domainData = NotificationDomainTypes.find((f) => f.value === filter.domain);

                  return x.group.includes(domainData.name);
                })
                .map((x) => ({ ...x, text: t(x.displayName) }))}
              onChange={(group) => setFilter((x) => ({ ...x, group }))}
              placeholder={t('SelectGroup')}
              templates={['filter']}
              defaultValue={filter.group}
              value={filter.group}
            />
          ),
          <Select
            key="user-role-filter"
            data={NotificationUserRoleTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(userRole) => setFilter((x) => ({ ...x, userRole }))}
            placeholder={t('SelectUserRole')}
            templates={['filter']}
            defaultValue={filter.userRole}
            value={filter.userRole}
          />,
          <Select
            key="channel-filter"
            data={NotificationChannelTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(channel) => setFilter((x) => ({ ...x, channel }))}
            placeholder={t('SelectChannel')}
            templates={['filter']}
            defaultValue={filter.channel}
            value={filter.channel}
          />,
          <Extra
            pageState={pageState}
            filter={filter}
            setFilter={setFilter}
            key="filter"
            selectedRowKeys={selectedRowKeys}
            onFilterChanged={() => setSelectedRowKeys([])}
            onDeleteClick={onDeleteClick}
          />,
        ]}
        templates={['role-fields']}
      >
        <Table
          ready={pageState.data}
          response={response}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onRowSelectChange={(values) => setSelectedRowKeys(values)}
          columns={RoleNotificationColumns({ onUpdateClick })}
        />

        <Drawer visible={panelVisibility} onClose={() => setPanelVisibility(false)}>
          <Element
            ready={pageState.data}
            formRef={formRef}
            onFinish={onFinish}
            inputs={RoleNotificationInputs({
              country: filter.country,
              onDomainChange,
              domainData: notificationDomainData,
              onGroupChange,
              group: notificationGroup,
              onUserRoleChange,
              userRole: notificationUserRole,
              onChannelChange,
              channel: notificationChannel,
            })}
            columnSize={24}
            submit={t('Confirm')}
          />
        </Drawer>
      </Section>
    </Container>
  );
}

const Container = styled.div``;

const Description = styled.div`
  margin-bottom: 24px;
`;
