import { Tooltip } from 'antd';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button } from '../../components/globals';
import ColumnBase from './_ColumnBase';

export default function ContentGroupColumns({ onUpdateClick }) {
  let columns = [
    {
      locale: 'Title',
      name: 'title',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
          <RiEdit2Fill />
        </Button>
      ),
    },
  ];

  return ColumnBase({ columns });
}
