import { PageHeader } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export default function LayoutPage({ templates, icon, parent, title, subTitle, routes, children, extra }) {
  const { t } = useTranslation();

  useEffect(() => {
    let result = 'Coral';

    if (title && parent) {
      result = `${title} - ${parent} | Coral`;
    } else if (title) {
      result = `${title} | Coral`;
    }

    document.title = result;
  }, [parent, title]);

  return (
    <PageHeaderContainer
      title={
        title && (
          <Title>
            {icon && <Icon>{icon}</Icon>} <Text>{title}</Text>
          </Title>
        )
      }
      templates={templates}
      subTitle={subTitle}
      extra={extra}
      breadcrumb={{
        routes: routes && [
          {
            path: '/',
            breadcrumbName: 'Home',
            name: t('Home'),
            icon: <FaHome />,
          },
          ...routes,
        ],
        seperator: '>',
        itemRender: (r, q, w) =>
          r.path ? (
            <Link to={r.path}>
              <span>
                {r.icon} {r.name}
              </span>
            </Link>
          ) : (
            <span>
              {r.icon} {r.name}
            </span>
          ),
      }}
    >
      {children}
    </PageHeaderContainer>
  );
}

const Default1Filter4 = css`
  ${({ templates }) =>
    templates?.includes('default-1-filter-4') &&
    css`
      width: 25%;

      &:last-child {
        margin-left: 0px;
        margin-right: 0px;

        width: calc(75% - 8px);
      }

      @media only screen and (max-width: 700px) {
        width: 100% !important;
        margin-bottom: 8px;
      }
    `}
`;

const Default0Filter4 = css`
  ${({ templates }) =>
    templates?.includes('default-0-filter-4') &&
    css`
      width: 100%;

      @media only screen and (max-width: 500px) {
        width: 100% !important;
        margin-bottom: 8px;
      }
    `}
`;

const Default0Filter2 = css`
  ${({ templates }) =>
    templates?.includes('default-0-filter-2') &&
    css`
      width: 100%;

      @media only screen and (max-width: 500px) {
        width: 100% !important;
        margin-bottom: 8px;
      }
    `}
`;

const Default1Filter2 = css`
  ${({ templates }) =>
    templates?.includes('default-1-filter-2') &&
    css`
      width: 100%;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0px;
      }

      @media only screen and (max-width: 500px) {
        width: 100% !important;
        margin-bottom: 8px;
      }
    `}
`;

const Default2Filter2 = css`
  ${({ templates }) =>
    templates?.includes('default-2-filter-2') &&
    css`
      width: calc(50% - 8px);
      margin-bottom: 8px;

      &:last-child {
        margin-left: 0px;
        margin-right: 0px;

        width: calc(100% - 8px);
      }

      @media only screen and (max-width: 700px) {
        width: 100% !important;
        margin-bottom: 8px;
      }
    `}
`;

const Default2Filter0 = css`
  ${({ templates }) =>
    templates?.includes('default-2-filter-0') &&
    css`
      width: calc(50% - 8px);
      margin-bottom: 8px;

      @media only screen and (max-width: 700px) {
        width: 100% !important;
        margin-bottom: 8px;
      }
    `}
`;

const Default1Filter0 = css`
  ${({ templates }) =>
    templates?.includes('default-1-filter-0') &&
    css`
      width: 100%;
    `}
`;

const Default3Filter4 = css`
  ${({ templates }) =>
    templates?.includes('default-3-filter-4') &&
    css`
      width: calc(33.33% - 8px);
      margin-bottom: 8px;

      &:last-child {
        margin-left: 0px;
        margin-right: 0px;

        width: calc(100% - 8px);
      }

      @media only screen and (max-width: 700px) {
        width: 100% !important;
        margin-bottom: 8px;
      }
    `}
`;

const DefaultTemplate = () => {
  return css`
    .ant-page-header-heading {
      flex-direction: column;
      .ant-page-header-heading-extra {
        width: 100%;
        .ant-space {
          display: block;
          width: 100%;
          .ant-space-item {
            float: left;
            margin-right: 8px;

            > * {
              width: 100%;
            }

            .ant-radio-group {
              width: 100%;
              justify-content: space-between;
              .ant-radio-button-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            ${Default1Filter4}
            ${Default0Filter4}
            ${Default0Filter2}
            ${Default1Filter2}
            ${Default2Filter2}
            ${Default2Filter0}
            ${Default3Filter4}
            ${Default1Filter0}

            @media only screen and (max-width: 500px) {
              .ant-radio-group {
                width: 100%;
                flex-direction: column;
                .ant-radio-button-wrapper {
                  margin: 0px;
                  &:not(:last-child) {
                    margin-bottom: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
};

const PageHeaderContainer = styled(PageHeader)`
  height: 100%;
  overflow: hidden;
  padding-top: 12px;
  padding-bottom: 24px;

  &.has-breadcrumb {
    padding-top: 22px;
  }

  .ant-page-header-content {
    height: 100%;
  }
  .ant-page-header-heading {
    .ant-page-header-heading-left,
    .ant-page-header-heading-extra {
      display: flex;
      align-items: center;

      .ant-space-item {
        display: flex;
        align-items: center;
      }
    }

    .ant-page-header-heading-left {
      height: 50px;
    }
  }

  .ant-breadcrumb {
    .ant-breadcrumb-link {
      display: inline-block;
      span {
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
        }
      }
    }
  }

  ${({ templates }) =>
    templates?.includes('default-1-filter-4') &&
    css`
      @media only screen and (max-width: 1300px) {
        ${DefaultTemplate}
      }
    `}

  ${({ templates }) =>
    templates?.includes('default-0-filter-4') &&
    css`
      @media only screen and (max-width: 700px) {
        ${DefaultTemplate}
      }
    `}

    ${({ templates }) =>
    templates?.includes('default-0-filter-2') &&
    css`
      @media only screen and (max-width: 500px) {
        ${DefaultTemplate}
      }
    `}

    ${({ templates }) =>
    templates?.includes('default-1-filter-2') &&
    css`
      @media only screen and (max-width: 1100px) {
        ${DefaultTemplate}
      }
    `}

    ${({ templates }) =>
    templates?.includes('default-2-filter-2') &&
    css`
      @media only screen and (max-width: 1300px) {
        ${DefaultTemplate}
      }
    `}

    ${({ templates }) =>
    templates?.includes('default-2-filter-0') &&
    css`
      @media only screen and (max-width: 800px) {
        ${DefaultTemplate}
      }
    `}

    ${({ templates }) =>
    templates?.includes('default-0-filter-2') &&
    css`
      @media only screen and (max-width: 700px) {
        ${DefaultTemplate}
      }
    `}

    ${({ templates }) =>
    templates?.includes('default-3-filter-4') &&
    css`
      @media only screen and (max-width: 1700px) {
        ${DefaultTemplate}
      }
    `}

    ${({ templates }) =>
    templates?.includes('default-1-filter-0') &&
    css`
      @media only screen and (max-width: 500px) {
        ${DefaultTemplate}
      }
    `}

    ${({ templates }) =>
    templates?.includes('journey-page') &&
    css`
      .ant-page-header-heading {
        flex-direction: column;
      }
    `}
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Icon = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const Text = styled.div``;
