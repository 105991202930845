import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { format } from 'react-string-format';
import { Element } from '../../../components/globals';
import {
  CountryType,
  CountryTypes,
  DrugTypes,
  LanguageTypes,
  RoleFieldGroupType,
  RoleFieldGroupTypes,
  RoleFieldType,
  RoleFieldTypes,
  UserInputs,
  UserRoleTypes,
} from '../../../constants';
import { cityListGlobal, locationRussianAddressList, roleDrugListGlobal, roleFieldCache, roleLanguageListGlobal } from '../../../services/api';
import { notifyError } from '../../../utilies/notification';
import { getRandomEmail } from '../../../utilies/randomHelper';
import { getUserFieldData, setFieldData } from '../../../utilies/userField';
import { ReduceContext } from '../../contexts/ReduceContext';

export default function UserField({ searchParams, userRoleType, countryType, formRef, onFinish }) {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userRole = UserRoleTypes.find((x) => x.value === parseInt(userRoleType));
  const country = CountryTypes.find((x) => x.value === parseInt(countryType));
  const defaultUserInputs = UserInputs({
    language: country.data.language,
    userRole: userRole.value,
    country: countryType,
    onAutoEmailClick: () => formRef.setFieldValue('email', getRandomEmail()),
  });

  const [state, dispatch] = useContext(ReduceContext);
  const [roleFields, setRoleFields] = useState([]);
  const [userInputs, setUserInputs] = useState(defaultUserInputs);

  const [roleFieldFilter] = useState({
    orderBy: ['row|asc'],
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    country: countryType,
    userRole: userRole.value,
  });

  const [languageFilter] = useState({
    country: countryType,
    userRole: userRole.value,
  });

  // Hooks
  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (state?.language?.change) {
      load();
      dispatch({ ...state, language: { ...state.language, change: false } });
    }
  }, [state]);

  // Functions
  const load = async () => {
    let [cityResponse, fieldResponse, languageResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        cityListGlobal({ country: countryType }, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        roleFieldCache(roleFieldFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        roleLanguageListGlobal(languageFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
    ]);

    if (fieldResponse?.data === null) {
      setTimeout(() => navigate(`/`, { error: 'UserForbiddenException' }), 500);
      return;
    }
    setRoleFields(fieldResponse.data);

    let languages = LanguageTypes;
    if (languageResponse?.data) {
      languages = languageResponse?.data?.map((x) => LanguageTypes.find((f) => f.value === x.language))?.filter((f) => f !== undefined);
    }

    let drugs = [];
    if (fieldResponse.data.some((x) => x.type === RoleFieldType.DiseaseTypes)) {
      let roleId = fieldResponse?.data[0].roleId;

      await roleDrugListGlobal({ country: countryType, roleId }, (status, res) => {
        if (status) {
          drugs = res.data.map((x) => DrugTypes.find((d) => d.value === x.drug));
        } else {
          notifyError(t('RoleDrugNotFoundException'));
          navigate(`/`);
          return;
        }
      });
    }

    setUserInputs((x) => [
      ...defaultUserInputs.map((m) => {
        if (m.name === 'cityId') m.data = cityResponse?.data?.map((c) => ({ value: c.id, text: c.name }));
        if (m.name === 'language') m.data = languages.map((c) => ({ ...c, text: t(c.displayName) }));
        return m;
      }),
      {
        name: 'fields',
        templates: ['contents'],
        elements: fieldResponse.data
          .sort((a, b) => a.row - b.row)
          .map((m, i) => {
            let field = RoleFieldTypes.find((f) => f.value === m.type);
            if (!field) return null;
            let fieldGroup = RoleFieldGroupTypes.find((g) => g.value === field.data.group);

            let result = {
              fieldValue: field.value,
              type: fieldGroup.name.toLowerCase(),
              name: m.id,
              label: t(field.displayName),
              placeholder: t(`${field.displayName}Empty`),
              rules: [],
            };

            if (fieldGroup.value === RoleFieldGroupType.Phone) {
              result = { ...result, country: country.data.code.toLocaleLowerCase() };
            }

            if (field.value === RoleFieldType.BirthDate) {
              result = { ...result, disabledDate: (current) => current && current > moment().add(-1, 'days').endOf('day'), showTime: false, format: 'll' };
            }

            if (field.value === RoleFieldType.ConsentExpirationDate) {
              result = { ...result, disabledDate: (current) => current && current < moment(), showTime: false, format: 'll' };
            }

            if (field.value === RoleFieldType.Address && countryType === CountryType.Russian) {
              result = { ...result, type: 'complete', onChange: onRussianAddressChange };
            }

            if (field.data.group === RoleFieldGroupType.MultiSelect) {
              result = { ...result, type: 'select', mode: 'multiple' };
            }

            if (m.isRequired) {
              result.rules.push({ required: m.isRequired, message: t(`${field.displayName}Empty`) });
            }

            if (field.data.group === RoleFieldGroupType.Double) {
              result = { ...result, type: 'number', step: '0.1' };
            }

            if (m.minimumValue > 0 && m.maximumValue > 0) {
              switch (field.data.group) {
                case RoleFieldGroupType.Number:
                  result.numberRule = { min: m.minimumValue, max: m.maximumValue, message: format(t('LengthValidNumber'), m.minimumValue, m.maximumValue) };
                  break;
                case RoleFieldGroupType.Input:
                default:
                  result.rules.push({ min: m.minimumValue, max: m.maximumValue, message: format(t('LengthValid'), m.minimumValue, m.maximumValue) });
                  break;
              }
            }

            if (field.data.group === RoleFieldGroupType.Select) {
              result.initialValue = 0;
              result.data = getUserFieldData(field.value, drugs);
            }

            if (field.data.group === RoleFieldGroupType.MultiSelect) {
              result.initialValue = [];
              result.data = getUserFieldData(field.value, drugs);
            }

            if (searchParams) {
              let therapeuticArea = searchParams.get('t');
              let diseaseTypes = searchParams.get('d');

              if (field.value === RoleFieldType.TherapeuticAreaType && therapeuticArea) {
                result.initialValue = parseInt(therapeuticArea);
              }

              if (field.value === RoleFieldType.DiseaseTypes && diseaseTypes) {
                result.initialValue = [parseInt(diseaseTypes)];
              }
            }

            return result;
          }),
      },
    ]);
  };

  // Events
  const onFieldFinish = (model) => {
    console.log(model);
    console.log(roleFields);

    if (model.fields) {
      model.fields = setFieldData(model.fields, roleFields);
    }

    onFinish(model);
  };
  const onRussianAddressChange = async ({ value }) => {
    if (value?.length > 1) {
      await locationRussianAddressList({ address: value }, (status, res) => {
        if (status) {
          let result = JSON.parse(res.data);
          if (result.suggestions) {
            setUserInputs((i) =>
              i.map((x) => {
                if (x.name === 'fields') {
                  x.elements = x.elements.map((e) => {
                    if (e.fieldValue === RoleFieldType.Address) {
                      e.options = result.suggestions;
                    }
                    return e;
                  });
                }

                return x;
              })
            );
          }
        }
      });
    }
  };

  // Render
  return userInputs && <Element formRef={formRef} onFinish={onFieldFinish} inputs={userInputs} columnSize={8} />;
}
