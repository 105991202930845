import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { format } from 'react-string-format';
import styled, { css } from 'styled-components';

export default function TableBase({
  rowKey,
  ready,
  response,
  columns,
  filter,
  setFilter,
  selectedRowKeys,
  onRowSelectChange,
  style,
  templates,
  scroll,
  expandable,
  expandedRowKeys,
  expandedRowRender,
}) {
  const { t } = useTranslation();

  return (
    <Container
      style={style}
      rowKey={rowKey ?? 'id'}
      dataSource={response?.data}
      columns={columns}
      scroll={{ x: scroll ?? 1100 }}
      templates={templates}
      rowClassName={(row) => row.className}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            if (
              expandable &&
              event.target.closest('.ant-table-row-expand-icon-cell') === null &&
              columns.length !== event.target.cellIndex &&
              !event.target.className.includes('dropdown') &&
              !event.target.className.includes('tooltip')
            ) {
              event.target.closest('.ant-table-row').querySelector('.ant-table-row-expand-icon-cell div').click();
            }
          },
        };
      }}
      rowSelection={
        selectedRowKeys && {
          columnWidth: 40,
          selectedRowKeys,
          onChange: (values) => onRowSelectChange(values),
        }
      }
      loading={!ready}
      pagination={
        response?.count > 0 && {
          pageSize: response?.limit,
          total: response?.count,
          showTotal: (total, range) => {
            return `${format(t('TotalRecords'), total)}`;
          },
          current: filter?.pageNumber,
          position: ['bottomRight'],
        }
      }
      onChange={(f, _, s) =>
        setFilter
          ? s.order !== undefined
            ? setFilter((p) => ({
                ...p,
                pageNumber: f.current,
                dataLimit: f.pageSize,
                orderBy: [`${s.field}|${s.order}`],
              }))
            : setFilter((p) => ({ ...p, pageNumber: f.current, dataLimit: f.pageSize, orderBy: ['modificationTime|desc'] }))
          : true
      }
      expandable={{
        rowExpandable: () => expandable === true,
        expandedRowRender: expandable && expandedRowRender,
        expandedRowKeys: expandedRowKeys,
        expandIcon: ({ expanded, onExpand, record }) =>
          expandable && (
            <ExpandIcon onClick={(e) => onExpand(record, e)} expanded={expanded}>
              {expanded ? <FaChevronUp /> : <FaChevronDown />}
            </ExpandIcon>
          ),
      }}
    />
  );
}

const Container = styled(Table)`
  @keyframes color-change {
    0% {
      background-color:${(x) => x.theme.colors.naplesYellowOpacity30};
    }
    50% {
      background-color:${(x) => x.theme.colors.naplesYellowOpacity10};
    }
    100% {
      background-color:${(x) => x.theme.colors.naplesYellowOpacity30};
    }
  }

  overflow: hidden;
  // margin-left: -10px;
  // width: calc(100% + 20px);
  // max-width: calc(100% + 20px);
  // padding: 0px 10px 0px 10px;
  
  .ant-table {
    .ant-table-container {
      &::after {
        box-shadow: none;
      }

      .ant-table-content{
        padding: 0px 10px 20px;
        margin: 0px -10px;
        
        &::-webkit-scrollbar{
          height:3px;
        }
      }

      table {
        border-spacing: 0px 12px !important;
        .ant-table-measure-row {
          display: none;
        }
        .ant-table-thead>tr>th{
          font-weight:bold;
          -webkit-user-select: none;  
          -moz-user-select: none; 
          -ms-user-select: none;   
          user-select: none;       

          .ant-table-column-sorter{
            opacity:0;
          }

          &:hover{
            .ant-table-column-sorter{
              opacity:1;
            }
          }
        }

        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          border-spacing: 0;
          background: ${(x) => x.theme.colors.white};
          border-top: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
          border-bottom: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};

          &:first-child {
            border-left: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
            border-top-left-radius ${(x) => x.theme.global.borderRadius};
            border-bottom-left-radius ${(x) => x.theme.global.borderRadius};
          }
          &:last-child {
            border-right: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
            border-top-right-radius ${(x) => x.theme.global.borderRadius};
            border-bottom-right-radius ${(x) => x.theme.global.borderRadius};
          }
        }

        .ant-table-tbody > tr {
          transition: all 0.3s;
          border-radius: ${(x) => x.theme.global.borderRadius};
        }

        .ant-table-tbody > tr:not(.ant-table-placeholder):hover {
          transform: translateY(-2px);
          box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
          border-radius: ${(x) => x.theme.global.borderRadius};
        }

        tr.naples-yellow-item {
          animation-name: color-change;
          animation-duration: 3s;
          animation-iteration-count: infinite;

          td{
            background-color:transparent;
          }
        }
      }
    }
  }

  .ant-checkbox {
    margin-bottom: 4px;

    &:hover{
      .ant-checkbox-inner {
      border-color: ${(x) => x.theme.colors.darkTurquoise};
      }
    }

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      border-radius: ${(x) => x.theme.global.smallBorderRadius};
      &::after {
        left: 28%;
      }
    }

    .ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner{
      border-color: ${(x) => x.theme.colors.darkTurquoise};
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${(x) => x.theme.colors.darkTurquoise};
        border-color: ${(x) => x.theme.colors.darkTurquoise};

        &::after {
          transform: rotate(45deg) scale(1.3) translate(-50%, -50%);
        }
      }

      &::after {
        display: none;
      }
    }

    &.ant-checkbox-indeterminate {
      .ant-checkbox-inner:after{
        width: 10px;
        height: 10px;
        left: 50% !important;
        background-color: ${(x) => x.theme.colors.darkTurquoise};
      }
    }
  }

  ${({ templates }) =>
    templates?.includes('drawer') &&
    css`
      .ant-table-container {
        overflow: hidden;
      }
    `}

  .ant-pagination{
    .ant-pagination-item-active{
      border-color: ${(x) => x.theme.colors.darkTurquoise};
      
      &:focus-visible,
      &:hover{
        border-color: ${(x) => x.theme.colors.darkTurquoise};
      }
      a{
        color: ${(x) => x.theme.colors.darkTurquoise};
      }
    }
  }

  ${({ templates }) =>
    templates?.includes('fit-content') &&
    css`
      .ant-table .ant-table-container .ant-table-content {
        padding: 0px 10px 0px;
      }
    `}
`;

const ExpandIcon = styled.div`
  position: absolute;
  cursor: pointer;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  ${({ expanded }) =>
    expanded
      ? css`
          color: ${(x) => x.theme.colors.darkTurquoise};
        `
      : css`
          color: ${(x) => x.theme.colors.deepKaomaru};
        `}
`;
