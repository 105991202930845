import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GiMedicines } from 'react-icons/gi';
import styled, { css } from 'styled-components';
import { shelfLifeEvrysdiCalculateWeightDuration } from '../../../calculators/evrysdiCalculators';
import { ShelfLifeEvrysdiCalculatorInputs } from '../../../constants';
import { Button, Element } from '../../globals';

export default function ShelfLifeEvrysdiCalculator({ user, templates, visible, onConfirm }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [pageState, setPageState] = useState({ form: true });
  const [response, setResponse] = useState(null);

  // Hooks
  useEffect(() => {
    if (user && visible) {
      formRef.setFieldsValue(user);
    }
  }, [user, visible]);

  useEffect(() => {
    if (visible === false) {
      setResponse(null);
      formRef.resetFields();
    }
  }, [visible]);

  // Events
  const onCalculateFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    let months = moment().diff(model.birthDate, 'months', true);
    let result = shelfLifeEvrysdiCalculateWeightDuration(model.weight, months);

    setResponse(result);
    setPageState((x) => ({ ...x, form: true }));
  };

  // Render
  return (
    <Container>
      <FormContainer visible={response === null} templates={templates}>
        <Element
          key="dose-calculator-form"
          onFinish={onCalculateFinish}
          ready={pageState.form}
          formRef={formRef}
          inputs={ShelfLifeEvrysdiCalculatorInputs()}
          columnSize={24}
        />

        <Button onClick={() => formRef.submit()} templates={['colored']} block={true}>
          {t('Calculate')}
        </Button>
      </FormContainer>

      {response && (
        <ResultContainer templates={templates}>
          <GiMedicines />
          <Title>{t('DailyDoseAmount')}</Title>
          <AmountResult>
            <Amount>
              {response.miligram}
              <span>mg</span>
            </Amount>
            <Divider></Divider>
            <Amount>
              {response.mililiter}
              <span>ml</span>
            </Amount>
          </AmountResult>

          <TextResults>
            <TextResult>
              <Title>{t('TotalBottleAmount')}</Title>
              <Amount>{response.bottle}</Amount>
            </TextResult>
            <TextResult>
              <Title>{t('TotalDays')}</Title>
              <Amount>{response.durationInDays}</Amount>
            </TextResult>
          </TextResults>

          <Button onClick={() => setResponse(null)} templates={['colored', 'full-width', !templates?.includes('delivery') && 'absolute-bottom']}>
            {t('CalculateAgain')}
          </Button>
          {templates?.includes('delivery') && onConfirm && (
            <Button onClick={() => onConfirm(response, formRef.getFieldsValue(), 'evrysdi-shelf-life')} templates={['colored', 'full-width', 'mt-24']}>
              {t('Confirm')}
            </Button>
          )}
        </ResultContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
`;

const FormContainer = styled.div`
  height: 100%;

  ${({ visible }) =>
    visible
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  flex-direction: column;
  justify-content: space-between;

  ${({ templates }) =>
    templates?.includes('delivery') &&
    css`
      justify-content: flex-start;
    `}
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  padding-bottom: 50px;

  ${({ templates }) =>
    templates?.includes('delivery') &&
    css`
      justify-content: flex-start;
    `}

  svg {
    margin-top: 12px;
    font-size: 40px;
    color: ${(x) => x.theme.colors.mediumSpringGreen};
  }
`;

const Title = styled.div`
  font-weight: bold;
  margin: 20px 0px;
`;

const AmountResult = styled.div`
  display: flex;
  align-items: center;
`;

const Amount = styled.div`
  font-size: 50px;
  font-weight: bold;
  span {
    font-size: initial;
    font-weight: initial;
  }
`;

const Divider = styled.div`
  margin: 0px 8px;
  width: 24px;
  height: 1px;
  background-color: #000;
  transform: rotate(110deg);
  margin-top: 10px;
`;

const TextResults = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TextResult = styled.div`
  width: 100%;
  text-align: center;
`;
