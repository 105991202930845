import moment from 'moment';

export const evrysdiCalculateWeightDuration = (startDate, weight, durationInMonths, months) => {
  let miligram = 0;
  let mililiter = 0;
  let bottle = 0;
  let days = 0;

  if (weight > 0 && durationInMonths > 0 && months > 0) {
    if (months < 24) {
      miligram = parseFloat(0.2 * weight);
    } else {
      if (weight < 20) {
        miligram = parseFloat(0.25 * weight);
      } else {
        miligram = parseFloat(5 * weight);
      }
    }
    if (miligram > 5) miligram = 5;

    mililiter = miligram / 0.75;
    if (mililiter > 6.5) mililiter = 6.6;

    days = moment(startDate).add(durationInMonths, 'months').diff(moment(startDate), 'days');

    bottle = Math.ceil(days / Math.floor(76 / mililiter));

    mililiter = parseFloat(mililiter.toFixed(2));
    miligram = parseFloat(miligram.toFixed(2));
    days = parseInt(days.toFixed(0));
  }

  return {
    weight,
    durationInMonths,
    miligram,
    mililiter,
    months,
    days,
    bottle,
  };
};

export const shelfLifeEvrysdiCalculateWeightDuration = (weight, months) => {
  let miligram = 0;
  let mililiter = 0;
  let bottle = 0;
  let days = 0;
  let durationInDays = 0;
  let periodDays = 0;

  if (weight > 0 && months > 0) {
    if (months < 24) {
      miligram = parseFloat(0.2 * weight);
    } else {
      if (weight < 20) {
        miligram = parseFloat(0.25 * weight);
      } else {
        miligram = parseFloat(5 * weight);
      }
    }
    if (miligram > 5) miligram = 5;

    mililiter = miligram / 0.75;
    if (mililiter > 6.5) mililiter = 6.6;

    periodDays = Math.floor(76 / mililiter);
    bottle = Math.floor(64 / periodDays);
    days = periodDays * bottle;

    mililiter = parseFloat(mililiter.toFixed(2));
    miligram = parseFloat(miligram.toFixed(2));
    days = parseInt(days.toFixed(0));
    durationInDays = days;
  }

  return {
    weight,
    durationInDays,
    periodDays,
    miligram,
    mililiter,
    months,
    days,
    bottle,
  };
};

export const evrysdiCalculateWeightBottle = (startDate, weight, bottle, months) => {
  let miligram = 0;
  let mililiter = 0;
  let days = 0;
  let durationInMonths = 0;

  if (weight > 0 && months > 0 && bottle > 0) {
    if (months < 24) {
      miligram = parseFloat(0.2 * weight);
    } else {
      if (weight < 20) {
        miligram = parseFloat(0.25 * weight);
      } else {
        miligram = parseFloat(5 * weight);
      }
    }
    if (miligram > 5) miligram = 5;

    mililiter = miligram / 0.75;
    if (mililiter > 6.5) mililiter = 6.6;

    days = Math.ceil(bottle * (76 / mililiter));

    durationInMonths = Math.ceil(moment(startDate).add(days, 'days').diff(moment(startDate), 'months'));

    mililiter = parseFloat(mililiter.toFixed(2));
    miligram = parseFloat(miligram.toFixed(2));
    days = parseInt(days.toFixed(0));
  }

  return {
    weight,
    durationInMonths,
    miligram,
    mililiter,
    months,
    days,
    bottle,
  };
};

export const shelfLifeEvrysdiCalculateWeightBottle = (weight, bottle, months) => {
  let miligram = 0;
  let mililiter = 0;
  let days = 0;
  let durationInDays = 0;
  let periodDays = 0;

  if (weight > 0 && months > 0 && bottle > 0) {
    if (months < 24) {
      miligram = parseFloat(0.2 * weight);
    } else {
      if (weight < 20) {
        miligram = parseFloat(0.25 * weight);
      } else {
        miligram = parseFloat(5 * weight);
      }
    }
    if (miligram > 5) miligram = 5;

    mililiter = miligram / 0.75;
    if (mililiter > 6.5) mililiter = 6.6;

    periodDays = Math.floor(76 / mililiter);
    days = Math.floor(bottle * periodDays);

    mililiter = parseFloat(mililiter.toFixed(2));
    miligram = parseFloat(miligram.toFixed(2));
    days = parseInt(days.toFixed(0));
    durationInDays = days;
  }

  return {
    weight,
    durationInDays,
    periodDays,
    miligram,
    mililiter,
    months,
    days,
    bottle,
  };
};
