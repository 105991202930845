export const convertUrl = (html) => {
  let plainText = html.replace(/(<([^>]+)>)/gi, '');
  if (isUrl(plainText)) {
    return plainText;
  }

  return html;
};

export const isUrl = (value) => {
  try {
    new URL(value);

    return true;
  } catch (err) {
    return false;
  }
};

export const urlSerializer = (params) => {
  let searchParams = new URLSearchParams();
  for (let key of Object.keys(params)) {
    let param = params[key];
    if (Array.isArray(param)) {
      for (let p of param) {
        searchParams.append(key, p);
      }
    } else if (param !== null && param !== undefined) searchParams.append(key, param);
  }
  return searchParams?.toString();
};

export const urlDeserializer = (url) => decodeURIComponent(url);
