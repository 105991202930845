import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NotificationStatusTypes, NotificationTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { replaceParameters } from '../../../utilies/localization';

export default function Delivery({ notification }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [data, setData] = useState(null);

  // Hooks
  useEffect(() => {
    if (notification) {
      let result = { ...notification, detail: JSON.parse(notification.data) };

      result.statusData = NotificationStatusTypes.find((x) => x.value === result.status);
      result.typeData = NotificationTypes.find((x) => x.value === result.type);
      result.nameKey = replaceParameters(t(result.detail.nameKey), result.detail.parameters);
      result.descriptionKey = replaceParameters(t(result.detail.descriptionKey), result.detail.parameters);

      setData(result);
    }
  }, [notification]);

  // Render
  return (
    data && (
      <Container>
        <Type>{t(data.typeData?.displayName)}</Type>
        <Date>{moment(data.creationTime).add(user.t, 'minutes').format('llll')}</Date>
        <Detail>{data.nameKey}</Detail>
        <Detail>{data.descriptionKey}</Detail>
      </Container>
    )
  );
}

const Container = styled.div``;

const Type = styled.div``;

const Date = styled.div``;

const Detail = styled.div``;
