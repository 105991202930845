import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { IoFolder } from 'react-icons/io5';
import styled from 'styled-components';
import { storageDirectoryList } from '../../../services/api';
import { Input, Tree } from '../../globals';

export default function StorageDirectories({ onSelect, isRefresh, setIsRefresh }) {
  // Definitions
  const [data, setData] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState(null);
  const [autoExpandParent, setAutoExpandParent] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  const [state, setState] = useState({ list: true });

  // Hooks
  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (isRefresh) {
      load();
      setIsRefresh(false);
    }
  }, [isRefresh]);

  // Functions
  const load = () => {
    setState((x) => ({ ...x, list: false }));

    storageDirectoryList((status, res) => {
      if (status) {
        let list = [];

        let tree = (data) => {
          return data.map((x) => {
            let result = {
              key: x.path,
              title: x.name,
              children: x.storages?.length > 0 ? tree(x.storages) : null,
              icon: <IoFolder />,
            };

            list.push(result);

            return result;
          });
        };

        let result = [
          {
            key: '/',
            title: '/',
            children: res.data && tree(res.data),
            icon: <IoFolder />,
          },
        ];

        setData(result);
        setDataList(list);
      }

      setState((x) => ({ ...x, list: true }));
    });
  };

  const getParentTitle = (title, values) => {
    let parentTitle;

    values.forEach((x) => {
      if (x.children) {
        if (x.children.some((item) => item.key === title)) {
          parentTitle = x.key;
        } else if (getParentTitle(title, x.children)) {
          parentTitle = getParentTitle(title, x.children);
        }
      }
    });

    return parentTitle;
  };

  // Events
  const onExpand = (keys) => {
    setExpandedKeys(keys);
    setAutoExpandParent(false);
  };

  const onSearchChange = (event) => {
    let value = event.target.value;

    let expandedKeys = ['/'];

    if (value !== '') {
      expandedKeys = dataList
        .map((item) => (item.title.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1 ? getParentTitle(item.key, data) : null))
        .filter((item, i, self) => item && self.indexOf(item) === i);
    }

    setSearchValue(value);
    setAutoExpandParent(true);
    setExpandedKeys(expandedKeys);
  };

  return (
    <Container>
      <Input.Search
        placeholder={t('Search')}
        onChange={onSearchChange}
        disabled={state.list !== undefined ? !state.list : false}
        loading={state.list !== undefined ? !state.list : false}
        templates={['storage']}
        value={searchValue}
      />

      <Tree.Directory
        defaultExpandAll
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        expandAction="doubleClick"
        treeData={data}
        onExpand={onExpand}
        onSelect={onSelect}
        disabled={!state.list}
      />
    </Container>
  );
}

const Container = styled.div``;
