import { Popover } from 'antd';
import styled from 'styled-components';

export default function PopoverBase({ children, title, content, placement }) {
  return (
    <Container title={title} content={content} placement={placement ?? 'bottom'}>
      {children}
    </Container>
  );
}

const Container = styled(Popover)``;
