import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { MdLocationCity } from 'react-icons/md';
import { format } from 'react-string-format';
import { Button, Drawer, Element, Extra, Page, Select, Table } from '../../components/globals';
import { ActionType, CityColumns, CityInputs, CountryTypes } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { cityCreate, cityList, cityMultipleDeleteRestore, cityUpdate } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';

export default function Cities() {
  // Definitions
  const { t } = useTranslation();

  const [formRef] = Form.useForm();
  const [user] = useCacheUser();

  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisiblity, setPanelVisibility] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
  });

  const [pageState, setPageState] = useState({
    list: true,
    form: true,
    update: true,
    delete: true,
  });

  const isAllCountry = user.checkAction(ActionType.CityAllCountry);

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await cityList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onCreateClick = () => {
    setIsUpdate(false);
    formRef.resetFields();
    setPanelVisibility(true);
  };

  const onUpdateClick = (row) => {
    setIsUpdate(true);
    formRef.setFieldsValue(row);
    setPanelVisibility(true);
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, delete: false }));

    await cityMultipleDeleteRestore({ ids: selectedRowKeys, country: filter.country }, (status, res) => {
      if (status) {
        list();

        notifySuccess(format(t(filter.isDeleted ? 'DataRestoreSuccess' : 'DataDeleteSuccess'), <strong>{res.data}</strong>));
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    let request = model.id === undefined ? cityCreate : cityUpdate;

    await request(model, (status) => {
      if (status) {
        setPanelVisibility(false);
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));

        if (filter.country !== model.country) {
          setFilter((x) => ({ ...x, country: model.country }));
        } else {
          list();
        }
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  // Render
  return (
    <Page
      title={t('Cities')}
      icon={<MdLocationCity />}
      subTitle={
        user.checkAction(ActionType.CityCreate) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Cities'),
          breadcrumbName: t('Cities'),
          icon: <MdLocationCity />,
        },
      ]}
      templates={[isAllCountry ? 'default-1-filter-4' : 'default-0-filter-4']}
      extra={[
        isAllCountry && (
          <Select
            key="country-filter"
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            value={filter.country}
            templates={['filter']}
            allowClear={false}
          />
        ),
        <Extra
          key="default-filter"
          pageState={pageState}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onFilterChanged={() => setSelectedRowKeys([])}
          onDeleteClick={user.checkAction(ActionType.CityDelete) && onDeleteClick}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        selectedRowKeys={selectedRowKeys}
        onRowSelectChange={(values) => setSelectedRowKeys(values)}
        columns={CityColumns({
          isAllCountry,
          onUpdateClick: user.checkAction(ActionType.CityUpdate) && onUpdateClick,
          filter,
          setFilter,
        })}
      />

      <Drawer title={t(isUpdate ? 'Update' : 'AddNew')} visible={panelVisiblity} onClose={() => setPanelVisibility(false)}>
        <Element
          key="form"
          ready={pageState.form}
          formRef={formRef}
          onFinish={onFinish}
          inputs={CityInputs({ country: filter.country, isAllCountry, isUpdate })}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>
    </Page>
  );
}
