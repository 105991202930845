const UserRoleActionType = {
  List: 0,
  AllCountry: 1,
  All: 2,
  JourneyPage: 3,
  Create: 4,
  Update: 5,
  Delete: 6,
  Relation: 7,
  Parent: 8,
  Anonymize: 9,
  Viewer: 10
};

export default UserRoleActionType;