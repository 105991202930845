import { notification } from 'antd';
import i18next from 'i18next';
import { ExceptionType, UserRoleTypes } from '../constants';
import { replaceParameters } from './localization';

export const notifyResponseError = (res) => {
  if (typeof window !== 'undefined' && window.location.pathname.includes('logout')) {
    return;
  }

  let errors = res?.response?.data?.errors;

  if (errors) {
    errors.forEach((x) => {
      switch (x.type) {
        case ExceptionType.Validation:
          notifyError(i18next.t(x.message ?? 'ServerConnectionError'));
          break;

        case ExceptionType.Core:
        case ExceptionType.Default:
        case ExceptionType.Authentication:
        default:
          switch (x.code) {
            case 'UserParentRolesNotFoundException':
              let parentUserRoles = x.message
                .split(',')
                .map((x) => {
                  let userRoleData = UserRoleTypes.find((u) => u.value === parseInt(x));

                  return i18next.t(userRoleData.displayName);
                })
                .join(',');

              let parentUserRoleMessage = replaceParameters(i18next.t(x.code), {
                'UserRole.Names': parentUserRoles,
              });

              notifyError(parentUserRoleMessage);
              break;
            case 'DeliveryUserRelationNotFoundException':
              let deliveryUserRoles = x.message
                .split(',')
                .map((x) => {
                  let userRoleData = UserRoleTypes.find((u) => u.value === parseInt(x));

                  return i18next.t(userRoleData.displayName);
                })
                .join(',');

              let deliveryUserRoleMessage = replaceParameters(i18next.t(x.code), {
                'UserRole.Names': deliveryUserRoles,
              });

              notifyError(deliveryUserRoleMessage);
              break;

            case 'RoleNotFoundUserRoleException':
              let roleUserRoles = x.message
                .split(',')
                .map((x) => {
                  let userRoleData = UserRoleTypes.find((u) => u.value === parseInt(x));

                  return i18next.t(userRoleData.displayName);
                })
                .join(',');

              let roleUserRoleMessage = replaceParameters(i18next.t(x.code), {
                'UserRole.Names': roleUserRoles,
              });

              notifyError(roleUserRoleMessage);
              break;

            case 'RoleActionNotFoundException':
              if (x.message.includes('-')) {
                let roleActionMessages = x.message.split('-');

                let roleActionUserRoles = roleActionMessages[0]
                  .split(',')
                  .map((x) => {
                    let userRoleData = UserRoleTypes.find((u) => u.value === parseInt(x));

                    return i18next.t(userRoleData.displayName);
                  })
                  .join(',');

                let roleActionTypes = roleActionMessages[1]
                  .split(',')
                  .map((x) => i18next.t(x))
                  .join(',');

                let roleActionMessage = replaceParameters(i18next.t(x.code), {
                  'Role.Actions': roleActionTypes,
                  'Role.UserRoles': roleActionUserRoles,
                });

                notifyError(roleActionMessage);
              } else {
                notifyError(i18next.t('RoleActionNotFoundException'));
              }
              break;

            default:
              notifyError(i18next.t(x.code ?? 'ServerConnectionError'));
              break;
          }
          break;
      }
    });
  } else {
    notifyError(i18next.t('ServerConnectionError'));
  }
};

export const notifySuccess = (message, description) => {
  notification.success({ message, description, placement: 'bottomRight' });
};

export const notifyError = (message, description) => {
  notification.error({ message, description, placement: 'bottomRight' });
};

export const notifyInfo = (message, description) => {
  notification.info({ message, description, placement: 'bottomRight' });
};

export const notifyWarning = (message, description) => {
  notification.success({ message, description, placement: 'bottomRight' });
};
