import { Tooltip } from 'antd';
import ColumnBase from './_ColumnBase';

export default function DrugDailyColumns() {
  let columns = [
    {
      locale: 'Drug',
      name: 'drug',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Last24Hours',
      name: 'last24Hours',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'LastWeek',
      name: 'lastWeek',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'LastMonth',
      name: 'lastMonth',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
  ];

  return ColumnBase({ columns });
}
