import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function LinkBase({ children, style, to }) {
  return (
    <Container to={to} style={style}>
      {children}
    </Container>
  );
}

const Container = styled(Link)`
  color: ${(x) => x.theme.colors.deepKaomaru};
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: ${(x) => x.theme.colors.deepKaomaru};
    text-decoration: underline;
  }
`;
