import { Form, Tooltip } from 'antd';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle, FaTrash, FaTrashRestore } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import { AutoSizer, List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import styled, { css } from 'styled-components';
import { DrugType, TreatmentUsageStatusType } from '../../../../constants';
import { Button, Calendar, Card, Drawer, PopupConfirm } from '../../../globals';
import TreatmentUsage from './TreatmentUsage';

export default function TreatmentUsages({ selectedDrug, usages, setUsages, selectedUsage, setSelectedUsage }) {
  // Definitions
  const { t } = useTranslation();
  const [usageFormRef] = Form.useForm();

  const [selectedDate, setSelectedDate] = useState(usages[0].time);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [rowData, setRowData] = useState(null);

  // Hooks
  useEffect(() => {
    if (selectedUsage) {
      setSelectedDate(moment(selectedUsage.time));
      setSelectedIndex(selectedUsage.row);
    }
  }, [selectedUsage]);

  // Functions
  const getDoseText = (usage) => {
    switch (selectedDrug.value) {
      default:
      case DrugType.Evrysdi:
        return `${usage.dailyDoseMg}mg/${usage.dailyDoseMl}ml`;

      case DrugType.Enspryng:
        return `${usage.dailyDoseMg}mg`;
    }
  };

  // Events
  const onSelectUsage = (usage) => {
    setSelectedDate(moment(usage.time));
    setSelectedIndex(usage.row - 1);
  };

  const onSelectDate = (date) => {
    setSelectedDate(date);

    let usage = usages.find((x) => x.time.isSame(date, 'day'));
    if (usage) {
      setSelectedIndex(usage.row - 1);
    }
  };

  const onDeleteUsage = (usage) => {
    setUsages((u) => u.map((x) => ({ ...x, isDeleted: x.isDeleted || x.row === usage.row })));
  };

  const onRestoreUsage = (usage) => {
    setUsages((u) => u.map((x) => ({ ...x, isDeleted: x.row === usage.row ? false : x.isDeleted })));
  };

  const onClickUpdateUsage = (usage) => {
    setPanelVisibility(true);
    setRowData(usage);
  };

  const onFinishUpdateUsage = (model) => {
    setUsages((u) =>
      u.map((x) => {
        if (x.row === model.row) {
          x = { ...x, ...model };
        }

        return x;
      })
    );

    setSelectedUsage({ row: model.row, time: model.time });

    setPanelVisibility(false);
  };

  // Components
  const RenderDateCell = (value) => {
    let result = usages?.filter((x) => value.isSame(x.time, 'day'));

    return (
      <Events>
        {result?.map((x, i) => (
          <Event key={`event-${i}`}>
            <EventUsage status={x.status} isDeleted={x.isDeleted}>
              <EventUsageTitle>{t(selectedDrug?.displayName)} </EventUsageTitle>
              <EventUsageDose>{getDoseText(x)}</EventUsageDose>
            </EventUsage>
          </Event>
        ))}
      </Events>
    );
  };

  const UsageAction = ({ usage }) => {
    if (usage.isDeleted) {
      return (
        <PopupConfirm
          key="restore-confirm"
          title={t('AreYouSureWantToRestore')}
          onConfirm={() => onRestoreUsage(usage)}
          okText={t('Confirm')}
          cancelText={t('Cancel')}
          placement="top"
        >
          <Button templates={['colored', 'usage-icon']}>
            <FaTrashRestore />
          </Button>
        </PopupConfirm>
      );
    }

    switch (usage.status) {
      case TreatmentUsageStatusType.Pending:
        return (
          <Fragment>
            <Button templates={['colored', 'usage-icon', 'usage-icon-second']} onClick={() => onClickUpdateUsage(usage)}>
              <RiEdit2Fill />
            </Button>
            <PopupConfirm
              key="delete-confirm"
              title={t('AreYouSureWantToDelete')}
              onConfirm={() => onDeleteUsage(usage)}
              okText={t('Confirm')}
              cancelText={t('Cancel')}
              placement="top"
            >
              <Button templates={['colored', 'usage-icon']}>
                <FaTrash />
              </Button>
            </PopupConfirm>
          </Fragment>
        );

      case TreatmentUsageStatusType.Used:
        return (
          <Tooltip placement="top" title={t(usage.statusData.displayName)}>
            <UsageStatus status={usage.status}>
              <FaCheckCircle />
            </UsageStatus>
          </Tooltip>
        );

      case TreatmentUsageStatusType.NotUsed:
        return (
          <Tooltip placement="top" title={t(usage.statusData.displayName)}>
            <UsageStatus status={usage.status}>
              <FaCheckCircle />
            </UsageStatus>
          </Tooltip>
        );

      default:
        return <>{t('NotImplemented')}</>;
    }
  };

  const RenderRow = ({ index, key, style }) => {
    let usage = usages[index];

    return (
      <UsageListItem key={key} style={style}>
        <UsageItem selected={selectedDate.isSame(usage.time, 'day')}>
          <UsageDetail onClick={() => onSelectUsage(usage)} isDeleted={usage.isDeleted}>
            <UsageTime>{moment(usage.time).format('llll')}</UsageTime>
            <UsageDose>
              {t(selectedDrug?.displayName)} - {getDoseText(usage)}
            </UsageDose>
          </UsageDetail>

          <UsageAction usage={usage} />
        </UsageItem>
      </UsageListItem>
    );
  };

  // Render
  return (
    <Usages>
      <UsageContainer>
        <Card
          ready={true}
          templates={['widget', 'treatment-usage', 'with-header', 'header-information', 'header-colored', 'full-width']}
          information={t('TreatmentUsageInformation')}
          title={t('TreatmentUsages')}
        >
          {usages.length > 0 ? (
            <AutoSizer>
              {({ height, width }) => (
                <UsageList scrollToIndex={selectedIndex} height={height - 24} rowCount={usages.length} rowHeight={70} rowRenderer={RenderRow} width={width} />
              )}
            </AutoSizer>
          ) : (
            <UsageMessage>{t('PleaseEnterTreatmentDetails')}</UsageMessage>
          )}
        </Card>
      </UsageContainer>

      <CalendarContainer>
        <Calendar
          value={selectedDate}
          onSelect={onSelectDate}
          information={t('CalendarUsageInformation')}
          templates={['treatment-usage', 'full-width']}
          dateCellRender={RenderDateCell}
        />
      </CalendarContainer>

      <Drawer visible={panelVisibility} onClose={() => setPanelVisibility(false)} width={500}>
        <TreatmentUsage formRef={usageFormRef} selectedDrug={selectedDrug} data={rowData} onFinish={onFinishUpdateUsage} />
      </Drawer>
    </Usages>
  );
}

const Usages = styled.div`
  display: flex;

  @media only screen and (max-width: 1400px) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`;

const UsageMessage = styled.div`
  margin-top: 12px;
`;

const CalendarContainer = styled.div`
  width: 70%;
`;

const UsageContainer = styled.div`
  width: 30%;
  margin-right: 24px;

  @media only screen and (max-width: 1400px) {
    height: 600px;
    margin-bottom: 24px;
  }
`;

const UsageList = styled(List)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const UsageListItem = styled.div``;

const UsageItem = styled.div`
  position: relative;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
  width: calc(100% - 8px);
  transition: all 0.3s;

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${(x) => x.theme.colors.darkTurquoiseOpacity20};
    `}

  &:hover {
    transform: translateY(-0.5px);
    box-shadow: 2px 2px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;
    border-radius: ${(x) => x.theme.global.borderRadius};
  }
`;

const UsageDetail = styled.div`
  width: calc(100% - 70px);

  ${({ isDeleted }) =>
    isDeleted &&
    css`
      opacity: 0.5;
    `}
`;

const UsageTime = styled.div`
  font-weight: bold;

  @media only screen and (max-width: 1600px) {
    font-size: 12px;
  }
`;

const UsageDose = styled.div`
  @media only screen and (max-width: 1600px) {
    font-size: 10px;
  }
`;

const Events = styled.div`
  height: 100%;
  width: 100%;
`;

const Event = styled.div`
  min-height: 25px;
`;

const EventUsage = styled.div`
  color: ${(x) => x.theme.colors.white};
  background-color: ${(x) => x.theme.colors.darkTurquoise};
  font-size: 12px;
  padding: 6px;
  border-radius: 10px;

  ${({ status }) => {
    switch (status) {
      case TreatmentUsageStatusType.NotUsed:
        return css`
          background-color: ${(x) => x.theme.colors.rustyRed};
        `;
      case TreatmentUsageStatusType.Used:
        return css`
          background-color: ${(x) => x.theme.colors.mediumSpringGreen};
        `;
      case TreatmentUsageStatusType.Pending:
        return css`
          background-color: ${(x) => x.theme.colors.darkTurquoise};
        `;
      default:
        return css`
          background-color: ${(x) => x.theme.colors.deepKaomaru};
        `;
    }
  }}

  ${({ isDeleted }) =>
    isDeleted &&
    css`
      opacity: 0.5;
    `}
`;

const EventUsageTitle = styled.div`
  font-weight: bold;
`;

const EventUsageDose = styled.div`
  font-size: 10px;

  @media only screen and (max-width: 1700px) {
    font-size: 8px;
  }
`;

const UsageStatus = styled.div`
  width: 30px;
  height: 30px;
  padding: 0px;
  font-size: 12px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  ${({ status }) => {
    switch (status) {
      case TreatmentUsageStatusType.NotUsed:
        return css`
          background-color: ${(x) => x.theme.colors.rustyRed};
        `;
      case TreatmentUsageStatusType.Used:
        return css`
          background-color: ${(x) => x.theme.colors.mediumSpringGreen};
        `;
      default:
        return css`
          background-color: ${(x) => x.theme.colors.deepKaomaru};
        `;
    }
  }}
`;
