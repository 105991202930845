import { Checkbox } from 'antd';
import styled, { css } from 'styled-components';

export default function CheckboxBase({ children, style, checked, defaultChecked, onChange, disabled, templates, ...restProps }) {
  return (
    <CheckboxContainer checked={checked} style={style} defaultChecked={defaultChecked} onChange={onChange} disabled={disabled} templates={templates} {...restProps}>
      {children}
    </CheckboxContainer>
  );
}

CheckboxBase.Group = ({ value, options, children, style, onChange, disabled, templates }) => (
  <GroupCheckboxContainer value={value} style={style} options={options} onChange={onChange} disabled={disabled} templates={templates}>
    {children}
  </GroupCheckboxContainer>
);

const Bold = css`
  ${({ templates }) =>
    templates?.includes('bold') &&
    css`
      font-weight: bold;
    `}
`;

const Multiple = css`
  ${({ templates }) =>
    templates?.includes('multiple') &&
    css`
      margin-bottom: 8px;

      &.ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0px;
      }
    `}
`;

const CheckboxContainer = styled(Checkbox)`
  display: flex;
  align-items: center;

  .ant-checkbox {
    margin-bottom: 4px;

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      border-radius: ${(x) => x.theme.global.xxxlargeBorderRadius};
      &::after {
        left: 8px;
        top: 5px;
        transition: all 0.2s;
        transform: rotate(45deg) scale(1);
      }
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${(x) => x.theme.colors.darkTurquoise};
        border-color: ${(x) => x.theme.colors.darkTurquoise};
      }

      &::after {
        display: none;
      }
    }
  }

  &:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${(x) => x.theme.colors.darkTurquoise};
  }

  ${Bold}
  ${Multiple}
`;

const GroupCheckboxContainer = styled(Checkbox.Group)`
  .ant-checkbox {
    top: 0px;

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      border-radius: ${(x) => x.theme.global.xxxlargeBorderRadius};
      &::after {
        left: 8px;
        top: 5px;
        transition: all 0.2s;
        transform: rotate(45deg) scale(1);
      }
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${(x) => x.theme.colors.darkTurquoise};
        border-color: ${(x) => x.theme.colors.darkTurquoise};
      }

      &::after {
        display: none;
      }
    }
  }

  &:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${(x) => x.theme.colors.darkTurquoise};
  }

  ${Bold}
  ${Multiple}
`;
