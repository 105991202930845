import { Input } from 'antd';
import styled from 'styled-components';

const { Search } = Input;

export default function SearchInputBase({ style, placeholder, onChange, ready, ...restProps }) {
  return (
    <Container
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      disabled={ready !== undefined ? !ready : false}
      loading={ready !== undefined ? !ready : false}
      {...restProps}
    />
  );
}

const Container = styled(Search)``;
